import classNames from 'classnames';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { showVerifyEmailDialog } from 'src/ui/features/ui';
import { isUnverifiedUser } from 'src/ui/selectors/isUnverifiedUser';
import { copy } from 'src/ui/templates/copy';

export const UnverifiedEmailWarning: React.VFC = () => {
  const isUnverified = useAppSelector(isUnverifiedUser());
  const location = useLocation();
  const isGetStarted = Boolean(location.pathname?.startsWith('/get-started'));
  const displayWarning = isUnverified && isGetStarted;
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(showVerifyEmailDialog());
  };

  return displayWarning ? (
    <button
      className={classNames('sd-warning', 'sd-warning__clickable')}
      type="button"
      onClick={handleClick}
    >
      {copy.errors.unverified}
    </button>
  ) : null;
};
