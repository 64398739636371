export const ALIAS_GRAPH = 'tag:stardog:api:graph:aliases';
export const CREATE_GRAPHS_ALIAS_QUERY = (graph: string) => `INSERT DATA {
    graph <${ALIAS_GRAPH}> {
        <urn:databricks:demos:${graph}> <tag:stardog:api:graph:alias> <urn:databricks:demos:tpch:vg:${graph}> .
    }
}
`;

export const MBASE = 'urn:stardog:modules:';
export const MPREFIXES = `prefix : <${MBASE}>
prefix m: <${MBASE}>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix sh: <http://www.w3.org/ns/shacl#>
`;

export const CREATE_README_QUERY = (graph: string) => `${MPREFIXES}
  INSERT DATA {
  graph <urn:mp:stardog:demos:databricks:${graph}:1.0> {
  <urn:mp:stardog:demos:databricks:${graph}:1.0> <urn:stardog:modules:readme> " https://docs.microsoft.com/en-us/azure/databricks/sql/get-started/sample-dashboards" .
  }
}
`;

export const NAMESPACES = `prefix stardog: <tag:stardog:api:>
prefix catalog: <tag:stardog:api:catalog:>
prefix m: <urn:stardog:modules:>

prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix qudt: <http://qudt.org/schema/qudt/>
prefix unit: <http://qudt.org/vocab/unit/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix prov: <http://www.w3.org/ns/prov#>
prefix void: <http://rdfs.org/ns/void#>

prefix prov: <http://www.w3.org/ns/prov#>
prefix void: <http://rdfs.org/ns/void#>`;
