/* istanbul ignore file: just rendering a button */
import { Classes } from '@blueprintjs/core';
import * as React from 'react';

import { copy } from 'src/ui/templates/copy';
import oktaLogo from 'src/ui/templates/images/okta-logo.svg';
import { doOktaRedirect } from 'src/ui/utils/window';

/**
 * This uses just a plain button as we use an image to display the actual
 * button. We don't want this to be styled by blueprintjs.
 */
export const OktaLink: React.VFC = () => {
  return (
    <button
      type="button"
      onClick={doOktaRedirect}
      className={`${Classes.BUTTON} oauth`}
    >
      <img src={oktaLogo} alt={copy.okta.label} />
    </button>
  );
};
