import { Card, Elevation, H5 } from '@blueprintjs/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { ActionLink } from 'src/ui/components/ActionLink';
import {
  DASHBOARD_INVITE_USERS,
  DASHBOARD_MANAGE_DATABRICKS,
  DASHBOARD_MANAGE_ENDPOINTS,
} from 'src/ui/constants/testIds';
import * as copy from 'src/ui/templates/copy';

interface QuickActionsProps {
  onInviteUsers: () => void;
  showInviteUsers: boolean;
}

export const QuickActions: React.VFC<QuickActionsProps> = ({
  onInviteUsers,
  showInviteUsers,
}) => {
  const {
    inviteUsers,
    // exploreCatalog,
    // startProject,
    manageEndpoints,
    manageDatabricks,
  } = copy.components.dashboard.quickActions;

  return (
    <Card elevation={Elevation.ONE} className="dashboard-quick-actions">
      <H5>{copy.components.dashboard.quickActionsTitle}</H5>
      {showInviteUsers ? (
        <div className="action-row">
          <ActionLink
            testId={DASHBOARD_INVITE_USERS}
            onClick={() => onInviteUsers()}
          >
            {inviteUsers}
          </ActionLink>
        </div>
      ) : null}
      {/* <div className="action-row">
        <ExternalLink href="">{exploreCatalog}</ExternalLink>
      </div>
      <div className="action-row">
        <ExternalLink href="">{startProject}</ExternalLink>
      </div> */}
      <div className="action-row">
        <Link data-testid={DASHBOARD_MANAGE_ENDPOINTS} to="/connections">
          {manageEndpoints}
        </Link>
      </div>
      <div className="action-row">
        <Link
          data-testid={DASHBOARD_MANAGE_DATABRICKS}
          to="/databricks-partner-home"
        >
          {manageDatabricks}
        </Link>
      </div>
    </Card>
  );
};
