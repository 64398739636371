/**
 * Parse a url if valid and return the host without port otherwise
 * just returns the url unchanged.
 */
export const getHostnameFromUrl = (url: string) => {
  try {
    const parsed = new URL(url);
    return parsed.hostname;
  } catch (err) {
    return url;
  }
};
