import { AnchorButton, H2, H4, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  LAUNCHPAD_ADD_BASIC_CONNECTION_BUTTON,
  LAUNCHPAD_ADD_SSO_CONNECTION_BUTTON,
} from 'src/ui/constants/testIds';
import { Welcome } from 'src/ui/containers/landing/Welcome';
import { showAddNewDialog, showAddSSOConnection } from 'src/ui/features/ui';
import * as copy from 'src/ui/templates/copy';

export const NewUserLanding: React.VFC = () => {
  const dispatch = useAppDispatch();

  const handleAddConnection = () => dispatch(showAddNewDialog());
  const handleAddSSOConnection = () => dispatch(showAddSSOConnection());

  return (
    <>
      <Welcome
        className="welcome-container-voicebox"
        titleText={copy.components.launchpad.dashboard.welcome}
        subText=""
      />
      <div className="sd-landing-new-user-launchpad">
        <Icon icon={IconNames.OFFLINE} size={80} />
        <H2>{copy.components.launchpad.emptyState.title}</H2>
        <div className="sd-landing-new-user-actions">
          <H4>{copy.components.launchpad.emptyState.description}</H4>
          <div className="new-connection-section">
            <AnchorButton
              data-testid={LAUNCHPAD_ADD_BASIC_CONNECTION_BUTTON}
              icon={IconNames.Plus}
              intent={Intent.PRIMARY}
              onClick={handleAddConnection}
            >
              {copy.components.launchpad.connections.addBasic}
            </AnchorButton>
            <AnchorButton
              data-testid={LAUNCHPAD_ADD_SSO_CONNECTION_BUTTON}
              icon={IconNames.LOG_IN}
              intent={Intent.PRIMARY}
              onClick={handleAddSSOConnection}
            >
              {copy.components.launchpad.connections.addSso}
            </AnchorButton>
          </div>
        </div>
      </div>
    </>
  );
};
