import { Add, Chat } from '@carbon/icons-react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NavbarItem, NavbarSubItem } from 'vet-bones/components';

import { useAppSelector } from 'src/ui/app/hooks';
import {
  NAVBAR_VOICEBOX_ADD_NEW_BUTTON,
  NAVBAR_VOICEBOX_CHAT_LIST_BUTTON,
  NAVBAR_VOICEBOX_CHAT_LIST_LATEST_ITEM,
} from 'src/ui/constants/testIds';
import { useVoiceboxConversations } from 'src/ui/containers/voicebox/aside/useVoiceboxConversations';
import {
  NavbarAsideType,
  startedNewVoiceboxConversation,
  toggledNavbarAside,
} from 'src/ui/features/ui';
import { useCurrentConnection } from 'src/ui/hooks/connection';
import { useVoiceboxRouteParams } from 'src/ui/hooks/navbar/routes';
import * as copy from 'src/ui/templates/copy';

export const useVoiceboxNavbarItems = (): NavbarItem[] => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: connectionIdx, conversation: conversationId } =
    useVoiceboxRouteParams() || {};

  const { connection } = useCurrentConnection(connectionIdx);
  const conversations = useVoiceboxConversations(connection?.id || '');
  const conversationItems = conversations.items;

  const handleAddNew = React.useCallback(() => {
    dispatch(startedNewVoiceboxConversation());
    dispatch(toggledNavbarAside({ isAsideOpen: false }));
    history.push(`/u/${connectionIdx}/voicebox/`);
  }, [connectionIdx, dispatch, history]);

  const isChatListAsideOpen = useAppSelector(
    (state) =>
      state.ui.navbar.aside === NavbarAsideType.VOICEBOX_CHAT_LIST &&
      state.ui.navbar.isAsideOpen
  );
  const handleVoiceboxChatListAside = React.useCallback(() => {
    dispatch(
      toggledNavbarAside({
        aside: NavbarAsideType.VOICEBOX_CHAT_LIST,
        isAsideOpen: !isChatListAsideOpen,
      })
    );
  }, [dispatch, isChatListAsideOpen]);

  const handleVoiceboxChatListItem = React.useCallback(
    (nextConverationId: string) => {
      dispatch(toggledNavbarAside({ isAsideOpen: false }));
      history.push(`/u/${connectionIdx}/voicebox/${nextConverationId}`);
    },
    [connectionIdx, dispatch, history]
  );

  return React.useMemo(() => {
    const items: NavbarItem[] = [
      {
        active: !conversationId,
        icon: Add,
        label: copy.components.navbar.voicebox.addNew,
        onClick: handleAddNew,
        testId: NAVBAR_VOICEBOX_ADD_NEW_BUTTON,
      },
    ];

    if (conversationItems.length) {
      const subItems: NavbarSubItem[] = [];
      conversationItems.slice(0, 3).forEach((conversation, converationIdx) => {
        subItems.push({
          active: conversation.value === conversationId,
          label: conversation.text,
          onClick: () => handleVoiceboxChatListItem(conversation.value),
          testId: NAVBAR_VOICEBOX_CHAT_LIST_LATEST_ITEM(
            conversation.value,
            converationIdx
          ),
        });
      });
      items.push({
        active: isChatListAsideOpen,
        icon: Chat,
        label: copy.components.navbar.voicebox.chatList,
        onClick: handleVoiceboxChatListAside,
        subItemsLabel: copy.components.navbar.voicebox.chatListItems,
        subItems,
        testId: NAVBAR_VOICEBOX_CHAT_LIST_BUTTON,
      });
    }

    return items;
  }, [
    conversationId,
    conversationItems,
    handleAddNew,
    handleVoiceboxChatListAside,
    handleVoiceboxChatListItem,
    isChatListAsideOpen,
  ]);
};
