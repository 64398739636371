import { NonIdealState, NonIdealStateProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { components } from 'src/ui/templates/copy';

export const NotFound: React.VFC<NonIdealStateProps> = ({
  icon = IconNames.DIAGNOSIS,
  title = components.notFound.title,
  description = components.notFound.description,
}: NonIdealStateProps) => {
  return (
    <div className="sd-container">
      <NonIdealState icon={icon} title={title} description={description} />
    </div>
  );
};
