import * as React from 'react';
import { useQuery } from 'react-query';
import semver from 'semver';
import { server as StardogServer } from 'stardog';
import { getStardogConnection } from 'vet-bones/utils';

import { useCurrentConnection, useProfile } from 'src/ui/hooks/connection';
import { useConnectionIdx } from 'src/ui/hooks/navbar/connection';
import { runWithRefetchConnectionToken } from 'src/ui/hooks/refetchConnectionToken';

const useNavbarSupportQuery = () => {
  const connectionIdx = useConnectionIdx();
  const { connection } = useCurrentConnection(connectionIdx);
  const stardogConnection = connection
    ? getStardogConnection(connection)
    : undefined;

  return useQuery<{ serverVersion: string; voiceboxLimit: number } | null>(
    `useNavbarSupportQuery-${connection ? connection.index : null}`,
    async () => {
      if (!connection || !stardogConnection) {
        return null;
      }

      try {
        const response = await runWithRefetchConnectionToken(
          stardogConnection,
          Number(connection.index),
          (stardogConn) =>
            StardogServer.status(stardogConn, {
              databases: false,
            })
        );

        if (!response.ok) {
          console.error(
            `problem requesting useNavbarSupportQuery: ${
              response.statusText || String(response.status)
            }`
          );
          return null;
        }

        return {
          serverVersion: response.body?.['dbms.version']?.value || '0.0.0',
          voiceboxLimit:
            response.body?.['dbms.license.voicebox.limit']?.value || 0,
        };
      } catch (err) {
        console.error(`problem requesting useNavbarSupportQuery: ${err}`);
        return null;
      }
    },
    { initialData: null }
  );
};

export const useNavbarSupport = () => {
  const { data: profileData } = useProfile();
  const { data: navbarSupportData } = useNavbarSupportQuery();

  const serverVersion = navbarSupportData?.serverVersion || '0.0.0';
  const voiceboxLimit = navbarSupportData?.voiceboxLimit || 0;

  const isKnowledgeCatalogSupported = semver.gte(serverVersion, '9.0.0');
  const isVoiceboxSupported =
    profileData?.profile?.is_voicebox_enabled &&
    Boolean(voiceboxLimit) &&
    semver.gte(serverVersion, '10.0.0');

  return React.useMemo(
    () => ({
      isKnowledgeCatalogSupported,
      isVoiceboxSupported,
    }),
    [isKnowledgeCatalogSupported, isVoiceboxSupported]
  );
};
