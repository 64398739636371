import { Icon, IconName } from '@blueprintjs/core';
import * as React from 'react';

import { AnyFunc } from 'src/ui/constants/types';

export type OwnProps = {
  onClick: AnyFunc;
  testId?: string;
  icon?: IconName;
};

export const ActionLink: React.FC<OwnProps> = ({
  children,
  onClick,
  testId,
  icon,
}) => {
  const action = icon ? <Icon icon={icon} /> : null;
  return (
    <button
      className="inline-link link-style"
      data-testid={testId}
      onClick={onClick}
      type="button"
    >
      {action}
      {children}
    </button>
  );
};
