const idCounter: Record<string, number> = {};

/**
 * Generates a unique ID. If `prefix` is given, the ID is appended to it.
 *
 * @param {string} [prefix=''] The value to prefix the ID with.
 * @returns Returns the unique ID.
 * @example
 *
 * uniqueId('contact_')
 * // => 'contact_104'
 *
 * uniqueId()
 * // => '105'
 */
export const uniqueId = (prefix = 'unique_'): string => {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0;
  }

  /* eslint-disable no-plusplus */
  const id = ++idCounter[prefix];

  return `${prefix}${id}`;
};
