import { Flash } from '@carbon/icons-react';
import * as React from 'react';
import { NavbarDropdownItem, NavbarSchema } from 'vet-bones/components';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { NAVBAR_PORTAL_ENDPOINT_BUTTON } from 'src/ui/constants/testIds';
import { useLaunchpadNavbarAvatarSchema } from 'src/ui/containers/launchpad/navbar/avatar';
import { NavbarAsideType, toggledNavbarAside } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useSavedConnectionsQuery } from 'src/ui/graph/types';
import {
  useKnowledgeCatalogNavbarDropdownItem,
  usePortalNavbarDropdownItem,
  useVoiceboxNavbarDropdownItem,
} from 'src/ui/hooks/navbar/dropdown';
import { useNavbarExpandSchema } from 'src/ui/hooks/navbar/expand';
import { useNavbarLearnStardogSchema } from 'src/ui/hooks/navbar/learnStardog';
import { useNavbarSupport } from 'src/ui/hooks/navbar/support';
import { useVoiceboxNavbarItems } from 'src/ui/hooks/navbar/voicebox';
import * as copy from 'src/ui/templates/copy';

export const useLaunchpadLandingNavbarSchema = (): NavbarSchema => {
  const dispatch = useAppDispatch();

  const dropdownSelectedItem = usePortalNavbarDropdownItem();
  const voiceboxItem = useVoiceboxNavbarDropdownItem();
  const knowledgeCatalogItem = useKnowledgeCatalogNavbarDropdownItem();
  const {
    isKnowledgeCatalogSupported,
    isVoiceboxSupported,
  } = useNavbarSupport();

  const dropdownItems = React.useMemo(() => {
    const items: NavbarDropdownItem[] = [];
    if (isVoiceboxSupported) {
      items.push(voiceboxItem);
    }
    if (isKnowledgeCatalogSupported) {
      items.push(knowledgeCatalogItem);
    }
    return items;
  }, [
    isKnowledgeCatalogSupported,
    isVoiceboxSupported,
    knowledgeCatalogItem,
    voiceboxItem,
  ]);

  const { data: savedConnectionsData } = useSavedConnectionsQuery(
    graphQLClient
  );
  const hasEndpoints = savedConnectionsData?.listConnections?.length;

  const isEndpointAsideOpen = useAppSelector(
    (state) =>
      state.ui.navbar.aside === NavbarAsideType.PORTAL_ENDPOINT &&
      state.ui.navbar.isAsideOpen
  );
  const handlePortalEndpointAside = React.useCallback(() => {
    dispatch(
      toggledNavbarAside({
        aside: NavbarAsideType.PORTAL_ENDPOINT,
        isAsideOpen: !isEndpointAsideOpen,
      })
    );
  }, [dispatch, isEndpointAsideOpen]);

  const expand = useNavbarExpandSchema();
  const learnStardog = useNavbarLearnStardogSchema(true);
  const avatar = useLaunchpadNavbarAvatarSchema();

  return React.useMemo(
    () => ({
      dropdown: {
        items: dropdownItems,
        selectedItem: dropdownSelectedItem,
      },
      expand,
      secondaryItems: hasEndpoints
        ? [
            {
              active: isEndpointAsideOpen,
              icon: Flash,
              label: copy.components.navbar.landing.endpoint,
              onClick: handlePortalEndpointAside,
              testId: NAVBAR_PORTAL_ENDPOINT_BUTTON,
            },
          ]
        : [],
      tertiaryItems: [learnStardog],
      footer: { avatar },
    }),
    [
      avatar,
      dropdownItems,
      dropdownSelectedItem,
      expand,
      handlePortalEndpointAside,
      hasEndpoints,
      isEndpointAsideOpen,
      learnStardog,
    ]
  );
};

export const useLaunchpadDefaultNavbarSchema = (): NavbarSchema => {
  const dropdownSelectedItem = usePortalNavbarDropdownItem();

  const expand = useNavbarExpandSchema();
  const learnStardog = useNavbarLearnStardogSchema(true);
  const avatar = useLaunchpadNavbarAvatarSchema();

  return React.useMemo(
    () => ({
      dropdown: {
        items: [],
        selectedItem: dropdownSelectedItem,
      },
      expand,
      tertiaryItems: [learnStardog],
      footer: { avatar },
    }),
    [avatar, dropdownSelectedItem, expand, learnStardog]
  );
};

export const useLaunchpadVoiceboxNavbarSchema = (): NavbarSchema => {
  const dropdownSelectedItem = useVoiceboxNavbarDropdownItem();
  const portalItem = usePortalNavbarDropdownItem();
  const knowledgeCatalogItem = useKnowledgeCatalogNavbarDropdownItem();
  const { isKnowledgeCatalogSupported } = useNavbarSupport();

  const dropdownItems = React.useMemo(() => {
    const items: NavbarDropdownItem[] = [portalItem];
    if (isKnowledgeCatalogSupported) {
      items.push(knowledgeCatalogItem);
    }
    return items;
  }, [isKnowledgeCatalogSupported, knowledgeCatalogItem, portalItem]);

  const secondaryItems = useVoiceboxNavbarItems();

  const expand = useNavbarExpandSchema();
  const learnStardog = useNavbarLearnStardogSchema(true);
  const avatar = useLaunchpadNavbarAvatarSchema();

  return React.useMemo(
    () => ({
      dropdown: {
        items: dropdownItems,
        selectedItem: dropdownSelectedItem,
      },
      expand,
      secondaryItems,
      tertiaryItems: [learnStardog],
      footer: { avatar },
    }),
    [
      avatar,
      dropdownItems,
      dropdownSelectedItem,
      expand,
      learnStardog,
      secondaryItems,
    ]
  );
};
