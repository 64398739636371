import { Card, CardProps, Elevation } from '@blueprintjs/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { VoiceboxLogo } from 'vet-bones/components';

import { KIT_VOICEBOX_ICON } from 'src/ui/constants/testIds';
import { components } from 'src/ui/templates/copy';

export interface NonModule {
  id: string;
  name: string;
  description: string;
  link: string;
}

interface OwnProps extends CardProps {
  module: NonModule;
}

export const NonKitCard: React.VFC<OwnProps> = ({ module }) => {
  return (
    <Link to={module.link} className="sd-link-undecorated">
      <Card
        className="kits-card"
        data-testid={module.id}
        interactive
        elevation={Elevation.ONE}
      >
        <div className="kits-card-top">
          <div className="kits-title">
            <span className="kits-name page-overview">{module.name}</span>
            <VoiceboxLogo
              className="kits-icon"
              data-testid={KIT_VOICEBOX_ICON}
              size={20}
              title={components.marketplace.voiceboxKits}
            />
          </div>
          <span className="kits-description page-text">
            {module.description}
          </span>
        </div>
        <div className="kits-tags kits-card-bottom" />
      </Card>
    </Link>
  );
};
