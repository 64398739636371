import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import Stardog, {
  Connection as StardogConnection,
  dataSources as StardogDataSources,
  db as StardogDb,
  query as StardogQuery,
  virtualGraphs as StardogVirtualGraphs,
} from 'stardog';
import { BaseConnection, getStardogConnection } from 'vet-bones/utils';

import { DEMO_KIT_DB_DEFAULT } from 'src/ui/features/connection';
import {
  Connection as GraphConnection,
  MarketplaceSettings,
} from 'src/ui/graph/types';
import {
  ConnectionFactory,
  MarketplaceOptions,
  create,
  createWithConnection,
} from 'src/ui/hooks/ConnectionFactory';
import { runWithRefetchConnectionToken } from 'src/ui/hooks/refetchConnectionToken';
import {
  ADD_ALIAS_DATA,
  GET_DATASET_META,
  GET_DATASET_THEMES,
  GET_DATA_SOURCE_OPTIONS,
  GET_MODULE_CONTRIBUTORS,
  GET_MODULE_DATA_GRAPHS,
  GET_MODULE_EXAMPLES_PROMPTS,
  GET_MODULE_GRAPHS,
  GET_MODULE_GRAPHS_FOR_MAIN_SCHEMA,
  GET_MODULE_LINKS,
  GET_MODULE_PUBLISHERS,
  GET_MODULE_RELATED,
  GET_MODULE_SCHEMAS_WITH_GRAPHS,
  GET_MODULE_STORED_QUERIES,
  GET_MODULE_TAGS,
  GET_MODULE_TYPES,
  GET_MODULE_VGS,
  LIST_MODULES,
  LIST_MODULE_TAGS,
  LOAD_MODULE,
  MODULE_OVERVIEW,
  MODULE_OVERVIEW_IRI,
  MPREFIXES,
  REMOVE_ALIAS_DATA,
  SEARCH_FOR_MODULES,
} from 'src/ui/templates/modules';

export interface Link {
  text: string;
  url: string;
}

export interface Entity {
  iri: string;
  label: string;

  id?: string;
}

export interface Tag extends Entity {
  icon?: string;
}

export interface Subject extends Entity {
  broader?: string;
}

export interface Module {
  id: string;
  name: string;

  // todo: should be required?
  iri?: string;
  description?: string;
  version?: string;

  primaryConcept?: string;
  databaseId?: string;
  schemaName?: string;
  subject?: string;
  initialSearch?: string;
  alias?: string;

  readme?: string;
  licenseName?: string;
  license?: string;
  model?: string;
  icon?: string;

  lastModified?: string;
  type?: string;
}

export interface GraphMeta extends Entity {
  description?: string;
  size?: string;

  search?: string;

  primaryConcept?: Entity;
  concepts?: Entity[];
}

export interface GraphProv {
  generatedAtTime?: string;
  wasGeneratedBy?: Entity;

  // prov:wasAttributedTo
  author?: Entity;
}

export interface ModuleExt {
  publishers: Entity[];
  contributors: Entity[];
  types: Entity[];
  tags: Tag[];
  links: Link[];
  related: Module[];
  graphMeta: GraphMeta[];
  virtualGraphs: VirtualGraph[];
  examplePrompts: string[];
  voiceboxEnabled: boolean;
}

export interface ModuleAndMeta {
  module?: Module | null;
  moduleMeta?: ModuleExt | null;
}

export interface ModuleFilters {
  type?: string;
  subjects?: string[];
  tags?: string[];
}

export interface SchemaGraph {
  schema: string;
  graph: string;
}

// Based on StoredQueryOptions, but some fields are optional or removed
export interface StoredQuery {
  name: string;
  query: string;
  shared?: boolean;
  reasoning?: boolean;
  description?: boolean;
}

export interface VirtualGraph {
  id: string;
  name: string;
  source: DataSource;
  mapping: Mapping;
}

export type DataSourceOptions = {
  'unique.key.sets'?: string;
  'parser.sql.quoting'?: string;
  'jdbc.driver'?: string;
  'jdbc.url'?: string;
  'jdbc.username'?: string;
  'jdbc.password'?: string;
  'sql.dialect'?: string;
  'sql.defaultCatalog'?: string;
  'sql.catalogs'?: string;
  'sql.defaultSchema'?: string;
  'sql.schemas'?: string;
  'mongodb.uri'?: string;
  'elasticsearch.rest.urls'?: string;
  'elasticsearch.indexes'?: string;
  'elasticsearch.username'?: string;
  'elasticsearch.password'?: string;
  'cassandra.contact.point'?: string;
  'cassandra.port'?: string;
  'cassandra.keyspace'?: string;
  'cassandra.username'?: string;
  'cassandra.password'?: string;
  'cassandra.allow.filtering'?: string;
  'sparql.url'?: string;
  'sparql.username'?: string;
  'sparql.password'?: string;
  'sparql.graphname'?: string;
  'sparql.stats.based.optimization'?: string;

  [key: string]: string | undefined;
};

export interface DataSource {
  id: string;
  name: string;
  type: string;
  options: DataSourceOptions;
}

export interface Mapping {
  id: string;
  code: string;
  syntax: 'R2RML' | 'SMS2' | 'STARDOG' | string;
}

export const viewExplorerURL = (module: Module, conn: number, db: string) => {
  const cls = module.primaryConcept
    ? `&class=${encodeURIComponent(module.primaryConcept)}`
    : '';

  const schema = module.schemaName
    ? `&model=${encodeURIComponent(module.schemaName)}`
    : '';

  const graph = `&graph=${encodeURIComponent(
    module.alias || 'tag:stardog:api:context:local'
  )}`;

  return `/u/${conn}/explorer/#/graph?db=${db}&query=${encodeURIComponent(
    module.initialSearch || ''
  )}${graph}${cls}${schema}`;
};

export const viewStudioURL = (module: Module, conn: number, db: string) => {
  const model = module.schemaName
    ? `&model=${encodeURIComponent(module.schemaName)}`
    : '';

  const graph = `&graph=${encodeURIComponent(
    module.alias || 'tag:stardog:api:context:local'
  )}`;

  const moduleId = `&module=${encodeURIComponent(module.id)}`;

  return `/u/${conn}/studio/#/?db=${db}${graph}${model}${moduleId}`;
};

const repo = async (
  connection: GraphConnection,
  db: string,
  marketplaceConfig: MarketplaceOptions
): Promise<ModuleRepository> => {
  try {
    const stardogConnection = getStardogConnection(
      connection as BaseConnection
    );
    return new ModuleRepository(
      createWithConnection(stardogConnection, connection.index, {
        database: db,
        endpoint: connection.endpoint,
        username: connection.username || '',
      }),
      getMarketplace(marketplaceConfig)
    );
  } catch (e) /* istanbul ignore next */ {
    console.warn(e);
    return Promise.reject();
  }
};

export const addDataSource = async (
  connection: GraphConnection,
  dataSource: DataSource
): Promise<Stardog.HTTP.Body> => {
  const stardogConnection = getStardogConnection(connection as BaseConnection);

  const resp = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) =>
      StardogDataSources.add(stardogConn, dataSource.name, dataSource.options)
  );

  return resp;
};

export const uninstall = async (
  connection: GraphConnection | null | undefined,
  db: string,
  module: Module,
  marketplaceConfig: MarketplaceOptions
): Promise<boolean> => {
  if (!connection || connection.isAllocating) {
    return false;
  }

  try {
    const r = await repo(connection, db, marketplaceConfig);
    return r.remove(module.id);
  } catch (e) /* istanbul ignore next */ {
    console.warn(e);
    return false;
  }
};

export const install = async (
  connection: GraphConnection | null | undefined,
  db: string,
  module: Module,
  marketplaceConfig: MarketplaceOptions
): Promise<boolean> => {
  if (!connection || connection.isAllocating) {
    return false;
  }

  try {
    const r = await repo(connection, db, marketplaceConfig);
    return r.add(module.id);
  } catch (e) /* istanbul ignore next */ {
    console.warn(e);
    return false;
  }
};

export const listModules = async (
  connection: GraphConnection | null | undefined,
  db: string | null | undefined,
  marketplaceConfig: MarketplaceOptions
): Promise<Module[]> => {
  if (!connection || connection.isAllocating || !db) {
    return [];
  }

  try {
    const r = await repo(connection, db, marketplaceConfig);
    return r.list();
  } catch (e) /* istanbul ignore next */ {
    console.warn(e);
    return [];
  }
};

export const searchModules = async (
  token: string,
  filters: ModuleFilters,
  marketplaceConfig: MarketplaceOptions
): Promise<Module[]> => {
  const marketplace = getMarketplace(marketplaceConfig);
  try {
    return marketplace.search(token, filters);
  } catch (e) /* istanbul ignore next */ {
    console.warn(e);
    return [];
  }
};

export const listModuleTags = async (
  marketplaceConfig: MarketplaceOptions
): Promise<Tag[]> => {
  const marketplace = getMarketplace(marketplaceConfig);
  const db = marketplace.connectionFactory.options.database;

  const results = await runWithRefetchConnectionToken(
    marketplace.connectionFactory.connection,
    marketplace.connectionFactory.connectionIndex,
    (stardogConn) => StardogQuery.execute(stardogConn, db, LIST_MODULE_TAGS)
  );

  return results.body.results?.bindings.map((rs: any) => resultToTag(rs)) || [];
};

export const listInstalledDataSources = async (
  stardogConnection: StardogConnection,
  stardogConnectionIdx: number | undefined
): Promise<DataSource[]> => {
  const response = await runWithRefetchConnectionToken(
    stardogConnection,
    stardogConnectionIdx,
    (stardogConn) => StardogDataSources.listInfo(stardogConn)
  );
  const results = response.body?.data_sources || [];

  return results.map((ds: any) => ({
    name: ds.entityName,
  }));
};

export const getMeta = async (
  modId: string | null | undefined,
  marketplaceConfig: MarketplaceOptions,
  moduleDb: string | undefined
): Promise<ModuleExt> => {
  if (!modId) {
    return {
      publishers: [],
      contributors: [],
      types: [],
      tags: [],
      links: [],
      related: [],
      graphMeta: [],
      virtualGraphs: [],
      examplePrompts: [],
      voiceboxEnabled: false,
    };
  }

  const marketplace = getMarketplace(marketplaceConfig);
  const db = marketplace.connectionFactory.options.database;

  // todo: get this as all one query?
  // that might be faster. might not. this will all be v. fast.
  // one big query will save the network trips, but will be
  // necessarily broader and slower.

  const tags = runWithRefetchConnectionToken(
    marketplace.connectionFactory.connection,
    marketplace.connectionFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(stardogConn, db, GET_MODULE_TAGS(modId))
  ).then(
    (results) =>
      results.body.results?.bindings.map((rs: any) => resultToTag(rs)) || []
  );

  const pubs = runWithRefetchConnectionToken(
    marketplace.connectionFactory.connection,
    marketplace.connectionFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(stardogConn, db, GET_MODULE_PUBLISHERS(modId))
  ).then(
    (results) =>
      results.body.results?.bindings.map((rs: any) =>
        resultToEntity(rs, 'pub')
      ) || []
  );

  const contribs = runWithRefetchConnectionToken(
    marketplace.connectionFactory.connection,
    marketplace.connectionFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(stardogConn, db, GET_MODULE_CONTRIBUTORS(modId))
  ).then(
    (results) =>
      results.body.results?.bindings.map((rs: any) =>
        resultToEntity(rs, 'pers')
      ) || []
  );

  const types = runWithRefetchConnectionToken(
    marketplace.connectionFactory.connection,
    marketplace.connectionFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(stardogConn, db, GET_MODULE_TYPES(modId))
  ).then(
    (results) =>
      results.body.results?.bindings.map((rs: any) =>
        resultToEntity(rs, 'type')
      ) || []
  );

  const links = runWithRefetchConnectionToken(
    marketplace.connectionFactory.connection,
    marketplace.connectionFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(stardogConn, db, GET_MODULE_LINKS(modId))
  ).then(
    (results) =>
      results.body.results?.bindings.map((rs: any) => {
        return { text: rs.linkText.value, url: rs.linkURL.value };
      }) || []
  );

  const related: Promise<Module[]> = getModuleRelatedWithOverviews(
    marketplace.connectionFactory,
    db,
    modId
  );

  const virtualGraphs = getVirtualGraphsForModule(
    marketplace.connectionFactory,
    modId
  );

  const examplePrompts = runWithRefetchConnectionToken(
    marketplace.connectionFactory.connection,
    marketplace.connectionFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(stardogConn, db, GET_MODULE_EXAMPLES_PROMPTS(modId))
  ).then(
    (results) =>
      results.body.results?.bindings.map((rs: any) =>
        resultToLiteral(rs, 'example')
      ) || []
  );

  const voiceboxEnabled = runWithRefetchConnectionToken(
    marketplace.connectionFactory.connection,
    marketplace.connectionFactory.connectionIndex,
    (stardogConn) =>
      StardogDb.options.get(
        stardogConn,
        moduleDb || marketplaceConfig?.database || DEMO_KIT_DB_DEFAULT,
        { 'database.online': '', 'voicebox.enabled': '' }
      )
  ).then(
    (results) =>
      results.body['database.online'] && results.body['voicebox.enabled']
  );

  return {
    tags: await tags,
    publishers: await pubs,
    contributors: await contribs,
    types: await types,
    links: await links,
    related: (await related).reduce((p: Module[], c) => p.concat(c), []),
    graphMeta: ((await graphMeta(modId, marketplaceConfig)) || []).filter(
      (m) =>
        m.label !== undefined &&
        (m.size !== undefined ||
          (m.concepts?.length || 0) > 0 ||
          m.primaryConcept !== undefined)
    ),
    virtualGraphs: await virtualGraphs,
    examplePrompts: await examplePrompts,
    voiceboxEnabled: await voiceboxEnabled,
  };
};

const getModuleRelatedWithOverviews = async (
  connFactory: ConnectionFactory,
  db: string,
  modId: string
): Promise<Module[]> => {
  const results = await runWithRefetchConnectionToken(
    connFactory.connection,
    connFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(stardogConn, db, GET_MODULE_RELATED(modId))
  );
  const overviewPromises =
    results.body.results?.bindings.map(async (rs: any) => {
      const modResults = await runWithRefetchConnectionToken(
        connFactory.connection,
        connFactory.connectionIndex,
        (stardogConn) =>
          StardogQuery.execute(
            stardogConn,
            db,
            MODULE_OVERVIEW_IRI(rs.related.value)
          )
      );
      return (
        modResults.body.results?.bindings.map((modResult: any) =>
          resultToModule(undefined, modResult)
        ) || []
      );
    }) || [];

  return Promise.all(overviewPromises);
};

const graphMeta = async (
  modId: string,
  marketplaceConfig: MarketplaceOptions
): Promise<GraphMeta[] | undefined> => {
  const marketplace = getMarketplace(marketplaceConfig);
  const db = marketplace.connectionFactory.options.database;

  const moduleGraphs = await getGraphs(
    marketplace.connectionFactory,
    modId,
    GET_MODULE_GRAPHS
  );
  const datasetMetas = await runWithRefetchConnectionToken(
    marketplace.connectionFactory.connection,
    marketplace.connectionFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(stardogConn, db, GET_DATASET_META(moduleGraphs))
  );

  const resultPromises = datasetMetas.body.results?.bindings.map(
    async (rs: any) => {
      const meta = resultToDatasetMeta(rs);

      const themesResp = await runWithRefetchConnectionToken(
        marketplace.connectionFactory.connection,
        marketplace.connectionFactory.connectionIndex,
        (stardogConn) =>
          StardogQuery.execute(stardogConn, db, GET_DATASET_THEMES(meta.iri))
      );

      const themes: Entity[] = themesResp.body.results?.bindings.map(
        (themeResult: any) => ({
          iri: themeResult.theme.value,
          label: themeResult.label?.value,
        })
      );

      return { ...meta, concepts: themes };
    }
  );

  return Promise.all(resultPromises);
};

const resultToDatasetMeta = (rs: any): GraphMeta => {
  return {
    iri: rs.g.value,
    ...(rs.label && { label: rs.label.value }),
    ...(rs.description && { description: rs.description.value }),
    ...(rs.size && { size: rs.size.value }),
    ...(rs.search && { search: rs.search.value }),
    ...(rs.primaryConcept && {
      primaryConcept: rs.primaryConcept.value,
    }),
  };
};

const resultToTag = (rs: any): Tag => {
  return {
    iri: rs.tag.value,
    ...(rs.name && { label: rs.name.value }),
    ...(rs.id && { id: rs.id.value }),
  };
};

const resultToEntity = (rs: any, iriVar = 'iri'): Entity => {
  return {
    iri: rs[iriVar].value,
    ...(rs.name && { label: rs.name.value }),
    ...(rs.id && { id: rs.id.value }),
  };
};

const resultToLiteral = (rs: any, key: string): string => {
  return rs[key].value;
};

const resultToModule = (modId: string | undefined, rs: any): Module => {
  const mid = rs.mod_id?.value || modId;
  const mod: Module = {
    id: mid,
    iri: rs.module.value,
    name: rs.name.value,
    schemaName: rs.schemaName?.value || mid.replace(':', '_'),

    ...(rs.databaseId && { databaseId: rs.databaseId.value }),
    ...(rs.desc && { description: rs.desc.value }),
    ...(rs.version && { version: rs.version.value }),
    ...(rs.pc && { primaryConcept: rs.pc.value }),
    ...(rs.search && { initialSearch: rs.search.value }),
    ...(rs.readme && { readme: rs.readme.value }),
    ...(rs.license && { license: rs.license.value }),
    ...(rs.licenseName && { licenseName: rs.licenseName.value }),
    ...(rs.alias && { alias: rs.alias.value }),
    ...(rs.icon && { icon: rs.icon.value }),
    ...(rs.lastModified && { lastModified: rs.lastModified.value }),
    ...(rs.type && { type: rs.type.value }),
    ...(rs.subject && { subject: rs.subject.value }),
  };

  return mod;
};

export class ModuleRepository {
  public connectionFactory: ConnectionFactory;

  private db: string;

  private parent?: ModuleRepository;

  constructor(connectionFactory: ConnectionFactory, parent?: ModuleRepository) {
    this.connectionFactory = connectionFactory;

    this.db = connectionFactory.options.database;
    this.parent = parent;
  }

  public async list(): Promise<Module[]> {
    const { body } = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) => StardogQuery.execute(stardogConn, this.db, LIST_MODULES)
    );

    return body.results.bindings.map((b: any) =>
      resultToModule(b.mod_id.value, b)
    );
  }

  public async search(
    token: string,
    filters: ModuleFilters
  ): Promise<Module[]> {
    const response = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) =>
        StardogQuery.execute(
          stardogConn,
          this.db,
          SEARCH_FOR_MODULES(token, filters)
        )
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.body?.results.bindings.map((b: any) =>
      resultToModule(b.mod_id.value, b)
    );
  }

  public async get(moduleId: string): Promise<Module | null> {
    const response = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) =>
        StardogQuery.execute(stardogConn, this.db, MODULE_OVERVIEW(moduleId))
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    if (!response.body?.results.bindings.length) {
      return null;
    }

    const rs = response.body.results.bindings[0];
    if (!rs.module) {
      /* istanbul ignore next */
      return null;
    }

    return resultToModule(moduleId, rs);
  }

  public async remove(moduleId: string): Promise<boolean> {
    const sourceConnFactory = this.parent?.connectionFactory;

    const module = await this.get(moduleId);

    if (!module) {
      /* istanbul ignore next */
      return false;
    }

    const graphs = await getGraphs(
      this.connectionFactory,
      moduleId,
      GET_MODULE_GRAPHS
    );

    const removeGraphs = graphs
      .filter((e: string) => e !== undefined)
      .map((g: string) => `clear silent graph <${g}>`)
      .join(';\n');
    const removeQuery = `${MPREFIXES}\n${removeGraphs}`;

    const removeResp = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) => StardogQuery.execute(stardogConn, this.db, removeQuery)
    );
    if (!removeResp.ok) {
      return false;
    }

    const removeSchemas: Promise<boolean> = this.removeSchemas(
      sourceConnFactory,
      module
    );
    const removeAlias: Promise<boolean> = this.removeAlias(module);
    const removeSecurity: Promise<boolean> = this.removeSecurity();
    const removeStoredQueries: Promise<boolean> = this.removeStoredQueries(
      sourceConnFactory,
      module
    );
    const removeVirtualGraphs: Promise<boolean> = this.removeVirtualGraphs(
      sourceConnFactory,
      module
    );

    const allResults = await Promise.all([
      removeAlias,
      removeSchemas,
      removeSecurity,
      removeStoredQueries,
      removeVirtualGraphs,
    ]);

    return !allResults.find((r) => r === false);
  }

  private async removeAlias(module: Module): Promise<boolean> {
    const { alias } = module;
    if (!alias) {
      return true;
    }

    const resp = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) =>
        StardogQuery.execute(stardogConn, this.db, REMOVE_ALIAS_DATA(alias))
    );

    return resp.ok;
  }

  // eslint-disable-next-line class-methods-use-this
  private removeSecurity(): Promise<boolean> {
    // todo implement me
    return Promise.resolve(true);
  }

  private async removeSchemas(
    sourceConnFactory: ConnectionFactory | undefined,
    module: Module
  ): Promise<boolean> {
    const mainSchemaName = module.schemaName || module.id.replace(':', '_');
    const meta = {
      'reasoning.schemas': '',
    };

    const schemaGraphs = sourceConnFactory
      ? await getAllSchemasWithGraphs(
          sourceConnFactory,
          module.id,
          GET_MODULE_SCHEMAS_WITH_GRAPHS
        )
      : [];

    const schemasToRemove = new Set([
      mainSchemaName,
      ...schemaGraphs.map((s) => s.schema),
    ]);

    const { body } = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) => StardogDb.options.get(stardogConn, this.db, meta)
    );

    meta['reasoning.schemas'] = (
      body['reasoning.schemas'] ??
      body.reasoning?.schemas ??
      []
    )
      .filter((pair: string) => !schemasToRemove.has(pair.split('=')[0]))
      .join(String.fromCharCode(2));

    const setMetaResp = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) => StardogDb.options.set(stardogConn, this.db, meta)
    );

    return setMetaResp.ok;
  }

  // eslint-disable-next-line class-methods-use-this
  private async removeStoredQueries(
    sourceConnFactory: ConnectionFactory | undefined,
    module: Module
  ): Promise<boolean> {
    if (!sourceConnFactory) {
      // No way to know what queries to remove
      return true;
    }

    const queriesToRemove = await getStoredQueriesForModule(
      sourceConnFactory,
      module.id
    );

    const removed = await Promise.all(
      queriesToRemove.map((q) =>
        runWithRefetchConnectionToken(
          this.connectionFactory.connection,
          this.connectionFactory.connectionIndex,
          (stardogConn) => StardogQuery.stored.remove(stardogConn, q.name)
        )
      )
    );

    return removed.every((r) => r.ok);
  }

  // eslint-disable-next-line class-methods-use-this
  private async removeVirtualGraphs(
    sourceConnFactory: ConnectionFactory | undefined,
    module: Module
  ): Promise<boolean> {
    if (!sourceConnFactory) {
      // No way to know what virtual graphs to remove
      return true;
    }

    const virtualGraphsToRemove = await getVirtualGraphsForModule(
      sourceConnFactory,
      module.id
    );

    const removed = await Promise.all(
      virtualGraphsToRemove.map((vg) =>
        runWithRefetchConnectionToken(
          this.connectionFactory.connection,
          this.connectionFactory.connectionIndex,
          (stardogConn) => StardogVirtualGraphs.remove(stardogConn, vg.name)
        )
      )
    );

    return removed.every((r) => r.ok);
  }

  public async add(moduleId: string): Promise<boolean> {
    if (!this.parent) {
      // no where to install from
      // todo: better error handling!?
      return false;
    }

    // todo: verify the module actually exists first?

    // TODO this is a big hack around https://stardog.atlassian.net/browse/PLAT-3696
    // it makes the service call log in with these details rather than none, so we get
    // a successful auth

    const {
      endpoint,
      username,
      password,
      database,
    } = this.parent?.connectionFactory.options;

    const queryEndpoint = `${endpoint.replace(
      'https://',
      `https://${username}:${password}@`
    )}/${database}/query`;

    const sourceConnFactory = this.parent?.connectionFactory;

    const isValid = await this.hasDataSourcePrereqs(
      moduleId,
      sourceConnFactory
    );

    if (!isValid) {
      return false;
    }

    const graphs = await getGraphs(
      sourceConnFactory,
      moduleId,
      GET_MODULE_GRAPHS
    );

    const loadResponse = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) =>
        StardogQuery.execute(
          stardogConn,
          this.db,
          LOAD_MODULE(graphs, queryEndpoint)
        )
    );
    if (!loadResponse.ok) {
      return false;
    }

    const module = await this.get(moduleId);

    if (!module) {
      return false;
    }

    const saveAlias: Promise<boolean> = this.saveAlias(
      sourceConnFactory,
      module
    );
    const saveSchemas: Promise<boolean> = this.saveSchemasWithGraphs(
      sourceConnFactory,
      module
    );
    const saveStoredQueries: Promise<boolean> = this.saveStoredQueries(
      sourceConnFactory,
      module
    );
    const saveVirtualGraphs: Promise<boolean> = this.saveVirtualGraphs(
      sourceConnFactory,
      module
    );
    const persistSecurity: Promise<boolean> = this.persistSecurity();

    const allResults = await Promise.all([
      saveAlias,
      saveSchemas,
      saveStoredQueries,
      saveVirtualGraphs,
      persistSecurity,
    ]);

    return !allResults.find((r) => r === false);
  }

  // eslint-disable-next-line class-methods-use-this
  private async hasDataSourcePrereqs(
    moduleId: string,
    sourceConnFactory: ConnectionFactory | undefined
  ): Promise<boolean> {
    if (!sourceConnFactory) {
      return false;
    }

    const virtualGraphs: VirtualGraph[] = await getVirtualGraphsForModule(
      sourceConnFactory,
      moduleId
    );

    if (virtualGraphs.length === 0) {
      return true;
    }

    const existingSources = new Set(
      (
        await listInstalledDataSources(
          this.connectionFactory.connection,
          this.connectionFactory.connectionIndex
        )
      ).map((s) => s.name.replace(/^data-source:\/\//, ''))
    );

    const missingSources = virtualGraphs
      .map((vg) => vg.source)
      .filter((source) => !existingSources.has(source.name));

    return missingSources.length === 0;
  }

  // eslint-disable-next-line class-methods-use-this
  private persistSecurity(): Promise<boolean> {
    // todo implement me
    return Promise.resolve(true);
  }

  // eslint-disable-next-line class-methods-use-this
  private async saveVirtualGraphs(
    sourceConnFactory: ConnectionFactory,
    module: Module
  ): Promise<boolean> {
    const virtualGraphs: VirtualGraph[] = await getVirtualGraphsForModule(
      sourceConnFactory,
      module.id
    );

    const vgAdds = await Promise.all(
      virtualGraphs.map((vg) =>
        runWithRefetchConnectionToken(
          this.connectionFactory.connection,
          this.connectionFactory.connectionIndex,
          (stardogConn) =>
            StardogVirtualGraphs.add(
              stardogConn,
              vg.name,
              vg.mapping.code,
              {
                'mappings.syntax': vg.mapping.syntax,
              },
              {
                dataSource: vg.source.name,
                db: this.db,
              }
            )
        )
      )
    );

    return vgAdds.every((r) => r.ok);
  }

  private async saveStoredQueries(
    sourceConnFactory: ConnectionFactory,
    module: Module
  ): Promise<boolean> {
    const newQueries = await getStoredQueriesForModule(
      sourceConnFactory,
      module.id
    );
    const oldQueries = (
      await runWithRefetchConnectionToken(
        this.connectionFactory.connection,
        this.connectionFactory.connectionIndex,
        (stardogConn) => StardogQuery.stored.list(stardogConn)
      )
    ).body?.queries;
    const oldQueryNames = new Set(oldQueries?.map((q: any) => q.name));

    const addQueries = await Promise.all(
      newQueries
        .filter((q) => !oldQueryNames.has(q.name))
        .map((query) =>
          runWithRefetchConnectionToken(
            this.connectionFactory.connection,
            this.connectionFactory.connectionIndex,
            (stardogConn) =>
              StardogQuery.stored.create(stardogConn, {
                shared: true,
                ...query,
                database: this.db,
              })
          )
        )
    );

    return addQueries.every((r) => r.ok);
  }

  private async saveSchemasWithGraphs(
    sourceConnFactory: ConnectionFactory,
    module: Module
  ): Promise<boolean> {
    const meta = {
      'reasoning.schemas': '',
    };

    // The "main schema" is kind of deprecated, but we still need to support it for now
    const mainSchemaName = module.schemaName || module.id.replace(':', '_');

    const [graphsOfMainSchema, schemaGraphs] = await Promise.all([
      getGraphs(
        sourceConnFactory,
        module.id,
        GET_MODULE_GRAPHS_FOR_MAIN_SCHEMA
      ),
      getAllSchemasWithGraphs(
        sourceConnFactory,
        module.id,
        GET_MODULE_SCHEMAS_WITH_GRAPHS
      ),
    ]);

    const { body } = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) => StardogDb.options.get(stardogConn, this.db, meta)
    );

    meta['reasoning.schemas'] = [
      ...(body['reasoning.schemas'] ?? body.reasoning?.schemas ?? []),
      ...graphsOfMainSchema.map((s) => `${mainSchemaName}=${s}`),
      ...schemaGraphs.map((s) => `${s.schema}=${s.graph}`),
    ].join(String.fromCharCode(2));

    const resp = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) => StardogDb.options.set(stardogConn, this.db, meta)
    );

    return resp.ok;
  }

  private async saveAlias(
    sourceConnFactory: ConnectionFactory,
    module: Module
  ): Promise<boolean> {
    const { alias } = module;
    if (!alias) {
      return true;
    }

    const dataGraphs = await getGraphs(
      sourceConnFactory,
      module.id,
      GET_MODULE_DATA_GRAPHS
    );

    if (dataGraphs.length === 0) {
      return true;
    }

    const resp = await runWithRefetchConnectionToken(
      this.connectionFactory.connection,
      this.connectionFactory.connectionIndex,
      (stardogConn) =>
        StardogQuery.execute(
          stardogConn,
          this.db,
          ADD_ALIAS_DATA(alias, dataGraphs)
        )
    );

    return resp.ok;
  }
}

const getGraphs = async (
  connFactory: ConnectionFactory,
  moduleId: string,
  query: any
): Promise<string[]> => {
  const response = await runWithRefetchConnectionToken(
    connFactory.connection,
    connFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(
        stardogConn,
        connFactory.options.database,
        query(moduleId)
      )
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const graphs = new Set<string>();

  response.body?.results.bindings.forEach((rs: any) => {
    if (rs.module_graph) {
      graphs.add(rs.module_graph.value);
    }

    if (rs.model) {
      graphs.add(rs.model.value);
    }

    if (rs.constraints) {
      graphs.add(rs.constraints.value);
    }

    if (rs.data) {
      graphs.add(rs.data.value);
    }
  });

  return Array.from(graphs);
};

const getAllSchemasWithGraphs = async (
  connFactory: ConnectionFactory,
  moduleId: string,
  query: any
): Promise<SchemaGraph[]> => {
  const response = await runWithRefetchConnectionToken(
    connFactory.connection,
    connFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(
        stardogConn,
        connFactory.options.database,
        query(moduleId)
      )
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.body?.results.bindings.map((rs: any) => ({
    schema: rs.schemaName.value,
    graph: rs.model.value,
  }));
};

const getStoredQueriesForModule = async (
  connFactory: ConnectionFactory,
  moduleId: string
): Promise<StoredQuery[]> => {
  const response = await runWithRefetchConnectionToken(
    connFactory.connection,
    connFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(
        stardogConn,
        connFactory.options.database,
        GET_MODULE_STORED_QUERIES(moduleId)
      )
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.body?.results.bindings.map(
    (rs: any): StoredQuery => ({
      name: rs.queryName.value,
      query: rs.query.value,
      ...(rs.shared && { shared: rs.shared.value }),
      ...(rs.reasoning && { reasoning: rs.reasoning.value }),
      ...(rs.description && { description: rs.description.value }),
    })
  );
};

const getVirtualGraphsForModule = async (
  connFactory: ConnectionFactory,
  moduleId: string
): Promise<VirtualGraph[]> => {
  const response = await runWithRefetchConnectionToken(
    connFactory.connection,
    connFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(
        stardogConn,
        connFactory.options.database,
        GET_MODULE_VGS(moduleId)
      )
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.body?.results.bindings.map(
    (rs: any): VirtualGraph => ({
      id: rs.vg.value,
      name: rs.vgName.value,
      source: {
        id: rs.source.value,
        name: rs.sourceName.value,
        type: rs.sourceType.value,
        options: {},
      },
      mapping: {
        id: rs.mapping.value,
        code: rs.mappingStatement.value,
        syntax: rs.mappingSyntax.value,
      },
    })
  );
};

const getOptionsForDataSource = async (
  connFactory: ConnectionFactory,
  dataSourceId: string
): Promise<DataSourceOptions> => {
  const response = await runWithRefetchConnectionToken(
    connFactory.connection,
    connFactory.connectionIndex,
    (stardogConn) =>
      StardogQuery.execute(
        stardogConn,
        connFactory.options.database,
        GET_DATA_SOURCE_OPTIONS(dataSourceId)
      )
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const options: DataSourceOptions = {};

  response.body?.results.bindings.forEach((rs: any) => {
    options[rs.key.value] = rs.value.value;
  });

  return options;
};

export const fillDataSourceOptions = async (
  connFactory: ConnectionFactory,
  dataSource: DataSource
): Promise<DataSource> => {
  const options = await getOptionsForDataSource(connFactory, dataSource.id);

  return {
    ...dataSource,
    options,
  };
};

export const EMPTY: Module = { id: '', name: '' };

export const getMarketplace = (databaseOptions: MarketplaceOptions) => {
  return new ModuleRepository(create(undefined, databaseOptions));
};

export const marketplaceSettingsToDbOptions = (
  settings?: MarketplaceSettings | null
): MarketplaceOptions | null => {
  if (!settings) {
    return null;
  }

  const {
    marketplaceUsername,
    marketplacePassword,
    marketplaceEndpoint,
    marketplaceDatabase,
  } = settings;

  return {
    username: marketplaceUsername || '',
    password: marketplacePassword,
    endpoint: marketplaceEndpoint || '',
    database: marketplaceDatabase || '',
  };
};

/**
 * MarketPlace list
 *
 * Hook to fetch all the available market place modules
 */
export const useMarketplaceList = (
  dbOptions: MarketplaceOptions | null,
  searchQuery?: string,
  filters?: ModuleFilters
) =>
  useQuery<Module[], Error>(
    ['marketplace-list', searchQuery, filters],
    () => {
      /* istanbul ignore next */
      if (!dbOptions) {
        return [];
      }

      const marketplace = getMarketplace(dbOptions);

      if (
        searchQuery ||
        filters?.type ||
        filters?.subjects?.length ||
        filters?.tags?.length
      ) {
        return marketplace.search(searchQuery || '', filters || {});
      }

      return marketplace.list();
    },
    { enabled: !!dbOptions }
  );

/**
 * Get Module and Meta
 *
 * Hook to fetch a module and its metadata
 */
export const useModuleMeta = (
  id: string,
  dbOptions: MarketplaceOptions | null
) =>
  useQuery<ModuleAndMeta, Error>(
    ['module-meta', id],
    async () => {
      /* istanbul ignore next */
      if (!dbOptions) {
        return {};
      }

      const marketplace = getMarketplace(dbOptions);
      const module = await marketplace.get(id);
      const moduleMeta = await getMeta(
        module?.id,
        dbOptions,
        module?.databaseId
      );
      return { module, moduleMeta };
    },
    { enabled: !!dbOptions }
  );

/**
 * List Module Tags
 *
 * Hook to fetch all module tags that could be applied to a module
 */
export const useListModuleTags = (dbOptions: MarketplaceOptions | null) =>
  useQuery<Tag[], Error>(
    'list-module-tags',
    () => {
      /* istanbul ignore next */
      if (!dbOptions) {
        return [];
      }

      return listModuleTags(dbOptions);
    },
    { enabled: !!dbOptions }
  );

export const useListDataSources = (
  connection: GraphConnection | null | undefined
) =>
  useQuery<DataSource[], Error>(
    ['list-data-sources', connection],
    async () => {
      /* istanbul ignore next */
      if (!connection) {
        return [];
      }

      const stardogConnection = getStardogConnection(
        connection as BaseConnection
      );

      return listInstalledDataSources(stardogConnection, connection.index);
    },
    { enabled: !!connection }
  );

export const useFillDataSourceOptions = (
  dataSources: DataSource[] | null,
  dbOptions: MarketplaceOptions | null
) =>
  useQuery<DataSource[], Error>(
    ['fill-data-source-options', dbOptions, dataSources],
    async () => {
      /* istanbul ignore next */
      if (!dbOptions || !dataSources) {
        return [];
      }

      const marketplace = getMarketplace(dbOptions);

      const filledPromises = dataSources.map((dataSource) =>
        fillDataSourceOptions(marketplace.connectionFactory, dataSource)
      );

      return Promise.all(filledPromises);
    },
    { enabled: !!dbOptions && !!dataSources }
  );

export const useAddDataSource = (
  connection: GraphConnection | null | undefined,
  options?: UseMutationOptions<unknown, unknown, DataSource, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (dataSource: DataSource) => {
      /* istanbul ignore next */
      if (!connection) {
        throw new Error('No connection selected');
      }

      return addDataSource(connection, dataSource);
    },
    {
      ...options,
      onSuccess: (data, dataSource, context) => {
        queryClient.invalidateQueries('list-data-sources');

        options?.onSuccess?.(data, dataSource, context);
      },
    }
  );
};
