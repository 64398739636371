import { Classes, H2, InputGroup } from '@blueprintjs/core';
import * as React from 'react';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';

import { Loading } from 'src/ui/components/Loading';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { StardogCloud, useGetReportQuery } from 'src/ui/graph/types';

type TParams = { days?: string };

/* istanbul ignore next: skipping tests for this component as admin only */
export const CloudReport: React.VFC = () => {
  const params = useParams<TParams>();
  const inactiveDays = params.days ? parseInt(params.days, 10) : 0;
  const { data, isLoading } = useGetReportQuery(graphQLClient, {
    inactiveDays,
  });
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = evt.target;
      setFilterText(value);
      if (!value) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <Link className={Classes.BUTTON} to="/internal/cloud/report">
          All
        </Link>
        <Link className={Classes.BUTTON} to="/internal/cloud/report/7">
          Inactive for 7 days
        </Link>
        <Link className={Classes.BUTTON} to="/internal/cloud/report/30">
          Inactive for 30 days
        </Link>
        <InputGroup
          type="search"
          data-testid="report-cloud-filter"
          name="cloud-filter"
          placeholder="search clouds"
          onChange={handleChange}
          leftIcon="search"
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  if (isLoading || !data || !data.listStardogCloud || !data?.checkCloudQueue) {
    return <Loading />;
  }

  const cloudCount = data.listStardogCloud?.length;
  const microCount = data.checkCloudQueue.micro_count;
  const smallCount = data.checkCloudQueue.small_count;
  const mediumCount = data.checkCloudQueue.medium_count;

  const paginationRowsPerPageOptions = [100, 200, 300, 400, 500];
  const paginationPerPage = 100;

  const columns = [
    {
      name: 'Name',
      id: 1,
      selector: (row: StardogCloud) => row?.name || '',
    },
    {
      name: 'Flavor',
      id: 2,
      selector: (row: StardogCloud) => row?.flavor?.name || '',
    },
    {
      name: 'Status',
      id: 3,
      selector: (row: StardogCloud) => row?.status || '',
    },
    {
      name: 'Owner',
      id: 4,
      selector: (row: StardogCloud) => row?.owner?.username || '',
    },
    {
      name: 'Last Login',
      id: 5,
      selector: (row: StardogCloud) => row?.owner?.last_login || '',
      sortable: true,
    },
    {
      name: 'Endpoint',
      id: 6,
      selector: (row: StardogCloud) => row?.endpoint || '',
      sortable: true,
    },
  ];

  const filteredData = data.listStardogCloud.filter(
    (item) =>
      item?.owner?.username
        ?.toLowerCase()
        ?.includes(filterText.toLowerCase()) ||
      item?.endpoint?.toLowerCase()?.includes(filterText.toLowerCase())
  );

  const countText = inactiveDays
    ? `There are currently ${cloudCount} clouds allocated that have been inactive for ${inactiveDays} days`
    : `There are a total of ${cloudCount} allocated`;

  return (
    <div className="sd-gutter">
      <div>
        <H2>Cloud Report</H2>
        <p>
          In the available queue we have: {microCount} micros, {smallCount}{' '}
          smalls, and {mediumCount} mediums
        </p>
        <p>{countText}</p>
      </div>
      <div>
        <DataTable
          title="Cloud Report"
          noHeader
          columns={columns}
          data={filteredData as StardogCloud[]}
          defaultSortFieldId={5}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          paginationPerPage={paginationPerPage}
          dense
          responsive
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
      </div>
    </div>
  );
};
