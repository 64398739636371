import { Button, Callout, H3, Intent, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { Connection as GraphConnection } from 'src/ui/graph/types';
import { components } from 'src/ui/templates/copy';

type ConnectionDetailsProps = {
  connection: GraphConnection;
  onReauthenticateSSOConnection: () => void;
  onReauthenticateUserPasswordConnection: () => void;
  isBrowserAuthFailure: boolean;
  isUnauthorized: boolean;
};

/* istanbul ignore next */
export const ConnectionDetails: React.FC<ConnectionDetailsProps> = ({
  connection,
  onReauthenticateSSOConnection,
  onReauthenticateUserPasswordConnection,
  isUnauthorized,
  isBrowserAuthFailure,
}) => {
  const { connectionDetails } = components.launchpad;

  const renderReauthorizationButton = (isSSO: boolean) => (
    <Button
      icon={IconNames.Refresh}
      intent={Intent.WARNING}
      minimal
      outlined
      text={components.launchpad.connectionDetails.buttons.reauthenticate}
      onClick={
        isSSO
          ? onReauthenticateSSOConnection
          : onReauthenticateUserPasswordConnection
      }
    />
  );

  const renderAuthorizationError = () => {
    const renderCallout = (title: string, content: JSX.Element | string) => (
      <Callout
        className="launchpad-dashboard__connection-details__error-callout"
        title={title}
        intent={Intent.WARNING}
      >
        <div>{content}</div>
      </Callout>
    );

    const handleBrowserAuthFailure = () =>
      renderCallout(
        connectionDetails.errorCallouts.browserAuthFailure.title,
        connectionDetails.errorCallouts.browserAuthFailure.body
      );

    const handleSSOFailure = () => {
      if (!connection.token) {
        return renderCallout(
          connectionDetails.errorCallouts.ssoAuthFailure.noToken.title,
          connectionDetails.errorCallouts.ssoAuthFailure.noToken.body(
            renderReauthorizationButton(true)
          )
        );
      }

      try {
        const tokenData = JSON.parse(atob(connection.token.split('.')[1]));
        const roleClaims = tokenData.roles || [];
        return renderCallout(
          connectionDetails.errorCallouts.ssoAuthFailure.existingToken.title,
          connectionDetails.errorCallouts.ssoAuthFailure.existingToken.body(
            roleClaims,
            renderReauthorizationButton(true)
          )
        );
      } catch (error) {
        return renderCallout(
          connectionDetails.errorCallouts.ssoAuthFailure.existingTokenFallback
            .title,
          connectionDetails.errorCallouts.ssoAuthFailure.existingTokenFallback.body(
            renderReauthorizationButton(true)
          )
        );
      }
    };

    const handleGenericAuthFailure = () => {
      return renderCallout(
        connectionDetails.errorCallouts.basicAuthFailure.title,
        connectionDetails.errorCallouts.basicAuthFailure.body(
          renderReauthorizationButton(false)
        )
      );
    };

    if (connection.useBrowserAuth && isBrowserAuthFailure) {
      return handleBrowserAuthFailure();
    }

    if (connection.useConnectionSSO && isUnauthorized) {
      return handleSSOFailure();
    }

    if (
      !connection.useBrowserAuth &&
      !connection.useConnectionSSO &&
      isUnauthorized
    ) {
      return handleGenericAuthFailure();
    }

    return null;
  };

  return (
    <div className="launchpad-dashboard__connection-details">
      <div className="launchpad-dashboard__connection-details__header">
        <H3>{connection.name}</H3>
        {connection.useSSO && (
          <Tag intent={Intent.PRIMARY} minimal large>
            {connectionDetails.tags.sso}
          </Tag>
        )}
      </div>
      {renderAuthorizationError()}
    </div>
  );
};
