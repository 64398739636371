import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Navbar,
  NavbarSchema,
  NavbarSchemaContext,
  NavbarWrapper,
} from 'vet-bones/components';
import { useDidValueChange } from 'vet-bones/utils/hooks';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { AppBody } from 'src/ui/containers/AppBody';
import { PortalEndpointAsideWrapper } from 'src/ui/containers/navbar/PortalEndpointAsideWrapper';
import { VoiceboxChatListAside } from 'src/ui/containers/voicebox/aside/VoiceboxChatListAside';
import { NavbarAsideType, toggledNavbarAside } from 'src/ui/features/ui';
import {
  useLandingRouteParams,
  useVoiceboxRouteParams,
} from 'src/ui/hooks/navbar/routes';
import {
  usePortalDefaultNavbarSchema,
  usePortalLandingNavbarSchema,
  usePortalVoiceboxNavbarSchema,
} from 'src/ui/hooks/navbar/schema';

const LANDING_ASIDES = new Set<NavbarAsideType | null>([
  NavbarAsideType.PORTAL_ENDPOINT,
]);
const VOICEBOX_ASIDES = new Set<NavbarAsideType | null>([
  NavbarAsideType.VOICEBOX_CHAT_LIST,
]);

export const PortalNavbarWrapper: React.VFC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const maybeAside = useAppSelector((state) => state.ui.navbar.aside);
  const isAsideOpen = useAppSelector((state) => state.ui.navbar.isAsideOpen);

  const didPathChange = useDidValueChange(history.location.pathname);
  React.useEffect(() => {
    if (didPathChange && isAsideOpen) {
      dispatch(toggledNavbarAside({ isAsideOpen: false }));
    }
  }, [didPathChange, dispatch, isAsideOpen]);

  const landingParams = useLandingRouteParams();
  const voiceboxParams = useVoiceboxRouteParams();

  const landingSchema = usePortalLandingNavbarSchema();
  const voiceboxSchema = usePortalVoiceboxNavbarSchema();
  const defaultSchema = usePortalDefaultNavbarSchema();

  let key: string;
  let asideType: NavbarAsideType | null;
  let schema: NavbarSchema;
  if (landingParams) {
    key = 'landing-and-voicebox';
    asideType = LANDING_ASIDES.has(maybeAside) ? maybeAside : null;
    schema = landingSchema;
  } else if (voiceboxParams) {
    key = 'landing-and-voicebox';
    asideType = VOICEBOX_ASIDES.has(maybeAside) ? maybeAside : null;
    schema = voiceboxSchema;
  } else {
    key = 'default';
    asideType = null;
    schema = defaultSchema;
  }

  let aside: JSX.Element | null;
  if (asideType === NavbarAsideType.PORTAL_ENDPOINT) {
    aside = <PortalEndpointAsideWrapper />;
  } else if (asideType === NavbarAsideType.VOICEBOX_CHAT_LIST) {
    aside = <VoiceboxChatListAside />;
  } else {
    aside = null;
  }

  return (
    <div className="sd-horizontal">
      <NavbarSchemaContext.Provider value={schema}>
        <NavbarWrapper
          key={key}
          aside={aside}
          isAsideOpen={Boolean(aside && isAsideOpen)}
          navbar={<Navbar />}
        >
          <AppBody />
        </NavbarWrapper>
      </NavbarSchemaContext.Provider>
    </div>
  );
};
