import React from 'react';
import { useHistory } from 'react-router-dom';
import { PortalEndpointAside } from 'vet-bones/components/molecules/Portal';

import {
  PORTAL_ENDPOINT_ASIDE_MANAGE_ENDPOINTS,
  PORTAL_ENDPOINT_ASIDE_SELECTOR,
  PORTAL_ENDPOINT_ASIDE_SELECTOR_ITEM,
} from 'src/ui/constants/testIds';
import {
  useCurrentConnection,
  useSetConnectionIndex,
} from 'src/ui/hooks/connection';
import { useConnectionIdx } from 'src/ui/hooks/navbar/connection';
import * as copy from 'src/ui/templates/copy';

export const PortalEndpointAsideWrapper: React.VFC = () => {
  const history = useHistory();
  const connectionIdx = useConnectionIdx();
  const { connection } = useCurrentConnection(connectionIdx);

  const setConnectionIndex = useSetConnectionIndex();

  return (
    <PortalEndpointAside
      connectionInfo={
        connection?.index !== undefined
          ? { connectionIndex: connection.index }
          : undefined
      }
      copy={copy.components.navbar.aside.portalEndpoint}
      onManageEndpoints={() => {
        history.push('/connections');
      }}
      onSelectConnection={(connectionItem) =>
        setConnectionIndex(connectionItem.index)
      }
      testIds={{
        manageEndpoints: PORTAL_ENDPOINT_ASIDE_MANAGE_ENDPOINTS,
        selector: PORTAL_ENDPOINT_ASIDE_SELECTOR,
        selectorItem: PORTAL_ENDPOINT_ASIDE_SELECTOR_ITEM,
      }}
    />
  );
};
