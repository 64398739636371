import * as React from 'react';

import { DASHBOARD_BROWSER_AUTH_FAILED_WARNING } from 'src/ui/constants/testIds';
import { copy } from 'src/ui/templates/copy';

interface BrowserAuthFailedWarningProps {
  index: number;
}

export const BrowserAuthFailedWarning: React.VFC<BrowserAuthFailedWarningProps> = ({
  index,
}) => {
  return (
    <a
      className="sd-warning"
      href={`/u/${index}/diagnostic`}
      data-testid={DASHBOARD_BROWSER_AUTH_FAILED_WARNING}
    >
      {copy.errors.browserAuthFailed} &rarr;
    </a>
  );
};
