import cloneDeep from 'lodash/cloneDeep';
import {
  DEFAULT_QUERY_BUILDER_SCHEMA,
  UNPROCESSED_QUERY_BUILDER_TEST_IDS,
} from 'vet-bones/components';
import {
  DEFAULT_VOICEBOX_SCHEMA,
  UNPROCESSED_VOICEBOX_TEST_IDS,
} from 'vet-bones/components/molecules/Voicebox';
import { VoiceboxCopy, VoiceboxTestIds } from 'vet-bones/constants/voicebox';
import { getTestId, processTestIds } from 'vet-bones/utils';
import {
  QueryBuilderCopy,
  QueryBuilderSchema,
  QueryBuilderTestIds,
} from 'vet-bones/utils/queryBuilder';

import { colors } from 'src/ui/templates/styles/colors';

export const voiceboxCopy: VoiceboxCopy = {
  ...DEFAULT_VOICEBOX_SCHEMA.copy,
  pending: {
    messages: [
      'Question received',
      'Transforming your question to a query',
      'Processing the query',
      'Validating results',
      'Summarizing results',
    ],
    timeout: 10,
  },
};

const baseVoiceboxTestIds = cloneDeep(UNPROCESSED_VOICEBOX_TEST_IDS);
processTestIds(baseVoiceboxTestIds, '-', 'sd-voicebox');

export const voiceboxTestIds: VoiceboxTestIds = {
  ...baseVoiceboxTestIds,
};

const voiceboxQueryBuilderCopy: QueryBuilderCopy = {
  ...DEFAULT_QUERY_BUILDER_SCHEMA.copy,
  error: {
    // we do not show validation in voicebox
    classInstanceDefined: '',
    classModelDomainOrRange: '',
    classModelUndefined: '',
    classPreviousInstance: '',
    classSubjectConnected: '',
    classVariableInvalid: '',
    instanceValueBackticks: '',
    instanceValueCharacters: /* istanbul ignore next */ () => '',
    instanceValueSemicolon: '',
    instanceValueSplitCharacter: '',
    instanceValueStartsWith: '',
    propertyModelDomainOrRange: '',
    propertyModelUndefined: '',
  },
};

const baseQueryBuilderTestIds = cloneDeep(UNPROCESSED_QUERY_BUILDER_TEST_IDS);
processTestIds(baseQueryBuilderTestIds, '-', 'sd-voicebox-query-builder');

export const voiceboxQueryBuilderTestIds: QueryBuilderTestIds = {
  ...baseQueryBuilderTestIds,
  classSelectorItem: /* istanbul ignore next */ (id: string, idx?: number) =>
    `sd-voicebox-query-builder-class-item-${getTestId(id, idx)}`,
  classSelectorSubItem: /* istanbul ignore next */ (
    id: string,
    subId: string,
    idx?: number,
    subIdx?: number
  ) =>
    `sd-voicebox-query-builder-class-sub-item-${getTestId(id, idx)}-${getTestId(
      subId,
      subIdx
    )}`,
  instanceSelectorItem: /* istanbul ignore next */ (id: string, idx?: number) =>
    `sd-voicebox-query-builder-instance-item-${getTestId(id, idx)}`,
  operatorSelectorItem: /* istanbul ignore next */ (id: string, idx?: number) =>
    `sd-voicebox-query-builder-operator-item-${getTestId(id, idx)}`,
  operatorSelectorSubItem: /* istanbul ignore next */ (
    id: string,
    subId: string,
    idx?: number,
    subIdx?: number
  ) =>
    `sd-voicebox-query-builder-operator-sub-item-${getTestId(
      id,
      idx
    )}-${getTestId(subId, subIdx)}`,
  propertySelectorItem: /* istanbul ignore next */ (id: string, idx?: number) =>
    `sd-voicebox-query-builder-property-item-${getTestId(id, idx)}`,
  propertySelectorSubItem: /* istanbul ignore next */ (
    id: string,
    subId: string,
    idx?: number,
    subIdx?: number
  ) =>
    `sd-voicebox-query-builder-property-sub-item-${getTestId(
      id,
      idx
    )}-${getTestId(subId, subIdx)}`,
};

export const voiceboxQueryBuilder: QueryBuilderSchema = {
  colors: {
    darkIcon: colors.darkIcon,
    anyClassColorId: colors.queryBuilderGrayId,
  },
  copy: voiceboxQueryBuilderCopy,
  testIds: voiceboxQueryBuilderTestIds,
  readOnly: true,
};
