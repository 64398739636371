import { Classes, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';

import { Dialog } from 'src/ui/components/Dialog';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { RECENT_CONNECTION_FOR_ENDPOINT } from 'src/ui/constants/session';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import { Connection } from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useUserPartnerConnectionsQuery } from 'src/ui/graph/types';
import {
  useSetConnectionIndex,
  useSetConnectionIndexForPartnerConnect,
} from 'src/ui/hooks/connection';
import * as copy from 'src/ui/templates/copy';
import { modulo } from 'src/ui/utils/modulo';
import { doServiceRedirect } from 'src/ui/utils/window';

interface SelectConnectionDialogProps {
  connections: Connection[];
  redirectSubPath?: string;
  isOpen: boolean;
}

export const SelectConnectionDialog: React.VFC<SelectConnectionDialogProps> = ({
  connections,
  redirectSubPath,
  isOpen,
}) => {
  const { data: partnerInfo } = useUserPartnerConnectionsQuery(graphQLClient);
  const setConnectionIndex = useSetConnectionIndex();
  const setConnectionIndexForPartner = useSetConnectionIndexForPartnerConnect();

  const { endpoint, index: firstIndex } = connections[0];
  const indexToArrIndex = React.useMemo(
    () => new Map(connections.map((conn, i) => [conn.index ?? 0, i])),
    [connections]
  );

  const recentConnections = React.useMemo<Record<string, number>>(() => {
    const saved = localStorage.getItem(RECENT_CONNECTION_FOR_ENDPOINT);
    return saved ? JSON.parse(saved) : {};
  }, []);
  const [activeItem, setActiveItem] = React.useState<number | undefined>(
    recentConnections[endpoint] || firstIndex
  );
  const [hasChosen, setHasChosen] = React.useState(false);

  React.useEffect(() => {
    if (hasChosen) {
      // The active item is frozen once chosen
      return () => {};
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSelect();
        return;
      }

      const deltas: { [key: string]: number } = {
        ArrowDown: 1,
        ArrowUp: -1,
      };

      if (deltas[e.key] === undefined) {
        return;
      }

      const delta = deltas[e.key] || 0;
      const arrIndex = indexToArrIndex.get(activeItem ?? 0) ?? 0;
      const finalArrIndex = modulo(arrIndex + delta, connections.length);

      setActiveItem(connections[finalArrIndex].index);
    };

    // This adds the event listener, then when activeItem changes, it will remove the old event listener and add a new one
    // For some reason, if you don't re-make the event listener when activeItem changes, the onKeyDown function will never know about new activeItem values
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [activeItem, recentConnections, hasChosen]);

  const onSelect = (index = activeItem ?? 0) => {
    if (hasChosen) {
      // It's already been selected, no backsies
      return;
    }

    if (recentConnections) {
      recentConnections[endpoint] = index;

      localStorage.setItem(
        RECENT_CONNECTION_FOR_ENDPOINT,
        JSON.stringify(recentConnections)
      );
    }

    setActiveItem(index);
    setHasChosen(true);

    if (redirectSubPath) {
      doServiceRedirect(index, redirectSubPath);
      return;
    }

    if (
      partnerInfo?.userPartnerConnections &&
      partnerInfo?.userPartnerConnections.length > 0
    ) {
      setConnectionIndexForPartner(index, `/databricks-partner-home`);
      return;
    }

    setConnectionIndex(index);
  };

  const isSubmitDisabled = activeItem === undefined || hasChosen;

  const actions: FormAction[] = [
    makeSubmitAction(copy.components.connectionDialog.select.submit, hasChosen),
  ];

  return (
    <Dialog
      icon={IconNames.OFFLINE}
      title={copy.components.connectionDialog.select.title}
      className={classNames('sd-dialog-connection')}
      isCloseButtonShown={false}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        <Menu large>
          <MenuDivider title={endpoint} />
          {connections.map((conn) => (
            <MenuItem
              key={conn.index}
              icon={IconNames.OFFLINE}
              active={conn.index === activeItem}
              onClick={() => onSelect(conn.index)}
              text={conn.name}
            />
          ))}
        </Menu>
      </div>

      <FormActions
        actions={actions}
        handleSubmit={() => onSelect()}
        isSubmitDisabled={isSubmitDisabled}
      />
    </Dialog>
  );
};
