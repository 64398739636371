// parse a Python datetime string in ISO 8601 format, returning a displayable date[time] string
export const getFormattedDateTime = (
  dateTimeString: string,
  includeTime: boolean
) => {
  // dateTimeString format is expected to be "2023-03-21 22:26:21.933235+00:00"
  //   (could also be "2023-03-21T22:26:21.933235+00:00");
  //   the fractional part of the seconds field may be omitted if it is 0,
  //   in which case dateTimeString would be "2023-03-21 22:26:21+00:00"
  //   we also gracefully handle a naive datetime (no "+00:00" TZ portion)
  //
  //   if includeTime is false: include only the date portion
  //   if includeTime is true: strip off the milliseconds; if TZ is +00:00, add UTC
  //
  //   returns an empty string if the dateTimeString cannot be parsed
  // look for the space (or possibly 'T') char separator between "YYYY-MM-DD" and the timestamp
  // we also allow it to be a date-only string
  const sepCharIdx = 10;
  const sepChar = dateTimeString.charAt(sepCharIdx);
  if (
    sepChar !== ' ' &&
    sepChar !== 'T' &&
    dateTimeString.length !== sepCharIdx
  ) {
    return '';
  }

  if (!includeTime) {
    return dateTimeString.substring(0, sepCharIdx);
  }

  // we're including the timestamp, so look for the end of the time part of
  // the string (the part after the seconds)
  const timeEndIdx = 19;
  const timeEndChar = dateTimeString.charAt(timeEndIdx);
  if (
    timeEndChar !== '.' &&
    timeEndChar !== '+' &&
    dateTimeString.length !== timeEndIdx
  ) {
    return '';
  }

  const dateTimeOnly = dateTimeString.substring(0, timeEndIdx);
  let tzStr = '';

  // see if we have a TZ specifier of the form "+hh:mm" at the end of the string
  const tzIdx = dateTimeString.length - 6;
  if (dateTimeString.charAt(tzIdx) === '+') {
    // if the TZ string is "+00:00", we append " UTC" to the string, else use it as-is
    const restOfString = dateTimeString.substring(tzIdx);
    if (restOfString === '+00:00') {
      tzStr = ' UTC';
    } else {
      tzStr = restOfString;
    }
  }
  return dateTimeOnly.concat(tzStr);
};
