import { useQuery } from 'react-query';
import { db as StardogDb, server as StardogServer } from 'stardog';
import { BaseConnection, getStardogConnection } from 'vet-bones/utils';

import { Connection as GraphConnection } from 'src/ui/graph/types';
import { runWithRefetchConnectionToken } from 'src/ui/hooks/refetchConnectionToken';

export type ServerMetrics = {
  numEdges: number;
  numDatabases: number;
  numNodes: number;
  numQueries: number;
  numReach: number;
  numWrites: number;
};

export const generateServerOverview = async (
  connection: GraphConnection | null | undefined
): Promise<ServerMetrics> => {
  const defaultMetrics = {
    numEdges: 0,
    numDatabases: 0,
    numNodes: 0,
    numQueries: 0,
    numReach: 0,
    numWrites: 0,
  };

  if (!connection || connection.isAllocating) {
    return defaultMetrics;
  }

  // if connection is not using SSO and there is no token on connection,
  // Stardog API requests are going to fail so short-circuit
  if (!connection.token && !connection.useSSO) {
    return defaultMetrics;
  }

  try {
    const stardogConnection = getStardogConnection(
      connection as BaseConnection
    );

    const serverStatusResponse = await runWithRefetchConnectionToken(
      stardogConnection,
      connection.index,
      (stardogConn) => StardogServer.status(stardogConn)
    );
    if (!serverStatusResponse.ok) {
      throw new Error(serverStatusResponse.statusText);
    }

    const databaseListResponse = await runWithRefetchConnectionToken(
      stardogConnection,
      connection.index,
      (stardogConn) => StardogDb.list(stardogConn)
    );
    if (!databaseListResponse.ok) {
      throw new Error(databaseListResponse.statusText);
    }

    return databaseListResponse.body?.databases.reduce(
      (prev: ServerMetrics, db: string): ServerMetrics => {
        const numNodes =
          serverStatusResponse.body?.[`kga.${db}.cn`]?.value || 0;
        const numEdges =
          serverStatusResponse.body?.[`kga.${db}.take`]?.value || 0;
        const numQueries =
          serverStatusResponse.body?.[`databases.${db}.queries.latency`]
            ?.count || 0;
        const numReach =
          serverStatusResponse.body?.[`kga.${db}.reach.cardinality`]?.value ||
          0;
        const numWrites =
          serverStatusResponse.body?.[`databases.${db}.txns.size`]?.count || 0;

        return {
          numEdges: prev.numEdges + numEdges,
          numDatabases: prev.numDatabases + 1,
          numNodes: prev.numNodes + numNodes,
          numQueries: prev.numQueries + numQueries,
          numReach: prev.numReach + numReach,
          numWrites: prev.numWrites + numWrites,
        };
      },
      defaultMetrics
    );
  } catch (e) {
    console.warn(e);
  }

  return defaultMetrics;
};

export const useServerOverview = (
  connection: GraphConnection | null | undefined
) => {
  const id = connection?.id || 'unknown';

  return useQuery(
    ['connection-overview', id],
    () => generateServerOverview(connection),
    {
      refetchInterval: 60000,
    }
  );
};
