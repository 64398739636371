import {
  AnchorButton,
  Button,
  Checkbox,
  H2,
  H3,
  Icon,
  Intent,
} from '@blueprintjs/core';
import * as React from 'react';
import DataTable from 'react-data-table-component';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import {
  GRAFANA_BASE_URL,
  GRAFANA_NAMESPACE_FILTER_PARAM,
  GRAFANA_NAMESPACE_FILTER_PARAM_KEY,
} from 'src/ui/constants/grafana';
import {
  INTERNAL_USER_DASHBOARD_BACK_TO_SEARCH,
  INTERNAL_USER_DASHBOARD_CLOUDS,
  INTERNAL_USER_DASHBOARD_FEATURE_TOGGLE,
} from 'src/ui/constants/testIds';
import {
  HistoryState,
  BASE_PATH as USER_SEARCH_HOME,
} from 'src/ui/containers/internal/UserSearch';
import { queueMessage } from 'src/ui/features/notifications';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  ArchivedCloud,
  Connection as GraphConnection,
  StardogCloud,
  UserFeaturesInput,
  useGetUserInfoQuery,
  useUpdateUserFeaturesMutation,
} from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import * as copy from 'src/ui/templates/copy';
import { getFormattedDateTime } from 'src/ui/utils/dateUtils';
import { getKubernetesNamespaceFromEndpoint } from 'src/ui/utils/getKubernetesNamespaceFromCloudEndpoint';
import { openApplicationInTab } from 'src/ui/utils/window';

type TParams = { id?: string };

export const UserDashboard: React.VFC = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedCloud, setSelectedCloud] = React.useState<StardogCloud | null>(
    null
  );
  const params = useParams<TParams>();
  const userId = params.id;
  const { data, isLoading, isError } = useGetUserInfoQuery(
    graphQLClient,
    { user_id: userId || '' },
    { enabled: !!userId }
  );

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const onError = useOnError();
  const onMutate = useOnMutate();
  const {
    mutate: updateUserFeatures,
    isLoading: isUpdatingUserFeatures,
  } = useUpdateUserFeaturesMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        useGetUserInfoQuery.getKey({ user_id: userId || '' })
      );

      if (variables.input.is_voicebox_enabled !== undefined) {
        const message = data.updateUserFeatures?.is_voicebox_enabled
          ? copy.components.internalDashboard.userDashboard.sections.userInfo
              .userFeatures.voicebox.enabled
          : copy.components.internalDashboard.userDashboard.sections.userInfo
              .userFeatures.voicebox.disabled;
        dispatch(queueMessage({ message, intent: Intent.SUCCESS }));
      }

      if (variables.input.is_static_voicebox !== undefined) {
        const message = data.updateUserFeatures?.is_static_voicebox
          ? copy.components.internalDashboard.userDashboard.sections.userInfo
              .userFeatures.staticVoicebox.enabled
          : copy.components.internalDashboard.userDashboard.sections.userInfo
              .userFeatures.staticVoicebox.disabled;
        dispatch(queueMessage({ message, intent: Intent.SUCCESS }));
      }

      if (variables.input.is_voicebox_api_access_enabled !== undefined) {
        const message = data.updateUserFeatures?.is_voicebox_api_access_enabled
          ? copy.components.internalDashboard.userDashboard.sections.userInfo
              .userFeatures.voiceboxAPI.enabled
          : copy.components.internalDashboard.userDashboard.sections.userInfo
              .userFeatures.voiceboxAPI.disabled;
        dispatch(queueMessage({ message, intent: Intent.SUCCESS }));
      }
    },
  });

  const connectionColumns = [
    {
      name: 'Name',
      id: 1,
      selector: (row: GraphConnection) => row?.name || '',
      wrap: true,
    },
    {
      name: 'Endpoint',
      id: 2,
      selector: (row: GraphConnection) => row?.endpoint || '',
      wrap: true,
    },
    {
      name: 'Type',
      id: 3,
      selector: (row: GraphConnection) => getConnectionType(row?.type || ''),
      wrap: true,
    },
  ];

  const cloudColumns = [
    {
      name: 'Endpoint',
      id: 1,
      selector: (row: StardogCloud) => row?.endpoint || '',
      grow: 2,
    },
    {
      name: 'Flavor',
      id: 2,
      selector: (row: StardogCloud) => row?.flavor?.name || '',
    },
    {
      name: 'Status',
      id: 3,
      selector: (row: StardogCloud) => row?.status || '',
    },
    {
      name: 'Region',
      id: 4,
      selector: (row: StardogCloud) => row?.region || '',
    },
  ];

  const archivedCloudColumns = [
    {
      name: 'Endpoint',
      id: 1,
      selector: (row: ArchivedCloud) => row?.endpoint || '',
      grow: 2,
    },
    {
      name: 'Flavor',
      id: 2,
      selector: (row: ArchivedCloud) => row?.flavor || '',
    },
    {
      name: 'Region',
      id: 3,
      selector: (row: ArchivedCloud) => row?.region || '',
    },
    {
      name: 'Payment Ref',
      id: 4,
      selector: (row: ArchivedCloud) => row?.payment_ref || '',
    },
    {
      name: 'Archived On',
      id: 5,
      selector: (row: ArchivedCloud) =>
        getFormattedDateTime(row?.created || '', false),
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className="error-page">
        <div className="error-content-wrapper">
          <p>{copy.components.internalDashboard.userDashboard.error.message}</p>
          <AnchorButton
            href="/internal/user/search"
            text={
              copy.components.internalDashboard.userDashboard.error.buttonText
            }
            icon="search"
            intent={Intent.PRIMARY}
            minimal
            outlined
          />
        </div>
      </div>
    );
  }

  const selectCloud = (cloud: StardogCloud) => {
    setSelectedCloud(cloud);
  };

  const trueOrFalse = (value: boolean) => {
    return value ? (
      <Icon icon="endorsed" intent={Intent.SUCCESS} />
    ) : (
      <Icon icon="cross" intent={Intent.DANGER} />
    );
  };

  const userFeatureCheckbox = (
    key: keyof UserFeaturesInput,
    value: boolean
  ) => {
    const onChange = () => {
      if (!userId) {
        return;
      }
      updateUserFeatures({
        user_id: userId,
        input: { [key]: !value },
      });
    };
    return (
      <Checkbox
        data-testid={INTERNAL_USER_DASHBOARD_FEATURE_TOGGLE(key)}
        checked={value}
        disabled={isUpdatingUserFeatures}
        onChange={onChange}
      />
    );
  };

  const getConnectionType = (connectionType: string) => {
    switch (connectionType) {
      case 'U':
        return 'User Defined';
      case 'F':
        return 'Stardog Free';
      case 'C':
        return 'Stardog Cloud';
      default:
        return 'Unknown';
    }
  };

  const richFormatDateTime = (dateTimeString: string) => {
    const splitString = dateTimeString.split(' ', 2);

    if (splitString.length !== 2) {
      return 'None';
    }

    const date = splitString[0];
    const timeWithOffset = splitString[1];
    const splitTimeWithOffset = timeWithOffset.split('+', 2);
    const timeWithoutOffset = splitTimeWithOffset[0];
    return (
      <div className="date-time">
        <span>
          <Icon icon="calendar" /> {date}
        </span>
        <span>
          <Icon icon="time" /> {timeWithoutOffset} UTC
        </span>
      </div>
    );
  };

  const openGrafanaInNewTab = () => {
    const cloudEndpoint = selectedCloud?.endpoint || '';
    const instancesKubernetesNamespace = getKubernetesNamespaceFromEndpoint(
      cloudEndpoint
    );

    // See https://grafana.com/docs/grafana/latest/dashboards/build-dashboards/create-dashboard-url-variables/#passing-ad-hoc-filters for additional info
    const grafanaURL = `${GRAFANA_BASE_URL}/d/${data?.grafanaHighLevelDashboardSettings?.id}/${data?.grafanaHighLevelDashboardSettings?.slug}?orgId=1&${GRAFANA_NAMESPACE_FILTER_PARAM}=${GRAFANA_NAMESPACE_FILTER_PARAM_KEY}|=|${instancesKubernetesNamespace}`;
    const encodedGrafanaURL = encodeURI(grafanaURL);
    openApplicationInTab(encodedGrafanaURL);
  };

  const goBackToSearch = () => {
    const locationState = location.state as HistoryState;
    if (locationState && locationState.from === USER_SEARCH_HOME) {
      history.goBack();
    } else {
      history.push(USER_SEARCH_HOME);
    }
  };

  return (
    <div className="user-dashboard">
      <div className="button-bar">
        <AnchorButton
          data-testid={INTERNAL_USER_DASHBOARD_BACK_TO_SEARCH}
          onClick={() => goBackToSearch()}
          text="Back to Search"
          icon="search"
          intent={Intent.PRIMARY}
          minimal
          outlined
        />
      </div>
      <div className="grid-container">
        <div className="user-info-panel">
          <H2>
            {data?.getUser?.first_name} {data?.getUser?.last_name}
          </H2>

          <div className="details-grid">
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.email
              }
            </div>
            <div>{data?.getUser?.email}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.firstName
              }
            </div>
            <div>{data?.getUser?.first_name}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.lastName
              }
            </div>

            <div>{data?.getUser?.last_name}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.company
              }
            </div>
            <div>{data?.getUser?.company}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.title
              }
            </div>
            <div>{data?.getUser?.title}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.phone
              }
            </div>
            <div>{data?.getUser?.phone}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.useCase
              }
            </div>
            <div>{data?.getUser?.use_case}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.dateJoined
              }
            </div>
            <div>{richFormatDateTime(data?.getUser?.date_joined || '')}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.lastLogin
              }
            </div>
            <div>{richFormatDateTime(data?.getUser?.last_login || '')}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.hasStardogFree
              }
            </div>
            <div>{trueOrFalse(data?.getUser?.has_stardog_free || false)}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.isDatabricksUser
              }
            </div>
            <div>{trueOrFalse(data?.getUser?.is_databricks_user || false)}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.isVerified
              }
            </div>
            <div>{trueOrFalse(data?.getUser?.is_verified || false)}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.isStaff
              }
            </div>
            <div>{trueOrFalse(data?.getUser?.is_staff || false)}</div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.isVoiceboxEnabled
              }
            </div>
            <div>
              {userFeatureCheckbox(
                'is_voicebox_enabled',
                data?.getUser?.is_voicebox_enabled || false
              )}
            </div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.isStaticVoicebox
              }
            </div>
            <div>
              {userFeatureCheckbox(
                'is_static_voicebox',
                data?.getUser?.is_static_voicebox || false
              )}
            </div>
            <div className="key">
              {
                copy.components.internalDashboard.userDashboard.sections
                  .userInfo.detailsKeyNames.isVoiceboxAPIEnabled
              }
            </div>
            <div>
              {userFeatureCheckbox(
                'is_voicebox_api_access_enabled',
                data?.getUser?.is_voicebox_api_access_enabled || false
              )}
            </div>
          </div>
        </div>
        <div className="connection-cloud-panel">
          <div className="connection-container">
            <H3>
              {
                copy.components.internalDashboard.userDashboard.sections
                  .connections.header
              }
            </H3>
            <DataTable
              columns={connectionColumns}
              data={
                data?.getUserConnections
                  ? (data.getUserConnections as GraphConnection[])
                  : []
              }
              dense
              pagination
              progressPending={isLoading}
              responsive
              striped
            />
          </div>
          <div
            data-testid={INTERNAL_USER_DASHBOARD_CLOUDS}
            className="cloud-container"
          >
            <H3>
              {
                copy.components.internalDashboard.userDashboard.sections.clouds
                  .header
              }
            </H3>
            <p>
              {
                copy.components.internalDashboard.userDashboard.sections.clouds
                  .description
              }
            </p>
            <DataTable
              columns={cloudColumns}
              data={
                data?.getUserClouds
                  ? (data.getUserClouds as StardogCloud[])
                  : []
              }
              dense
              pagination
              pointerOnHover
              progressPending={isLoading}
              striped
              onRowClicked={selectCloud}
              highlightOnHover
              responsive
            />
          </div>
          <div className="archived-cloud-container">
            <H3>
              {
                copy.components.internalDashboard.userDashboard.sections
                  .archivedClouds.header
              }
            </H3>
            <DataTable
              columns={archivedCloudColumns}
              data={
                data?.getUserArchivedClouds
                  ? (data.getUserArchivedClouds as ArchivedCloud[])
                  : []
              }
              dense
              pagination
              progressPending={isLoading}
              striped
              responsive
            />
          </div>
        </div>
        {selectedCloud && (
          <div className="cloud-details-panel">
            <div className="cloud-details-header">
              <H3>{selectedCloud.endpoint}</H3>
              <hr />
            </div>
            <div className="details-grid">
              <div className="key">
                {
                  copy.components.internalDashboard.userDashboard.sections
                    .selectedCloudDetails.detailsKeyNames.endpoint
                }
              </div>
              <div>{selectedCloud.endpoint}</div>
              <div className="key">
                {
                  copy.components.internalDashboard.userDashboard.sections
                    .selectedCloudDetails.detailsKeyNames.flavor
                }
              </div>
              <div>{selectedCloud.flavor?.name}</div>
              <div className="key">
                {
                  copy.components.internalDashboard.userDashboard.sections
                    .selectedCloudDetails.detailsKeyNames.status
                }
              </div>
              <div>{selectedCloud.status}</div>
              <div className="key">
                {
                  copy.components.internalDashboard.userDashboard.sections
                    .selectedCloudDetails.detailsKeyNames.region
                }
              </div>
              <div>{selectedCloud.region}</div>
              <div className="key">
                {
                  copy.components.internalDashboard.userDashboard.sections
                    .selectedCloudDetails.detailsKeyNames.paymentRef
                }
              </div>
              <div>{selectedCloud.payment_ref}</div>
              <div className="key">
                {
                  copy.components.internalDashboard.userDashboard.sections
                    .selectedCloudDetails.detailsKeyNames.created
                }
              </div>
              <div>{richFormatDateTime(selectedCloud.created || '')}</div>
              <div className="key">
                {
                  copy.components.internalDashboard.userDashboard.sections
                    .selectedCloudDetails.detailsKeyNames.updated
                }
              </div>
              <div>{richFormatDateTime(selectedCloud.updated || '')}</div>
            </div>
            <div>
              <Button
                minimal
                outlined
                intent={Intent.PRIMARY}
                rightIcon="chart"
                onClick={openGrafanaInNewTab}
                text={
                  copy.components.internalDashboard.userDashboard.sections
                    .selectedCloudDetails.grafanaButtonText
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
