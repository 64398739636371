import { UseQueryOptions, useQuery } from 'react-query';
import Stardog, {
  Connection as StardogConnection,
  db as StardogDb,
  query as StardogQuery,
  server as StardogServer,
  user as StardogUser,
} from 'stardog';
import { BaseConnection, getStardogConnection } from 'vet-bones/utils';

import { AnyFunc } from 'src/ui/constants/types';
import { Connection as GraphConnection } from 'src/ui/graph/types';
import { runWithRefetchConnectionToken } from 'src/ui/hooks/refetchConnectionToken';

export const DB_NAME_REGEX = /[A-Za-z]{1}[A-Za-z0-9_-]*/;
export const CATALOG_DB_SERVER_PROPERTY = 'catalog.database';
export const CATALOG_DB_DEFAULT = 'catalog';
export const QUERYLOG_DB_SERVER_PROPERTY = 'dbms.query.log.database';
export const QUERYLOG_DB_DEFAULT = 'querylog';

/**
 * For any databases we create, these are the known set of options that provide the best
 * experience with our tooling.
 */
export const EXPLORER_DB_OPTIONS = {
  // for optimal Explorer experience
  search: { enabled: true },

  // for the marketplace
  graph: { aliases: true },
};

/**
 * Lists the names of databases on the server associated with the provided connection
 */
export const canCreateDb = async (
  connection: GraphConnection | null | undefined
): Promise<boolean> => {
  if (!connection || connection.isAllocating) {
    return Promise.resolve(false);
  }

  try {
    // this is not as comprehensive a check as a standard security check on the server
    // and may not entirely match the semantics. however this logic dictates whether or
    // not a UX component is shown, so it's not critical that it's a perfect match.
    //
    // when the platform adds in standard role definitions, we might be able to adopt
    // those here and make the check simpler
    const checker = (p: StardogUser.Permission) => {
      // @ts-ignore ALL is a valid action for Stardog, stardog.js is wrong
      const canCreate = p.action === 'ALL' || p.action === 'CREATE';

      const resourceIsDb =
        // @ts-ignore * is a valid resource type, stardog.js is wrong. the property name is also wrong in stardog.js
        p.resource_type === '*' || p.resource_type === 'db';

      return canCreate && resourceIsDb;
    };

    return hasPermission(connection, connection.username || '', checker);
  } catch (e) {
    console.warn(e);
  }

  return Promise.resolve(false);
};

const filterUserDefinedDatabases = async (
  connection: GraphConnection,
  stardogConnection: StardogConnection,
  allDatabases: string[]
): Promise<string[]> => {
  let catalogDb = CATALOG_DB_DEFAULT;
  let querylogDb = QUERYLOG_DB_DEFAULT;

  try {
    // TODO: This should maybe be cached? React query could maybe help with this somehow?
    const serverProps = await runWithRefetchConnectionToken(
      stardogConnection,
      connection.index,
      (stardogConn) =>
        StardogServer.properties(stardogConn, [
          CATALOG_DB_SERVER_PROPERTY,
          QUERYLOG_DB_SERVER_PROPERTY,
        ])
    );
    catalogDb =
      serverProps.body[CATALOG_DB_SERVER_PROPERTY] ?? CATALOG_DB_DEFAULT;
    querylogDb =
      serverProps.body[QUERYLOG_DB_SERVER_PROPERTY] ?? QUERYLOG_DB_DEFAULT;
  } catch (e) {
    console.warn(e);
  }

  return allDatabases.filter((db) => db !== catalogDb && db !== querylogDb);
};

/**
 * Lists the names of databases on the server associated with the provided connection
 */
export const listDatabases = async (
  connection: GraphConnection | null | undefined,
  options = {
    userDefinedOnly: false,
  }
): Promise<string[]> => {
  if (!connection || connection.isAllocating) {
    return [];
  }

  try {
    const stardogConnection = getStardogConnection(
      connection as BaseConnection
    );

    const databaseResponse = await runWithRefetchConnectionToken(
      stardogConnection,
      connection.index,
      (stardogConn) => StardogDb.list(stardogConn)
    );
    if (!databaseResponse.ok) {
      throw new Error(databaseResponse.statusText);
    }

    if (options.userDefinedOnly) {
      return filterUserDefinedDatabases(
        connection,
        stardogConnection,
        databaseResponse.body?.databases || []
      );
    }

    return databaseResponse.body?.databases || [];
  } catch (e) {
    console.warn(e);
  }

  return [];
};

export const useListDatabases = (
  connection: GraphConnection,
  options?: UseQueryOptions<string[], Error, string[]>
) =>
  useQuery<string[], Error>(
    ['listDatabases', connection.id],
    () => listDatabases(connection),
    options
  );

/**
 * The result of sharing a database with another user. The provided URL can be used to
 * complete the share. If that user did not have an existing account on this database,
 * the password for their new account will also be specified.
 */
export type ShareResult = {
  url: string;
  password?: string;
  message?: string;
};

export enum ShareAction {
  Read,
  ReadWrite,
}

const generator = (len: number) => {
  const base = [
    ...'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
  ];
  return [...Array(len)]
    .map(() => base[Math.floor(Math.random() * base.length)])
    .join('');
};

const permissionsEqual = (
  p1: StardogUser.Permission,
  p2: StardogUser.Permission
): boolean => {
  return (
    p1.action === p2.action &&
    p1.resourceType === p2.resourceType &&
    p1.resources.length === p2.resources.length &&
    p1.resources.every((r) => p2.resources.includes(r))
  );
};

export type SelectResult = {
  head?: { vars?: string[]; link?: string[] };
  results?: {
    bindings: any[];
  };
};

/**
 * Execute a SPARQL query against the provided database
 */
export const query = async (
  connection: GraphConnection | null | undefined,
  db: string | null | undefined,
  query: string | null | undefined
): Promise<SelectResult> => {
  /* istanbul ignore next: no need to test defensive code */
  if (!connection || connection.isAllocating || !db || !query) {
    return {};
  }

  const stardogConnection = getStardogConnection(connection as BaseConnection);

  return runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogQuery.execute(stardogConn, db, query)
  ).then((response) => {
    return response.body;
  });
};

/**
 * Execute a SPARQL update query against the provided database
 */
export const update = async (
  connection: GraphConnection | null | undefined,
  db: string | null | undefined,
  query: string | null | undefined
): Promise<Stardog.HTTP.Body> => {
  /* istanbul ignore next: no need to test defensive code */
  if (!connection || connection.isAllocating || !db || !query) {
    return Promise.reject();
  }

  const stardogConnection = getStardogConnection(connection as BaseConnection);

  return runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogQuery.execute(stardogConn, db, query)
  ).then((response) => {
    return response;
  });
};

/**
 * List the stored queries for the given database
 */
export const sq = async (
  connection: GraphConnection | null | undefined,
  db: string | null | undefined
): Promise<StardogQuery.StoredQueryOptions[]> => {
  /* istanbul ignore next: no need to test defensive code */
  if (!connection || connection.isAllocating) {
    return [];
  }

  const stardogConnection = getStardogConnection(connection as BaseConnection);

  return runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogQuery.stored.list(stardogConn)
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.body?.queries.filter(
      (sq: StardogQuery.StoredQueryOptions) => sq.database === db
    );
  });
};

/**
 * Return whether or not the user passes the permission check
 *
 * @param connection portal connection
 * @param user user name
 * @param permissionCheck permission checker, accepts a single input of `StardogUser.Permission`
 * @returns whether or not any of the user's permissions satisifies the provided permission checker
 */
export const hasPermission = async (
  connection: GraphConnection | null | undefined,
  user: string,
  permissionCheck: AnyFunc
): Promise<boolean> => {
  const defaultResult = false;

  /* istanbul ignore next: no need to test defensive code */
  if (!connection || connection.isAllocating) {
    return defaultResult;
  }

  try {
    const stardogConnection = getStardogConnection(
      connection as BaseConnection
    );

    // note this is limited by https://stardog.atlassian.net/browse/PLAT-2815
    return runWithRefetchConnectionToken(
      stardogConnection,
      connection.index,
      (stardogConn) => StardogUser.effectivePermissions(stardogConn, user)
    ).then((result) => {
      const { permissions } = result.body;
      return (
        permissions.find((p: StardogUser.Permission) => permissionCheck(p)) !==
        undefined
      );
    });
  } catch (e) /* istanbul ignore next: no need to test defensive code */ {
    console.warn(e);
    return defaultResult;
  }
};

/**
 * Create a database on the server
 */
export const create = async (
  connection: GraphConnection,
  db: string,
  options: any
): Promise<boolean> => {
  try {
    const stardogConnection = getStardogConnection(
      connection as BaseConnection
    );

    return runWithRefetchConnectionToken(
      stardogConnection,
      connection.index,
      (stardogConn) => StardogDb.create(stardogConn, db, options)
    ).then((r) => r.ok);
  } catch (e) /* istanbul ignore next: no need to test defensive code */ {
    console.warn(e);
    return Promise.resolve(false);
  }
};

/**
 * Add the given content to the database
 */
export const add = async (
  connection: GraphConnection,
  db: string,
  data: string,
  contentType: Stardog.HTTP.RdfMimeType
): Promise<boolean> => {
  try {
    const stardogConnection = getStardogConnection(
      connection as BaseConnection
    );

    return runWithRefetchConnectionToken(
      stardogConnection,
      connection.index,
      (stardogConn) => StardogDb.transaction.begin(stardogConn, db)
    ).then((response) => {
      if (!response.ok) {
        return false;
      }

      return runWithRefetchConnectionToken(
        stardogConnection,
        connection.index,
        (stardogConn) =>
          StardogDb.add(stardogConn, db, response.transactionId, data, {
            // @ts-ignore working around an issue in stardog.js
            encoding: undefined,
            contentType,
          })
      ).then((results) => {
        if (!results.ok) {
          // what if this fails? :grimacing: -- result is always false because the tx failed, even if rollback is fine
          return runWithRefetchConnectionToken(
            stardogConnection,
            connection.index,
            (stardogConn) =>
              StardogDb.transaction.rollback(
                stardogConn,
                db,
                response.transactionId
              )
          ).then(() => false);
        }

        return runWithRefetchConnectionToken(
          stardogConnection,
          connection.index,
          (stardogConn) =>
            StardogDb.transaction.commit(
              stardogConn,
              db,
              response.transactionId
            )
        ).then((r) => {
          if (!r.ok) {
            return runWithRefetchConnectionToken(
              stardogConnection,
              connection.index,
              (stardogConn) =>
                StardogDb.transaction.rollback(
                  stardogConn,
                  db,
                  response.transactionId
                )
            ).then(() => false);
          }
          return r.ok;
        });
      });
    });
  } catch (e) /* istanbul ignore next: no need to test defensive code */ {
    console.warn(e);
    return Promise.resolve(false);
  }
};

// TODO: will need to reconsider the dataset this is executed against
const QUERY_CLASSES = `select distinct ?label ?cls ?freq
from <tag:stardog:api:context:local>
where {
    ?inst a ?cls .
    ?cls rdfs:label ?label .
    ?cls <tag:stardog:frequency:class> ?freq
}
order by desc(?freq)`;

export interface Concept {
  iri: string;
  label: string;
  count: number;
}

export const explain = async (
  connection: GraphConnection | null | undefined,
  db: string | null | undefined,
  query: string | null | undefined
): Promise<string> => {
  /* istanbul ignore next: no need to test defensive code */
  if (!connection || connection.isAllocating || !db) {
    return '';
  }

  const stardogConnection = getStardogConnection(connection as BaseConnection);

  return runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogQuery.explain(stardogConn, db, query || '')
  ).then(({ body }) => body);
};

export const getConcepts = async (
  connection: GraphConnection | null | undefined,
  db: string | null | undefined
): Promise<Concept[]> => {
  /* istanbul ignore next: no need to test defensive code */
  if (!connection || connection.isAllocating || !db) {
    return [];
  }

  const stardogConnection = getStardogConnection(connection as BaseConnection);

  return runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogQuery.execute(stardogConn, db, QUERY_CLASSES)
  ).then(({ body }) => {
    return body.results.bindings.map(
      (rs: any): Concept => {
        return {
          iri: rs.cls.value,
          label: rs.label.value,
          count: parseInt(rs.freq.value, 10),
        };
      }
    );
  });
};

/**
 * Share a resource on the connection's server with another person.
 *
 * If the user does not exist on the server, it will be created.
 * If a database is provided, the user will be given permissions to
 * be able to perform the specified actions (read or read/write)
 * on that database.
 */
export const share = async (
  connection: GraphConnection | null | undefined,
  user: string,
  db: string | null | undefined,
  action: ShareAction | null | undefined
): Promise<ShareResult> => {
  const defaultResult = { url: '' };

  /* istanbul ignore next: no need to test defensive code */
  if (!connection || connection.isAllocating) {
    return defaultResult;
  }

  try {
    const stardogConnection = getStardogConnection(
      connection as BaseConnection
    );

    let password: string | undefined;

    const userRequest = await runWithRefetchConnectionToken(
      stardogConnection,
      connection.index,
      (stardogConn) => StardogUser.list(stardogConn)
    );
    if (!userRequest.ok) {
      return { ...defaultResult, message: 'Failed to list users' };
    }

    const { users } = userRequest.body;

    const promises: Array<Promise<Stardog.HTTP.Body>> = [];

    if (!users.includes(user)) {
      password = generator(16);

      const userResult = await runWithRefetchConnectionToken(
        stardogConnection,
        connection.index,
        (stardogConn) =>
          StardogUser.create(stardogConn, {
            // @ts-ignore, i think this is an issue in stardog.js?
            name: user,
            password: password as string,
            superuser: false,
          })
      );
      /* istanbul ignore next: no need to test defensive code */
      if (!userResult.ok) {
        return { ...defaultResult, message: 'Failed to create user' };
      }
    }

    if (db) {
      const requiredReadPermissions: StardogUser.Permission[] = [
        { action: 'READ', resourceType: 'db', resources: [db] },
        { action: 'READ', resourceType: 'metadata', resources: [db] },
      ];

      const requiredWritePermissions: StardogUser.Permission[] = [
        { action: 'WRITE', resourceType: 'db', resources: [db] },
        { action: 'WRITE', resourceType: 'metadata', resources: [db] },
      ];

      const currentPermissions: StardogUser.Permission[] = await runWithRefetchConnectionToken(
        stardogConnection,
        connection.index,
        (stardogConn) => StardogUser.permissions(stardogConn, user)
      ).then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        return response.body?.permissions;
      });

      requiredReadPermissions
        .filter(
          (p) => !currentPermissions.some((cp) => permissionsEqual(p, cp))
        )
        .forEach((p) =>
          promises.push(
            runWithRefetchConnectionToken(
              stardogConnection,
              connection.index,
              (stardogConn) =>
                StardogUser.assignPermission(stardogConn, user, p)
            )
          )
        );

      if (action === ShareAction.ReadWrite) {
        requiredWritePermissions
          .filter(
            (p) => !currentPermissions.some((cp) => permissionsEqual(p, cp))
          )
          .forEach((p) =>
            promises.push(
              runWithRefetchConnectionToken(
                stardogConnection,
                connection.index,
                (stardogConn) =>
                  StardogUser.assignPermission(stardogConn, user, p)
              )
            )
          );
      }
    }

    const results = await Promise.all(promises);

    /* istanbul ignore next: no need to test defensive code */
    if (results.some((b) => !b.ok)) {
      return { ...defaultResult, message: 'Failed to assign permissions.' };
    }

    const shareUrl = `https://cloud.stardog.com/connect?endpoint=${connection.endpoint}&username=${user}&useSSO=true&name=${connection.name}`;

    return {
      url: shareUrl,
      password,
    };
  } catch (e) /* istanbul ignore next: no need to test defensive code */ {
    console.warn(e);
    return defaultResult;
  }
};
