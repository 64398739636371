import { Classes } from '@blueprintjs/core';
import * as React from 'react';

import { copy } from 'src/ui/templates/copy';
import microsoftSignInBtn from 'src/ui/templates/images/ms-icon.svg';
import { doAzureRedirect } from 'src/ui/utils/window';

/**
 * This uses just a plain button as we use an image to display the actual
 * button. We don't want this to be styled by blueprintjs.
 */
export const AzureLink: React.VFC = () => {
  return (
    <button
      type="button"
      onClick={doAzureRedirect}
      className={`${Classes.BUTTON} oauth`}
    >
      <img src={microsoftSignInBtn} alt={copy.azure.label} />
      {copy.azure.label}
    </button>
  );
};
