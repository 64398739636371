/**
 * Returns a kubernetes namespace contained in a Stardog Cloud instance endpoint
 * e.g. https://sd-12345.stardoglabs.com -> sd-12345
 */
export const getKubernetesNamespaceFromEndpoint = (endpoint: string) => {
  const httpRegex = new RegExp('^https?://');
  const portRegex = new RegExp(':\\d+');
  const intprodDomainRegex = new RegExp('.stardoglabs.com');
  const prodDomainRegex = new RegExp('.stardog.cloud');

  return endpoint
    .replace(httpRegex, '')
    .replace(portRegex, '')
    .replace(intprodDomainRegex, '')
    .replace(prodDomainRegex, '');
};
