import { UseQueryResult } from 'react-query';
import {
  STARDOG_CONTEXT_ALL_IRI,
  STARDOG_CONTEXT_DEFAULT_IRI,
  STARDOG_CONTEXT_LOCAL_IRI,
  STARDOG_CONTEXT_NAMED_IRI,
  STARDOG_CONTEXT_VIRTUAL_IRI,
} from 'vet-bones/constants';
import { VoiceboxNamedGraphs } from 'vet-bones/constants/voicebox';
import { alphaSort, alphaSorter, getLocalName } from 'vet-bones/utils';
import {
  SelectItem,
  createSelectItem,
  createSelectItemDivider,
} from 'vet-bones/utils/select';
import {
  usePortalVoiceboxDatabases,
  usePortalVoiceboxDatabasesNamedGraphs,
} from 'vet-bones/utils/voicebox';

export const SPECIAL_NAMED_GRAPH_IRIS = [
  STARDOG_CONTEXT_ALL_IRI,
  STARDOG_CONTEXT_DEFAULT_IRI,
  STARDOG_CONTEXT_LOCAL_IRI,
  STARDOG_CONTEXT_NAMED_IRI,
  STARDOG_CONTEXT_VIRTUAL_IRI,
];

const getNamedGraphsByDatabase = (
  queries: Array<
    UseQueryResult<{
      databaseId: string;
      namedGraphs: VoiceboxNamedGraphs;
    } | null>
  >
) =>
  queries
    .map((query) => query.data)
    .filter((data): data is {
      databaseId: string;
      namedGraphs: VoiceboxNamedGraphs;
    } => Boolean(data))
    .reduce<Record<string, VoiceboxNamedGraphs>>(
      (acc, { databaseId, namedGraphs }) => {
        acc[databaseId] = namedGraphs;
        return acc;
      },
      {}
    );

export const useVoiceboxConfigItems = ({
  copy,
  connectionIdx,
  databaseId,
  enabled,
}: {
  copy: { alias: string; named: string; virtual: string };
  connectionIdx: number;
  databaseId: string;
  enabled: boolean;
}) => {
  const databasesQuery = usePortalVoiceboxDatabases(connectionIdx, {
    enabled,
  });
  const databasesById = databasesQuery.data || {};

  const databaseNamedGraphQueries = usePortalVoiceboxDatabasesNamedGraphs(
    connectionIdx,
    Object.keys(databasesById),
    () => ({
      enabled: enabled && !databasesQuery.isLoading,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    })
  );
  const namedGraphsByDatabaseId = getNamedGraphsByDatabase(
    databaseNamedGraphQueries
  );

  const databaseItems = Object.keys(databasesById)
    .sort(alphaSorter)
    .map((databaseId) => createSelectItem(databaseId, databaseId));

  const namedGraphItems = SPECIAL_NAMED_GRAPH_IRIS.map((item) =>
    createSelectItem(getLocalName(item), item)
  );
  const modelItems: SelectItem[] = [];

  const addIrisToNamedGraphs = (text: string, iris: string[]) => {
    namedGraphItems.push(
      createSelectItemDivider(text),
      ...iris
        .map((iri) => createSelectItem(getLocalName(iri), iri))
        .sort(alphaSort(({ text }) => text))
    );
  };

  const namedGraphs = namedGraphsByDatabaseId[databaseId];
  if (namedGraphs) {
    const named: string[] = [];
    const aliases: string[] = [];
    const virtual: string[] = [];

    Object.values(namedGraphs).forEach(({ iri, isAlias, isVirtual }) => {
      if (isAlias) {
        aliases.push(iri);
      } else if (isVirtual) {
        virtual.push(iri);
      } else {
        named.push(iri);
      }
    });

    if (aliases.length) {
      addIrisToNamedGraphs(copy.alias, aliases);
    }
    if (named.length) {
      addIrisToNamedGraphs(copy.named, named);
    }
    if (virtual.length) {
      addIrisToNamedGraphs(copy.virtual, virtual);
    }
  }

  const databaseData = databasesById[databaseId];
  if (databaseData) {
    modelItems.push(
      ...[...databaseData.models]
        .sort(alphaSorter)
        .map((model) => createSelectItem(model, model))
    );
  }

  return {
    databasesById,
    databaseItems,
    namedGraphsByDatabaseId,
    namedGraphItems,
    modelItems,
  };
};
