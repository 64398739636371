import {
  Button,
  Callout,
  DialogBody,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import {
  DELETE_ACCOUNT_DIALOG_CONFIRMATION_INPUT,
  DELETE_ACCOUNT_DIALOG_DELETE_ACCOUNT_BUTTON,
} from 'src/ui/constants/testIds';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useDeleteAccountMutation } from 'src/ui/graph/types';
import { useOnError } from 'src/ui/hooks/graph';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import { components } from 'src/ui/templates/copy';
import * as Window from 'src/ui/utils/window';

const isOpenSelector = isDialogOpenSelector(DialogType.DELETE_ACCOUNT);

export const DeleteAccountDialog: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isOpenSelector);

  const onError = useOnError();
  const { mutate: deleteAccountMutation } = useDeleteAccountMutation(
    graphQLClient,
    {
      onError,
      onSuccess: () => {
        dispatch(closedDialog());
        Window.redirectToUrl('/auth/logout');
      },
    }
  );
  const [deleteInput, setDeleteInput] = React.useState<string>('');
  const DELETE = 'DELETE';

  return (
    <Dialog
      canOutsideClickClose
      isOpen={isOpen}
      onClose={() => {
        setDeleteInput('');
        dispatch(closedDialog());
      }}
      className="sd-dialog-delete-account"
      canEscapeKeyClose
      title={components.deleteAccountDialog.title}
      icon={IconNames.WARNING_SIGN}
    >
      <DialogBody className="sd-dialog-delete-account__body">
        <Callout intent={Intent.DANGER} icon={null}>
          {components.deleteAccountDialog.callout}
        </Callout>
        <div className="sd-dialog-delete-account__input_container">
          <FormGroup
            label={components.deleteAccountDialog.deletionConfirmationLabel}
            labelFor="deletion-input-confirmation"
            labelInfo="(required)"
          >
            <InputGroup
              data-testid={DELETE_ACCOUNT_DIALOG_CONFIRMATION_INPUT}
              id="deletion-input-confirmation"
              placeholder={DELETE}
              value={deleteInput}
              onChange={(e) => setDeleteInput(e.target.value)}
            />
          </FormGroup>
          <Button
            data-testid={DELETE_ACCOUNT_DIALOG_DELETE_ACCOUNT_BUTTON}
            minimal
            outlined
            intent={Intent.DANGER}
            text={components.deleteAccountDialog.deletionButton}
            onClick={() => deleteAccountMutation({})}
            disabled={deleteInput !== DELETE}
          />
        </div>
      </DialogBody>
    </Dialog>
  );
};
