import { FormGroup, InputGroup } from '@blueprintjs/core';
import * as React from 'react';

import type { DataSourceSubFormProps } from 'src/ui/containers/dialogs/DataSourceForm';
import * as copy from 'src/ui/templates/copy';

export const DataSourceElasticSearchForm: React.VFC<DataSourceSubFormProps> = ({
  dataSource,
  formHandler,
  disabled,
}) => {
  const { formData, handleTextFieldChange } = formHandler;

  return (
    <>
      <FormGroup
        labelFor="data-source-elasticsearch-name"
        label={copy.components.dataSourceDialog.form.name}
        helperText={copy.components.dataSourceDialog.form.nameHelp}
      >
        <InputGroup
          id="data-source-elasticsearch-name"
          value={dataSource.name}
          fill
          disabled
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.elasticsearch.urls}
        labelFor="data-source-elasticsearch-url"
      >
        <InputGroup
          id="data-source-elasticsearch-url"
          value={formData['elasticsearch.rest.urls']}
          disabled
          name="elasticsearch.rest.urls"
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.elasticsearch.username}
        labelFor="data-source-elasticsearch-username"
      >
        <InputGroup
          autoFocus
          id="data-source-elasticsearch-username"
          name="elasticsearch.username"
          autoComplete="username"
          value={formData['elasticsearch.username']}
          disabled={disabled}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.elasticsearch.password}
        labelFor="data-source-elasticsearch-password"
      >
        <InputGroup
          id="data-source-elasticsearch-password"
          name="elasticsearch.password"
          type="password"
          autoComplete="password"
          disabled={disabled}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <input type="submit" hidden />
    </>
  );
};
