import {
  Button,
  Callout,
  Card,
  Collapse,
  DialogBody,
  FormGroup,
  InputGroup,
  Intent,
  MenuItem,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Select2 } from '@blueprintjs/select';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  SsoConnectionRegistration,
  useAddSsoConnectionMutation,
  useGetSsoConnectionRegistryQuery,
} from 'src/ui/graph/types';
import { useOnError } from 'src/ui/hooks/graph';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import { components } from 'src/ui/templates/copy';
import { redirectToUrl } from 'src/ui/utils/window';

const isOpenSelector = isDialogOpenSelector(DialogType.SSO_CONNECTION);

/* istanbul ignore next */
export const AddSSOConnectionDialog: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isOpenSelector);

  const onError = useOnError();
  const { mutate: addSSOConnectionMutation } = useAddSsoConnectionMutation(
    graphQLClient,
    {
      onError,
      onSuccess: (data) => {
        dispatch(closedDialog());
        if (data?.addSSOConnection) {
          redirectToUrl(data?.addSSOConnection?.redirect_url);
        }
      },
    }
  );
  const {
    data: SsoConnectionRegistry,
    isLoading: isRegistryLoading,
  } = useGetSsoConnectionRegistryQuery(graphQLClient);

  const [name, setName] = React.useState<string>('');
  const [stardogEndpoint, setStardogEndpoint] = React.useState<string>('');
  const [
    selectedRegistration,
    setSelectedRegistation,
  ] = React.useState<SsoConnectionRegistration | null>(null);
  const [nameTouched, setNameTouched] = React.useState(false);
  const [endpointTouched, setEndpointTouched] = React.useState(false);
  const [registrationTouched, setRegistrationTouched] = React.useState(false);
  const [isHelpOpen, setIsHelpOpen] = React.useState(false);

  const { addSsoConnectionDialog: dialogCopy } = components.launchpad;

  const toggleHelp = () => setIsHelpOpen(!isHelpOpen);

  const handleAddConnection = () => {
    addSSOConnectionMutation({
      input: {
        connection_name: name,
        stardog_endpoint: stardogEndpoint,
        provider_name: selectedRegistration?.provider_name ?? '',
      },
    });
  };

  const renderRegistration = (
    registration: SsoConnectionRegistration,
    {
      handleClick,
    }: { handleClick: (event: React.MouseEvent<HTMLElement>) => void }
  ) => (
    <MenuItem
      key={registration.provider_name}
      text={registration.display_provider_name}
      onClick={handleClick}
    />
  );

  const handleRegistrationChange = (
    registration: SsoConnectionRegistration
  ) => {
    setRegistrationTouched(true);
    setSelectedRegistation(registration);
    if (registration.stardog_endpoint) {
      setStardogEndpoint(registration.stardog_endpoint);
      setEndpointTouched(true);
    }
  };

  const registrations =
    SsoConnectionRegistry?.getSSOConnectionRegistry?.filter(
      (r): r is SsoConnectionRegistration => r !== null
    ) ?? [];

  // Validation checks
  const isNameValid = name.trim() !== '';
  const isEndpointValid = stardogEndpoint.trim().startsWith('http');
  const isRegistrationValid = !!selectedRegistration;
  const isFormValid = isNameValid && isEndpointValid && isRegistrationValid;

  function getRegistrationHelperText() {
    if (isRegistryLoading) {
      return dialogCopy.inputs.connectionRegistration.loading;
    }
    if (registrationTouched && !isRegistrationValid) {
      return dialogCopy.validation.connectionRegistration;
    }
    return dialogCopy.inputs.connectionRegistration.helperText;
  }

  function getRegistrationIntent() {
    return registrationTouched && !isRegistrationValid
      ? Intent.DANGER
      : Intent.NONE;
  }

  return (
    <Dialog
      canOutsideClickClose
      isOpen={isOpen}
      onClose={() => {
        setName('');
        setStardogEndpoint('');
        setSelectedRegistation(null);
        setNameTouched(false);
        setIsHelpOpen(false);
        setEndpointTouched(false);
        setRegistrationTouched(false);
        dispatch(closedDialog());
      }}
      canEscapeKeyClose
      title={dialogCopy.title}
      icon={IconNames.LOG_IN}
    >
      <DialogBody>
        <Callout
          className="sso-connection-dialog__overview"
          intent={Intent.PRIMARY}
          icon={null}
        >
          <p>{dialogCopy.overview}</p>
          <em>{dialogCopy.redirectNote}</em>
        </Callout>
        <div>
          <FormGroup
            label={dialogCopy.inputs.connectionRegistration.label}
            labelInfo="(required)"
            helperText={getRegistrationHelperText()}
            intent={getRegistrationIntent()}
            className="sso-connection-dialog__details__row"
          >
            {isRegistryLoading ? (
              <Spinner size={SpinnerSize.SMALL} />
            ) : (
              <Select2<SsoConnectionRegistration>
                items={registrations ?? []}
                itemRenderer={renderRegistration}
                onItemSelect={handleRegistrationChange}
                filterable={false}
                disabled={isRegistryLoading}
              >
                <Button
                  text={
                    selectedRegistration?.display_provider_name ??
                    'Select a registration'
                  }
                  rightIcon="caret-down"
                  intent={getRegistrationIntent()}
                  disabled={isRegistryLoading}
                  onClick={() => setRegistrationTouched(true)}
                />
              </Select2>
            )}
          </FormGroup>
          <Button
            minimal
            small
            icon={isHelpOpen ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_RIGHT}
            onClick={toggleHelp}
            className="sso-connection-dialog__details__row"
          >
            {dialogCopy.helpToggle.label}
          </Button>

          <Collapse isOpen={isHelpOpen}>
            <Card className="sso-connection-dialog__details__help">
              <p>{dialogCopy.helpToggle.details}</p>
            </Card>
          </Collapse>
          <FormGroup
            label={dialogCopy.inputs.name.label}
            labelFor="name-input"
            labelInfo="(required)"
            helperText={
              nameTouched && !isNameValid
                ? dialogCopy.validation.name
                : dialogCopy.inputs.name.helperText
            }
            intent={nameTouched && !isNameValid ? Intent.DANGER : Intent.NONE}
            className="sso-connection-dialog__details__row"
          >
            <InputGroup
              id="name-input"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameTouched(true);
              }}
              intent={nameTouched && !isNameValid ? Intent.DANGER : Intent.NONE}
            />
          </FormGroup>
          <FormGroup
            label={dialogCopy.inputs.endpoint.label}
            labelFor="endpoint-input"
            labelInfo="(required)"
            helperText={
              endpointTouched && !isEndpointValid
                ? dialogCopy.validation.endpoint
                : dialogCopy.inputs.endpoint.helperText
            }
            intent={
              endpointTouched && !isEndpointValid ? Intent.DANGER : Intent.NONE
            }
            className="sso-connection-dialog__details__row"
          >
            <InputGroup
              id="endpoint-input"
              value={stardogEndpoint}
              onChange={(e) => {
                setStardogEndpoint(e.target.value);
                setEndpointTouched(true);
              }}
              intent={
                endpointTouched && !isEndpointValid
                  ? Intent.DANGER
                  : Intent.NONE
              }
            />
          </FormGroup>
        </div>
        <div className="sso-connection-dialog__details__buttons">
          <Tooltip2
            disabled={isFormValid}
            content={dialogCopy.tooltips.invalidInputs}
          >
            <Button
              minimal
              outlined
              text={dialogCopy.buttons.addAndAuthenticate}
              intent={Intent.PRIMARY}
              onClick={handleAddConnection}
              disabled={!isFormValid || isRegistryLoading}
            />
          </Tooltip2>
        </div>
      </DialogBody>
    </Dialog>
  );
};
