import { Classes, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { Tooltip } from 'vet-bones/components';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { CONNECTION_DIALOG_OWNERS_CAN_ONLY_DELETE_TOOLTIP } from 'src/ui/constants/testIds';
import { DeleteForm } from 'src/ui/containers/dialogs/DeleteForm';
import { EditConnectionForm } from 'src/ui/containers/dialogs/EditConnectionForm';
import { NewConnectionForm } from 'src/ui/containers/dialogs/NewConnectionForm';
import { SignInForm } from 'src/ui/containers/dialogs/SignInForm';
import {
  ConnectionDialogTab,
  ConnectionStatus,
  clearEditIndex,
  setConnectionTab,
} from 'src/ui/features/connection';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { useCurrentConnection } from 'src/ui/hooks/connection';
import {
  connectionDialogTab,
  editIndexSelector,
  isConnectionStatus,
} from 'src/ui/selectors/connection';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import * as copy from 'src/ui/templates/copy';

const isOpenSelector = isDialogOpenSelector(DialogType.CONNECTION);
const isConnectionInvalidSelector = isConnectionStatus(
  ConnectionStatus.INVALID
);

interface ConnectionDialogProps {
  showSSO?: boolean;
}

export const ConnectionDialog: React.VFC<ConnectionDialogProps> = ({
  showSSO = true,
}) => {
  const dispatch = useAppDispatch();
  const editIndex = useAppSelector(editIndexSelector);
  const { connection, isLoading } = useCurrentConnection(editIndex.toString());
  const isOpen = useAppSelector(isOpenSelector);
  const isConnectionInvalid = useAppSelector(isConnectionInvalidSelector);
  const selectedTab = useAppSelector(connectionDialogTab);

  const handleTabChange = (newTabId: ConnectionDialogTab) =>
    dispatch(setConnectionTab(newTabId));
  const handleCloseDialog = () => {
    dispatch(closedDialog());
    dispatch(clearEditIndex());
  };

  const newTab = !isConnectionInvalid ? (
    <Tab
      id={ConnectionDialogTab.NEW}
      disabled={isLoading}
      title={copy.components.connectionDialog.tabs.new}
      panel={<NewConnectionForm showSSO={showSSO} />}
    />
  ) : null;

  const editTab = connection ? (
    <Tab
      id={ConnectionDialogTab.EDIT}
      disabled={isLoading}
      title={copy.components.connectionDialog.tabs.edit}
      panel={<EditConnectionForm showSSO={showSSO} />}
    />
  ) : null;

  const signInTab =
    connection && isConnectionInvalid ? (
      <Tab
        id={ConnectionDialogTab.SIGN_IN}
        disabled={isLoading}
        title={copy.components.connectionDialog.tabs.signIn}
        panel={<SignInForm />}
      />
    ) : null;

  const isOwnerOfCloudConnection =
    connection?.cloud?.owner?.email === connection?.username;

  const deleteTitle = () => {
    if (!isOwnerOfCloudConnection) {
      return copy.components.connectionDialog.tabs.delete;
    }
    return (
      <Tooltip
        content={
          <p
            data-testid={CONNECTION_DIALOG_OWNERS_CAN_ONLY_DELETE_TOOLTIP}
            className="sd-dialog-connection__tooltip"
          >
            {
              copy.components.connectionDialog.tooltips
                .deleteConnectionOfCloudOwner
            }
          </p>
        }
      >
        {copy.components.connectionDialog.tabs.delete}
      </Tooltip>
    );
  };

  const deleteTab = connection ? (
    <Tab
      id={ConnectionDialogTab.DELETE}
      disabled={isLoading || isOwnerOfCloudConnection}
      title={deleteTitle()}
      panel={<DeleteForm connection={connection} />}
    />
  ) : null;

  return (
    <Dialog
      className="sd-dialog-connection"
      icon={IconNames.OFFLINE}
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title={copy.components.connectionDialog.title}
    >
      <Tabs
        className={Classes.DIALOG_BODY}
        renderActiveTabPanelOnly
        selectedTabId={selectedTab}
        animate={false}
        onChange={handleTabChange}
      >
        {newTab}
        {editTab}
        {signInTab}
        {deleteTab}
      </Tabs>
    </Dialog>
  );
};
