import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';

import {
  DIAGNOSTIC_AUTHORIZATION_HEADER,
  DIAGNOSTIC_CUSTOM_HEADER,
} from 'src/ui/constants/environment';
import { copy } from 'src/ui/templates/copy';

interface CustomResponse extends AxiosResponse {
  responseTime: number;
}

/* istanbul ignore next: too hard to test this */
export const requestInterceptor = (config: AxiosRequestConfig) => {
  const requestStartedAt = new Date().getTime();
  config.headers['X-Request-Started-At'] = requestStartedAt;
  return config;
};

/* istanbul ignore next: too hard to test this */
export const responseInterceptor = (
  response: AxiosResponse
): CustomResponse => {
  const requestStartedAt = response.config.headers['X-Request-Started-At'];
  const responseTime = new Date().getTime() - requestStartedAt;
  return {
    ...response,
    responseTime,
  };
};

export const diagnosticTest = (): Promise<CustomResponse> => {
  axios.interceptors.request.use(requestInterceptor);
  axios.interceptors.response.use(responseInterceptor);

  const validationUrl = '/api/diagnostic';
  return axios
    .get(validationUrl, {
      headers: {
        Authorization: DIAGNOSTIC_AUTHORIZATION_HEADER,
        'X-Custom-Header': DIAGNOSTIC_CUSTOM_HEADER,
      },
    })
    .then((response: any) => {
      return response;
    })
    .catch(() => {
      throw new Error(copy.errors.connectionFailed);
    });
};

/**
 * Diagnostic request to test authentication details.
 */
export const useDiagnostic = () => {
  return useQuery<CustomResponse, Error>('diagnostic', diagnosticTest, {
    cacheTime: 0, // disable cache so we don't use stale data
    retry: false,
  });
};

/**
 * Diagnostic request to test endpoint health details.
 */
export const useHealthCheck = () => {
  const alive = (endpoint: string) => {
    const url = endpoint.endsWith('/')
      ? `${endpoint}admin/alive`
      : `${endpoint}/admin/alive`;
    return axios.get(url);
  };

  return useMutation<AxiosResponse, Error, string>('diagnostic-health', alive);
};
