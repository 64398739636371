import { Logout } from '@carbon/icons-react';
import * as React from 'react';
import { NavbarMenuItem, NavbarSchema } from 'vet-bones/components';

import {
  NAVBAR_AVATAR_MENU,
  NAVBAR_AVATAR_MENU_ITEM,
} from 'src/ui/constants/testIds';
import { useProfile } from 'src/ui/hooks/connection';
import * as copy from 'src/ui/templates/copy';
import { redirectToUrl } from 'src/ui/utils/window';

export const useLaunchpadNavbarAvatarSchema = (): NonNullable<
  NavbarSchema['footer']
>['avatar'] => {
  const { data: profileData } = useProfile();
  const profile = profileData?.profile;

  const handleLogout = React.useCallback(() => {
    redirectToUrl('/auth/logout');
  }, []);

  return React.useMemo(() => {
    if (!profile || !profile.is_authenticated) {
      return undefined;
    }

    const firstName = profile.first_name || profile.username || '';
    const lastName = profile.last_name || '';

    const items: NavbarMenuItem[] = [
      {
        icon: Logout,
        label: copy.components.navbar.avatar.menuItems.logout,
        onClick: handleLogout,
        testId: NAVBAR_AVATAR_MENU_ITEM('logout'),
      },
    ];

    return {
      description: '',
      label: `${firstName} ${lastName}`,
      menuItems: [{ items }],
      testId: NAVBAR_AVATAR_MENU,
      text: `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`,
    };
  }, [handleLogout, profile]);
};
