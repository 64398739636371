import {
  AnchorButton,
  Button,
  ButtonGroup,
  Divider,
  Intent,
  Position,
  Spinner,
  Tag,
  Toaster,
} from '@blueprintjs/core';
import uniqBy from 'lodash/uniqBy';
import { TrackingEventList } from 'portal-sdk';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIsMounted } from 'vet-bones/utils/hooks';
import { sendHubspotEvent } from 'vet-bones/utils/portal';

import {
  BUTTON_MP,
  KIT_ACTION_BUTTON,
  KIT_EXPLORER_BUTTON,
  KIT_OVERVIEW_PANEL,
} from 'src/ui/constants/testIds';
import { ConfigureDataSources } from 'src/ui/containers/dialogs/ConfigureDataSources';
import { KitDetails } from 'src/ui/containers/marketplace/KitDetails';
import {
  IDatabase,
  KitLocationSelector,
} from 'src/ui/containers/marketplace/KitLocationSelector';
import { DEMO_CONNECTION_INDEX } from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useMarketplaceSettingsQuery,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { MarketplaceOptions } from 'src/ui/hooks/ConnectionFactory';
import { EXPLORER_DB_OPTIONS, create, listDatabases } from 'src/ui/hooks/db';
import {
  DataSource,
  install,
  listModules,
  marketplaceSettingsToDbOptions,
  uninstall,
  useFillDataSourceOptions,
  useListDataSources,
  useModuleMeta,
  viewExplorerURL,
  viewStudioURL,
} from 'src/ui/hooks/modules';
import { useValidateConnection } from 'src/ui/hooks/validateConnection';
import { components } from 'src/ui/templates/copy';
import { openApplicationInTab } from 'src/ui/utils/window';

type TParams = { id: string };
enum KitInteractionTypes {
  INSTALL_KIT = 'install_kit',
  OPEN_IN_EXPLORER = 'open_in_explorer',
  OPEN_IN_STUDIO = 'open_in_studio',
}

export const KitOverview: React.VFC = () => {
  const isMounted = useIsMounted();
  const history = useHistory();
  const params = useParams<TParams>();
  const {
    data: marketplaceSettingsData,
    isLoading: isMarketplaceSettingsLoading,
  } = useMarketplaceSettingsQuery(graphQLClient);

  const marketplaceConfig = marketplaceSettingsToDbOptions(
    marketplaceSettingsData?.marketplaceSettings
  );

  const { data: moduleData, isLoading: isModuleDataLoading } = useModuleMeta(
    params.id,
    marketplaceConfig
  );

  const [databases, setDatabases] = React.useState<IDatabase[]>([]);
  const [connections, setConnections] = React.useState<GraphConnection[]>([]);
  const [
    selectedConnection,
    setSelectedConnection,
  ] = React.useState<GraphConnection | null>(null);
  const [selectedDatabase, setSelectedDatabase] = React.useState<string>('');
  const [installed, setInstalled] = React.useState<boolean>(false);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [
    marketplaceConfiguration,
    setMarketplaceConfiguration,
  ] = React.useState<MarketplaceOptions | null>(null);
  const { data: connectionsData } = useSavedConnectionsQuery(graphQLClient);
  const { data: sourcesData } = useListDataSources(selectedConnection);
  const validateConnection = useValidateConnection();

  const missingDataSources = React.useMemo(() => {
    if (!moduleData?.moduleMeta || !sourcesData) {
      return null;
    }
    const { virtualGraphs } = moduleData.moduleMeta;

    // Remove data-source:// from beginning of source names
    const existingSources = new Set(
      sourcesData.map((source) => source.name.replace(/^data-source:\/\//, ''))
    );

    const newSources = uniqBy(
      virtualGraphs
        .map((vg) => vg.source)
        .filter((source) => !existingSources.has(source.name)),
      'name'
    );

    return newSources;
  }, [moduleData, sourcesData]);
  const hasCheckedDataSources = !!missingDataSources;
  const [configuringDataSources, setConfiguringDataSources] = React.useState<
    DataSource[] | null
  >(null);
  const [installPending, setInstallPending] = React.useState<boolean>(false);

  const { data: dataSourcesWithOptions } = useFillDataSourceOptions(
    configuringDataSources,
    marketplaceConfiguration ?? null
  );

  React.useEffect(() => {
    const changed =
      connectionsData?.listConnections?.filter(
        (conn): conn is GraphConnection => Boolean(conn)
      ) || [];
    setConnections(changed);

    if (changed.length === 1) {
      const onlyConnection = changed[0];
      validateConnection(onlyConnection).then((valid) => {
        if (!isMounted.current) {
          return;
        }

        if (valid) {
          setSelectedConnection(onlyConnection);
          listDatabases(onlyConnection, { userDefinedOnly: true }).then(
            (response) => {
              setDatabases(response.map((database) => ({ name: database })));
            }
          );
        }
      });
    }

    if (!marketplaceSettingsData) {
      return;
    }
    const { marketplaceSettings } = marketplaceSettingsData;

    if (!marketplaceSettings) {
      return;
    }

    setMarketplaceConfiguration({
      username: marketplaceSettings.marketplaceUsername,
      password: marketplaceSettings.marketplacePassword,
      endpoint: marketplaceSettings.marketplaceEndpoint,
      database: marketplaceSettings.marketplaceDatabase,
    });
  }, [connectionsData, isMounted, marketplaceSettingsData, validateConnection]);

  if (isMarketplaceSettingsLoading || isModuleDataLoading) {
    return <Spinner className="mp-spinner" />;
  }

  /* istanbul ignore next */
  if (!moduleData?.module) {
    return (
      <div className="mp-home bg-shapes">
        <div>Kit not found!</div>
      </div>
    );
  }

  const menu = (
    <ButtonGroup>
      <Button
        data-testid={BUTTON_MP}
        icon="shop"
        onClick={() => history.push(`/kits`)}
      />
    </ButtonGroup>
  );

  const updateConnection = (conn: string | null) => {
    const newConnection = connections?.find((c) => c.name === conn);
    if (!newConnection) {
      setSelectedConnection(null);
      setDatabases([]);
      setSelectedDatabase('');
      return;
    }

    validateConnection(newConnection).then((valid) => {
      if (!isMounted.current) {
        return;
      }

      if (valid) {
        setSelectedConnection(newConnection);
        listDatabases(newConnection, { userDefinedOnly: true }).then((data) => {
          setDatabases(data.map((database) => ({ name: database })));
        });
      } else {
        setSelectedConnection(null);
        setDatabases([]);
      }
      setSelectedDatabase('');
    });
  };

  /* istanbul ignore next */
  const updateDb = (database: string) => {
    setSelectedDatabase(database);
    const databaseExists = databases?.some((db) => db.name === database);
    if (!databaseExists) {
      setInstalled(false);
      return;
    }
    if (marketplaceConfiguration) {
      listModules(selectedConnection, database, marketplaceConfiguration).then(
        (modules) => {
          setInstalled(
            Boolean(modules.find((m) => m.id === moduleData?.module?.id))
          );
        }
      );
    }
  };

  const installModule = async (bypassDataSources?: boolean) => {
    if (!selectedDatabase || !hasCheckedDataSources) {
      return;
    }
    if (!bypassDataSources && configuringDataSources?.length) {
      return;
    }
    if (
      !bypassDataSources &&
      missingDataSources &&
      missingDataSources.length > 0
    ) {
      // Mark install pending and show data source configuration
      setInstallPending(true);
      setConfiguringDataSources(missingDataSources);
      return;
    }

    setProcessing(true);

    const dbExists = databases?.some((db) => db.name === selectedDatabase);
    if (!dbExists) {
      const created = await create(
        selectedConnection as GraphConnection,
        selectedDatabase as string,
        EXPLORER_DB_OPTIONS
      );
      listDatabases(selectedConnection, { userDefinedOnly: true }).then(
        (data) => {
          setDatabases(data.map((database) => ({ name: database })));
        }
      );
      Toaster.create({
        className: 'recipe-toaster',
        position: Position.TOP,
      }).show({
        intent: created ? 'primary' : 'warning',
        timeout: 2000,
        message: created
          ? components.marketplace.install.createdDatabase(selectedDatabase)
          : components.marketplace.install.failedToCreateDatabase(
              selectedDatabase
            ),
      });
      if (!created) {
        return;
      }
    }
    if (moduleData.module && marketplaceConfiguration) {
      install(
        selectedConnection,
        selectedDatabase,
        moduleData?.module,
        marketplaceConfiguration
      ).then((result) => {
        setInstalled(true);
        setProcessing(false);
        Toaster.create({
          className: 'recipe-toaster',
          position: Position.TOP,
        }).show({
          intent: result ? 'primary' : 'warning',
          timeout: 2000,
          message: result
            ? components.marketplace.install.complete
            : components.marketplace.install.failed,
        });
      });
    }

    Toaster.create({
      className: 'recipe-toaster',
      position: Position.TOP,
    }).show({
      timeout: 1000,
      message: components.marketplace.install.started,
    });
  };

  /* istanbul ignore next */
  const uninstallModule = () => {
    if (selectedDatabase) {
      setProcessing(true);
      if (moduleData.module && marketplaceConfiguration) {
        uninstall(
          selectedConnection,
          selectedDatabase,
          moduleData.module,
          marketplaceConfiguration
        ).then((result) => {
          setInstalled(false);
          setProcessing(false);

          Toaster.create({
            className: 'recipe-toaster',
            position: Position.TOP,
          }).show({
            intent: result ? 'success' : 'warning',
            timeout: 1000,
            message: `${result ? 'Successfully removed' : 'Failed to remove'} ${
              moduleData.module?.name
            }`,
          });
        });
      }
    }
  };

  const demoKitDB = moduleData.module?.databaseId;

  const types = moduleData.moduleMeta?.types ? (
    <div>
      {moduleData.moduleMeta?.types.map((t) => (
        <Tag key={t.label} large>
          {t.label}
        </Tag>
      ))}
    </div>
  ) : null;

  const installButton = !installed ? (
    <Button
      minimal
      outlined
      intent={Intent.PRIMARY}
      loading={processing}
      data-testid={KIT_ACTION_BUTTON}
      className="cta-button"
      text={components.marketplace.menu.install}
      onClick={() => {
        installModule();
        sendHubspotEvent(TrackingEventList.KNOWLEDGE_KIT_USAGE, {
          interaction_type: KitInteractionTypes.INSTALL_KIT,
          kit_name: moduleData?.module?.name || '',
        });
      }}
    />
  ) : (
    <Button
      minimal
      outlined
      intent={Intent.DANGER}
      loading={processing}
      data-testid={KIT_ACTION_BUTTON}
      className="cta-button"
      onClick={() => uninstallModule()}
      text={components.marketplace.card.menu.delete}
    />
  );

  const explorerButton = (
    <Button
      minimal
      outlined
      intent={Intent.PRIMARY}
      text={components.marketplace.kitSelector.openInExplorer}
      data-testid={KIT_EXPLORER_BUTTON}
      onClick={() => {
        if (moduleData.module && selectedConnection && selectedDatabase) {
          openApplicationInTab(
            viewExplorerURL(
              moduleData.module,
              selectedConnection.index,
              selectedDatabase
            )
          );
        }
      }}
    />
  );

  const showInstallButton = selectedConnection && selectedDatabase;

  const finishDataSources = () => {
    setConfiguringDataSources(null);

    if (installPending) {
      setInstallPending(false);
      installModule(true);
    }
  };

  const stopConfiguringDataSources = () => {
    setConfiguringDataSources(null);

    if (installPending) {
      setInstallPending(false);
    }
  };

  const actions = (
    <>
      <div className="overview-panel-metadata-header">
        <p className="title">
          {demoKitDB
            ? components.marketplace.overview.actions
            : components.marketplace.overview.install}
        </p>
      </div>
      <div className="metadata-actions-buttons">
        {demoKitDB && (
          <AnchorButton
            intent={Intent.PRIMARY}
            className="cta-button"
            text={components.marketplace.overview.demoExplorerButton}
            href={viewExplorerURL(
              moduleData.module,
              DEMO_CONNECTION_INDEX,
              demoKitDB
            )}
            onClick={() => {
              sendHubspotEvent(TrackingEventList.KNOWLEDGE_KIT_USAGE, {
                interaction_type: KitInteractionTypes.OPEN_IN_EXPLORER,
                kit_name: moduleData?.module?.name || '',
              });
            }}
            target="_blank"
          />
        )}
        {demoKitDB && (
          <AnchorButton
            outlined
            intent={Intent.PRIMARY}
            className="cta-button"
            text={components.marketplace.overview.demoStudioButton}
            href={viewStudioURL(
              moduleData.module,
              DEMO_CONNECTION_INDEX,
              demoKitDB
            )}
            onClick={() => {
              sendHubspotEvent(TrackingEventList.KNOWLEDGE_KIT_USAGE, {
                interaction_type: KitInteractionTypes.OPEN_IN_STUDIO,
                kit_name: moduleData?.module?.name || '',
              });
            }}
            target="_blank"
          />
        )}
      </div>
      {demoKitDB ? (
        <>
          <Divider />
          <div className="overview-panel-metadata-header">
            <p className="title">{components.marketplace.overview.install}</p>
          </div>
        </>
      ) : null}
      <KitLocationSelector
        databases={databases}
        connections={connections}
        connectionSelectorDisabled={connections?.length === 1 || processing}
        databaseSelectorDisabled={!selectedConnection || processing}
        databaseSelectorVisible={!!selectedConnection}
        selectedDatabase={selectedDatabase}
        selectedConnection={selectedConnection}
        onConnectionChange={updateConnection}
        onDatabaseChange={updateDb}
        label={components.marketplace.overview.ctaInstall}
        databaseLabel={components.marketplace.overview.ctaInstallDatabase}
      >
        {installed && explorerButton}
        {showInstallButton && installButton}
      </KitLocationSelector>
    </>
  );

  return (
    <div className="mp-home bg-shapes">
      <div className="sd-page-summary">
        <div className="title-bar">
          <span className="title">{moduleData.module.name}</span>
          {types}
          <div className="menu">{menu}</div>
        </div>
        <p className="page-overview">{moduleData.module.description}</p>
      </div>
      <div
        className="overview-panel"
        data-testid={KIT_OVERVIEW_PANEL(moduleData.module.id)}
      >
        <KitDetails
          actions={actions}
          module={moduleData.module}
          moduleMeta={moduleData.moduleMeta}
        />
        {dataSourcesWithOptions && selectedConnection && (
          <ConfigureDataSources
            dataSources={dataSourcesWithOptions}
            connection={selectedConnection}
            isOpen={!!configuringDataSources}
            onClose={() => stopConfiguringDataSources()}
            onFinished={() => finishDataSources()}
          />
        )}
      </div>
    </div>
  );
};
