/**
 * Interface CopyToClipboard params
 */
interface CopyToClipboard {
  /** String value to copy to clipboard */
  value: string;
}

// copyToClipboard will attempt to copy the value to the user's system clipboard.
export const copyToClipboard = async ({
  value,
}: CopyToClipboard): Promise<boolean> => {
  // Clipboard API is supported by nearly all modern browsers. This should not really ever happen.
  if (!navigator.clipboard) {
    return Promise.resolve(false);
  }
  await navigator.clipboard.writeText(value);
  return Promise.resolve(true);
};
