import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import { connectionSlice } from 'src/ui/features/connection';
import { notificationsSlice } from 'src/ui/features/notifications';
import { partnerConnectionSlice } from 'src/ui/features/partnerconnection';
import { uiSlice } from 'src/ui/features/ui';

/**
 * All our reducers, this makes it easier to pull these out for testing.
 */
export const reducer = {
  connection: connectionSlice.reducer,
  ui: uiSlice.reducer,
  notifications: notificationsSlice.reducer,
  partnerconnection: partnerConnectionSlice.reducer,
};

export const store = configureStore({
  reducer,
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
