import { GraphQLResponse } from 'graphql-request/dist/types';

export type GraphError = {
  response?: GraphQLResponse;
  message: string;
};

export const getErrorFromGraphQL = (error: GraphError) => {
  const errors = error.response?.errors || [];

  if (errors.length) {
    return errors[0].message;
  }

  return error.message;
};
