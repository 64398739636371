import * as React from 'react';

import { DASHBOARD_DEGRADED_WARNING } from 'src/ui/constants/testIds';
import { copy } from 'src/ui/templates/copy';

interface DegradedWarningProps {
  index: number;
}

/**
 * This uses just a plain button as we use an image to display the actual
 * button. We don't want this to be styled by blueprintjs.
 */
export const DegradedWarning: React.VFC<DegradedWarningProps> = ({ index }) => {
  return (
    <a
      className="sd-warning"
      href={`/u/${index}/configuration`}
      data-testid={DASHBOARD_DEGRADED_WARNING}
    >
      {copy.errors.degraded} &rarr;
    </a>
  );
};
