import Stardog, { Connection as StardogConnection } from 'stardog';

import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  GetConnectionByIndexDocument,
  GetConnectionByIndexQuery,
} from 'src/ui/graph/types';

export const refetchConnectionToken = async (
  connectionIdx: number
): Promise<string | null> => {
  const data = await graphQLClient.request<GetConnectionByIndexQuery>(
    GetConnectionByIndexDocument,
    { index: connectionIdx }
  );
  return data?.connection?.token || null;
};

export const runWithRefetchConnectionToken = async <
  HttpBody extends Stardog.HTTP.Body = Stardog.HTTP.Body
>(
  stardogConnection: StardogConnection,
  connectionIdx: number | undefined,
  wrappedRequest: (stardogConn: StardogConnection) => Promise<HttpBody>
) => {
  let response = await wrappedRequest(stardogConnection);
  if (connectionIdx !== undefined && response && response.status === 401) {
    const updatedToken = await refetchConnectionToken(connectionIdx);
    if (updatedToken) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      stardogConnection.token = updatedToken;
      response = await wrappedRequest(stardogConnection);
    }
  }
  return response;
};
