import { H2, Icon, IconName } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { INTERNAL_HOME_ACTION_SEARCH } from 'src/ui/constants/testIds';
import * as copy from 'src/ui/templates/copy';

interface ActionGridItemProps {
  title: string;
  description: string;
  iconName: IconName;
  link: string;
  testId?: string;
}

const ActionGridItem: React.VFC<ActionGridItemProps> = ({
  title,
  description,
  link,
  iconName,
  testId,
}) => {
  const iconSize = 40;
  const history = useHistory();

  return (
    <button
      type="button"
      className="employee-home-action-grid-item"
      onClick={() => {
        history.push(link);
      }}
      data-testid={testId}
    >
      <p className="employee-home-action-grid-item-title">{title}</p>
      <div className="employee-home-action-grid-item-icon-container">
        <Icon icon={iconName} size={iconSize} />
      </div>
      <p className="employee-home-action-grid-item-description">
        {description}
      </p>
    </button>
  );
};

export const Home: React.VFC = () => {
  return (
    <div id="employee-home-page">
      <div className="employee-home-header">
        <H2>{copy.components.internalDashboard.home.title}</H2>
        <p className="employee-home-header-description">
          {copy.components.internalDashboard.home.description}
        </p>
        <p className="employee-home-header-disclaimer">
          {copy.components.internalDashboard.home.disclaimer}
        </p>
      </div>
      <div className="employee-home-action-grid">
        <ActionGridItem
          title={
            copy.components.internalDashboard.home.actionGrid.userSearch.title
          }
          description={
            copy.components.internalDashboard.home.actionGrid.userSearch
              .description
          }
          iconName={IconNames.SEARCH}
          link="/internal/user/search"
          testId={INTERNAL_HOME_ACTION_SEARCH}
        />
        <ActionGridItem
          title={
            copy.components.internalDashboard.home.actionGrid.cloudReport.title
          }
          description={
            copy.components.internalDashboard.home.actionGrid.cloudReport
              .description
          }
          iconName={IconNames.CLOUD}
          link="/internal/cloud/report"
        />
      </div>
    </div>
  );
};
