import {
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Position,
  Toaster,
} from '@blueprintjs/core';
import React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import { queueMessage } from 'src/ui/features/notifications';
import { closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  PartnerConnectionDetail,
  UpdatePartnerConnectionInput,
  useUpdatePartnerConnectionMutation,
  useUserPartnerConnectionsQuery,
} from 'src/ui/graph/types';
import {
  useGetVirtualGraphsForDatasource,
  useRemoveDatabricksJdbcDataSource,
} from 'src/ui/hooks/connection';
import { useRemoveDatabricksStarterKits } from 'src/ui/hooks/databricksstarterkit/dbcore';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { selectedPartnerConnection } from 'src/ui/selectors/connection';
import * as copy from 'src/ui/templates/copy';

type OwnProps = {
  connections: GraphConnection[];
};

export const DeleteDataSourceForm: React.VFC<OwnProps> = ({ connections }) => {
  const dispatch = useAppDispatch();
  const partnerConnectionDetail: PartnerConnectionDetail = useAppSelector(
    selectedPartnerConnection
  );
  const onError = useOnError();
  const onMutate = useOnMutate();

  const { data: partnerInfo } = useUserPartnerConnectionsQuery(graphQLClient);

  const stardogServerName =
    partnerConnectionDetail.stardog_server_connection?.name;
  const stardogConnectionFound = connections.find(
    (connection) => stardogServerName === connection.name
  );
  const { isLoading, data } = useGetVirtualGraphsForDatasource(
    stardogConnectionFound || connections[0],
    partnerConnectionDetail.databricks_connection_name || ''
  );

  const {
    isLoading: updatingPartnerConnection,
    mutate: updatePartnerConnection,
  } = useUpdatePartnerConnectionMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: () => {},
  });

  const {
    mutate: removeDatabricksStarterKitsMutation,
  } = useRemoveDatabricksStarterKits({
    onError: (error: any) => {
      dispatch(
        queueMessage({
          intent: Intent.WARNING,
          message: copy.components.databrickspartner.uninstall.failed
            .concat('-')
            .concat(error.message),
        })
      );
    },
    onSuccess: () => {
      Toaster.create({
        className: 'recipe-toaster',
        position: Position.BOTTOM_RIGHT,
      }).show({
        intent: 'success',
        timeout: 2000,
        message: copy.components.databrickspartner.uninstall.complete,
      });
    },
  });

  const removeStarterKits = (connection: GraphConnection) => {
    Toaster.create({
      className: 'recipe-toaster',
      position: Position.BOTTOM_RIGHT,
    }).show({
      timeout: 2000,
      intent: 'primary',
      message: copy.components.databrickspartner.uninstall.started,
    });
    removeDatabricksStarterKitsMutation(connection);
  };

  const shouldRemoveStarterKit = (
    stardogConnectionFound: GraphConnection | undefined
  ) => {
    if (!stardogConnectionFound || !partnerInfo?.userPartnerConnections)
      return false;

    const partnerConnections = partnerInfo?.userPartnerConnections.filter(
      (partnerConnection) => {
        return (
          partnerConnection?.stardog_server_connection?.endpoint ===
          stardogConnectionFound.endpoint
        );
      }
    );
    return partnerConnections.length === 1;
  };

  const {
    isLoading: isDeletingConnection,
    mutate: removeJdbcConnectionMutation,
  } = useRemoveDatabricksJdbcDataSource({
    onError: (error: any) => {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: copy.components.databrickspartner.removeConnectionFailMessage(
            error.message
          ),
        })
      );
    },
    onSuccess: () => {
      const input: UpdatePartnerConnectionInput = {
        connection_id: partnerConnectionDetail.connection_id || '',
        stardog_connection_id:
          partnerConnectionDetail.stardog_server_connection?.id || '',
        databricks_connection_name: '',
      };
      updatePartnerConnection({ input });
      const stardogServerName =
        partnerConnectionDetail.stardog_server_connection?.name;
      const stardogConnectionFound = connections.find(
        (connection) => stardogServerName === connection.name
      );
      if (shouldRemoveStarterKit(stardogConnectionFound)) {
        removeStarterKits(stardogConnectionFound || connections[0]);
      }
      dispatch(closedDialog());
      Toaster.create({
        className: 'recipe-toaster',
        position: Position.BOTTOM_RIGHT,
      }).show({
        intent: 'success',
        timeout: 5000,
        message:
          copy.components.databrickspartner.datasource.deletion.completed,
      });
    },
  });

  const [databricksDataSource, setDatabrickDataSource] = React.useState<string>(
    ''
  );

  const handleTextFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setDatabrickDataSource(value);
  };

  const handleCancel = () => {
    dispatch(closedDialog());
  };

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    const stardogServerName =
      partnerConnectionDetail.stardog_server_connection?.name;
    const stardogConnectionFound = connections.find(
      (connection) => stardogServerName === connection.name
    );
    /* istanbul ignore next */
    removeJdbcConnectionMutation({
      connection: stardogConnectionFound || connections[0],
      databricksConnectionName: databricksDataSource,
    });
  };

  const submitTooltipText =
    databricksDataSource !== partnerConnectionDetail.databricks_connection_name
      ? copy.components.connectionDialog.formValidation.deleteNameMatch
      : '';
  const isSubmitDisabled =
    databricksDataSource !== partnerConnectionDetail.databricks_connection_name;

  const actions: FormAction[] = [
    makeSubmitAction(
      copy.components.connectionDialog.delete.submit,
      isDeletingConnection
    ),
    {
      text: copy.components.connectionDialog.delete.cancel,
      onClick: handleCancel,
    },
  ];

  const virtualGraphInfo = () => {
    if (isLoading) {
      return null;
    }
    if (
      data?.virtualGraphsForDataSource &&
      data?.virtualGraphsForDataSource.length > 0
    ) {
      return copy.components.databrickspartner.datasource.deletion.virtualGraphInfo(
        data?.virtualGraphsForDataSource || []
      );
    }
    return null;
  };
  return (
    <form className="sd-partner-form" onSubmit={handleSubmit}>
      <div className="sd-partner-form__body">
        <div className="sd-delete-connection__title">
          <div className={Classes.TEXT_LARGE}>
            <span>{copy.components.connectionDialog.delete.title}</span>
            &nbsp;
            <strong>
              {partnerConnectionDetail.databricks_connection_name} ?
            </strong>
            <br />
            <br />
            <br />
            <p> {virtualGraphInfo()}</p>
          </div>
        </div>

        <FormGroup
          label={copy.components.connectionDialog.form.delete}
          labelFor="sd-databricks-datasource-delete"
        >
          <InputGroup
            autoFocus
            data-testid="sd-databricks-datasource-delete"
            id="sd-databricks-datasource-delete"
            name="name"
            type="text"
            value={databricksDataSource}
            onChange={handleTextFieldChange}
          />
        </FormGroup>
        <input type="submit" hidden />
      </div>
      <FormActions
        actions={actions}
        handleSubmit={handleSubmit}
        isSubmitDisabled={isSubmitDisabled || updatingPartnerConnection}
        submitTooltipText={submitTooltipText}
      />
    </form>
  );
};
