import {
  Tooltip as BlueprintTooltip,
  Classes,
  TooltipProps,
} from '@blueprintjs/core';
import classNames from 'classnames';
import * as React from 'react';

interface OwnProps extends TooltipProps {
  children?: React.ReactNode;
}

export const Tooltip: React.VFC<OwnProps> = ({
  children,
  popoverClassName,
  ...tooltipProps
}) => (
  <BlueprintTooltip
    popoverClassName={classNames(Classes.DARK, popoverClassName)}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...tooltipProps}
  >
    {children}
  </BlueprintTooltip>
);
