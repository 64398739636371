import { Button, InputGroup, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  CONNECTION_LIST_ADD_CONNECTION,
  CONNECTION_LIST_GET_CLOUD,
} from 'src/ui/constants/testIds';
import { showAddNewDialog, showAddSSOConnection } from 'src/ui/features/ui';
import * as copy from 'src/ui/templates/copy';

type ConnectionListProps = {
  searchDisabled: boolean;
  setConnectionFilter: (value: string) => void;
};
export const ActionButtons: React.FC<ConnectionListProps> = ({
  searchDisabled,
  setConnectionFilter,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleAdd = () => dispatch(showAddNewDialog());
  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setConnectionFilter(evt.target.value);
  };

  return (
    <div className="sd-table__actions">
      <div className="sd-table__actions__button-container">
        <Button
          icon={IconNames.PLUS}
          onClick={handleAdd}
          intent={Intent.PRIMARY}
          outlined
          minimal
          data-testid={CONNECTION_LIST_ADD_CONNECTION}
        >
          {copy.components.connectionList.add}
        </Button>
        <Button
          icon={IconNames.CLOUD}
          onClick={() => {
            history.push('/get-started');
          }}
          intent={Intent.PRIMARY}
          outlined
          minimal
          data-testid={CONNECTION_LIST_GET_CLOUD}
        >
          {copy.components.connectionList.getCloud}
        </Button>
        <InputGroup
          disabled={searchDisabled}
          type="search"
          data-testid="connection-filter"
          name="connection-filter"
          placeholder={copy.components.connectionList.searchConnections}
          onChange={handleSearchChange}
          leftIcon={IconNames.SEARCH}
        />
      </div>
    </div>
  );
};
export const LaunchpadActionButtons: React.FC<ConnectionListProps> = ({
  searchDisabled,
  setConnectionFilter,
}) => {
  const dispatch = useAppDispatch();

  const handleAdd = () => dispatch(showAddNewDialog());
  const handleAddSSOConnection = () => dispatch(showAddSSOConnection());
  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setConnectionFilter(evt.target.value);
  };

  return (
    <div className="sd-table__actions">
      <div className="sd-table__actions__button-container">
        <Button
          icon={IconNames.PLUS}
          onClick={handleAdd}
          intent={Intent.PRIMARY}
          outlined
          minimal
          data-testid={CONNECTION_LIST_ADD_CONNECTION}
        >
          {copy.components.launchpad.connections.addBasic}
        </Button>
        <Button
          icon={IconNames.LOG_IN}
          onClick={handleAddSSOConnection}
          intent={Intent.PRIMARY}
          outlined
          minimal
        >
          {copy.components.launchpad.connections.addSso}
        </Button>
        <InputGroup
          disabled={searchDisabled}
          type="search"
          data-testid="connection-filter"
          name="connection-filter"
          placeholder={copy.components.connectionList.searchConnections}
          onChange={handleSearchChange}
          leftIcon={IconNames.SEARCH}
        />
      </div>
    </div>
  );
};
