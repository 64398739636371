import { Help } from '@carbon/icons-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { NavbarItem, NavbarMenuItem } from 'vet-bones/components';

import {
  NAVBAR_LEARN_STARDOG_MENU,
  NAVBAR_LEARN_STARDOG_MENU_ITEM,
} from 'src/ui/constants/testIds';
import { openApplicationInTab } from 'src/ui/utils/window';

export const useNavbarLearnStardogSchema = (hideKits = false): NavbarItem => {
  const history = useHistory();
  return React.useMemo(() => {
    const items: NavbarMenuItem[] = [
      {
        label: 'Learning Portal',
        onClick: () => {
          openApplicationInTab('https://www.stardog.com/learn-stardog/');
        },
        testId: NAVBAR_LEARN_STARDOG_MENU_ITEM('learning-portal'),
      },
      {
        label: 'Documentation',
        onClick: () => {
          openApplicationInTab('https://docs.stardog.com');
        },
        testId: NAVBAR_LEARN_STARDOG_MENU_ITEM('documentation'),
      },
      {
        label: 'Stardog Labs',
        onClick: () => {
          openApplicationInTab('https://www.stardog.com/labs/');
        },
        testId: NAVBAR_LEARN_STARDOG_MENU_ITEM('stardog-labs'),
      },
      {
        label: 'Community Support',
        onClick: () => {
          openApplicationInTab(
            'https://community.stardog.com/c/stardog-cloud/'
          );
        },
        testId: NAVBAR_LEARN_STARDOG_MENU_ITEM('community-support'),
      },
      {
        label: 'Trainings',
        onClick: () => {
          openApplicationInTab('https://academy.stardog.com/learn');
        },
        testId: NAVBAR_LEARN_STARDOG_MENU_ITEM('trainings'),
      },
    ];

    if (!hideKits) {
      items.unshift({
        label: 'Knowledge Kits',
        onClick: () => {
          history.push('/kits');
        },
        testId: NAVBAR_LEARN_STARDOG_MENU_ITEM('knowledge-kits'),
      });
    }

    return {
      icon: Help,
      label: 'Learn Stardog',
      menuItems: [{ items }],
      testId: NAVBAR_LEARN_STARDOG_MENU,
    };
  }, [hideKits, history]);
};
