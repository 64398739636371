import { Position, Toaster } from '@blueprintjs/core';
import * as React from 'react';

import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { DataSourceCassandraForm } from 'src/ui/containers/dialogs/DataSourceCassandraForm';
import { DataSourceElasticSearchForm } from 'src/ui/containers/dialogs/DataSourceElasticSearchForm';
import { DataSourceMongoForm } from 'src/ui/containers/dialogs/DataSourceMongoForm';
import { DataSourceRdbmsForm } from 'src/ui/containers/dialogs/DataSourceRdbmsForm';
import { DataSourceSparqlForm } from 'src/ui/containers/dialogs/DataSourceSparqlForm';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import { Connection as GraphConnection } from 'src/ui/graph/types';
import { FormValues, useFormHandler } from 'src/ui/hooks/forms';
import {
  DataSource,
  DataSourceOptions,
  useAddDataSource,
} from 'src/ui/hooks/modules';
import * as copy from 'src/ui/templates/copy';

type DataSourceFormProps = {
  dataSource: DataSource;
  connection: GraphConnection;
  disabled?: boolean;
  onSubmit?: (opts: DataSource) => any;
};

export type DataSourceSubFormProps = {
  dataSource: DataSource;
  formHandler: FormValues<DataSourceOptions>;
  disabled?: boolean;
};

const FORM_COMPONENTS_BY_TYPE: Record<
  string,
  React.FunctionComponent<DataSourceSubFormProps>
> = {
  'tag:stardog:api:catalog:db:Cassandra': DataSourceCassandraForm,
  'tag:stardog:api:catalog:db:ElasticSearch': DataSourceElasticSearchForm,
  'tag:stardog:api:catalog:db:MongoDb': DataSourceMongoForm,
  'tag:stardog:api:catalog:db:Sparql': DataSourceSparqlForm,
  'tag:stardog:api:catalog:RDBMS': DataSourceRdbmsForm,
};

export const DataSourceForm: React.VFC<DataSourceFormProps> = ({
  dataSource,
  connection,
  disabled,
  onSubmit,
}) => {
  const formHandler = useFormHandler<DataSourceOptions>(dataSource.options);

  const { formData, reset } = formHandler;

  const {
    mutate: addDataSource,
    isLoading: isAddingDataSource,
  } = useAddDataSource(connection);

  React.useEffect(() => {
    // Reset the form when the data source changes
    reset();
  }, [dataSource, reset]);

  const isWorking = isAddingDataSource;
  const isSubmitDisabled = disabled || isWorking;
  const submitTooltipText = '';
  const actions: FormAction[] = [
    makeSubmitAction(copy.components.dataSourceDialog.new.submit, isWorking),
  ];

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    /* istanbul ignore next: no need to test defensive code */
    if (isSubmitDisabled) {
      return;
    }

    const newDataSource = {
      ...dataSource,
      options: formData,
    };

    addDataSource(newDataSource, {
      onSuccess: (resp: any) => {
        Toaster.create({
          className: 'recipe-toaster',
          position: Position.TOP,
        }).show({
          intent: resp.ok ? 'primary' : 'warning',
          timeout: 2000,
          message: resp.ok
            ? copy.components.dataSourceDialog.alert.created(newDataSource.name)
            : copy.components.dataSourceDialog.alert.failed(
                newDataSource.name,
                resp
              ),
        });

        if (resp.ok) {
          onSubmit?.(newDataSource);
        }
      },
    });
  };

  // Look up the form component for the data source type
  const FormComponent =
    FORM_COMPONENTS_BY_TYPE[dataSource.type] ?? DataSourceRdbmsForm;

  return (
    <form className="sd-connection-form" onSubmit={handleSubmit}>
      <div className="sd-connection-form__body">
        <FormComponent
          dataSource={dataSource}
          formHandler={formHandler}
          disabled={disabled}
        />
        <input type="submit" hidden />
      </div>
      <FormActions
        actions={actions}
        handleSubmit={handleSubmit}
        isSubmitDisabled={isSubmitDisabled}
        submitTooltipText={submitTooltipText}
      />
    </form>
  );
};
