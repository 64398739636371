import { getConnectionCookie } from 'portal-sdk';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useHistory } from 'react-router-dom';
import Stardog, {
  Connection as StardogConnection,
  dataSources as StardogDataSources,
  user as StardogUser,
  virtualGraphs as StardogVirtualGraphs,
} from 'stardog';
import { BaseConnection, getStardogConnection } from 'vet-bones/utils';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  CLOUD_ROLE_BUILTIN,
  Connection,
  READER_ROLE_BUILTIN,
  StardogDBAccess,
  StardogDBAccessRoleStatus,
  clearConnection,
  expandPermissions,
  getFullStardogDBRole,
  getPermissionsForDBAccess,
  permissionHash,
  setConnectionIndex,
} from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { getSdk } from 'src/ui/graph/sdk';
import {
  AddConnectionInput,
  Connection as GraphConnection,
  useGetConnectionByIndexQuery,
  useProfileQuery,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { runWithRefetchConnectionToken } from 'src/ui/hooks/refetchConnectionToken';
import { copy } from 'src/ui/templates/copy';
import { cryptoRandomString } from 'src/ui/utils/randomString';

/**
 * Custom profile hook will return the user info. This will refresh
 * the data every time the window gets focus or on a timer set
 * by the refetchInterval.
 */
export const useProfile = () => {
  return useProfileQuery(graphQLClient, undefined, {
    refetchInterval: 30 * 1000,
    retry: false,
    cacheTime: 0,
  });
};

/**
 * Custom hook that returns a function to set the connection index
 * then redirect the user to the dashboard page.
 */
export const useSetConnectionIndex = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const setConnectionIndexWrapper = (index: number, path = '/') => {
    // TODO: validate the index is correct and the user has access.
    dispatch(setConnectionIndex(index));
    history?.push(`/u/${index}${path}`);
  };

  return setConnectionIndexWrapper;
};

/**
 * Custom hook that returns a function to set the connection index
 * then redirect the user to partner home.
 */
export const useSetConnectionIndexForPartnerConnect = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const setConnectionIndexWrapper = (index: number, partnerHomeUrl: string) => {
    dispatch(setConnectionIndex(index));
    history?.push(partnerHomeUrl);
  };

  return setConnectionIndexWrapper;
};

/**
 * Custom hook that returns a function to clear the connection index
 * and redirect the user to the home page.
 */
export const useClearConnection = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const clearConnectionWrapper = () => {
    dispatch(clearConnection());
    // Clear the cached results for savedConnections and force a refetch.
    queryClient.invalidateQueries(useSavedConnectionsQuery.getKey());
  };

  return clearConnectionWrapper;
};

export const verifyConnectionWithPortalJWT = async (
  connection: Connection
): Promise<AddConnectionInput> => {
  const { endpoint, name, useSSO, token } = connection;
  const connectionCookie = getConnectionCookie();
  const sdk = getSdk(graphQLClient);

  let accessToken = '';
  let username = '';

  if (connectionCookie && connectionCookie.isLaunchpad) {
    username = connection.username;
    accessToken = connection.token ?? '';
  } else {
    const tokenResponse = await sdk.generateToken({ endpoint });

    /* istanbul ignore next: no need to test defensive code */
    if (
      !tokenResponse.generateToken?.access_token ||
      !tokenResponse.generateToken?.user.username
    ) {
      throw new Error(copy.errors.verificationFailed);
    }
    username = tokenResponse.generateToken?.user.username;
    accessToken = tokenResponse.generateToken?.access_token;
  }

  const stardogConnection = getStardogConnection({
    endpoint,
    username,
    token: accessToken,
  });

  const isValid = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogUser.valid(stardogConn)
  );
  if (!isValid.ok) {
    throw new Error(copy.errors.verificationFailed);
  }

  return {
    endpoint,
    name,
    useSSO,
  };
};

export const verifyConnectionWithBrowserAuth = async (
  connection: Connection
): Promise<AddConnectionInput> => {
  const { endpoint, name, useBrowserAuth } = connection;

  const stardogConnection = getStardogConnection(connection);

  const isValid = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogUser.valid(stardogConn)
  );
  if (!isValid.ok) {
    throw new Error(copy.errors.verificationFailed);
  }

  return {
    endpoint,
    name,
    useBrowserAuth,
  };
};

export const verifyUsernamePasswordConnection = async (
  connection: Connection
): Promise<AddConnectionInput> => {
  const {
    endpoint,
    name,
    username,
    password,
    useBrowserAuth,
    useSSO,
    token,
  } = connection;
  const stardogConnection = getStardogConnection(connection);

  // if there is no password and conn doesn't have bearer token,
  // API call to Stardog will fail anyway with a `401` so fail early.
  // In order to get a token from Stardog to then set token on connection
  // the request needs to contain a password
  if (!password && !token) {
    throw new Error(copy.errors.invalidUserPassword);
  }

  const tokenResponse = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogUser.token(stardogConn)
  );
  switch (tokenResponse.status) {
    case 200:
      return {
        name,
        endpoint,
        username,
        token: tokenResponse.body.token,
        useBrowserAuth,
        useSSO,
      };
    case 400:
    case 404:
      // The connection does not support token auth but the password is ok
      return {
        name,
        endpoint,
        username,
        useBrowserAuth,
        useSSO,
      };
    case 401:
      throw new Error(copy.errors.invalidUserPassword);
    /* istanbul ignore next: no need to test the default case */
    default:
      return {
        name,
        endpoint,
        username,
        useBrowserAuth,
        useSSO,
      };
  }
};

/**
 * Custom hook that verifies a Stardog connection with username and password
 */
export const useVerifyConnection = () => {
  const verify = async (
    connection: Connection
  ): Promise<AddConnectionInput> => {
    const { useSSO, useBrowserAuth } = connection;

    if (useBrowserAuth) {
      return verifyConnectionWithBrowserAuth(connection);
    }

    // First we need to determine what type of connection to test, if there
    // is no username then we see if they can connection with a portal JWT.
    if (useSSO) {
      return verifyConnectionWithPortalJWT(connection);
    }
    return verifyUsernamePasswordConnection(connection);
  };

  return useMutation<AddConnectionInput, Error, Connection>(verify);
};

export type CurrentConnection = {
  notFound: boolean;
  connection: Connection | null;
  isLoading: boolean;
};

/**
 * Get the current connection details.
 */
export const useCurrentConnection = (
  connectionIndex: string | undefined
): CurrentConnection => {
  const { data, isLoading } = useGetConnectionByIndexQuery(graphQLClient, {
    index: parseInt(connectionIndex || '-1', 10),
  });

  const connection = (data?.connection || null) as Connection | null;
  const notFound = isLoading ? false : typeof connection === 'undefined';

  return { notFound, connection, isLoading };
};

export type CreateDatabricksConnectionInput = {
  connection: GraphConnection;
  databricksConnectionName: string;
  jdbcUrl: string;
  personalAccessToken: string;
  clusterId: string;
};

/**
 * Create Databricks JDBC connection on Stardog server
 */
/* istanbul ignore next */
export const createDatabricksJDBCConnection = async ({
  connection,
  databricksConnectionName,
  jdbcUrl,
  personalAccessToken,
  clusterId,
}: CreateDatabricksConnectionInput): Promise<boolean> => {
  const stardogConnection = getStardogConnection(connection as BaseConnection);
  let externalComputeHostName = '';
  try {
    externalComputeHostName = jdbcUrl.split(':')[2].substring(2);
  } catch (error) {
    console.warn(error);
  }

  /* istanbul ignore next */
  const resp = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) =>
      StardogDataSources.add(stardogConn, databricksConnectionName, {
        'jdbc.url': jdbcUrl,
        'jdbc.username': 'token',
        'jdbc.password': personalAccessToken,
        'jdbc.driver': 'com.databricks.client.jdbc.Driver',
        'sql.schemas': '*.*',
        'sql.catalogs': '*',
        'external.compute': 'true',
        'databricks.cluster.id': clusterId,
        'external.compute.host.name': externalComputeHostName,
        'stardog.host.url': connection.endpoint,
      })
  );
  if (!resp.ok) {
    throw new Error(
      ` Server returned error: "${resp.body?.message}" with ${resp.status} error code.`
    );
  }

  return resp.ok;
};

/**
 * Create Databricks JDBC connection on Stardog server
 */
export const useCreateDatabricksJdbcConnection = (
  options?: UseQueryOptions<boolean>
) => {
  const ret = useMutation<boolean, Error, CreateDatabricksConnectionInput>(
    createDatabricksJDBCConnection,
    options as any
  );
  return ret;
};

export type RemoveDatabricksConnectionInput = {
  connection: GraphConnection;
  databricksConnectionName: string;
};

/**
 * Delete Databricks JDBC DataSource on Stardog server
 */
/* istanbul ignore next */
export const removeDatabricksJDBCDataSource = async ({
  connection,
  databricksConnectionName,
}: RemoveDatabricksConnectionInput): Promise<boolean> => {
  if (!connection || databricksConnectionName === '') {
    return true;
  }
  const stardogConnection = getStardogConnection(connection as BaseConnection);
  const virtualGraphsInfo: VirtualGraphInfo = await getVirtualGraphs(
    connection,
    databricksConnectionName
  );
  virtualGraphsInfo.virtualGraphsForDataSource.forEach(
    async (virtualGraph: string) => {
      const vgRemove = await runWithRefetchConnectionToken(
        stardogConnection,
        connection.index,
        (stardogConn) => StardogVirtualGraphs.remove(stardogConn, virtualGraph)
      );
      if (!vgRemove.ok) {
        throw new Error(
          ` Server returned error: "${vgRemove.body?.message}" with ${vgRemove.status} error code.`
        );
      }
    }
  );

  /* istanbul ignore next */
  const resp = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) =>
      StardogDataSources.remove(stardogConn, databricksConnectionName)
  );
  if (!resp.ok) {
    throw new Error(
      ` Server returned error: "${resp.body?.message}" with ${resp.status} error code.`
    );
  }

  return resp.ok;
};

/**
 * Create Databricks JDBC connection on Stardog server
 */
export const useRemoveDatabricksJdbcDataSource = (
  options?: UseQueryOptions<boolean>
) => {
  return useMutation<boolean, Error, RemoveDatabricksConnectionInput>(
    removeDatabricksJDBCDataSource,
    options as any
  );
};

export type VirtualGraphInfo = {
  virtualGraphsForDataSource: string[];
};

/**
 * Returns the list of virtual graphs
 */
export const getVirtualGraphs = async (
  connection: GraphConnection,
  datasource: string
): Promise<VirtualGraphInfo> => {
  const stardogConnection = getStardogConnection(connection as BaseConnection);

  const resp = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogVirtualGraphs.listInfo(stardogConn)
  );
  if (!resp.ok) {
    throw new Error(
      ` Server returned error: "${resp.body?.message}" with ${resp.status} error code.`
    );
  }

  const vgArray = resp.body.virtual_graphs;
  const datasourceArray: string[] = [];
  const targetDataSource: string = 'data-source://'.concat(datasource);

  vgArray.forEach((vg: any) => {
    if (targetDataSource === vg.data_source) {
      datasourceArray.push(vg.name);
    }
  });

  return {
    virtualGraphsForDataSource: datasourceArray,
  };
};

export const useGetVirtualGraphsForDatasource = (
  connection: GraphConnection,
  datasource: string
) => {
  return useQuery(
    'virtualgraphs',
    () => getVirtualGraphs(connection, datasource),
    {
      cacheTime: 0, // disable cache so we don't use stale data
      retry: false,
    }
  );
};

const respToPermission = (p: any): StardogUser.Permission => ({
  action: p.action,
  resourceType: p.resource_type,
  resources: p.resource,
});

const verifyExistingAccessLevelRole = (
  role: string,
  existing: Stardog.HTTP.Body,
  expectedPermissions: StardogUser.Permission[]
) => {
  if (existing.status === 404) {
    // role doesn't exist, so we can create it
    return StardogDBAccessRoleStatus.DOES_NOT_EXIST;
  }

  if (!existing.ok) {
    // Throw an error if there's an problem other than the role not existing
    if (existing.status === 403) {
      throw new Error('You do not have permission to view this role.');
    }

    throw new Error(
      `Server returned error: "${existing.statusText}" with ${existing.status} error code.`
    );
  }

  // Ignore the expected permissions if using a built-in role
  if (role === CLOUD_ROLE_BUILTIN || role === READER_ROLE_BUILTIN) {
    return StardogDBAccessRoleStatus.EXISTS_WITH_CORRECT_PERMISSIONS;
  }

  const expectedString = expectedPermissions
    .map(permissionHash)
    .sort()
    .join(' | ');

  const existingString = existing.body.permissions
    .map(respToPermission)
    .map(permissionHash)
    .sort()
    .join(' | ');

  if (expectedString === existingString) {
    return StardogDBAccessRoleStatus.EXISTS_WITH_CORRECT_PERMISSIONS;
  }

  return StardogDBAccessRoleStatus.EXISTS_WITH_WRONG_PERMISSIONS;
};

export const createAccessLevelRole = async (
  connection: GraphConnection,
  stardogConnection: StardogConnection,
  db: string,
  accessLevel: StardogDBAccess
) => {
  if (!db) {
    throw new Error('Database name is required');
  }

  const role = getFullStardogDBRole(db, accessLevel);
  const permissions = getPermissionsForDBAccess(db, accessLevel);
  const existing = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogUser.role.permissions(stardogConn, role)
  );
  const verify = verifyExistingAccessLevelRole(role, existing, permissions);

  if (verify === StardogDBAccessRoleStatus.EXISTS_WITH_CORRECT_PERMISSIONS) {
    // Role already exists with the correct permissions, so we can return
    return true;
  }

  if (verify === StardogDBAccessRoleStatus.EXISTS_WITH_WRONG_PERMISSIONS) {
    // Role already exists with the wrong permissions, so we need to correct it
    return fixAccessLevelRole(
      connection,
      stardogConnection,
      role,
      permissions,
      existing.body.permissions.map(respToPermission)
    );
  }

  const resp = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogUser.role.create(stardogConn, { name: role })
  );
  if (!resp.ok) {
    if (resp.status === 403) {
      throw new Error(
        'You do not have permission to create roles. Please contact your Stardog administrator.'
      );
    }

    throw new Error(
      `Server returned error: "${resp.statusText}" with ${resp.status} error code.`
    );
  }

  const assignPermissionsResp = await Promise.all(
    permissions.map((p) =>
      runWithRefetchConnectionToken(
        stardogConnection,
        connection.index,
        (stardogConn) => StardogUser.role.assignPermission(stardogConn, role, p)
      )
    )
  );
  const failed = assignPermissionsResp.find((resp) => !resp.ok);

  if (failed) {
    if (failed.status === 403) {
      throw new Error(
        'You do not have permission to assign these permissions to roles. Please contact your Stardog administrator.'
      );
    }

    throw new Error(
      `Server returned error: "${failed.body?.message}" with ${failed.status} error code.`
    );
  }

  return true;
};

const fixAccessLevelRole = async (
  connection: GraphConnection,
  stardogConnection: StardogConnection,
  role: string,
  expectedPermissions: StardogUser.Permission[],
  existingPermissions: StardogUser.Permission[]
) => {
  // Stardog will convert the ALL action to individual permissions for all 7 actions,
  // so we need to expand the expected permissions or else this won't match up properly with the existing permissions
  const expandedExpected = expandPermissions(expectedPermissions);
  const expectedMap = new Map(
    expandedExpected.map((p) => [permissionHash(p), p])
  );
  const existingMap = new Map(
    existingPermissions.map((p) => [permissionHash(p), p])
  );

  const toDelete = existingPermissions.filter(
    (p) => !expectedMap.has(permissionHash(p))
  );
  const toAdd = expandedExpected.filter(
    (p) => !existingMap.has(permissionHash(p))
  );

  const adds = await Promise.all(
    toAdd.map((p) =>
      runWithRefetchConnectionToken(
        stardogConnection,
        connection.index,
        (stardogConn) => StardogUser.role.assignPermission(stardogConn, role, p)
      )
    )
  );
  const failedAdds = adds.find((resp) => !resp.ok);

  if (failedAdds) {
    if (failedAdds.status === 403) {
      throw new Error(
        'You do not have permission to assign these permissions to a role. Please contact your Stardog administrator.'
      );
    }

    throw new Error(
      `Server returned error: "${failedAdds.body?.message}" with ${failedAdds.status} error code.`
    );
  }

  const deletes = await Promise.all(
    toDelete.map((p) =>
      runWithRefetchConnectionToken(
        stardogConnection,
        connection.index,
        (stardogConn) => StardogUser.role.deletePermission(stardogConn, role, p)
      )
    )
  );
  const failedDeletes = deletes.find((resp) => !resp.ok);

  if (failedDeletes) {
    if (failedDeletes.status === 403) {
      throw new Error(
        'You do not have permission to delete these permissions from a role. Please contact your Stardog administrator.'
      );
    }

    throw new Error(
      `Server returned error: "${failedDeletes.body?.message}" with ${failedDeletes.status} error code.`
    );
  }

  return true;
};

interface UserSetupInfo {
  input: AddConnectionInput;
  username: string;
  password: string | null;
}

export const setupUsersAndRolesForInvite = async (
  connection: GraphConnection,
  db: string,
  accessLevel: StardogDBAccess,
  emails: string[]
): Promise<UserSetupInfo[]> => {
  const stardogConnection = getStardogConnection(connection as BaseConnection);

  const createRole = await createAccessLevelRole(
    connection,
    stardogConnection,
    db,
    accessLevel
  );

  if (!createRole) {
    throw new Error('Failed to create role');
  }

  const existingUsers = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogUser.list(stardogConn)
  );
  if (!existingUsers.ok) {
    if (existingUsers.status === 403) {
      throw new Error(
        'You do not have permission to view or modify users. Please contact your Stardog administrator.'
      );
    }

    throw new Error(
      `Server returned error: "${existingUsers.body?.message}" with ${existingUsers.status} error code.`
    );
  }

  const existingUserSet = new Set(existingUsers.body.users);
  const users = emails.map((email) => ({
    username: email,
    password: existingUserSet.has(email) ? null : cryptoRandomString(64),
  }));

  // TODO: Until VET-2366 is fixed, I'm specifying both name and username.
  // Once that's fixed, we can remove whichever one is not needed and stop abusing TypeScript.
  const newUsers = users.filter((user) => user.password !== null);
  const createUsers = newUsers.map((user: any) =>
    runWithRefetchConnectionToken(
      stardogConnection,
      connection.index,
      (stardogConn) =>
        StardogUser.create(stardogConn, {
          name: user.username,
          username: user.username,
          password: user.password,
        } as StardogUser.User)
    )
  );

  const createUsersResp = await Promise.all(createUsers);
  const failed = createUsersResp.find((resp) => !resp.ok);

  if (failed) {
    if (failed.status === 403) {
      throw new Error(
        'You do not have permission to create users. Please contact your Stardog administrator.'
      );
    }

    throw new Error(
      `Server returned error: "${failed.body?.message}" with ${failed.status} error code.`
    );
  }

  const roleName = getFullStardogDBRole(db, accessLevel);

  const usersWithRole = await runWithRefetchConnectionToken(
    stardogConnection,
    connection.index,
    (stardogConn) => StardogUser.role.usersWithRole(stardogConn, roleName)
  );
  if (!usersWithRole.ok) {
    if (usersWithRole.status === 403) {
      throw new Error(
        'You do not have permission to view or modify users. Please contact your Stardog administrator.'
      );
    }

    throw new Error(
      `Server returned error: "${usersWithRole.body?.message}" with ${usersWithRole.status} error code.`
    );
  }

  const usersWithRoleSet = new Set(usersWithRole.body.users);

  const assignRoles = emails
    .filter((e) => !usersWithRoleSet.has(e))
    .map((email) =>
      runWithRefetchConnectionToken(
        stardogConnection,
        connection.index,
        (stardogConn) => StardogUser.assignRole(stardogConn, email, roleName)
      )
    );

  const assignRolesResp = await Promise.all(assignRoles);
  const failedAssignRoles = assignRolesResp.find((resp) => !resp.ok);

  if (failedAssignRoles) {
    if (failedAssignRoles.status === 403) {
      throw new Error(
        'You do not have permission to assign roles to users. Please contact your Stardog administrator.'
      );
    }

    throw new Error(
      `Server returned error: "${failedAssignRoles.body?.message}" with ${failedAssignRoles.status} error code.`
    );
  }

  return users.map((user) => ({
    ...user,
    input: {
      name: connection.name,
      endpoint: connection.endpoint,
      username: user.username,
      useBrowserAuth: false,
      useSSO: true,
    },
  }));
};

interface SetupUsersAndRolesForInviteVars {
  connection: GraphConnection;
  db: string;
  accessLevel: StardogDBAccess;
  emails: string[];
}

export const useSetupUsersAndRolesForInvite = (
  options: UseMutationOptions<
    UserSetupInfo[],
    unknown,
    SetupUsersAndRolesForInviteVars
  > = {}
) =>
  useMutation(
    ({
      connection,
      db,
      accessLevel,
      emails,
    }: SetupUsersAndRolesForInviteVars) =>
      setupUsersAndRolesForInvite(connection, db, accessLevel, emails),
    {
      retry: false,
      ...options,
    }
  );
