import * as React from 'react';
import { VoiceboxDrawer } from 'vet-bones/components/molecules/Voicebox';
import { VoiceboxApp, VoiceboxSchema } from 'vet-bones/constants/voicebox';
import { copyToClipboard, getIsClipboardEnabled } from 'vet-bones/utils';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import {
  PortalVoiceboxContext,
  StaticVoiceboxContext,
} from 'src/ui/containers/voicebox/VoiceboxContext';
import { toggledVoiceboxDrawer } from 'src/ui/features/ui';
import { useCurrentConnection, useProfile } from 'src/ui/hooks/connection';
import {
  DEFAULT_VOICEBOX_NAMED_GRAPH,
  useVoiceboxDrawerConnectionData,
} from 'src/ui/hooks/voicebox';
import {
  voiceboxCopy,
  voiceboxQueryBuilder,
  voiceboxTestIds,
} from 'src/ui/templates/voicebox';

export const Voicebox: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.ui.voicebox.isDrawerOpen);

  const { data: profileData } = useProfile();
  const data = useVoiceboxDrawerConnectionData();
  const connectionIdx = Number(data?.connectionIndex) || 0;
  const { connection } = useCurrentConnection(data?.connectionIndex);
  const connectionId = connection?.id || '';

  const voiceboxSchema = React.useMemo(
    (): VoiceboxSchema | null =>
      data
        ? {
            copy: voiceboxCopy,
            testIds: voiceboxTestIds,
            queryBuilder: voiceboxQueryBuilder,

            clipboard: {
              isClipboardEnabled: getIsClipboardEnabled(),
              copyToClipboard,
            },

            config: {
              app: VoiceboxApp.CLOUD,
              catalogDatabaseId: data.catalogDatabaseId,
              connectionId,
              connectionIdx,
              defaultSettings: {
                databaseId: '',
                model: '',
                namedGraphs: [DEFAULT_VOICEBOX_NAMED_GRAPH],
                reasoning: true,
              },
              initialSettings: {
                databaseId: data.databaseId || '',
                model: data.model || '',
                namedGraphs: data.namedGraphs.length
                  ? data.namedGraphs
                  : [DEFAULT_VOICEBOX_NAMED_GRAPH],
                reasoning: true,
              },
            },

            drawer: {
              isOpen,
              openLinksInNewTab: true,
              toggleIsOpen: (isOpen) => dispatch(toggledVoiceboxDrawer(isOpen)),
              usePortal: true,
              useSettings: false,
            },
          }
        : null,
    [data, connectionId, connectionIdx, isOpen, dispatch]
  );

  const isStaticVoicebox = profileData?.profile?.is_static_voicebox;

  if (!connection || !voiceboxSchema) {
    return null;
  }

  return isStaticVoicebox ? (
    <StaticVoiceboxContext schema={voiceboxSchema}>
      <VoiceboxDrawer />
    </StaticVoiceboxContext>
  ) : (
    <PortalVoiceboxContext schema={voiceboxSchema}>
      <VoiceboxDrawer />
    </PortalVoiceboxContext>
  );
};
