import { FormGroup, InputGroup, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { Selector } from 'src/ui/components/Selector';
import { CREATE_PROFILE_DIALOG_BEST_DESCRIBES_ROLE_SELECTOR } from 'src/ui/constants/testIds';
import { SelectOption } from 'src/ui/constants/types';
import { ProfileInput } from 'src/ui/graph/types';
import { components } from 'src/ui/templates/copy';

export type AboutYouProps = {
  handleValueChange: <K extends keyof ProfileInput>(
    name: K
  ) => (value: ProfileInput[K]) => void;
  handleTextFieldChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  profile: ProfileInput;
};

export const AboutYou: React.VFC<AboutYouProps> = ({
  handleTextFieldChange,
  handleValueChange,
  profile,
}) => {
  const selectorsCopy = components.createProfileDialog.selectors;

  const bestDescribesRoleOptions: SelectOption[] = Object.values(
    selectorsCopy.bestDescribesRole.options
  ).map((option) => {
    return { label: option, value: option };
  });

  const handleBestDescribesRoleChange = (selectOption: SelectOption) => {
    const { value } = selectOption;
    handleValueChange('best_describes_role')(value as string);
  };

  return (
    <>
      <div className="sd-dialog-profile__name__container">
        <FormGroup
          label={components.createProfileDialog.textInputs.firstName.label}
          labelFor="create-profile-first_name"
          className="sd-dialog-profile__name__form-group"
        >
          <InputGroup
            className="sd-dialog-profile__text-input"
            id="create-profile-first_name"
            name="first_name"
            value={profile.first_name}
            onChange={handleTextFieldChange}
          />
        </FormGroup>
        <FormGroup
          label={components.createProfileDialog.textInputs.lastName.label}
          labelFor="create-profile-last_name"
          className="sd-dialog-profile__name__form-group"
        >
          <InputGroup
            className="sd-dialog-profile__text-input"
            id="create-profile-last_name"
            name="last_name"
            value={profile.last_name}
            onChange={handleTextFieldChange}
          />
        </FormGroup>
      </div>
      <div className="sd-dialog-profile__selector-container">
        <p>
          {components.createProfileDialog.selectors.bestDescribesRole.label}
        </p>
        <Selector
          testId={CREATE_PROFILE_DIALOG_BEST_DESCRIBES_ROLE_SELECTOR}
          selectedItem={{ label: profile.company, value: profile.company }}
          onSelect={handleBestDescribesRoleChange}
          items={bestDescribesRoleOptions}
          buttonText={profile.best_describes_role || ''}
          popoverPosition={Position.BOTTOM_LEFT}
          filterable={false}
          className="sd-dialog-profile__selector"
          popoverTargetClassName="sd-dialog-profile__selector__popover-target"
          buttonClassName="sd-dialog-profile__selector__button"
          buttonProps={{
            outlined: true,
          }}
          rightIcon={IconNames.CHEVRON_DOWN}
        />
      </div>
    </>
  );
};
