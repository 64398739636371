export const randomString = (length: number): string => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%!&';
  const charsLength = chars.length;
  let result = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charsLength));
  }
  return result;
};

export const cryptoRandomString = (length: number): string => {
  const crypto =
    window.crypto || (window as any).msCrypto || (window as any).webkitCrypto;

  if (!crypto) {
    throw new Error('Browser not supported');
  }

  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%!&';
  const array = new Uint32Array(length);

  crypto.getRandomValues(array);
  return Array.from(array, (n) => chars[n % chars.length]).join('');
};
