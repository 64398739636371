import { Button, Intent, Menu, MenuItem, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useIsMounted } from 'vet-bones/utils/hooks';

import { withConfirmation } from 'src/ui/components/withConfirmation';
import {
  MANAGE_API_TOKEN_DELETE,
  MANAGE_API_TOKEN_DELETE_CANCEL,
  MANAGE_API_TOKEN_DELETE_SUBMIT,
  MANAGE_API_TOKEN_MENU,
  MANAGE_API_TOKEN_ROW,
} from 'src/ui/constants/testIds';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  ApiToken,
  Scalars,
  useDeleteApiTokenMutation,
} from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import * as copy from 'src/ui/templates/copy';

interface ApiTokenRowProps {
  apiToken: ApiToken;
  appId?: string;
  idx?: number;
}

const formatDate = (maybeDate: Scalars['Datetime'] | undefined) =>
  maybeDate ? new Date(maybeDate).toLocaleDateString() : '';
const formatDateTime = (maybeDate: Scalars['Datetime'] | undefined) =>
  maybeDate ? new Date(maybeDate).toLocaleString() : '';

export const ApiTokenRow: React.VFC<ApiTokenRowProps> = ({
  apiToken,
  appId,
  idx,
}) => {
  const isMounted = useIsMounted();
  const onError = useOnError();
  const onMutate = useOnMutate();
  const queryClient = useQueryClient();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const { mutate: deleteApiToken } = useDeleteApiTokenMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: async (data) => {
      if (!data.deleteApiToken?.success) {
        return;
      }
      /* istanbul ignore if */
      if (!isMounted.current) {
        return;
      }
      await queryClient.refetchQueries(['listApiTokens']);
      /* istanbul ignore if */
      if (!isMounted.current) {
        return;
      }
      setIsMenuOpen(false);
    },
  });

  const popoverMenu = (
    <Menu>
      <MenuItem
        data-testid={MANAGE_API_TOKEN_DELETE}
        icon={IconNames.TRASH}
        text={copy.components.manageApiTokens.apiTokenRow.delete.submit}
        intent={Intent.DANGER}
        onClick={() =>
          withConfirmation({
            message:
              copy.components.manageApiTokens.apiTokenRow.delete
                .confirmationMessage,
            noPrompt: copy.components.manageApiTokens.apiTokenRow.delete.cancel,
            noPromptTestId: MANAGE_API_TOKEN_DELETE_CANCEL,
            onConfirmationSuccess: () => {
              deleteApiToken({ input: { id: apiToken.id, app_id: appId } });
            },
            title: copy.components.manageApiTokens.apiTokenRow.delete.title,
            yesPrompt:
              copy.components.manageApiTokens.apiTokenRow.delete.submit,
            yesPromptTestId: MANAGE_API_TOKEN_DELETE_SUBMIT,
          })
        }
      />
    </Menu>
  );

  const popover = (
    <Popover2
      minimal
      content={popoverMenu}
      position={Position.LEFT_TOP}
      isOpen={isMenuOpen}
      onClose={(e) => {
        if (e) {
          // prevent triggering card link when clicking menu items
          e.stopPropagation();
          setIsMenuOpen(false);
        }
      }}
    >
      <Button
        minimal
        active={isMenuOpen}
        data-testid={MANAGE_API_TOKEN_MENU}
        icon={IconNames.MORE}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (e) {
            e.stopPropagation();
            setIsMenuOpen(!isMenuOpen);
          }
        }}
      />
    </Popover2>
  );

  return (
    <tr data-testid={MANAGE_API_TOKEN_ROW(apiToken.id, idx)}>
      <td>
        <div className="sd-api-token-name">
          <strong>{apiToken.name}</strong>
        </div>
      </td>
      <td>{formatDateTime(apiToken.created)}</td>
      <td>{formatDateTime(apiToken.last_used)}</td>
      <td>{formatDate(apiToken.expires)}</td>
      <td>{popover}</td>
    </tr>
  );
};
