import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import { ProductCard } from 'src/ui/components/ProductCard';
import { DASHBOARD_CARDS } from 'src/ui/constants/testIds';
import { QuickActions } from 'src/ui/containers/dashboard/QuickActions';
import { ServerOverview } from 'src/ui/containers/dashboard/ServerOverview';
import { Widgets } from 'src/ui/containers/dashboard/Widgets';
import { ShareConnection } from 'src/ui/containers/dialogs/ShareConnection';
import {
  Connection,
  connectionInvalid,
  setConnectionBrowserAuthFailed,
  setConnectionDegraded,
  setConnectionOwnerEmail,
  setConnectionUnauthorized,
} from 'src/ui/features/connection';
import { showConnectionDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useGetConnectionByIndexQuery,
} from 'src/ui/graph/types';
import { useVerifyConnection } from 'src/ui/hooks/connection';
import {
  isDegradedSelector,
  isUnauthorizedSelector,
} from 'src/ui/selectors/connection';
import { copy } from 'src/ui/templates/copy';
import stardogDesigner from 'src/ui/templates/images/stardog-designer.svg';
import stardogExplorer from 'src/ui/templates/images/stardog-explorer.svg';
import stardogStudio from 'src/ui/templates/images/stardog-studio.svg';
import {
  doDesignerRedirect,
  doExplorerRedirect,
  doStudioRedirect,
  redirectToUrl,
} from 'src/ui/utils/window';

interface DashboardProps {
  index: number;
}

export const Dashboard: React.VFC<DashboardProps> = ({ index }) => {
  const dispatch = useAppDispatch();
  const isDegraded = useAppSelector(isDegradedSelector);
  const isUnauthorized = useAppSelector(isUnauthorizedSelector);
  const {
    data: connectionLookup,
    isLoading: isLoadingConnection,
  } = useGetConnectionByIndexQuery(
    graphQLClient,
    { index },
    { refetchInterval: 10000 }
  );
  const { isLoading: isValidating, mutate: validate } = useVerifyConnection();
  const history = useHistory();
  const [current, setCurrent] = React.useState<GraphConnection>();
  const [isValidated, setIsValidated] = React.useState<boolean>(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);

  // This function is run every time the connection changes, for example when the
  // url is changed or when the initial connection information is resolved.
  React.useEffect(() => {
    if (isLoadingConnection || !connectionLookup) {
      return;
    }

    const { connection } = connectionLookup;

    if (!connection) {
      history.push('/');
      return;
    }

    if (connection.isAllocating) {
      return;
    }

    if (!current || connection.id !== current.id) {
      setCurrent(connection);
      setIsValidated(false);
      dispatch(setConnectionBrowserAuthFailed(false));
      dispatch(setConnectionUnauthorized(false));
      dispatch(setConnectionDegraded(false));
      if (connection.cloud?.owner?.email) {
        dispatch(setConnectionOwnerEmail(connection.cloud?.owner?.email));
      }
    }
  }, [connectionLookup, isLoadingConnection, current, dispatch, history]);

  React.useEffect(() => {
    if (
      !current ||
      current?.isAllocating ||
      isValidated ||
      isDegraded ||
      isUnauthorized
    ) {
      return;
    }

    const isUsingStardogToken = Boolean(!current?.useSSO && current?.token);

    validate(current as Connection, {
      onSuccess: () => setIsValidated(true),
      onError: () => {
        // If they are using Stardog token it has expired and they need
        // re-enter their password to get a new token.
        if (isUsingStardogToken) {
          dispatch(connectionInvalid(index));
          dispatch(showConnectionDialog());
          return;
        }

        if (current.useBrowserAuth) {
          // If useBrowserAuth was enabled and validate connection failed,
          // show banner warning for this
          dispatch(setConnectionBrowserAuthFailed(true));
        } else if (current.useSSO) {
          // If they are using sso, then there they are most likely not
          // authorized or some other error with communication
          dispatch(setConnectionUnauthorized(true));
        } else {
          // If they are not using sso and don't have a token, then Stardog
          // does not support tokens (most likely) show configuration helper
          dispatch(setConnectionDegraded(true));
        }
      },
    });
  }, [
    isDegraded,
    isValidated,
    isUnauthorized,
    current,
    validate,
    dispatch,
    index,
  ]);

  if (
    isLoadingConnection ||
    !connectionLookup ||
    !connectionLookup.connection
  ) {
    return <Loading />;
  }

  const { connection } = connectionLookup;

  if (connection.isAllocating) {
    if (connection.stripeSubscription?.isWaitingForPayment) {
      redirectToUrl(connection.stripeSubscription?.url || '/');
    }
    return (
      <div className="sd-container">
        <p>Please wait while your instance is being built.</p>
      </div>
    );
  }

  const goToStudio = () => {
    return isUnauthorized ? null : doStudioRedirect(Number(index));
  };

  const goToExplorer = () => {
    return isUnauthorized ? null : doExplorerRedirect(Number(index));
  };

  const goToDesigner = () => {
    return isUnauthorized ? null : doDesignerRedirect(Number(index));
  };

  return isValidating ? (
    <Loading />
  ) : (
    <div className="dashboard">
      <ShareConnection
        isOpen={isShareDialogOpen}
        connection={connection}
        onClose={() => setIsShareDialogOpen(false)}
      />
      <div data-testid={DASHBOARD_CARDS} className="dashboard-cards">
        <ProductCard
          onClick={goToDesigner}
          image={stardogDesigner}
          altText={copy.dashboard.designer.action}
          label={copy.dashboard.designer.label}
          description={copy.dashboard.designer.description}
        />
        <ProductCard
          onClick={goToExplorer}
          image={stardogExplorer}
          altText={copy.dashboard.explorer.action}
          label={copy.dashboard.explorer.label}
          description={copy.dashboard.explorer.description}
        />
        <ProductCard
          onClick={goToStudio}
          image={stardogStudio}
          altText={copy.dashboard.studio.action}
          label={copy.dashboard.studio.label}
          description={copy.dashboard.studio.description}
        />
        <ServerOverview connection={connection} />
        <QuickActions
          onInviteUsers={() => setIsShareDialogOpen(true)}
          showInviteUsers={Boolean(current?.useSSO)}
        />
        <Widgets />
      </div>
    </div>
  );
};
