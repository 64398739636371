import { Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';

import { Dialog } from 'src/ui/components/Dialog';
import { Loading } from 'src/ui/components/Loading';
import { NewConnectionForm } from 'src/ui/containers/dialogs/NewConnectionForm';
import { Connection, ConnectionStatus } from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useListConnectionsByEndpointQuery } from 'src/ui/graph/types';
import { useSetConnectionIndex } from 'src/ui/hooks/connection';
import { useQueryParams } from 'src/ui/hooks/query';
import { getHostnameFromUrl } from 'src/ui/utils/getHostnameFromUrl';

export const Connect: React.VFC = () => {
  const params = useQueryParams();
  const endpoint = decodeURIComponent(params.get('endpoint') || '');
  const username = decodeURIComponent(params.get('username') || '');
  const hostname = getHostnameFromUrl(endpoint);
  const { data, isLoading } = useListConnectionsByEndpointQuery(graphQLClient, {
    endpoint,
  });
  const setConnectionIndex = useSetConnectionIndex();
  const newConnection: Connection = {
    endpoint,
    name: hostname,
    username,
    status: ConnectionStatus.PENDING,
    useSSO: !username,
  };

  React.useEffect(() => {
    const firstConnection = data?.listConnectionsByEndpoint?.[0];

    if (!firstConnection) {
      return;
    }

    // If they already have an endpoint that matches we should redirect
    // them to that existing dashboard as that is most likely what they want.
    setConnectionIndex(firstConnection.index);
  }, [data]);

  return isLoading ? (
    <Loading />
  ) : (
    <Dialog
      icon={IconNames.OFFLINE}
      title="Connect To Stardog"
      className={classNames('sd-dialog-connection')}
      isCloseButtonShown={false}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      isOpen={!isLoading}
    >
      <div className={Classes.DIALOG_BODY}>
        <NewConnectionForm connection={newConnection} />
      </div>
    </Dialog>
  );
};
