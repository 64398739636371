import {
  Dialog as BlueprintDialog,
  Button,
  Classes,
  DialogProps,
  H4,
  Icon,
  IconSize,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';

import {
  DIALOG_CLOSE_ICON_TEST_ID,
  DIALOG_ICON_TEST_ID,
} from 'src/ui/constants/testIds';

interface OwnProps extends DialogProps {
  children?: React.ReactNode;
}

export const Dialog: React.VFC<OwnProps> = ({
  children,
  icon,
  isCloseButtonShown = true,
  title,
  ...dialogProps
}) => (
  <BlueprintDialog
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...dialogProps}
  >
    <>
      {icon || isCloseButtonShown || title ? (
        <div className={classNames(Classes.DARK, Classes.DIALOG_HEADER)}>
          {icon ? (
            <Icon
              icon={icon}
              iconSize={IconSize.LARGE}
              data-testid={DIALOG_ICON_TEST_ID}
            />
          ) : null}
          {title ? <H4>{title}</H4> : null}
          {isCloseButtonShown ? (
            <Button
              className={Classes.DIALOG_CLOSE_BUTTON}
              icon={
                <Icon
                  data-testid={DIALOG_CLOSE_ICON_TEST_ID}
                  icon={IconNames.SMALL_CROSS}
                  iconSize={IconSize.LARGE}
                />
              }
              minimal
              onClick={dialogProps.onClose}
            />
          ) : null}
        </div>
      ) : null}
      {children}
    </>
  </BlueprintDialog>
);
