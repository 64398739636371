import { DEFAULT_NEXT_URL, STARDOG_APPS } from 'src/ui/constants/environment';
import { Connection } from 'src/ui/features/connection';
import { copy } from 'src/ui/templates/copy';

interface GlobalFeatures {
  [key: string]: string;
}

declare global {
  export interface Window {
    baseURL?: string;
    designerVersion?: string;
    explorerVersion?: string;
    FEATURES?: GlobalFeatures;
    friendlyName?: string;
    restart(): void;
    stardogEndpoint?: string;
    studioRedirect?: string;
    studioVersion?: string;
  }
}

/* istanbul ignore next */
export const setRestartGlobal = () => {
  // This just exposes the 'restart' function to reset state
  // no need to test this as it is purely an advanced tool not
  // meant to be used within the application.
  window.restart = () => {
    window.location.reload();
  };
};

export const getInitialMessages = (): string[] => {
  const params = new URLSearchParams(getSearchString());
  const initialMessage = params.get('error');
  if (initialMessage) {
    const decoded = atob(initialMessage);
    return [decoded];
  }

  return [];
};

/**
 * Simple wrapper around `lodash.get` that also checks to see if the
 * attribute is set to the default bash variable. During development
 * the webpack template is read from the local file system and not
 * the final production version which has had the bash variables replaced
 * with the settings from the environment. If the attribute starts with
 * the string `${` we know it have not been replaced properly.
 */
export const getDefault = (obj: any, key: string, defaultValue: any) => {
  const value = obj[key];
  const results = typeof value === 'undefined' ? defaultValue : value;
  if (results.startsWith('${')) {
    return defaultValue;
  }
  return results;
};

export const getFriendlyName = (): string => {
  return getDefault(window, 'friendlyName', copy.appNavBar.title);
};

export const doAzureRedirect = () => {
  const next = getNext();
  redirectToUrl(`/oauth/azure/init?next=${next}`);
};

export const doGeoaxisRedirect = () => {
  const next = getNext();
  redirectToUrl(`/oauth/geoaxis/init?next=${next}`);
};

export const doGoogleRedirect = () => {
  const next = getNext();
  redirectToUrl(`/oauth/google/init?next=${next}`);
};

export const doKeycloakRedirect = () => {
  const next = getNext();
  redirectToUrl(`/oauth/keycloak/init?next=${next}`);
};

export const doOpenIDRedirect = () => {
  const next = getNext();
  redirectToUrl(`/oauth/openid/init?next=${next}`);
};

export const doPingRedirect = () => {
  const next = getNext();
  redirectToUrl(`/oauth/ping/init?next=${next}`);
};

export const doOktaRedirect = () => {
  const next = getNext();
  redirectToUrl(`/oauth/okta/init?next=${next}`);
};

export const doCustomerAuthRedirect = (customer: string, provider: string) => {
  const next = getNext();
  redirectToUrl(`/oauth/${customer}-${provider}/init?next=${next}`);
};

export const doAuth0Redirect = () => {
  const next = getNext();
  const signup = getSignupFlag();
  const signupParam = signup ? '&screen_hint=signup' : '';
  redirectToUrl(`/oauth/auth0/init?next=${next}${signupParam}`);
};

export const doStudioRedirect = (index = 0) => {
  openApplicationInTab(`/u/${index}/studio/`);
};

export const doExplorerRedirect = (index = 0) => {
  openApplicationInTab(`/u/${index}/explorer/`);
};

export const doDesignerRedirect = (index = 0) => {
  openApplicationInTab(`/u/${index}/designer/`);
};

export const doServiceRedirect = (index: number, redirectSubPath: string) => {
  redirectToUrl(`/u/${index}/${redirectSubPath}`);
};

export const doPortalLink = (connection: Connection) => {
  const endpoint = encodeURIComponent(connection.endpoint);
  const useSSO = Boolean(connection.useSSO);
  const username = !useSSO ? `&username=${connection.username}` : '';
  redirectToUrl(`${STARDOG_APPS}/connect?endpoint=${endpoint}${username}`);
};

export const getNext = (): string => {
  const params = new URLSearchParams(getSearchString());
  const hash = getHash() || '';
  const next = params.get('next') || DEFAULT_NEXT_URL;

  const url = next.match(/^http/) ? DEFAULT_NEXT_URL : `${next}${hash}`;
  const escaped = encodeURIComponent(url);
  return escaped;
};

export const getSignupFlag = (): boolean => {
  const params = new URLSearchParams(getSearchString());
  const signup = params.get('signup');
  return Boolean(signup);
};

/* istanbul ignore next: this is just a testing shim */
export const redirectToUrl = (url: string) => {
  window.location.assign(url);
};

/* istanbul ignore next: this is just a testing shim */
export const openApplicationInTab = (url: string) => {
  window.open(url, '_blank');
};

/* istanbul ignore next: this is just a testing shim */
export const getSearchString = () => {
  return window.location.search;
};

/* istanbul ignore next: this is just a testing shim */
export const getHash = () => {
  return window.location.hash;
};

/* istanbul ignore next: this is just a testing shim */
export const getMainTarget = () => {
  return document.getElementById('app-main');
};
