import { H2, HTMLTable } from '@blueprintjs/core';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Loading } from 'src/ui/components/Loading';
import {
  ActionButtons,
  LaunchpadActionButtons,
} from 'src/ui/containers/connection-list/ActionButtons';
import { ConnectionRow } from 'src/ui/containers/connection-list/ConnectionRow';
import { CancelCloudDialog } from 'src/ui/containers/dialogs/CancelCloudDialog';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { useSetConnectionIndex } from 'src/ui/hooks/connection';

interface ConnectionListProps {
  header: string;
  isOnPremise?: boolean;
}

export const ConnectionList: React.VFC<ConnectionListProps> = ({
  header,
  isOnPremise = false,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const setConnectionIndex = useSetConnectionIndex();
  const { data, isLoading } = useSavedConnectionsQuery(graphQLClient);
  const [connectionFilter, setConnectionFilter] = React.useState<string>('');
  const [connections, setConnections] = React.useState<GraphConnection[]>([]);
  const [isCancelCloudDialogOpen, setIsCancelCloudDialogOpen] = React.useState(
    false
  );
  const [
    cloudConnectionToDelete,
    setCloudConnectionToDelete,
  ] = React.useState<GraphConnection | null>(null);

  React.useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    const changed = data.listConnections || [];
    setConnections(changed as React.SetStateAction<GraphConnection[]>);

    if (changed.length === 0) {
      if (isOnPremise) {
        history.push('/');
      } else {
        history.push('/get-started');
      }
    }
  }, [data, isLoading, history, isOnPremise]);

  const goToDashboard = (index: number) => {
    setConnectionIndex(index);
  };

  const cards = connections
    .filter(
      (c) =>
        !connectionFilter ||
        c.name
          .toLocaleLowerCase()
          .includes(connectionFilter.toLocaleLowerCase())
    )
    .map((conn) => {
      return (
        <ConnectionRow
          connection={conn}
          key={conn.id}
          onClick={() => goToDashboard(conn.index)}
          onCancelCloud={(c) => {
            if (c) {
              setCloudConnectionToDelete(c);
              setIsCancelCloudDialogOpen(true);
            }
          }}
        />
      );
    });

  const body = (
    <div className="sd-table">
      <div className="sd-table__header">
        <H2>{header}</H2>
        {isOnPremise ? (
          <LaunchpadActionButtons
            searchDisabled={isLoading}
            setConnectionFilter={setConnectionFilter}
          />
        ) : (
          <ActionButtons
            searchDisabled={isLoading}
            setConnectionFilter={setConnectionFilter}
          />
        )}
      </div>
      <hr className="divider" />

      <HTMLTable striped interactive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Endpoint URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{cards}</tbody>
      </HTMLTable>
    </div>
  );

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {body}
      {cloudConnectionToDelete && (
        <CancelCloudDialog
          connection={cloudConnectionToDelete}
          isOpen={isCancelCloudDialogOpen}
          onClose={() => setIsCancelCloudDialogOpen(false)}
          onSuccess={() => {
            setIsCancelCloudDialogOpen(false);
            // Invalidate the query so that the new connection shows up in the list
            queryClient.invalidateQueries(useSavedConnectionsQuery.getKey());
          }}
        />
      )}
    </>
  );
};
