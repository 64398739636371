import { UseQueryOptions, useMutation } from 'react-query';
import { Connection as StardogConnection, db as StardogDb } from 'stardog';
import { BaseConnection, getStardogConnection } from 'vet-bones/utils';

import { Connection as GraphConnection } from 'src/ui/graph/types';
import { NAMESPACES } from 'src/ui/hooks/databricksstarterkit/constants/common';
import { ModuleDeployment } from 'src/ui/hooks/databricksstarterkit/modulecore';
import { runWithRefetchConnectionToken } from 'src/ui/hooks/refetchConnectionToken';

const DATABRICKS_DATABASE = 'databricks-demos';
const DATABRICKS_GRAPHS = ['tpch', 'nyctaxi'];

export class DatabaseDeployment {
  private db: string;

  private connection: GraphConnection;

  private stardogConnection: StardogConnection;

  constructor(
    connection: GraphConnection,
    stardogConnection: StardogConnection,
    database: string
  ) {
    this.connection = connection;
    this.stardogConnection = stardogConnection;
    this.db = database;
  }

  public createDatabase = async (): Promise<boolean> => {
    try {
      const dbInfo = await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) => StardogDb.get(stardogConn, this.db)
      );
      if (dbInfo.ok) {
        return dbInfo.ok;
      }

      const response = await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) =>
          StardogDb.create(stardogConn, this.db, {
            database: DATABRICKS_DATABASE,
            'search.enabled': true,
            'graph.aliases': true,
            'virtual.transparency': true,
          })
      );
      return response.ok;
    } catch (e) /* istanbul ignore next: no need to test defensive code */ {
      console.warn(e);
      return Promise.resolve(false);
    }
  };

  public importDataSources = async (
    jdbcUrl: string,
    personalAccessToken: string
  ) => {
    const moduleDeployment = new ModuleDeployment(
      this.connection,
      this.stardogConnection,
      this.db
    );
    DATABRICKS_GRAPHS.forEach((graph) => {
      moduleDeployment
        .add(graph, jdbcUrl, personalAccessToken)
        .then((response) => {
          return response;
        });
    });

    return true;
  };

  public importNamespaces = async () => {
    try {
      const response = await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) =>
          StardogDb.namespaces.add(stardogConn, this.db, NAMESPACES)
      );
      return response.ok;
    } catch (e) /* istanbul ignore next: no need to test defensive code */ {
      console.warn(e);
      return false;
    }
  };

  public removeDataSources = async () => {
    const moduleDeployment = new ModuleDeployment(
      this.connection,
      this.stardogConnection,
      this.db
    );
    DATABRICKS_GRAPHS.forEach((graph) => {
      moduleDeployment.remove(graph).then((response) => {
        Promise.resolve(response);
      });
    });
    return true;
  };

  public removeDatabase = async (): Promise<boolean> => {
    try {
      const dropdb = await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) => StardogDb.drop(stardogConn, this.db)
      );
      return dropdb.ok;
    } catch (e) /* istanbul ignore next: no need to test defensive code */ {
      console.warn(e);
      return Promise.resolve(false);
    }
  };
}

export type InstallDatabricksStarterKitInput = {
  connection: GraphConnection | null | undefined;
  jdbcUrl: string;
  personalAccessToken: string;
};

export const installDatabricksStarterKits = async ({
  connection,
  jdbcUrl,
  personalAccessToken,
}: InstallDatabricksStarterKitInput) => {
  if (!connection || connection.isAllocating) {
    return false;
  }
  const stardogConnection = getStardogConnection(connection as BaseConnection);
  try {
    const databaseDeployment = new DatabaseDeployment(
      connection,
      stardogConnection,
      DATABRICKS_DATABASE
    );
    let success = await databaseDeployment.createDatabase();
    if (success) {
      success = await databaseDeployment.importNamespaces();
      if (success) {
        success = await databaseDeployment.importDataSources(
          jdbcUrl,
          personalAccessToken
        );
      } else {
        success = false;
      }
    } else {
      success = false;
    }
    return success;
  } catch (e) /* istanbul ignore next: no need to test defensive code */ {
    console.warn(e);
    throw new Error(`Error while installing starter kits: ${e}`);
  }
};

export const removeDatabricksStarterKits = async (
  connection: GraphConnection | null | undefined
) => {
  if (!connection || connection.isAllocating) {
    throw new Error(`Either connection is not defined or allocating`);
  }
  const stardogConnection = getStardogConnection(connection as BaseConnection);
  try {
    const databaseDeployment = new DatabaseDeployment(
      connection,
      stardogConnection,
      DATABRICKS_DATABASE
    );
    await databaseDeployment.removeDataSources();
    await databaseDeployment.removeDatabase();

    return true;
  } catch (e: any) /* istanbul ignore next: no need to test defensive code */ {
    console.warn(e);
    throw new Error(` Server returned error: "${e.message}"`);
  }
};

/**
 * Remove Databricks Starter Kit from Stardog Server
 */
export const useRemoveDatabricksStarterKits = (
  options?: UseQueryOptions<boolean>
) => {
  return useMutation<boolean, Error, GraphConnection>(
    removeDatabricksStarterKits,
    options as any
  );
};

/**
 * Install Databricks Starter Kit from Stardog Server
 */
export const useInstallDatabricksStarterKits = (
  options?: UseQueryOptions<boolean>
) => {
  return useMutation<boolean, Error, InstallDatabricksStarterKitInput>(
    installDatabricksStarterKits,
    options as any
  );
};
