import { Classes, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';

import { useAppDispatch } from 'src/ui/app/hooks';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import { Connection } from 'src/ui/features/connection';
import { queueMessage } from 'src/ui/features/notifications';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useDeleteConnectionMutation } from 'src/ui/graph/types';
import { useClearConnection } from 'src/ui/hooks/connection';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import * as copy from 'src/ui/templates/copy';

type DeleteFormProps = {
  connection: Connection;
};

export const DeleteForm: React.VFC<DeleteFormProps> = ({ connection }) => {
  const dispatch = useAppDispatch();
  const onError = useOnError();
  const onMutate = useOnMutate();
  const clearConnection = useClearConnection();

  const {
    isLoading: isDeletingConnection,
    mutate: deleteConnection,
  } = useDeleteConnectionMutation(graphQLClient, {
    onMutate,
    onError,
    onSuccess: (data) => {
      if (data.deleteConnection?.success) {
        dispatch(
          queueMessage({
            intent: Intent.WARNING,
            message: `Connection ${connection.name} deleted`,
          })
        );
        clearConnection();
      } else {
        dispatch(
          queueMessage({
            intent: Intent.DANGER,
            message:
              data.deleteConnection?.error || 'Error deleting connection',
          })
        );
      }
    },
  });

  const [localConnection, setLocalConnection] = React.useState<string>('');

  const handleTextFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setLocalConnection(value);
  };

  const submitTooltipText =
    localConnection !== connection.name
      ? copy.components.connectionDialog.formValidation.deleteNameMatch
      : '';
  const isSubmitDisabled = submitTooltipText !== '';

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    /* istanbul ignore next: no need to test defensive code */
    if (isSubmitDisabled) {
      return;
    }

    deleteConnection({ name: localConnection });
  };

  const actions: FormAction[] = [
    makeSubmitAction(
      copy.components.connectionDialog.delete.submit,
      isDeletingConnection
    ),
    {
      text: copy.components.connectionDialog.delete.cancel,
      onClick: clearConnection,
    },
  ];

  return (
    <div className="sd-delete-connection">
      <form className="sd-connection-form" onSubmit={handleSubmit}>
        <div className="sd-connection-form__body">
          <div className="sd-delete-connection__title">
            <div className={Classes.TEXT_LARGE}>
              <span>{copy.components.connectionDialog.delete.title}</span>
              &nbsp;
              <strong>{connection.name}</strong>
            </div>
            <div>{connection.endpoint}</div>
          </div>

          <FormGroup
            label={copy.components.connectionDialog.form.delete}
            labelFor="sd-connection-delete"
          >
            <InputGroup
              autoFocus
              id="sd-connection-delete"
              name="name"
              type="text"
              value={localConnection}
              onChange={handleTextFieldChange}
            />
          </FormGroup>
          <input type="submit" hidden />
        </div>
        <FormActions
          actions={actions}
          handleSubmit={handleSubmit}
          isSubmitDisabled={isSubmitDisabled}
          submitTooltipText={submitTooltipText}
        />
      </form>
    </div>
  );
};
