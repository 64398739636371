import { Classes } from '@blueprintjs/core';
import * as React from 'react';

import { copy } from 'src/ui/templates/copy';
import { doOpenIDRedirect } from 'src/ui/utils/window';

export const OpenIDLink: React.VFC = () => {
  return (
    <button
      type="button"
      onClick={doOpenIDRedirect}
      className={`${Classes.BUTTON} oauth`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="40"
        viewBox="0 0 16 16"
      >
        <desc>log-in</desc>
        <path
          d="M11 8c0-.28-.11-.53-.29-.71l-3-3a1.003 1.003 0 00-1.42 1.42L7.59 7H1c-.55 0-1 .45-1 1s.45 1 1 1h6.59L6.3 10.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l3-3c.18-.18.29-.43.29-.71zm4-8H9c-.55 0-1 .45-1 1s.45 1 1 1h5v12H9c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1z"
          fillRule="evenodd"
        />
      </svg>
      {copy.openid.label}
    </button>
  );
};
