import { Button, Classes, Intent } from '@blueprintjs/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Dialog } from 'src/ui/components/Dialog';
import { noop } from 'src/ui/constants/empty';
import * as copy from 'src/ui/templates/copy';

interface WithConfirmationOptions {
  isCloseButtonShown?: boolean;
  message: React.ReactNode;
  noPrompt?: string | null; // explicit null to not show
  onConfirmationFailure?: () => any;
  onConfirmationSuccess: () => any;
  title?: string;
  yesPrompt?: string;
  yesPromptTestId?: string;
  noPromptTestId?: string;
}

interface WithConfirmationProps extends WithConfirmationOptions {
  noPrompt: string | null;
  onConfirmationFailure: () => any;
  title: string;
  yesPrompt: string;
}

export const WithConfirmation: React.VFC<WithConfirmationProps> = ({
  isCloseButtonShown,
  message,
  noPrompt,
  onConfirmationFailure,
  onConfirmationSuccess,
  title,
  yesPrompt,
  yesPromptTestId,
  noPromptTestId,
}) => {
  return (
    <Dialog
      className="sd-dialog-confirmation"
      canOutsideClickClose={false}
      isCloseButtonShown={isCloseButtonShown}
      isOpen
      onClose={onConfirmationFailure}
      title={title}
    >
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          onConfirmationFailure();
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>{message}</p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {yesPrompt ? (
              <Button
                className={Classes.POPOVER_OPEN}
                data-testid={yesPromptTestId}
                intent={Intent.PRIMARY}
                onClick={onConfirmationSuccess}
                text={yesPrompt}
              />
            ) : null}
            {noPrompt ? (
              <Button
                autoFocus
                className={Classes.POPOVER_DISMISS}
                data-testid={noPromptTestId}
                text={noPrompt}
                type="submit"
              />
            ) : null}
          </div>
        </div>
      </form>
    </Dialog>
  );
};

const unmountAndRemove = (node: HTMLElement) => {
  ReactDOM.unmountComponentAtNode(node);
  node.remove();
};

// Render an unattached dialog for receiving confirmation imperatively.
export const withConfirmation = ({
  isCloseButtonShown = true,
  message,
  noPrompt = copy.components.defaults.confirmation.no,
  noPromptTestId,
  onConfirmationFailure = noop,
  onConfirmationSuccess,
  title = copy.components.defaults.confirmation.title,
  yesPrompt = copy.components.defaults.confirmation.yes,
  yesPromptTestId,
}: WithConfirmationOptions) => {
  const node = document.createElement('div');
  ReactDOM.render(
    <WithConfirmation
      isCloseButtonShown={isCloseButtonShown}
      message={message}
      noPrompt={noPrompt}
      noPromptTestId={noPromptTestId}
      onConfirmationFailure={() => {
        unmountAndRemove(node);
        onConfirmationFailure();
      }}
      onConfirmationSuccess={() => {
        unmountAndRemove(node);
        onConfirmationSuccess();
      }}
      title={title}
      yesPrompt={yesPrompt}
      yesPromptTestId={yesPromptTestId}
    />,
    node
  );
};
