import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  KNOWLEDGE_CATALOG_NAVBAR_DROPDOWN_ITEM,
  PORTAL_NAVBAR_DROPDOWN_ITEM,
  VOICEBOX_NAVBAR_DROPDOWN_ITEM,
} from 'vet-bones/components';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  NAVBAR_DROPDOWN_KNOWLEDGE_CATALOG_ITEM,
  NAVBAR_DROPDOWN_PORTAL_ITEM,
  NAVBAR_DROPDOWN_VOICEBOX_ITEM,
} from 'src/ui/constants/testIds';
import { startedNewVoiceboxConversation } from 'src/ui/features/ui';
import { useConnectionIdx } from 'src/ui/hooks/navbar/connection';
import { redirectToUrl } from 'src/ui/utils/window';

export const useKnowledgeCatalogNavbarDropdownItem = () => {
  const connectionIdx = useConnectionIdx();

  const handleClick = React.useCallback(() => {
    const path = connectionIdx ? `/u/${connectionIdx}/knowledge-catalog/` : '/';
    redirectToUrl(path);
  }, [connectionIdx]);

  return React.useMemo(
    () => ({
      ...KNOWLEDGE_CATALOG_NAVBAR_DROPDOWN_ITEM,
      onClick: handleClick,
      testId: NAVBAR_DROPDOWN_KNOWLEDGE_CATALOG_ITEM,
    }),
    [handleClick]
  );
};

export const usePortalNavbarDropdownItem = () => {
  const history = useHistory();
  const connectionIdx = useConnectionIdx();

  const handleClick = React.useCallback(() => {
    const path = connectionIdx ? `/u/${connectionIdx}/` : '/';
    history.push(path);
  }, [connectionIdx, history]);

  return React.useMemo(
    () => ({
      ...PORTAL_NAVBAR_DROPDOWN_ITEM,
      onClick: handleClick,
      testId: NAVBAR_DROPDOWN_PORTAL_ITEM,
    }),
    [handleClick]
  );
};

export const useVoiceboxNavbarDropdownItem = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const connectionIdx = useConnectionIdx();

  const handleClick = React.useCallback(() => {
    dispatch(startedNewVoiceboxConversation());
    const path = connectionIdx ? `/u/${connectionIdx}/voicebox/` : '/';
    history.push(path);
  }, [connectionIdx, dispatch, history]);

  return React.useMemo(
    () => ({
      ...VOICEBOX_NAVBAR_DROPDOWN_ITEM,
      onClick: handleClick,
      testId: NAVBAR_DROPDOWN_VOICEBOX_ITEM,
    }),
    [handleClick]
  );
};
