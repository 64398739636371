import { Card, Elevation, H6 } from '@blueprintjs/core';
import * as React from 'react';

import {
  DASHBOARD_COMMUNITY,
  DASHBOARD_DOCS,
  DASHBOARD_KNOWLEDGE_KITS,
  DASHBOARD_TRAININGS,
} from 'src/ui/constants/testIds';
import communityIcon from 'src/ui/templates/images/community.svg';
import docsIcon from 'src/ui/templates/images/docs.svg';
import knowledgeKitIcon from 'src/ui/templates/images/knowledgeKits.svg';
import trainingsIcon from 'src/ui/templates/images/trainings.svg';
import { openApplicationInTab } from 'src/ui/utils/window';

type Widget = {
  title: string;
  link: string;
  icon: string;
  testId: string;
};
export const WIDGETS: Widget[] = [
  {
    title: 'Knowledge Kits',
    link: '/kits',
    icon: knowledgeKitIcon,
    testId: DASHBOARD_KNOWLEDGE_KITS,
  },
  {
    title: 'Docs',
    link: 'https://docs.stardog.com/',
    icon: docsIcon,
    testId: DASHBOARD_DOCS,
  },
  {
    title: 'Trainings',
    link: 'https://academy.stardog.com/learn',
    icon: trainingsIcon,
    testId: DASHBOARD_TRAININGS,
  },
  {
    title: 'Community',
    link: 'https://community.stardog.com/',
    icon: communityIcon,
    testId: DASHBOARD_COMMUNITY,
  },
];

export const Widgets: React.FC = () => {
  return (
    <div className="dashboard-widgets">
      {WIDGETS.map((widget) => (
        <Card
          className="widget-card"
          elevation={Elevation.ONE}
          key={widget.title}
          interactive
          onClick={() => openApplicationInTab(widget.link)}
          data-testid={widget.testId}
        >
          <img className="widget-icon" src={widget.icon} alt={widget.title} />
          <H6>{widget.title}</H6>
        </Card>
      ))}
    </div>
  );
};
