import type { ModuleFilters } from 'src/ui/hooks/modules';

export const MBASE = 'urn:stardog:modules:';

export const ALIAS_GRAPH = 'tag:stardog:api:graph:aliases';

export const MPREFIXES = `prefix : <${MBASE}>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix prov: <http://www.w3.org/ns/prov#>
prefix kg: <tag:stardog:api:context:>
prefix catalog: <tag:stardog:api:catalog:>
`;

export const MODULE_META_VARS = `?lastModified ?publisher ?type`;
export const MODULE_META = `optional { ?module dct:modified ?lastModified }
      optional { ?module dct:publisher ?publisher }
      optional { ?module dct:type ?type }`;

export const MODULE_PROPS_VARS = `?desc ?version ?databaseId ?schemaName ?pc ?search ?readme ?licenseName ?license ?alias ?icon ?subject ${MODULE_META_VARS}`;
export const MODULE_PROPS = `optional { ?module rdfs:comment ?desc }
      optional { ?module :version ?version }
      optional { ?module :kitDb ?databaseId }
      optional { ?module :schemaName ?schemaName }
      optional { ?module :primaryConcept ?pc }
      optional { ?module :initialSearch ?search }
      optional { ?module :alias ?alias }
      optional { ?module :icon ?icon }
      optional { ?module :readme ?readme }
      optional { ?module dct:license [ rdfs:label ?licenseName ; :link ?license ] }
      optional { ?module dct:subject ?subject }
      ${MODULE_META}`;

export const LIST_MODULES = `${MPREFIXES}
select distinct ?module_graph ?mod_id ?module ?name ${MODULE_PROPS_VARS} {
    graph ?module_graph {
      ?module a :Module;
      :id ?mod_id ;
      rdfs:label ?name ;
      ${MODULE_PROPS}
    }
}`;

export const SEARCH_FOR_MODULES = (
  q: string,
  { type, subjects, tags }: ModuleFilters
) => {
  const textMatch = q
    ? `?literal <tag:stardog:api:property:textMatch> """${q}""".`
    : '';
  const literal = q
    ? `graph ?g {
      ?s ?p ?literal .
    }`
    : '';

  const typeFilter = type ? [`dct:type <${type}> ;`] : [];
  const subjFilterVar =
    subjects && subjects.length ? [`dct:subject ?subjectToFilter ;`] : [];
  const subjFilterExp =
    subjects && subjects.length
      ? [
          `filter(?subjectToFilter in (${subjects
            .map((subj) => `<${subj}>`)
            .join(', ')}))`,
        ]
      : [];
  const tagFilterVar = tags && tags.length ? [`:tag ?tagToFilter ;`] : [];
  const tagsFilterExp =
    tags && tags.length
      ? [`filter(?tagToFilter in (${tags.map((t) => `<${t}>`).join(', ')}))`]
      : [];

  const filters = [
    ...typeFilter,
    ...subjFilterVar,
    ...tagFilterVar,
    ...subjFilterExp,
    ...tagsFilterExp,
  ].join('\n');

  return `${MPREFIXES}
select distinct ?module_graph ?mod_id ?module ?name ${MODULE_PROPS_VARS} {
  ${textMatch}
  graph ?module_graph {
    ?module :model | :data ?g .
    ?module a :Module;
      :id ?mod_id ;
      rdfs:label ?name ;
      ${filters}
      ${MODULE_PROPS}
  }
  ${literal}
}
`;
};

export const LOAD_MODULE = (graphs: string[], endpoint: string) => {
  const stemplate = (g: string) =>
    `construct { ?s ?p ?o . } where { graph <${g}> { ?s ?p ?o . } }`;

  const ltemplate = (g: string) =>
    `load <${endpoint}?query=${encodeURIComponent(
      stemplate(g)
    )}> into graph <${g}>`;

  return graphs.map((g) => ltemplate(g)).join(';\n');
};

export const REMOVE_ALIAS_DATA = (alias: string) => `DELETE {
  graph <${ALIAS_GRAPH}> {
    <${alias}> <tag:stardog:api:graph:alias> ?g.
  }
} WHERE {
  graph <${ALIAS_GRAPH}> {
      <${alias}> <tag:stardog:api:graph:alias> ?g.
  }
}
`;

export const ADD_ALIAS_DATA = (
  alias: string,
  graphs: string[]
) => `INSERT DATA {
    graph <${ALIAS_GRAPH}> {
        <${alias}> <tag:stardog:api:graph:alias> ${graphs
  .map((g) => `<${g}>`)
  .join(', ')} .
    }
}
`;

export const MODULE_OVERVIEW = (modId: string) => `${MPREFIXES}
select ?module_graph ?module ?mod_id ?name ${MODULE_PROPS_VARS} where {
    bind ("${modId}" as ?mod_id).
    graph ?module_graph {
        ?module a :Module;
            :id ?mod_id ;
            rdfs:label ?name ;
            ${MODULE_PROPS}
    }
}`;

export const MODULE_OVERVIEW_IRI = (modIRI: string) => `${MPREFIXES}
select ?module_graph ?module ?mod_id ?name ${MODULE_PROPS_VARS} where {
    bind (<${modIRI}> as ?module).
    graph ?module_graph {
        <${modIRI}> a :Module;
            :id ?mod_id ;
            rdfs:label ?name ;
            ${MODULE_PROPS}
    }
}`;

export const GET_MODULE_GRAPHS = (modId: string) => `${MPREFIXES}
select distinct ?module_graph ?model ?mod_id ?constraints ?data where {
            bind ("${modId}" as ?mod_id).
            graph ?module_graph {
                ?module a :Module;
                    :id ?mod_id ;
                    optional { { ?module :model ?model } union { ?module :hasSchema [ m:model ?model ] } }
                    optional { ?module :constraints ?constraints }
                    optional { ?module :data ?data }
            }
        }`;

export const GET_MODULE_DATA_GRAPHS = (modId: string) =>
  `${MPREFIXES}
select distinct ?module ?data where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    :data ?data .
            }
}`;

export const GET_MODULE_GRAPHS_FOR_MAIN_SCHEMA = (modId: string) =>
  `${MPREFIXES}
select distinct ?module ?model where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    :model ?model .
            }
}`;

export const GET_MODULE_SCHEMAS_WITH_GRAPHS = (modId: string) =>
  `${MPREFIXES}
select distinct ?module ?schemaName ?model where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    :hasSchema [
                      rdfs:label ?schemaName ;
                      :model ?model ;
                    ]
            }
}`;

export const GET_MODULE_STORED_QUERIES = (modId: string) =>
  `${MPREFIXES}
select distinct ?module ?queryName ?query where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    :hasQuery [
                      rdfs:label ?queryName ;
                      :queryCode ?query ;
                    ]
            }
}`;

export const GET_MODULE_TAGS = (modId: string) => `${MPREFIXES}
select distinct ?tag (coalesce(?l, localname(?tag)) as ?name) ?id where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    :tag ?tag .
                  }
            optional {
              graph ?g {
                ?tag rdfs:label ?l .
            }
          }
        }`;

export const GET_MODULE_TYPES = (modId: string) => `${MPREFIXES}
select distinct ?type ?id (coalesce(?l, localname(?type)) as ?name) where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    dct:type ?type .
                  }
            graph ?g {
                ?type rdfs:label ?l .
                optional { ?type :id ?id } .
            }
        }`;

export const DEMO_MODULE_TYPE = 'urn:stardog:modules:types:Demo';
export const TUTORIAL_MODULE_TYPE = 'urn:stardog:modules:types:Tutorial';

export const GET_MODULE_PUBLISHERS = (modId: string) => `${MPREFIXES}
select distinct ?pub ?id (coalesce(?l, localname(?pub)) as ?name) where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    dct:publisher ?pub .
            }
            graph ?g {
                ?pub rdfs:label ?l .
                optional { ?pub :id ?id } .
            }
}`;

export const GET_MODULE_CONTRIBUTORS = (modId: string) => `${MPREFIXES}
select distinct ?pers ?id (coalesce(?l, localname(?pers)) as ?name) where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    dct:contributor ?pers .
            }
            graph ?g {
                ?pers rdfs:label ?l .
                optional { ?pers :id ?id } .
            }
}`;

export const GET_MODULE_LINKS = (modId: string) => `${MPREFIXES}
select distinct ?linkText ?linkURL where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    rdfs:seeAlso [ rdfs:label ?linkText ; :link ?linkURL ] .
            }
        }`;

export const GET_MODULE_RELATED = (modId: string) => `${MPREFIXES}
select distinct ?related where {
  {
    graph ?module_graph {
        ?module a :Module; :id "${modId}" .
        ?module :related ?related
    }
  }
    union {
      graph ?module_graph {
        ?module a :Module; :id "${modId}" .
        ?module dct:type ?moduleType
      }
      graph ?g {
        ?related a :Module; dct:type ?moduleType .
      }
    }
    filter (?related != ?module)
}`;

export const GET_MODULE_EXAMPLES_PROMPTS = (modId: string) =>
  `${MPREFIXES}
select distinct ?example where {
            graph ?module_graph {
                ?module a :Module;
                    :id "${modId}" ;
                    :examplePrompts ?example .
            }
}`;

export const GET_DATASET_META = (graphs: string[]) => `${MPREFIXES}
select distinct ?g ?label ?description ?size ?search ?primary ?user ?timestamp
from kg:local
where {
    values ?g { ${graphs.map((g) => `<${g}>`).join(' ')} }

    ?g a ?type .
    ?g rdfs:label ?label.
    optional { ?g dct:description ?description }
    optional { ?g void:triples ?size }
    optional { ?g :initialSearch ?search }
    optional { ?g foaf:primaryTopic ?primary }

    optional {
        ?g prov:wasAttributedTo/rdfs:label ?user .
        ?g prov:generatedAtTime ?timestamp .
    }
}
`;

export const GET_DATASET_THEMES = (iri: string) => `${MPREFIXES}
select distinct ?theme ?label
from kg:local
where {
    bind (<${iri}> as ?g)

    ?g dcat:theme ?theme .
    optional { ?theme rdfs:label ?label }
}
`;

// https://docs.stardog.com/virtual-graphs/data-sources/data-source-configuration#configuration-options
// https://tomcat.apache.org/tomcat-9.0-doc/jdbc-pool.html#Common_Attributes
export const GET_MODULE_VGS = (modId: string) => `${MPREFIXES}
select distinct ?vg ?vgName ?source ?sourceName ?mapping ?mappingStatement ?mappingSyntax ?sourceType where {
  graph ?module_graph {
    ?module a :Module;
            :id "${modId}" ;
            :data ?vg .
  }
  graph ?g {
    ?vg a catalog:VirtualGraph ;
          rdfs:label ?vgName ;
          catalog:hasSource ?source ;
          catalog:hasMapping ?mapping .

    values ?sourceType { catalog:RDBMS catalog:db:MongoDb catalog:db:Sparql catalog:db:ElasticSearch catalog:db:Cassandra }
    ?source a catalog:DataSource, ?sourceType ;
            rdfs:label ?sourceName .

    ?mapping a catalog:Mapping ;
             catalog:mappingSyntax ?mappingSyntax ;
             catalog:statement ?mappingStatement .
  }
}`;

export const GET_DATA_SOURCE_OPTIONS = (sourceIri: string) => `${MPREFIXES}
select distinct ?key ?value from stardog:context:local where {
  {
    <${sourceIri}> ?p ?value .

    ?p a owl:DatatypeProperty ;
      catalog:vegaProperty ?key .
  }
  union {
    <${sourceIri}> catalog:otherOptions [
      catalog:key ?key ;
      catalog:value ?value ;
    ] .
  }
}
`;

export const LIST_MODULE_TAGS = `${MPREFIXES}
select distinct ?tag ?name
where {
  GRAPH kg:local {
    FILTER EXISTS { ?module m:tag ?tag }
  }
  GRAPH m:model {
    ?tag a skos:Concept ;
        skos:inScheme :TagScheme ;
        rdfs:label ?name .
  }
}
`;

export const LIST_MODULE_TYPES = `${MPREFIXES}
select distinct ?type ?name
from m:model
where {
    ?type a skos:Concept ;
        skos:inScheme :TypeScheme ;
        rdfs:label ?name .
}
`;
