import * as React from 'react';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  Connection,
  connectionInvalid,
  setConnectionBrowserAuthFailed,
  setConnectionDegraded,
  setConnectionUnauthorized,
} from 'src/ui/features/connection';
import { showConnectionDialog } from 'src/ui/features/ui';
import { Connection as GraphConnection } from 'src/ui/graph/types';
import { useVerifyConnection } from 'src/ui/hooks/connection';

export const useValidateConnection = () => {
  const dispatch = useAppDispatch();
  const { mutate: validate } = useVerifyConnection();

  return React.useCallback(
    (connection: GraphConnection | null) =>
      new Promise<boolean>((res) => {
        if (!connection || connection?.isAllocating) {
          res(false);
          return;
        }

        const isUsingStardogToken = Boolean(
          !connection?.useSSO && connection?.token
        );

        validate(connection as Connection, {
          onSuccess: () => res(true),
          onError: () => {
            if (isUsingStardogToken) {
              // If they are using Stardog token it has expired and they need
              // re-enter their password to get a new token.
              dispatch(connectionInvalid(connection.index));
              dispatch(showConnectionDialog(true));
            } else if (connection.useBrowserAuth) {
              // If useBrowserAuth was enabled and validate connection failed,
              // show banner warning for this
              dispatch(setConnectionBrowserAuthFailed(true));
            } else if (connection.useSSO) {
              // If they are using sso, then there they are most likely not
              // authorized or some other error with communication
              dispatch(setConnectionUnauthorized(true));
            } else {
              // If they are not using sso and don't have a token, then Stardog
              // does not support tokens (most likely) show configuration helper
              dispatch(setConnectionDegraded(true));
            }
            res(false);
          },
        });
      }),
    [dispatch, validate]
  );
};
