import { Button, HTMLSelect, Intent, Menu, MenuItem } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import {
  ItemListRenderer,
  ItemPredicate,
  ItemRenderer,
  Select,
} from '@blueprintjs/select';
import uniq from 'lodash/uniq';
import React from 'react';

import {
  KIT_CONNECTION_SELECTOR,
  KIT_CONNECTION_SELECTOR_OPTION,
  KIT_DB_SELECTOR_BUTTON,
  KIT_DB_SELECTOR_CREATE_NEW_INVALID_MENU_ITEM,
  KIT_DB_SELECTOR_CREATE_NEW_MENU_ITEM,
  KIT_DB_SELECTOR_MENU_ITEM,
} from 'src/ui/constants/testIds';
import { Connection as GraphConnection } from 'src/ui/graph/types';
import { components } from 'src/ui/templates/copy';

interface KitLocationSelectorProps {
  connectionSelectorDisabled?: boolean;
  databaseLabel?: string;
  databaseSelectorDisabled?: boolean;
  databaseSelectorVisible?: boolean;
  label?: string;
  selectedDatabase: string;
  selectedConnection: GraphConnection | null;
  databases?: IDatabase[];
  connections?: GraphConnection[];
  onConnectionChange: (name: string | null) => void;
  onDatabaseChange: (name: string) => void;
}

export interface IDatabase {
  name: string;
}

export const KitLocationSelector: React.FC<KitLocationSelectorProps> = ({
  children,
  connectionSelectorDisabled,
  databaseLabel,
  databaseSelectorDisabled,
  databaseSelectorVisible,
  label,
  selectedDatabase,
  selectedConnection,
  connections,
  databases,
  onConnectionChange,
  onDatabaseChange,
}) => {
  const handleConnectionOnChange = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    onConnectionChange(evt.currentTarget.value);
    onDatabaseChange('');
  };

  const handleDatabaseOnChange = (db: IDatabase) => {
    onDatabaseChange(db?.name || '');
  };

  const DatabaseSelect = Select.ofType<IDatabase>();

  const renderDatabase: ItemRenderer<IDatabase> = (
    database,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        data-testid={KIT_DB_SELECTOR_MENU_ITEM}
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={database.name}
        onClick={handleClick}
        text={database.name}
      />
    );
  };

  const filterDatabase: ItemPredicate<IDatabase> = (
    query,
    database,
    _index,
    exactMatch
  ) => {
    const normalizedName = database.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedName === normalizedQuery;
    }
    return `${normalizedName}`.indexOf(normalizedQuery) >= 0;
  };

  function validateDatabaseName(name: string): string {
    if (!name) {
      return components.marketplace.validation.databaseName.required;
    }

    const INVALID_NAME_MATCHER = /[^A-Za-z0-9_-]/gi;
    const invalidChars = name.match(INVALID_NAME_MATCHER);
    if (invalidChars) {
      return components.marketplace.validation.databaseName.characters(
        uniq(invalidChars)
      );
    }

    return '';
  }

  function createDatabase(name: string): IDatabase {
    const errorMessage = validateDatabaseName(name);
    if (errorMessage) {
      return { name: '' };
    }
    return { name };
  }

  function renderCreateDatabaseOption(
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
  ) {
    const errorMessage = validateDatabaseName(query);

    if (errorMessage) {
      return (
        <Tooltip2
          className="create-database-tooltip"
          intent={Intent.DANGER}
          content={errorMessage}
        >
          <MenuItem
            data-testid={KIT_DB_SELECTOR_CREATE_NEW_INVALID_MENU_ITEM}
            intent={Intent.DANGER}
            text={errorMessage}
          />
        </Tooltip2>
      );
    }

    const existsOnServer = databases?.some(
      (database) => database.name === query
    );

    if (!existsOnServer) {
      return (
        <Tooltip2
          className="create-database-tooltip"
          intent={Intent.PRIMARY}
          content={components.marketplace.kitSelector.createDatabaseTooltip}
        >
          <MenuItem
            data-testid={KIT_DB_SELECTOR_CREATE_NEW_MENU_ITEM}
            icon="add"
            text={`Create: "${query}"`}
            active={active}
            onClick={handleClick}
          />
        </Tooltip2>
      );
    }

    return <></>;
  }

  const renderMenu: ItemListRenderer<IDatabase> = ({
    items,
    itemsParentRef,
    renderItem,
    renderCreateItem,
  }) => {
    const renderedDatabases = items
      .sort((db1, db2) => {
        const lowerCasedDb1 = db1.name.toLowerCase();
        const lowerCasedDb2 = db2.name.toLowerCase();
        if (lowerCasedDb1 < lowerCasedDb2) return -1;
        if (lowerCasedDb1 > lowerCasedDb2) return 1;
        return 0;
      })
      .map(renderItem)
      .filter((item) => item != null);
    return (
      <Menu className="database-selector-menu" ulRef={itemsParentRef}>
        {renderCreateItem()}
        {renderedDatabases}
      </Menu>
    );
  };

  const getDatabaseSelectorButton = () => {
    return (
      selectedDatabase ||
      components.marketplace.kitSelector.databaseSelectorButton
    );
  };

  const getConnectionOptions = () => {
    if (connections?.length === 1) {
      return (
        <option
          data-testid={KIT_CONNECTION_SELECTOR_OPTION}
          value={connections[0].name}
        >
          {connections[0].name}
        </option>
      );
    }
    const connectionOptions = connections?.map((c) => (
      <option
        data-testid={KIT_CONNECTION_SELECTOR_OPTION}
        key={c.name}
        value={c.name}
      >
        {c.name}
      </option>
    ));
    return (
      <>
        <option data-testid={KIT_CONNECTION_SELECTOR_OPTION} value="">
          {components.marketplace.kitSelector.connectionSelectorInitialText}
        </option>
        {connectionOptions}
      </>
    );
  };

  return (
    <div className="kit-install">
      <div className="kit-install-row">
        <p className="kit-install-label">
          <strong>{label}</strong>
        </p>
        <HTMLSelect
          id="connection-selector"
          data-testid={KIT_CONNECTION_SELECTOR}
          value={selectedConnection?.name || ''}
          onChange={handleConnectionOnChange}
          disabled={connectionSelectorDisabled}
        >
          {getConnectionOptions()}
        </HTMLSelect>
      </div>
      {databaseSelectorVisible ? (
        <div className="kit-install-row">
          <p className="kit-install-label">{databaseLabel}</p>
          <DatabaseSelect
            className="database-selector"
            inputProps={{
              placeholder:
                components.marketplace.kitSelector.databaseSelectorPlaceholder,
            }}
            disabled={databaseSelectorDisabled}
            items={databases || ([] as IDatabase[])}
            onItemSelect={(db) => {
              handleDatabaseOnChange(db);
            }}
            itemRenderer={renderDatabase}
            itemPredicate={filterDatabase}
            itemListRenderer={renderMenu}
            createNewItemPosition="first"
            createNewItemFromQuery={createDatabase}
            createNewItemRenderer={renderCreateDatabaseOption}
          >
            <Button
              data-testid={KIT_DB_SELECTOR_BUTTON}
              disabled={databaseSelectorDisabled}
              text={getDatabaseSelectorButton()}
              rightIcon="double-caret-vertical"
            />
          </DatabaseSelect>
        </div>
      ) : null}
      <div className="kit-install-buttons">{children}</div>
    </div>
  );
};
