import { ActionProps, Intent } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { copy } from 'src/ui/templates/copy';
import { uniqueId } from 'src/ui/utils/uniqueId';
import { getInitialMessages, redirectToUrl } from 'src/ui/utils/window';

/** Available Message actions */
export type MessageAction = {
  RE_AUTH: ActionProps;
};

/** Concrete Message actions */
export const MessageActions: MessageAction = {
  RE_AUTH: {
    text: 'Re-authenticate',
    onClick: () => redirectToUrl('/auth/logout'),
  },
};

export type Message = {
  id: string;
  message: string;
  action?: keyof MessageAction;
  icon: IconName;
  intent: Intent;
};

export type MessageInput = Omit<Message, 'id' | 'icon'>;

export interface NotificationsState {
  // TODO(rmyers): deprecate notifications
  notifications: string[];
  messages: Message[];
}

const iconMapping = {
  [Intent.DANGER]: IconNames.WARNING_SIGN as IconName,
  [Intent.WARNING]: IconNames.WARNING_SIGN as IconName,
  [Intent.PRIMARY]: IconNames.TICK as IconName,
  [Intent.SUCCESS]: IconNames.TICK as IconName,
  [Intent.NONE]: IconNames.TICK as IconName,
};

const initialState = (): NotificationsState => ({
  // TODO(rmyers): deprecate notifications
  notifications: getInitialMessages(),
  messages: [],
});

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    unqueueNotification: (state, action: PayloadAction<string>) => {
      const index = state.notifications.indexOf(action.payload);
      if (index > -1) {
        state.notifications.splice(index, 1);
      }
    },
    queueNotification: (state, action: PayloadAction<string>) => {
      const hasMessage = Boolean(action.payload);
      state.notifications.push(
        hasMessage ? action.payload : copy.errors.unknown
      );
    },
    clearNotifications: (state) => {
      state.notifications = [];
      state.messages = [];
    },
    queueMessage: (state, action: PayloadAction<MessageInput>) => {
      const id = uniqueId('notification_');
      const icon = iconMapping[action.payload.intent];
      const message: Message = { ...action.payload, id, icon };
      state.messages.push(message);
    },
    clearMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter(({ id }) => id !== action.payload);
    },
  },
});

export const {
  unqueueNotification,
  queueNotification,
  clearNotifications,
  clearMessage,
  queueMessage,
} = notificationsSlice.actions;

export const { getInitialState } = notificationsSlice;
