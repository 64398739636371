import { Card, Elevation, H5, Intent, ProgressBar } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { TrackingEventList } from 'portal-sdk';
import * as React from 'react';
import { sendHubspotEvent } from 'vet-bones/utils/portal';

import {
  ESSENTIALS_TRIPLES_LIMIT,
  FREE_TRIPLES_LIMIT,
} from 'src/ui/constants/billing';
import { ServerOverviewButton } from 'src/ui/containers/dashboard/ServerOverviewButton';
import { InstanceFlavors } from 'src/ui/features/connection';
import { Connection as GraphConnection } from 'src/ui/graph/types';
import { useServerOverview } from 'src/ui/hooks/overview';
import * as copy from 'src/ui/templates/copy';

interface ServerOverviewProps {
  connection: GraphConnection | null | undefined;
}

export const ServerOverview: React.VFC<ServerOverviewProps> = ({
  connection,
}) => {
  const { data, isLoading } = useServerOverview(connection);
  const [hasSentEvent, setHasSentEvent] = React.useState(false);

  const isEssentials =
    connection?.cloud?.flavor?.name === InstanceFlavors.SMALL_VBX ||
    connection?.cloud?.flavor?.name === InstanceFlavors.SMALL;
  const isFree = connection?.isStardogFree;
  React.useEffect(() => {
    if (
      !hasSentEvent &&
      data?.numEdges &&
      data?.numDatabases &&
      !isLoading &&
      (isEssentials || isFree)
    ) {
      const totalEdges = isEssentials
        ? ESSENTIALS_TRIPLES_LIMIT
        : FREE_TRIPLES_LIMIT;
      const properties = {
        data_source_type: isFree ? 'free' : 'essentials',
        edge_count: data.numEdges.toString(),
        edges_remaining: (totalEdges - data.numEdges).toString(),
      };
      sendHubspotEvent(TrackingEventList.CLOUD_STORED_EDGES, properties);
      setHasSentEvent(true);
    }
  }, [data, hasSentEvent, isLoading, isEssentials, isFree]);

  if (isLoading || !data || !data.numDatabases) {
    return <Card elevation={Elevation.ONE} className="server-overview" />;
  }

  const edgesLabel =
    isEssentials || isFree
      ? copy.components.ServerOverview.labels.storedEdges
      : copy.components.ServerOverview.metrics.edges.label;

  // Format the numbers so they are easier to read
  const formattedEdges = new Intl.NumberFormat().format(data.numEdges);
  const formattedNodes = new Intl.NumberFormat().format(data.numNodes);
  const formattedQueries = new Intl.NumberFormat().format(data.numQueries);
  const triplesLimit = isEssentials
    ? ESSENTIALS_TRIPLES_LIMIT
    : FREE_TRIPLES_LIMIT;
  const formattedTriplesLimit = new Intl.NumberFormat().format(triplesLimit);

  const {
    payment_system: paymentSystem,
    trial_days_remaining: trialDaysRemaining,
    trial_days_total: trialDaysTotal,
  } = connection?.stripeSubscriptionOrder || {};
  const isTrial =
    connection?.cloud?.flavor?.name === InstanceFlavors.SMALL_VBX &&
    paymentSystem === 'stripe_subscription' &&
    Number.isFinite(trialDaysRemaining) &&
    trialDaysTotal;

  const trialDaysRemainingProgressBar =
    isTrial && trialDaysTotal ? (
      <div className="metric metric-sub-section">
        <ProgressBar
          animate={false}
          intent={Intent.PRIMARY}
          stripes={false}
          value={1 - (trialDaysRemaining || 0) / trialDaysTotal}
        />
      </div>
    ) : null;

  const edgeCountProgressBar =
    (isEssentials || isFree) && Number.isFinite(data.numEdges) ? (
      <>
        <div className="metric metric-sub-section">
          <span className="metric-label">
            {`${formattedEdges} of ${formattedTriplesLimit}`}
          </span>
        </div>
        <div className="metric metric-sub-section">
          <ProgressBar
            animate={false}
            intent={Intent.PRIMARY}
            stripes={false}
            value={data.numEdges / triplesLimit}
          />
        </div>
      </>
    ) : null;

  const hostingMetricBlock =
    isEssentials || isFree ? (
      <div className="metric">
        <Tooltip2
          content={
            isEssentials
              ? copy.components.ServerOverview.metrics.hostingEssentials
                  .description
              : copy.components.ServerOverview.metrics.hostingFree.description
          }
        >
          <span className="metric-label">
            {isEssentials
              ? copy.components.ServerOverview.metrics.hostingEssentials.label
              : copy.components.ServerOverview.metrics.hostingFree.label}
          </span>
        </Tooltip2>
        <span className="metric-value">
          {isEssentials
            ? copy.components.ServerOverview.metrics.hostingEssentials.value
            : copy.components.ServerOverview.metrics.hostingFree.value}
        </span>
      </div>
    ) : null;

  return (
    <Card elevation={Elevation.ONE} className="server-overview">
      <div className="metrics">
        <div className="server-overview-header">
          <H5>{copy.components.ServerOverview.title.metrics}</H5>
          <ServerOverviewButton connection={connection} />
        </div>
        {isTrial ? (
          <>
            <div className="metric">
              <Tooltip2
                content={
                  copy.components.ServerOverview.metrics.edges.description
                }
              >
                <span className="metric-label">
                  {
                    copy.components.ServerOverview.metrics.trialDaysRemaining
                      .label
                  }
                </span>
              </Tooltip2>
              <span className="metric-value">
                {copy.components.ServerOverview.metrics.trialDaysRemaining.days(
                  trialDaysRemaining || 0
                )}
              </span>
            </div>
            {trialDaysRemainingProgressBar}
          </>
        ) : null}
        <div className="metric">
          <Tooltip2
            content={copy.components.ServerOverview.metrics.edges.description}
          >
            <span className="metric-label">{edgesLabel}</span>
          </Tooltip2>
          <span className="metric-value">{formattedEdges}</span>
        </div>
        {edgeCountProgressBar}
        <div className="metric">
          <Tooltip2
            content={copy.components.ServerOverview.metrics.nodes.description}
          >
            <span className="metric-label">
              {copy.components.ServerOverview.metrics.nodes.label}
            </span>
          </Tooltip2>
          <span className="metric-value">{formattedNodes}</span>
        </div>
        <div className="metric">
          <Tooltip2
            content={copy.components.ServerOverview.metrics.queries.description}
          >
            <span className="metric-label">
              {copy.components.ServerOverview.metrics.queries.label}
            </span>
          </Tooltip2>
          <span className="metric-value">{formattedQueries}</span>
        </div>
        {hostingMetricBlock}
      </div>
    </Card>
  );
};
