import { Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';
import { useQueryClient } from 'react-query';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { CreateProfileForm } from 'src/ui/containers/dialogs/CreateProfile/CreateProfileForm';
import { DialogType, profileUpdated } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  ProfileInput,
  useProfileQuery,
  useUpdateProfileMutation,
} from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import { components } from 'src/ui/templates/copy';

const isOpenSelector = isDialogOpenSelector(DialogType.CREATE_PROFILE);
export const CreateProfileDialog: React.VFC = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(isOpenSelector);
  const onError = useOnError();
  const onMutate = useOnMutate();

  const {
    mutate: updateProfile,
    isLoading: isUpdatingProfile,
  } = useUpdateProfileMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: () => {
      queryClient.invalidateQueries(useProfileQuery.getKey());
      dispatch(profileUpdated());
    },
  });

  const handleUpdate = (input: ProfileInput) => {
    updateProfile({ input });
  };

  return (
    <Dialog
      className="sd-dialog-profile"
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      icon={IconNames.PERSON}
      isCloseButtonShown={false}
      isOpen={isOpen}
      title={components.createProfileDialog.title}
    >
      <div
        className={classNames(Classes.DIALOG_BODY, 'sd-dialog-profile__body')}
      >
        <CreateProfileForm
          isSubmitting={isUpdatingProfile}
          onSubmit={handleUpdate}
        />
      </div>
    </Dialog>
  );
};
