/**
 * Utility to split an array of any type into even sized chunks (arrays).
 * e.g. splitIntoChunks([1,2,3,4,5], 2) => [[1,2], [3,4], [5]]
 */
export function splitIntoChunks<T>(arr: T[], chunkSize: number): T[][] {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
}
