import { Button, Classes, Intent, Position, Toaster } from '@blueprintjs/core';
import React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { queueMessage } from 'src/ui/features/notifications';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  PartnerConnectionDetail,
  RemovePartnerConnectionInput,
  useRemovePartnerConnectionMutation,
  useUserPartnerConnectionsQuery,
} from 'src/ui/graph/types';
import { useRemoveDatabricksJdbcDataSource } from 'src/ui/hooks/connection';
import { useRemoveDatabricksStarterKits } from 'src/ui/hooks/databricksstarterkit/dbcore';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { selectedPartnerConnection } from 'src/ui/selectors/connection';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import * as copy from 'src/ui/templates/copy';

interface OwnProps {
  connections: GraphConnection[];
}

const isOpenSelector = isDialogOpenSelector(DialogType.PARTNER_CONNECTION);
export const DeletePartnerConnectionDialog: React.VFC<OwnProps> = ({
  connections,
}) => {
  const dispatch = useAppDispatch();
  const onError = useOnError();
  const onMutate = useOnMutate();
  const isOpen = useAppSelector(isOpenSelector);
  const partnerConnectionDetail: PartnerConnectionDetail = useAppSelector(
    selectedPartnerConnection
  );
  const { data: partnerInfo } = useUserPartnerConnectionsQuery(graphQLClient);

  const {
    mutate: removeDatabricksStarterKitsMutation,
  } = useRemoveDatabricksStarterKits({
    onError: (error: any) => {
      dispatch(
        queueMessage({
          intent: Intent.WARNING,
          message: copy.components.databrickspartner.uninstall.failed
            .concat('-')
            .concat(error.message),
        })
      );
    },
    onSuccess: () => {
      Toaster.create({
        className: 'recipe-toaster',
        position: Position.BOTTOM_RIGHT,
      }).show({
        intent: 'success',
        timeout: 2000,
        message: copy.components.databrickspartner.uninstall.complete,
      });
    },
  });

  const shouldRemoveStarterKit = (
    stardogConnectionFound: GraphConnection | undefined
  ) => {
    if (!stardogConnectionFound || !partnerInfo?.userPartnerConnections)
      return false;

    const partnerConnections = partnerInfo?.userPartnerConnections.filter(
      (partnerConnection) => {
        return (
          partnerConnection?.stardog_server_connection?.endpoint ===
          stardogConnectionFound.endpoint
        );
      }
    );
    return partnerConnections.length === 1;
  };

  const {
    mutate: removePartnerConnection,
  } = useRemovePartnerConnectionMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: () => {
      const stardogServerName =
        partnerConnectionDetail.stardog_server_connection?.name;
      const stardogConnectionFound = connections.find(
        (connection) => stardogServerName === connection.name
      );
      Toaster.create({
        className: 'recipe-toaster',
        position: Position.BOTTOM_RIGHT,
      }).show({
        timeout: 2000,
        intent: 'primary',
        message: copy.components.databrickspartner.uninstall.started,
      });
      if (shouldRemoveStarterKit(stardogConnectionFound)) {
        removeDatabricksStarterKitsMutation(
          stardogConnectionFound || connections[0]
        );
      }
    },
  });

  const {
    mutate: removeJdbcConnectionMutation,
  } = useRemoveDatabricksJdbcDataSource({
    onError: (error: any) => {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: copy.components.databrickspartner.removeConnectionFailMessage(
            error.message
          ),
        })
      );
    },
    onSuccess: () => {
      const input: RemovePartnerConnectionInput = {
        connection_id: partnerConnectionDetail.connection_id || '',
      };
      removePartnerConnection({ input });
      dispatch(closedDialog());
    },
  });

  const handleSubmit = () => {
    const stardogServerName =
      partnerConnectionDetail.stardog_server_connection?.name;
    const stardogConnectionFound = connections.find(
      (connection) => stardogServerName === connection.name
    );

    removeJdbcConnectionMutation({
      connection: stardogConnectionFound || connections[0],
      databricksConnectionName:
        partnerConnectionDetail.databricks_connection_name || '',
    });
  };

  const onConfirmationFailure = () => {
    dispatch(closedDialog());
  };

  const body = (
    <Dialog
      className="sd-dialog-confirmation"
      canOutsideClickClose={false}
      isOpen={isOpen}
      onClose={onConfirmationFailure}
      title={
        copy.components.databrickspartner.connectionMenu.deletePartnerConnection
      }
      canEscapeKeyClose
    >
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          onConfirmationFailure();
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            {copy.components.databrickspartner.deletePermanentlyConfirmation}
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              className={Classes.POPOVER_OPEN}
              intent={Intent.DANGER}
              onClick={handleSubmit}
              text={copy.components.databrickspartner.deletePermanently}
            />
            <Button
              autoFocus
              className={Classes.POPOVER_DISMISS}
              text="No"
              type="submit"
            />
          </div>
        </div>
      </form>
    </Dialog>
  );

  return body;
};
