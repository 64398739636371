import {
  AnchorButton,
  Callout,
  FormGroup,
  H2,
  InputGroup,
} from '@blueprintjs/core';
import * as React from 'react';

import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { CloudCleanupInput, useDeleteCloudMutation } from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';

/* istanbul ignore next: skipping tests for this component as admin only */
export const CloudAdmin: React.VFC = () => {
  const [input, setInput] = React.useState<CloudCleanupInput>({});
  const [warning, setWarning] = React.useState<string | null>(null);
  const onError = useOnError();
  const onMutate = useOnMutate();
  const { mutate: deleteCloud } = useDeleteCloudMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: (data) => {
      if (data.deleteCloud?.error) {
        setWarning(data.deleteCloud.error);
      } else {
        setInput({});
      }
    },
  });

  const handleTextFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    setWarning(null);

    if (!input.cloudName || !input.userName) {
      setWarning('CloudName and Username are required');
      return;
    }

    deleteCloud({ input });
  };

  const callout = warning ? <Callout intent="danger">{warning}</Callout> : null;

  return (
    <div className="sd-cloud">
      <div>
        <H2>Hello Admin</H2>
        <p>Be careful not to destroy things people are still using!</p>
        <p>Double check your work!</p>
      </div>
      <div>
        <form className="sd-free-form" onSubmit={handleSubmit}>
          <FormGroup
            label="Enter username of cloud owner"
            labelFor="sd-cloud-username"
          >
            <InputGroup
              autoFocus
              id="sd-cloud-username"
              name="userName"
              value={input.userName || ''}
              onChange={handleTextFieldChange}
            />
          </FormGroup>
          <FormGroup
            label="Enter name of cloud to delete"
            labelFor="sd-cloud-name"
          >
            <InputGroup
              id="sd-cloud-name"
              name="cloudName"
              value={input.cloudName || ''}
              onChange={handleTextFieldChange}
            />
          </FormGroup>
          {callout}
          <input type="submit" hidden />
          <AnchorButton intent="primary" onClick={handleSubmit}>
            Delete all the things!
          </AnchorButton>
        </form>
      </div>
    </div>
  );
};
