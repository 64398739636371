import {
  Button,
  Card,
  Classes,
  Elevation,
  H4,
  HTMLTable,
  Intent,
  NonIdealState,
} from '@blueprintjs/core';
import { getConnectionCookie } from 'portal-sdk';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import {
  DIAGNOSTIC_AUTHORIZATION_HEADER,
  DIAGNOSTIC_CUSTOM_HEADER,
} from 'src/ui/constants/environment';
import { DiagnosticCheck } from 'src/ui/containers/diagnostic/DiagnosticCheck';
import { DiagnosticCheckHealth } from 'src/ui/containers/diagnostic/DiagnosticCheckHealth';
import { DiagnosticCheckStardog } from 'src/ui/containers/diagnostic/DiagnosticCheckStardog';
import { DiagnosticConnection } from 'src/ui/containers/diagnostic/DiagnosticConnection';
import { DiagnosticVoiceboxSection } from 'src/ui/containers/diagnostic/DiagnosticVoiceboxSection';
import { queueMessage } from 'src/ui/features/notifications';
import { useCurrentConnection } from 'src/ui/hooks/connection';
import { useDiagnostic } from 'src/ui/hooks/diagnostic';
import { useIsVoiceboxEnabledServer } from 'src/ui/hooks/voicebox';
import { isUnverifiedUser } from 'src/ui/selectors/isUnverifiedUser';
import { copy } from 'src/ui/templates/copy';

export const Diagnostic: React.VFC = () => {
  const { isLoading, isFetching, isError, data, refetch } = useDiagnostic();
  const dispatch = useAppDispatch();
  const unverifiedUser = useAppSelector(isUnverifiedUser());
  const connectionIndex = useParams<{ id: string | undefined }>().id;
  const { connection } = useCurrentConnection(connectionIndex);
  const connectionId = connection?.id;
  const connectionCookie = getConnectionCookie();
  const isVoiceboxEnabledServer = useIsVoiceboxEnabledServer(
    connectionIndex || ''
  )?.data;

  const reloadReport = (
    <Button intent={Intent.PRIMARY} onClick={() => refetch()}>
      {copy.login.retry}
    </Button>
  );

  const copyDiagnosticReport = () => {
    const body = document.getElementById('diagnostic-report');
    const text = body?.innerText || '';
    navigator.clipboard.writeText(text);
    dispatch(
      queueMessage({
        message: copy.diagnostic.copiedNotification,
        intent: Intent.SUCCESS,
      })
    );
  };

  const responseTime: number = data?.responseTime || 2000;
  const responseTimeCheck = () => responseTime < 1000;

  const rawHeaders = data?.data.headers || {};
  const {
    Authorization,
    'User-Agent': UserAgent,
    'X-Custom-Header': CustomHeader,
    ...headers
  } = rawHeaders;
  const authCheck = () => Authorization === DIAGNOSTIC_AUTHORIZATION_HEADER;
  const headerCheck = () => CustomHeader === DIAGNOSTIC_CUSTOM_HEADER;
  const idCheck = () => typeof connection?.id !== 'undefined';
  const verifiedCheck = () => !unverifiedUser;
  // TODO(rmyers): check supported browsers here
  const userAgentCheck = () => typeof UserAgent !== 'undefined';

  const connectionInfo = data?.data?.connectionInfo;
  const connectionMessage =
    connectionInfo?.message || copy.diagnostic.notConnected;
  const connectionCheck = () => Boolean(connectionInfo);

  const diagnosticReport = isError ? (
    <NonIdealState
      icon="error"
      title="Error"
      description={copy.errors.connectionFailed}
      action={reloadReport}
    />
  ) : (
    <>
      <DiagnosticCheckStardog
        connection={connection}
        isLoading={isFetching}
        isLaunchpad={!!connectionCookie?.isLaunchpad}
      />
      <div className="retry">{reloadReport}</div>
      <H4>{copy.diagnostic.title}</H4>
      <p>{copy.diagnostic.support}</p>
      <Card
        elevation={Elevation.THREE}
        interactive
        onClick={copyDiagnosticReport}
      >
        <div id="diagnostic-report">
          <div className="checks">
            <DiagnosticCheck check={authCheck}>
              <span>{copy.diagnostic.authHeader}</span>
            </DiagnosticCheck>
            <DiagnosticCheck check={headerCheck}>
              <span>{copy.diagnostic.customHeader}</span>
            </DiagnosticCheck>
            <DiagnosticCheck check={idCheck}>
              <span>{`Connection ID: ${connection?.id}`}</span>
            </DiagnosticCheck>
            <DiagnosticCheck check={userAgentCheck}>
              <span>{`Browser: ${UserAgent}`}</span>
            </DiagnosticCheck>
            <DiagnosticCheck check={responseTimeCheck} isLoading={isFetching}>
              <span>{`Response Time: ${responseTime} ms`}</span>
            </DiagnosticCheck>
            <DiagnosticCheck check={connectionCheck}>
              <span>{connectionMessage}</span>
            </DiagnosticCheck>
            <DiagnosticCheck check={verifiedCheck}>
              <span>{copy.diagnostic.verifiedUser}</span>
            </DiagnosticCheck>
            <DiagnosticCheckHealth
              connection={connection}
              isLoading={isFetching}
            />
            <DiagnosticCheckStardog
              connection={connection}
              isLoading={isFetching}
              minimal
              isLaunchpad={!!connectionCookie?.isLaunchpad}
            />
            <DiagnosticConnection connectionInfo={connectionInfo} />
          </div>
          <div className="response-details">
            <HTMLTable condensed bordered>
              <thead>
                <tr>
                  <th>Header</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(headers)
                  .sort()
                  .map((header) => (
                    <tr key={header}>
                      <td>{`${header}:`}</td>
                      <td>{headers[header]}</td>
                    </tr>
                  ))}
              </tbody>
            </HTMLTable>
          </div>
        </div>
        <Button text={copy.diagnostic.copy} className={Classes.BUTTON} />
      </Card>
    </>
  );

  return (
    <div className="sd-diagnostic">
      {connection ? (
        <H4 className="sd-landing-header">{connection.name}</H4>
      ) : null}
      {isLoading ? <Loading /> : diagnosticReport}
      {isVoiceboxEnabledServer && connectionIndex && connectionId ? (
        <DiagnosticVoiceboxSection
          connectionIndex={Number(connectionIndex)}
          connectionId={connectionId}
        />
      ) : null}
    </div>
  );
};
