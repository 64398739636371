import { Position, Toast, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import {
  Message,
  MessageActions,
  clearMessage,
} from 'src/ui/features/notifications';

// Component to handle showing and closing "snackbar" notifications. The
// component renders only one notification (the first one in the notifications
// queue) at a time.
export const Messages: React.VFC = () => {
  const messages = useAppSelector((state) => state.notifications.messages);
  const dispatch = useAppDispatch();

  const dismissMessage = ({ id }: Message) => () => dispatch(clearMessage(id));

  return (
    <Toaster position={Position.BOTTOM_RIGHT}>
      {messages.map((message) => {
        return (
          <Toast
            key={message.id}
            timeout={15000}
            onDismiss={dismissMessage(message)}
            message={message.message}
            icon={message.icon || IconNames.WARNING_SIGN}
            intent={message.intent}
            action={message.action && MessageActions[message.action]}
            data-testid="toast-message"
          />
        );
      })}
    </Toaster>
  );
};
