import { Divider, Tag } from '@blueprintjs/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { alphaSort } from 'vet-bones/utils';

import { ActionLink } from 'src/ui/components/ActionLink';
import { ExternalLink } from 'src/ui/components/ExternalLink';
import { KitReadme } from 'src/ui/containers/marketplace/KitReadme';
import { GraphMeta, Module, ModuleExt } from 'src/ui/hooks/modules';
import { components } from 'src/ui/templates/copy';

interface KitDetailsProps {
  actions: JSX.Element;
  module: Module;
  moduleMeta?: ModuleExt | null;
}

export const KitDetails: React.VFC<KitDetailsProps> = ({
  actions,
  module,
  moduleMeta,
}) => {
  const history = useHistory();

  const tags =
    moduleMeta?.tags && moduleMeta?.tags.length > 0 ? (
      <>
        <span className="metadata-table-property">
          {components.marketplace.overview.meta.tags}
        </span>
        <span className="metadata-table-value">
          {moduleMeta.tags.sort(alphaSort((tag) => tag.label)).map((t) => (
            <Tag key={t.label}>{t.label}</Tag>
          ))}
        </span>
      </>
    ) : null;

  const contributors =
    moduleMeta?.contributors && moduleMeta?.contributors.length > 0 ? (
      <>
        <span className="metadata-table-property">
          {components.marketplace.overview.meta.contributors}
        </span>
        <span className="metadata-table-value">
          {moduleMeta.contributors.map((c) => c.label).join(', ')}
        </span>
      </>
    ) : null;

  const publishers =
    moduleMeta?.publishers && moduleMeta?.publishers.length > 0 ? (
      <>
        <span className="metadata-table-property">
          {components.marketplace.overview.meta.publisher}
        </span>
        <span className="metadata-table-value">
          {moduleMeta.publishers.map((c) => c.label).join(', ')}
        </span>
      </>
    ) : null;

  const license = module.license ? (
    <>
      <span className="metadata-table-property">
        {components.marketplace.overview.meta.license}
      </span>
      <span className="metadata-table-value">
        <ExternalLink key={module.license} href={module.license}>
          {module.licenseName}
        </ExternalLink>
      </span>
    </>
  ) : null;

  const createLink = (mod: Module) => (
    <ActionLink
      key={mod.id}
      onClick={
        /* istanbul ignore next */ () => {
          history.push(`/kits/${mod.id}`);
        }
      }
    >
      {mod.name}
    </ActionLink>
  );

  const related = moduleMeta?.related && moduleMeta?.related.length > 0 && (
    <div className="related-list">
      {moduleMeta.related.map((c) => createLink(c))}
    </div>
  );

  const links =
    moduleMeta?.links && moduleMeta.links.length > 0 ? (
      <>
        <span className="metadata-table-property">
          {components.marketplace.overview.meta.links}
        </span>
        <span className="metadata-table-value">
          <div style={{ display: 'grid', gridTemplateColumns: 'auto' }}>
            {moduleMeta.links.map((l) => (
              <ExternalLink key={l.url} href={l.url}>
                {l.text}
              </ExternalLink>
            ))}
          </div>
        </span>
      </>
    ) : null;

  const graphProv = (meta: GraphMeta) => (
    <div key={meta.iri} style={{ display: 'grid', rowGap: 5 }}>
      <span className="metadata-title">{meta.label}</span>
      <div className="metadata-table">
        {meta.size && (
          <>
            <span className="metadata-table-property">
              {components.marketplace.overview.meta.size}
            </span>
            <span className="metadata-table-value">{meta.size} edges</span>
          </>
        )}
        {meta.primaryConcept && (
          <>
            <span className="metadata-table-property">
              {components.marketplace.overview.meta.primary}
            </span>
            <span className="metadata-table-value">
              {meta.primaryConcept.label}
            </span>
          </>
        )}
        {meta.concepts && meta.concepts.length > 0 && (
          <>
            <span className="metadata-table-property">
              {components.marketplace.overview.meta.themes}
            </span>
            <span className="metadata-table-value">
              {meta.concepts.map((c) => c.label).join(', ')}
            </span>
          </>
        )}
      </div>
    </div>
  );

  const prov =
    moduleMeta?.graphMeta && moduleMeta.graphMeta.length > 0
      ? moduleMeta.graphMeta.map((meta) => graphProv(meta))
      : null;

  return (
    <div className="overview-panel-main">
      <div className="overview-panel-content">
        <div className="overview-panel-content-header">
          <p className="title">{components.marketplace.overview.title}</p>
        </div>
        <KitReadme module={module} />
      </div>

      <div className="overview-panel-metadata-container">
        <Divider />

        <div className="overview-panel-metadata">
          {actions}

          <Divider />

          <div className="overview-panel-metadata-header">
            <p className="title">{components.marketplace.overview.about}</p>
          </div>
          <div className="metadata-table">
            {publishers}
            {contributors}
            {license}
            {tags}
            {links}
            <span className="metadata-table-property">
              {components.marketplace.overview.meta.lastModified}
            </span>
            <span className="metadata-table-value">{module.lastModified}</span>
          </div>
          {related && (
            <div>
              <p className="sidebar-title">
                {components.marketplace.overview.related}
              </p>
              {related}
            </div>
          )}
          {prov && (
            <div>
              <p className="sidebar-title">
                {components.marketplace.overview.prov}
              </p>
              {prov}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
