export const NYCTAXI_MAPPINGS = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix : <urn:databricks:demos:nyctaxi:model:>

MAPPING <urn:databricks:demos:mappings:nyctaxi:NYCTaxiMappings>
FROM SQL {
    SELECT * FROM nyctaxi.trips
}
TO {
	?iri a :Trip ;
	    :dropoff_time ?dropoff_time ;
	    :pickup_time ?pickup_time ;
	    :distance ?distance ;
	    :fare_amount ?fare ;
	    :pickup_zip ?pickup_zip ;
	    :dropoff_zip ?dropoff_zip ;
		rdfs:label ?tpep_pickup_datetime .
}
WHERE {
	BIND(TEMPLATE("urn:databricks:demos:nyctaxi:data:{pickup_zip}:{dropoff_zip}:{trip_distance}:{fare_amount}:{tpep_pickup_datetime}:{tpep_dropoff_datetime}") AS ?iri)
	BIND(xsd:float(?fare_amount) as ?fare)
    BIND(xsd:float(?trip_distance) as ?distance)
    BIND(xsd:datetime(?tpep_pickup_datetime) as ?pickup_time)
    BIND(xsd:datetime(?tpep_dropoff_datetime) as ?dropoff_time)
}`;

export const DISTANCE_TO_FARE_ANALYSIS_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix sqs: <tag:stardog:api:sqs:>
prefix : <urn:databricks:demos:nyctaxi:model:>

SELECT ?dayName ?fare ?d
from kg:virtual
WHERE {
    {
        select distinct ?pt ?fare ?d {
            ?t a :Trip ; :pickup_zip ?zip ; :pickup_time ?pt ; :distance ?d ; :fare_amount ?fare.
            filter (?d < 10 && (?zip = "10001" || ?zip = "10018") && ?pt > "2016-01-01T12:07:00" && ?pt < "2016-01-16T12:07:00")
        }    
    }
    # adapted from https://stackoverflow.com/a/55619260
    BIND (xsd:date(?pt) - "1900-01-01"^^xsd:date AS ?days)
    BIND (?days - (strdt("P7D", xsd:dayTimeDuration) * floor(?days / strdt("P7D",xsd:dayTimeDuration))) AS ?mod)
    BIND (str(?mod) AS ?modStr)
    VALUES (?modStr ?dayName) {
            ("PT0S"   "Monday") # 1900-01-01 was Monday
            ("P1D"    "Tuesday")
            ("P2D"    "Wednesday")
            ("P3D"    "Thursday")
            ("P4D"    "Friday")
            ("P5D"    "Saturday")
            ("P6D"    "Sunday")
    }
}`;

export const DROPOFF_HOUR_DIST_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix sqs: <tag:stardog:api:sqs:>
prefix : <urn:databricks:demos:nyctaxi:model:>

select ?dropoff_hour (count(?t) as ?number_of_rides)
from kg:virtual {
    ?t a :Trip ; :pickup_zip ?zip ; :pickup_time ?pt ; :dropoff_time ?dt; :distance ?d ; :fare_amount ?fare.
    filter (?zip = "10001" && ?pt > "2016-01-01T12:07:00" && ?pt < "2016-01-16T12:07:00")
    bind(hours(?dt) as ?hour)    
    bind(concat(if(?hour < 10, concat("0", str(?hour)), str(?hour)), ":00") as ?dropoff_hour)
}
group by ?dropoff_hour`;

export const NYCTAXI_MODULE_TRIG = `
prefix sd: <urn:stardog:>
prefix m: <urn:stardog:modules:>
prefix catalog: <tag:stardog:api:catalog:>

prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix prov: <http://www.w3.org/ns/prov#>

prefix : <urn:databricks:demos:nyctaxi:>

prefix model: <urn:databricks:demos:nyctaxi:model:>

<urn:mp:databricks:demos:nyctaxi:1.0> {
    <urn:mp:databricks:demos:nyctaxi:1.0> a m:Module ;
        # Internal metadata
        m:moduleFormat "2";

        # Describing this module:

        # The required metadata
        m:version "1.0" ;
        m:id "demos:nyctaxi:1.0" ;
        rdfs:label "Databricks nyctaxi Tutorial" ;

        # MP meta
        dct:contributor sd:mhgrove ;
        dct:publisher sd:Stardog ;
        dct:publisher <urn:databricks:Databricks> ;
        m:status m:status:Draft ;
        dct:type m:types:Tutorial ;
        dct:subject m:General ;
        m:tag sd:Databricks;

        # Recommended bits
        rdfs:comment "Databricks nyctaxi Tutorial" ;
        m:primaryConcept model:Trip ;
        m:initialSearch "" ;
        m:icon "learning" ;

        # User-defined
        rdfs:seeAlso [ rdfs:label "Quick Start" ; m:link <https://docs.databricks.com/getting-started/quick-start.html> ] ;

        # Module artifacts
        m:schemaName "nyctaxi" ;
        m:model <urn:databricks:demos:nyctaxi:model> ;

        m:alias <urn:databricks:demos:nyctaxi> ;

        m:data :vg:nyctaxi ;
    .
}

:vg:nyctaxi {
    :vg:nyctaxi a catalog:VirtualGraph ; 
        rdfs:label "nyctaxi Dataset" ;
        dct:description "nyctaxi Dataset" ;
        foaf:primaryConcept model:Trip ;

        catalog:vgName "nyctaxi" ;
        catalog:hasSource :source:nyctaxi ; 
        catalog:hasMapping <urn:databricks:demos:mappings:nyctaxi:NYCTaxiMappings> ;
        catalog:graph <virtual://nyctaxi> .

    :source:nyctaxi a catalog:DataSource, catalog:db:Databricks ;
        rdfs:label "CentralDatabricks-Samples" .

    <urn:databricks:demos:mappings:nyctaxi:NYCTaxiMappings> a catalog:Mapping ; 
}

<urn:databricks:demos:nyctaxi:model> {
    <urn:databricks:demos:nyctaxi:model> a m:DataModel ;
        rdfs:label "nyctaxi Data Model" ;
        foaf:primaryConcept model:Trip ;
        dct:description "nyctaxi Data Model" .

    ## TODO: prov

    model:Trip a owl:Class .

    model:dropoff_time a owl:DatatypeProperty ; 
        rdfs:domain model:Trip ;
        rdfs:range xsd:dateTime .

    model:pickup_time a owl:DatatypeProperty ; 
        rdfs:domain model:Trip ;
        rdfs:range xsd:dateTime .

    model:distance a owl:DatatypeProperty ; 
        rdfs:domain model:Trip ;
        rdfs:range xsd:float .

    model:fare_amount a owl:DatatypeProperty ; 
        rdfs:domain model:Trip ;
        rdfs:range xsd:float .

    model:pickup_zip a owl:DatatypeProperty ; 
        rdfs:domain model:Trip ;
        rdfs:range xsd:string .

    model:dropoff_zip a owl:DatatypeProperty ; 
        rdfs:domain model:Trip ;
        rdfs:range xsd:string .
}`;

export const PICKUP_HOUR_DIST_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix sqs: <tag:stardog:api:sqs:>
prefix : <urn:databricks:demos:nyctaxi:model:>

select ?pickup_hour (count(?t) as ?number_of_rides)
from kg:virtual {
    ?t a :Trip ; :pickup_zip ?zip ; :pickup_time ?pt ; :distance ?d ; :fare_amount ?fare.
    filter (?zip = "10001" && ?pt > "2016-01-01T12:07:00" && ?pt < "2016-01-16T12:07:00")
    bind(hours(?pt) as ?hour)    
    bind(concat(if(?hour < 10, concat("0", str(?hour)), str(?hour)), ":00") as ?pickup_hour)
}
group by ?pickup_hour`;

export const ROUTE_REVENUE_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix sqs: <tag:stardog:api:sqs:>
prefix : <urn:databricks:demos:nyctaxi:model:>

select ?route (count(?t) as ?route_frequency) (sum(?fare) as ?total_fare)
from kg:virtual {
    ?t a :Trip ; :pickup_zip ?pickup_zip ; :dropoff_zip ?dropoff_zip ; :pickup_time ?pt ; :fare_amount ?fare .
    filter (?pickup_zip = "10001" && ?pt > "2016-01-01T12:07:00" && ?pt < "2016-01-16T12:07:00")
    bind(concat(str(?pickup_zip), "-", str(?dropoff_zip)) as ?route)
}
group by ?route
order by ?route`;

export const TOTAL_TRIPS_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

prefix kg: <tag:stardog:api:context:>
prefix : <urn:databricks:demos:nyctaxi:model:>

select (count(?t) as ?total_trips)
from kg:virtual {
    ?t a :Trip ; :pickup_zip "10001" ; :pickup_time ?pt .
    filter (?pt > "2016-01-01T12:07" && ?pt < "2016-01-16T12:07")
}`;

export const NYC_SPARQL_QUERY_FILE_MAP = new Map([
  ['distance_to_fare_analysis', DISTANCE_TO_FARE_ANALYSIS_SPARQL],
  ['dropoff_hour_distribution', DROPOFF_HOUR_DIST_SPARQL],
  ['pickup_hour_distribution', PICKUP_HOUR_DIST_SPARQL],
  ['route_revenues', ROUTE_REVENUE_SPARQL],
  ['total_trips', TOTAL_TRIPS_SPARQL],
]);
