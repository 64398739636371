import { Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { components } from 'src/ui/templates/copy';
import { copyToClipboard } from 'src/ui/utils/copyToClipboard';

interface CopyToClipboardButtonProps {
  textToCopy: string;
  /* the amount of time in milliseconds to reset button's text to default text after successful/failed copy */
  resetTimeMs?: number;
}

export const CopyToClipboardButton: React.VFC<CopyToClipboardButtonProps> = ({
  textToCopy,
  resetTimeMs = 5000,
}) => {
  const [text, setText] = React.useState<string>(
    components.copyToClipboardButton.initialText
  );
  const [intent, setIntent] = React.useState<Intent>(Intent.NONE);

  React.useEffect(() => {
    // no need to swap the text if text hasn't changed from its initial state
    if (text === components.copyToClipboardButton.initialText) {
      // looks weird to return undefined here but because we return a cleanup function later
      // we need to return something to have a consistent return
      return undefined;
    }

    const timer = setTimeout(() => {
      setText(components.copyToClipboardButton.initialText);
      setIntent(Intent.NONE);
    }, resetTimeMs);

    // if the component unmounts before setTimeout is finished,
    // we need to cleanup the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, [text, resetTimeMs]);

  return (
    <Button
      className="sd-copy-to-clipboard-button"
      outlined
      intent={intent}
      rightIcon={
        text === components.copyToClipboardButton.initialText
          ? IconNames.CLIPBOARD
          : false
      }
      text={text}
      onClick={() => {
        copyToClipboard({ value: textToCopy }).then((copied) => {
          if (!copied) {
            setText(components.copyToClipboardButton.failureText);
            setIntent(Intent.WARNING);
            return;
          }
          setText(components.copyToClipboardButton.successText);
          setIntent(Intent.SUCCESS);
        });
      }}
    />
  );
};
