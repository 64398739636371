import { Icon, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

interface OwnProps {
  children: React.ReactElement;
  check(): boolean;
  isLoading?: boolean;
}

export const DiagnosticCheck: React.VFC<OwnProps> = ({
  children,
  check,
  isLoading = false,
}) => {
  const success = check();
  const icon = success ? IconNames.TICK_CIRCLE : IconNames.ERROR;
  const intent = success ? Intent.SUCCESS : Intent.DANGER;

  return isLoading ? (
    <div className="diagnostic-check">
      <Spinner size={SpinnerSize.SMALL}>Loading...</Spinner>
    </div>
  ) : (
    <div className="diagnostic-check">
      <Icon icon={icon} intent={intent} />
      {children}
    </div>
  );
};
