import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PartnerConnectionDetail } from 'src/ui/graph/types';

export const enum PartnerConnectionOperation {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  REMOVE_PARTNER_CONNECTION = 'REMOVE_PARTNER_CONNECTION',
}

export interface PartnerConnectionState {
  operation: PartnerConnectionOperation;
  partnerConnectionDetail: PartnerConnectionDetail;
}

const initialState = (): PartnerConnectionState => ({
  operation: PartnerConnectionOperation.CREATE,
  partnerConnectionDetail: {
    __typename: undefined,
    databricks_workspace_id: undefined,
    jdbc_url: undefined,
    workspace_url: undefined,
    user_email: undefined,
    connection_id: undefined,
    user_first_name: undefined,
    is_configured_resources: undefined,
    databricks_connection_name: undefined,
    stardog_server_connection: undefined,
    databricks_personal_token_id: undefined,
    http_path: undefined,
    cluster_id: undefined,
  },
});

export const partnerConnectionSlice = createSlice({
  name: 'partnerconnection',
  initialState,
  reducers: {
    setPartnerConnectionDetail: (
      state,
      action: PayloadAction<PartnerConnectionDetail>
    ) => {
      state.partnerConnectionDetail = action.payload;
    },
    createPartnerConnection: (state) => {
      state.operation = PartnerConnectionOperation.CREATE;
    },
    editPartnerConnection: (state) => {
      state.operation = PartnerConnectionOperation.EDIT;
    },
    deletePartnerDataSource: (state) => {
      state.operation = PartnerConnectionOperation.DELETE;
    },
    deletePartnerConnectionDetail: (state) => {
      state.operation = PartnerConnectionOperation.REMOVE_PARTNER_CONNECTION;
    },
    setPartnerConnectionTab: (
      state,
      action: PayloadAction<PartnerConnectionOperation>
    ) => {
      state.operation = action.payload;
    },
  },
});

export const {
  createPartnerConnection,
  editPartnerConnection,
  deletePartnerDataSource,
  deletePartnerConnectionDetail,
  setPartnerConnectionTab,
  setPartnerConnectionDetail,
} = partnerConnectionSlice.actions;

export const { getInitialState } = partnerConnectionSlice;
