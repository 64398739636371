import { Button, Intent } from '@blueprintjs/core';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { withConfirmation } from 'src/ui/components/withConfirmation';
import { noop } from 'src/ui/constants/empty';
import { InstanceFlavors } from 'src/ui/features/connection';
import { DialogType, openedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useGetStripeSessionUrlMutation,
} from 'src/ui/graph/types';
import { userIdSelector } from 'src/ui/selectors/userId';
import { components } from 'src/ui/templates/copy';
import { redirectToUrl } from 'src/ui/utils/window';

interface ServerOverviewButtonProps {
  connection: GraphConnection | null | undefined;
}

export const ServerOverviewButton: React.VFC<ServerOverviewButtonProps> = ({
  connection,
}) => {
  const userId = useAppSelector(userIdSelector());
  const dispatch = useAppDispatch();
  const { mutate: billingMutate } = useGetStripeSessionUrlMutation(
    graphQLClient,
    {
      onSuccess: (data) => {
        redirectToUrl(data?.getStripeSessionUrl?.url || '/');
      },
    }
  );

  const handleBillingRedirect = () => billingMutate({});
  const handleContactOwner = () => {
    withConfirmation({
      isCloseButtonShown: false,
      message: components.contactOwnerDialog.body(
        connection?.cloud?.owner?.email || null
      ),
      noPrompt: null,
      onConfirmationSuccess: noop,
      title: components.contactOwnerDialog.title,
      yesPrompt: components.contactOwnerDialog.buttonText,
    });
  };

  const isEssentials =
    connection?.cloud?.flavor?.name === InstanceFlavors.SMALL_VBX ||
    connection?.cloud?.flavor?.name === InstanceFlavors.SMALL;
  const isFree = connection?.isStardogFree;
  const isUserCloudOwner = userId && userId === connection?.cloud?.owner?.id;

  if (!connection || (!(isFree && isUserCloudOwner) && !isEssentials)) {
    return null;
  }

  let text = '';
  let onClick = noop;
  let disabled = false;
  if (isFree && !isEssentials) {
    disabled = !connection;
    onClick = () => dispatch(openedDialog({ type: DialogType.UPGRADE_PLAN }));
    text = components.ServerOverview.button.upgradePlan;
  } else if (isUserCloudOwner) {
    onClick = handleBillingRedirect;
    text = components.ServerOverview.button.manageBilling;
  } else {
    onClick = handleContactOwner;
    text = components.ServerOverview.button.contactOwner;
  }

  return (
    <Button
      disabled={disabled}
      intent={Intent.PRIMARY}
      minimal
      onClick={onClick}
      outlined
      text={text}
    />
  );
};
