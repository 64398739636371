import { Icon, Intent, Menu, MenuItem, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';

import { useAppDispatch } from 'src/ui/app/hooks';
import { PopoverMenu } from 'src/ui/components/PopoverMenu';
import {
  createPartnerConnection,
  deletePartnerConnectionDetail,
  deletePartnerDataSource,
  editPartnerConnection,
  setPartnerConnectionDetail,
} from 'src/ui/features/partnerconnection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { PartnerConnectionDetail } from 'src/ui/graph/sdk';
import {
  Connection as GraphConnection,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { useSetConnectionIndex } from 'src/ui/hooks/connection';
import * as copy from 'src/ui/templates/copy';
import { colors } from 'src/ui/templates/styles/colors';

interface OwnProps {
  partnerConnectionDetail: PartnerConnectionDetail;
}

export const PartnerConnectionRow: React.VFC<OwnProps> = ({
  partnerConnectionDetail,
}) => {
  const dispatch = useAppDispatch();
  const { data } = useSavedConnectionsQuery(graphQLClient);

  const [connections, setConnections] = React.useState<GraphConnection[]>([]);

  React.useEffect(() => {
    const changed = data?.listConnections || [];
    setConnections(changed as React.SetStateAction<GraphConnection[]>);
  }, [data]);

  const goToDashboard = useSetConnectionIndex();

  const handleCreate = () => {
    dispatch(setPartnerConnectionDetail(partnerConnectionDetail));
    dispatch(createPartnerConnection());
  };
  const handleEdit = () => {
    dispatch(setPartnerConnectionDetail(partnerConnectionDetail));
    dispatch(editPartnerConnection());
  };

  const handleDelete = () => {
    dispatch(setPartnerConnectionDetail(partnerConnectionDetail));
    dispatch(deletePartnerDataSource());
  };

  const handleDeletePartnerConnection = () => {
    dispatch(setPartnerConnectionDetail(partnerConnectionDetail));
    dispatch(deletePartnerConnectionDetail());
  };

  const handleStardogConnectionClick = (e: any) => {
    if (e.target.className !== 'databricks-table-data') {
      e.stopPropagation();
    } else if (partnerConnectionDetail.is_configured_resources) {
      goToDashboard(
        partnerConnectionDetail.stardog_server_connection?.index || 0
      );
    }
  };

  const contextMenu = () => {
    return (
      <Menu>
        <MenuItem
          icon={IconNames.PLUS}
          text={copy.components.databrickspartner.connectionMenu.create}
          onClick={handleCreate}
          disabled={
            partnerConnectionDetail.is_configured_resources ||
            connections.length === 0
          }
        />
        <MenuItem
          icon={IconNames.EDIT}
          text={copy.components.databrickspartner.connectionMenu.edit}
          onClick={handleEdit}
          disabled={!partnerConnectionDetail.is_configured_resources}
        />
        <MenuItem
          icon={IconNames.TRASH}
          text={copy.components.databrickspartner.connectionMenu.delete}
          intent={Intent.WARNING}
          onClick={handleDelete}
          disabled={!partnerConnectionDetail.is_configured_resources}
        />
        <MenuItem
          icon={IconNames.DELETE}
          text={
            copy.components.databrickspartner.connectionMenu
              .deletePartnerConnection
          }
          intent={Intent.DANGER}
          onClick={handleDeletePartnerConnection}
        />
      </Menu>
    );
  };

  const popoverId = `${partnerConnectionDetail.databricks_workspace_id}-popover`;
  const contentMenuItem = contextMenu();

  const menuItem = (
    <PopoverMenu
      content={contentMenuItem}
      className="partner-menu"
      tooltip="Manage Partner Connection"
      testId={popoverId}
      data-testid={popoverId}
      position={Position.LEFT_TOP}
      icon={
        <Icon
          color={
            partnerConnectionDetail.is_configured_resources
              ? colors.connected
              : colors.darkIcon
          }
          icon={IconNames.MORE}
        />
      }
    />
  );
  return (
    <tr onClick={handleStardogConnectionClick}>
      <td className="databricks-table-data">
        {partnerConnectionDetail.databricks_workspace_id}
      </td>
      <td className="databricks-table-data">
        {partnerConnectionDetail.http_path}
      </td>
      <td>
        <strong className="databricks-table-data">
          {partnerConnectionDetail.stardog_server_connection?.name}
        </strong>
      </td>
      <td className="databricks-table-data">
        {partnerConnectionDetail.databricks_connection_name}
      </td>
      <td>{menuItem}</td>
    </tr>
  );
};
