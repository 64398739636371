import { Checkbox, FormGroup, InputGroup, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { Selector } from 'src/ui/components/Selector';
import {
  CREATE_PROFILE_BEST_DESCRIBES_COMPANY_SELECTOR,
  CREATE_PROFILE_DIALOG_INDUSTRY_SELECTOR,
  CREATE_PROFILE_PRIVACY_CHECKBOX,
  CREATE_PROFILE_TERMS_OF_USE_CHECKBOX,
} from 'src/ui/constants/testIds';
import { AnyFunc, SelectOption } from 'src/ui/constants/types';
import { ProfileInput } from 'src/ui/graph/types';
import { components } from 'src/ui/templates/copy';

export type AboutCompanyProps = {
  handleValueChange: <K extends keyof ProfileInput>(
    name: K
  ) => (value: ProfileInput[K]) => void;
  handleTextFieldChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  profile: ProfileInput;
  terms: boolean;
  setTerms: AnyFunc;

  policy: boolean;
  setPolicy: AnyFunc;
};

export const AboutCompany: React.VFC<AboutCompanyProps> = ({
  profile,
  handleValueChange,
  handleTextFieldChange,
  terms,
  setTerms,
  policy,
  setPolicy,
}) => {
  const selectorsCopy = components.createProfileDialog.selectors;

  const industryOptions: SelectOption[] = Object.values(
    selectorsCopy.industry.options
  ).map((option) => {
    return { label: option, value: option };
  });

  const bestDescribesCompanyOptions: SelectOption[] = Object.values(
    selectorsCopy.bestDescribesCompany.options
  ).map((option) => {
    return { label: option, value: option };
  });

  const handleIndustryChange = (selectOption: SelectOption) => {
    const { value } = selectOption;
    handleValueChange('industry')(value as string);
  };

  const handleBestDescribesCompanyChange = (selectOption: SelectOption) => {
    const { value } = selectOption;
    handleValueChange('best_describes_company')(value as string);
  };

  return (
    <>
      <FormGroup
        label={components.createProfileDialog.textInputs.companyName.label}
        labelFor="create-profile-company_name"
      >
        <InputGroup
          className="sd-dialog-profile__text-input"
          id="create-profile-company_name"
          name="company"
          value={profile.company}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <FormGroup
        label={components.createProfileDialog.textInputs.useCase.label}
        labelFor="create-profile-use_case"
      >
        <InputGroup
          className="sd-dialog-profile__text-input"
          id="create-profile-use_case"
          name="use_case"
          value={profile.use_case}
          onChange={handleTextFieldChange}
        />
      </FormGroup>

      <div className="sd-dialog-profile__selector-container">
        <p>{components.createProfileDialog.selectors.industry.label}</p>
        <Selector
          testId={CREATE_PROFILE_DIALOG_INDUSTRY_SELECTOR}
          selectedItem={{ label: profile.company, value: profile.company }}
          onSelect={handleIndustryChange}
          items={industryOptions}
          buttonText={profile.industry || ''}
          popoverPosition={Position.BOTTOM_LEFT}
          filterable={false}
          className="sd-dialog-profile__selector"
          popoverTargetClassName="sd-dialog-profile__selector__popover-target"
          buttonClassName="sd-dialog-profile__selector__button"
          buttonProps={{
            outlined: true,
          }}
          rightIcon={IconNames.CHEVRON_DOWN}
        />
      </div>

      <div className="sd-dialog-profile__selector-container">
        <p>
          {components.createProfileDialog.selectors.bestDescribesCompany.label}
        </p>
        <Selector
          testId={CREATE_PROFILE_BEST_DESCRIBES_COMPANY_SELECTOR}
          selectedItem={{
            label: profile.best_describes_company || '',
            value: profile.best_describes_company || '',
          }}
          onSelect={handleBestDescribesCompanyChange}
          items={bestDescribesCompanyOptions}
          buttonText={profile.best_describes_company || ''}
          popoverPosition={Position.BOTTOM_LEFT}
          filterable={false}
          className="sd-dialog-profile__selector"
          popoverTargetClassName="sd-dialog-profile__selector__popover-target"
          buttonClassName="sd-dialog-profile__selector__button"
          buttonProps={{
            outlined: true,
          }}
          rightIcon={IconNames.CHEVRON_DOWN}
        />
      </div>
      <FormGroup>
        <Checkbox
          data-testid={CREATE_PROFILE_TERMS_OF_USE_CHECKBOX}
          checked={terms}
          onChange={() => setTerms(!terms)}
        >
          {components.createProfileDialog.checkboxes.agree}
          &nbsp;
          <a
            href="https://www.stardog.com/cloud-tos"
            target="_blank"
            rel="noreferrer"
          >
            {components.createProfileDialog.checkboxes.usageTerms}
          </a>
        </Checkbox>
        <Checkbox
          data-testid={CREATE_PROFILE_PRIVACY_CHECKBOX}
          checked={policy}
          onChange={() => setPolicy(!policy)}
        >
          {components.createProfileDialog.checkboxes.agree}
          &nbsp;
          <a
            href="https://www.stardog.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {components.createProfileDialog.checkboxes.privacyPolicy}
          </a>
        </Checkbox>
      </FormGroup>
    </>
  );
};
