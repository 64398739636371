import { Classes, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { CreateEditDataSourceForm } from 'src/ui/containers/partner/databricks/CreateEditDataSourceForm';
import { DeleteDataSourceForm } from 'src/ui/containers/partner/databricks/DeleteDataSourceForm';
import { DeletePartnerConnectionDialog } from 'src/ui/containers/partner/databricks/DeletePartnerConnectionDialog';
import {
  PartnerConnectionOperation,
  setPartnerConnectionTab,
} from 'src/ui/features/partnerconnection';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { partnerConnectionSelectedOperation } from 'src/ui/selectors/connection';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import * as copy from 'src/ui/templates/copy';

const isOpenSelector = isDialogOpenSelector(DialogType.PARTNER_CONNECTION);
export const PartnerConnectionDialog: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isOpenSelector);
  const { data } = useSavedConnectionsQuery(graphQLClient);

  const [connections, setConnections] = React.useState<GraphConnection[]>([]);

  const selectedOperation = useAppSelector(partnerConnectionSelectedOperation);

  React.useEffect(() => {
    const changed = data?.listConnections || [];
    setConnections(changed as React.SetStateAction<GraphConnection[]>);
  }, [data]);

  const handleTabChange = (newTabId: PartnerConnectionOperation) =>
    dispatch(setPartnerConnectionTab(newTabId));
  const handleCloseDialog = () => {
    dispatch(closedDialog());
  };

  const createDatasourceTab = (
    <Tab
      id={PartnerConnectionOperation.CREATE}
      title={copy.components.databrickspartner.connectionMenu.create}
      disabled={false}
      panel={<CreateEditDataSourceForm connections={connections} />}
    />
  );
  const editDatasourceTab = (
    <Tab
      id={PartnerConnectionOperation.EDIT}
      title={copy.components.databrickspartner.connectionMenu.edit}
      panel={<CreateEditDataSourceForm connections={connections} />}
    />
  );
  const deleteDatasourceTab = (
    <Tab
      id={PartnerConnectionOperation.DELETE}
      title={copy.components.databrickspartner.connectionMenu.delete}
      panel={<DeleteDataSourceForm connections={connections} />}
    />
  );

  const mainDialog = (
    <Dialog
      className="sd-partner-dialog-profile"
      icon={IconNames.OFFLINE}
      onClose={handleCloseDialog}
      isOpen={isOpen}
      title={copy.components.databrickspartner.dialogTitle}
      canOutsideClickClose={false}
    >
      <Tabs
        className={Classes.DIALOG_BODY}
        renderActiveTabPanelOnly
        selectedTabId={selectedOperation}
        animate={false}
        onChange={handleTabChange}
      >
        {createDatasourceTab}
        {editDatasourceTab}
        {deleteDatasourceTab}
      </Tabs>
    </Dialog>
  );

  if (
    selectedOperation === PartnerConnectionOperation.REMOVE_PARTNER_CONNECTION
  )
    return <DeletePartnerConnectionDialog connections={connections} />;
  return mainDialog;
};
