import * as React from 'react';

import { ConnectionList } from 'src/ui/containers/connection-list/ConnectionList';
import { components } from 'src/ui/templates/copy';

interface ManageConnectionsProps {
  isOnPremise?: boolean;
}
export const ManageConnections: React.VFC<ManageConnectionsProps> = ({
  isOnPremise = false,
}) => {
  return (
    <div className="manage-connections-container">
      <ConnectionList
        header={components.manageConnections.header}
        isOnPremise={isOnPremise}
      />
    </div>
  );
};
