import { Card, CardProps, Elevation, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { PRODUCT_DESC_TEST_ID } from 'src/ui/constants/testIds';

interface OwnProps extends CardProps {
  image: any;
  altText: string;
  label: string;
  description: string;
}

export const ProductCard: React.VFC<OwnProps> = ({
  onClick,
  image,
  altText,
  label,
  description,
}) => {
  const labelWords = label.split(' ');

  // add the product name to the class list
  const productName = labelWords.pop();
  const className = `product ${productName?.toLowerCase()}`;
  const descElemId = `${productName?.toLowerCase()}-${PRODUCT_DESC_TEST_ID}`;

  return (
    <Card
      interactive
      elevation={Elevation.ONE}
      onClick={onClick}
      role="button"
      className="product-card"
    >
      <span className="product-card-icon">
        <Icon icon={IconNames.SHARE} />
      </span>
      <span className={className}>
        <img src={image} alt={altText} />
      </span>
      <p data-testid={descElemId} className="product-description">
        {description}
      </p>
    </Card>
  );
};
