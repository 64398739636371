import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Body, Button, Modal } from 'vet-bones/design-system/components';

import { noop } from 'src/ui/constants/empty';
import { DIALOG_CLOSE_ICON_TEST_ID } from 'src/ui/constants/testIds';
import * as copy from 'src/ui/templates/copy';

interface WithConfirmationDsOptions {
  isCloseButtonShown?: boolean;
  message: React.ReactNode;
  noPrompt?: string | null; // explicit null to not show
  onConfirmationFailure?: () => any;
  onConfirmationSuccess: () => any;
  title?: string;
  yesPrompt?: string;
  yesPromptTestId?: string;
  noPromptTestId?: string;
}

interface WithConfirmationDsProps extends WithConfirmationDsOptions {
  noPrompt: string | null;
  onConfirmationFailure: () => any;
  title: string;
  yesPrompt: string;
}

export const WithConfirmationDs: React.VFC<WithConfirmationDsProps> = ({
  isCloseButtonShown,
  message,
  noPrompt,
  onConfirmationFailure,
  onConfirmationSuccess,
  title,
  yesPrompt,
  yesPromptTestId,
  noPromptTestId,
}) => {
  return (
    <Modal
      canOutsideClickClose={false}
      closeIconTestId={DIALOG_CLOSE_ICON_TEST_ID}
      footerActions={
        <>
          {yesPrompt ? (
            <Button
              onClick={onConfirmationSuccess}
              size="sm"
              testId={yesPromptTestId}
              variant="primary-accent"
            >
              {yesPrompt}
            </Button>
          ) : null}
          {noPrompt ? (
            <Button
              onClick={onConfirmationFailure}
              size="sm"
              testId={noPromptTestId}
              type="submit"
              variant="secondary2-gray"
            >
              {noPrompt}
            </Button>
          ) : null}
        </>
      }
      headerSize="lg"
      isCloseButtonShown={isCloseButtonShown}
      isOpen
      onClose={onConfirmationFailure}
      title={title}
    >
      <Body size="md">{message}</Body>
    </Modal>
  );
};

const unmountAndRemove = (node: HTMLElement) => {
  ReactDOM.unmountComponentAtNode(node);
  node.remove();
};

// Render an unattached modal for receiving confirmation imperatively.
export const withConfirmationDs = ({
  isCloseButtonShown = true,
  message,
  noPrompt = copy.components.defaults.confirmation.no,
  noPromptTestId,
  onConfirmationFailure = noop,
  onConfirmationSuccess,
  title = copy.components.defaults.confirmation.title,
  yesPrompt = copy.components.defaults.confirmation.yes,
  yesPromptTestId,
}: WithConfirmationDsOptions) => {
  const node = document.createElement('div');
  ReactDOM.render(
    <WithConfirmationDs
      isCloseButtonShown={isCloseButtonShown}
      message={message}
      noPrompt={noPrompt}
      noPromptTestId={noPromptTestId}
      onConfirmationFailure={() => {
        unmountAndRemove(node);
        onConfirmationFailure();
      }}
      onConfirmationSuccess={() => {
        unmountAndRemove(node);
        onConfirmationSuccess();
      }}
      title={title}
      yesPrompt={yesPrompt}
      yesPromptTestId={yesPromptTestId}
    />,
    node
  );
};
