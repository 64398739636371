import { Icon, IconName } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';

export type ExternalLinkProps = {
  href: string;
  testId?: string;
  decorated?: boolean;
  icon?: IconName | null;
};

export const ExternalLink: React.FC<ExternalLinkProps> = ({
  children,
  href,
  testId,
  decorated = true,
  icon = IconNames.SHARE,
}) => {
  return (
    <a
      className={classNames('inline-link', {
        'sd-link-undecorated': !decorated,
      })}
      data-testid={testId}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
      {icon ? <Icon icon={icon as IconName} size={13} /> : ''}
    </a>
  );
};
