import { H4, Icon, IconName } from '@blueprintjs/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { ExternalLink } from 'src/ui/components/ExternalLink';

export type ContentListItem = {
  name: string;
  hyperlink: string;
  description?: string;
  internal?: boolean;
};

interface ContentListProps {
  header: string;
  headerIcon?: IconName;
  list: ContentListItem[];
}

export const ContentList: React.VFC<ContentListProps> = ({
  header,
  headerIcon,
  list,
}) => {
  return (
    <div className="content-list-container">
      <H4 className="content-list-header">
        {header} <Icon icon={headerIcon} />
      </H4>
      <hr className="content-list-divider" />
      <ul className="content-list">
        {list.map((item) => (
          <li key={item.name} className="content-list-item">
            {item.internal ? (
              <Link to={item.hyperlink}>{item.name}</Link>
            ) : (
              <ExternalLink href={item.hyperlink}>{item.name}</ExternalLink>
            )}
            <p>{item.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
