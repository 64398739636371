export const TPCH_MAPPINGS = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix : <urn:databricks:demos:tpch:model:>

MAPPING <urn:databricks:demos:mappings:tpch:CustomerMapping>
FROM SQL {
    SELECT * FROM tpch.customer
}
TO {
	?iri a :Customer ;
	    :name ?c_name ;
	    :phone ?c_phone ;
        :address ?c_address ;
        :nation ?nation ;
        :balance ?bal ;
        :mktSegment ?c_mktsegment ;
        rdfs:comment ?c_comment ; 
		rdfs:label ?c_name .
}
WHERE {
	BIND(TEMPLATE("urn:databricks:demos:tpch:data:customer:{c_custkey}") AS ?iri)
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:nation:{c_nationkey}") AS ?nation)
    BIND(xsd:float(?c_acctbal) as ?bal)
} ;

MAPPING :NationMapping
FROM SQL {
    SELECT * FROM tpch.nation
}
TO {
	?iri a :Nation ;
        rdfs:comment ?n_comment ;
        rdfs:label ?n_name .
}
WHERE {
	BIND(TEMPLATE("urn:databricks:demos:tpch:data:nation:{n_nationkey}") AS ?iri)
} ;

MAPPING <urn:databricks:demos:mappings:tpch:SupplierMapping>
FROM SQL {
    SELECT * FROM tpch.supplier
}
TO {
	?iri a :Organization ;
        :address ?s_address ;
        :balance ?bal ;
        :phone ?s_phone ;
        :nation ?nation ;
        rdfs:comment ?s_comment ;
        rdfs:label ?s_name .
}
WHERE {
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:suppliers:{s_suppkey}") AS ?iri)
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:nation:{s_nationkey}") AS ?nation)
    BIND(xsd:float(?s_acctbal) as ?bal)
} ;

MAPPING <urn:databricks:demos:mappings:tpch:PartMapping>
FROM SQL {
    SELECT * FROM tpch.part
}
TO {
	?iri a :Part ;
        :size ?sz ;
        :partType ?p_type ;
        :manufacturer ?p_mfgr ;
        :brand ?p_brand ;
        :msrp ?price ;
        :container ?p_container ;
        rdfs:comment ?p_comment ;
        rdfs:label ?p_name .
}
WHERE {
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:parts:{p_partkey}") AS ?iri)
    BIND(xsd:integer(?p_size) as ?sz)
    BIND(xsd:float(?p_retailprice) as ?price)
} ;

MAPPING <urn:databricks:demos:mappings:tpch:OrderMapping>
FROM SQL {
    SELECT * FROM tpch.orders
}
TO {
	?iri a :Order ;
        :status ?o_orderstatus ;
        :customer ?customer ;
        :price ?price ;
        :date ?ts ;
        :priority ?o_orderpriority ;
        :clerk ?o_clerk ;
        :shipPriority ?sp ;
        rdfs:comment ?o_comment ;
        rdfs:label ?o_orderkey .
}
WHERE {
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:orders:{o_orderkey}") AS ?iri)
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:customer:{o_custkey}") AS ?customer)
    BIND(xsd:float(?o_totalprice) as ?price)
    BIND(xsd:date(?o_orderdate) as ?ts)
    BIND(xsd:integer(?o_shippriority) as ?sp)
} ;

MAPPING <urn:databricks:demos:mappings:tpch:PartAvailabilityMapping>
FROM SQL {
    SELECT * FROM tpch.partsupp
}
TO {
	?iri a :PartInventory ;
        :part ?part ;
        :supplier ?supplier ;
        :quantity ?qty ;
        :msrp ?msrp ;
        rdfs:label ?ps_comment .
}
WHERE {
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:inventory:{ps_partkey}:{ps_suppkey}") AS ?iri)
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:parts:{ps_partkey}") AS ?part)
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:suppliers:{ps_suppkey}") AS ?supplier)
    BIND(xsd:integer(?ps_availqty) as ?qty)
    BIND(xsd:float(?ps_supplycost) as ?msrp)
}

;

MAPPING <urn:databricks:demos:mappings:tpch:LineItemMapping>
FROM SQL {
    SELECT * FROM tpch.lineitem
}
TO {
	?iri a :LineItem ;
        :part ?part ;
        :supplier ?supplier ;
        :order ?order ;
        :itemNumber ?l_linenumber ;
        :quantity ?qty ;
        :price ?price ;
        :discount ?discount ;
        :tax ?tax ;
        :returnFlag ?l_returnflag ;
        :status ?l_linestatus ;
        :shipInstructions ?l_shipinstruct ;
        :shipMode ?l_shipmode ;
        :commitDate ?commitDate ;
        :receiptDate ?receiptDate ;
        :shipDate ?shipDate ;
        rdfs:label ?l_comment .
}
WHERE {
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:orders:items:{l_orderkey}:{l_partkey}:{l_suppkey}:{l_linenumber}") AS ?iri)
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:orders:{l_orderkey}") AS ?order)
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:parts:{l_partkey}") AS ?part)
    BIND(TEMPLATE("urn:databricks:demos:tpch:data:suppliers:{l_suppkey}") AS ?supplier)
    BIND(xsd:integer(?l_quantity) as ?qty)
    BIND(xsd:float(?l_extendedprice) as ?price)
    BIND(xsd:float(?l_discount) as ?discount)
    BIND(xsd:float(?l_tax) as ?tax)
    BIND(xsd:date(?l_commitdate) as ?commitDate)
    BIND(xsd:date(?l_receiptdate) as ?receiptDate)
    BIND(xsd:date(?l_shipdate) as ?shipDate)
}`;

export const CUSTOMER_VALUE_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix : <urn:databricks:demos:tpch:model:>

select ?customer (sum(?p) as ?total_revenue)
from kg:virtual {
    ?o a :Order ; :customer ?customer ; :price ?p
}
GROUP BY ?customer
having (sum(?p) > 0)
ORDER BY (xsd:integer(localname(?customer)))
limit 400`;

export const NATIONAL_REVENUE_MAP_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix : <urn:databricks:demos:tpch:model:>

select distinct ?nation (sum(?price * (1 - ?d) * ((STRLEN(?nation)/100))) AS ?revenue)
from kg:virtual
where {
    #pragma optimizer.filters.in off
    
    {
        select ?o ?n ?nation {
            ?o :customer ?c .
            ?c :nation ?n .
            ?n rdfs:label ?nation .
        }
    }
    
    {
        select ?o ?n ?price ?d {
            ?li :order ?o ; 
                :supplier [:nation ?n] ;
                :price ?price; 
                :discount ?d .
        }
    }
}
GROUP BY ?nation
ORDER BY desc(?revenue)`;

export const NATIONAL_REVENUE_TRENDS_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix : <urn:databricks:demos:tpch:model:>

select distinct ?year ?nation (sum(?price * (1 - ?d) * (((STRLEN(?nation)/100)) + ((?year-1993)/100))) AS ?revenue)
from kg:virtual
where {
    #pragma optimizer.filters.in off
    ?o a :Order ; :date ?orderDate .
    ?o :customer ?c .
    ?c :nation ?n .
    ?n rdfs:label ?nation .

    ?li :order ?o ; 
        :supplier ?s ;
        :price ?price; 
        :discount ?d .
    
    ?s :nation ?n

    bind(year(?orderDate) as ?year)
    filter(?nation in ('ARGENTINA', 'UNITED KINGDOM', 'FRANCE','BRAZIL', 'CHINA', 'UNITED STATES', 'JAPAN', 'JORDAN'))
    filter(?orderDate >= '1994-01-01'^^xsd:date)
}
GROUP BY ?year ?nation
ORDER BY ?nation`;

export const PRICE_BY_PRIORITY_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix : <urn:databricks:demos:tpch:model:>

select ?d ?p (sum(?price) as ?total_price)
from kg:virtual {
    ?s a :Order ; :date ?d ; :priority ?p; :price ?price
    filter(?d > "1994-01-01"^^xsd:date && ?d < "1994-01-31"^^xsd:date)
}
GROUP BY ?d ?p
ORDER BY ?d ?p`;

export const UNIQUE_CUSTOMERS_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix : <urn:databricks:demos:tpch:model:>

select (count(distinct ?s) as ?unique_customers)
from kg:virtual {
    ?s a :Customer
}`;

export const UNIQUE_SUPPLIERS_SPARQL = `prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix rule: <tag:stardog:api:rule:>
prefix kg: <tag:stardog:api:context:>
prefix : <urn:databricks:demos:tpch:model:>

select (count(distinct ?s) as ?unique_suppliers)
from kg:virtual {
    ?s a :Organization
}`;

export const TPCH_MODULE_TRIG = `
prefix sd: <urn:stardog:>
prefix m: <urn:stardog:modules:>
prefix catalog: <tag:stardog:api:catalog:>

prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix foaf: <http://xmlns.com/foaf/0.1/>
prefix dcat: <http://www.w3.org/ns/dcat#>
prefix dct: <http://purl.org/dc/terms/>
prefix void: <http://rdfs.org/ns/void#>
prefix so: <https://schema.org/>
prefix prov: <http://www.w3.org/ns/prov#>

prefix : <urn:databricks:demos:tpch:>

prefix model: <urn:databricks:demos:tpch:model:>

<urn:mp:stardog:demos:databricks:tpch:1.0> {
    <urn:mp:stardog:demos:databricks:tpch:1.0> a m:Module ;
        # Internal metadata
        m:moduleFormat "2";

        # Describing this module:

        # The required metadata
        m:version "1.0" ;
        m:id "demos:tpch:1.0" ;
        rdfs:label "Databricks TPCH Tutorial" ;

        # MP meta
        dct:contributor sd:mhgrove ;
        dct:publisher sd:Stardog ;
        dct:publisher <urn:databricks:Databricks> ;
        m:status m:status:Draft ;
        dct:type m:types:Tutorial ;
        dct:subject m:General ;
        m:tag sd:Databricks;

        # Recommended bits
        rdfs:comment "Databricks tpch Tutorial" ;
        m:primaryConcept model:Customer ;
        m:initialSearch "" ;
        m:icon "learning" ;

        # User-defined
        rdfs:seeAlso [ rdfs:label "Get Started Guide" ; m:link <https://docs.microsoft.com/en-us/azure/databricks/sql/get-started/sample-dashboards> ] ;

        # Module artifacts
        m:schemaName "tpch" ;
        m:model <urn:databricks:demos:tpch:model> ;

        m:alias <urn:databricks:demos:tpch> ;

        m:data :vg:tpch ;
    .
}

:vg:tpch {
    :vg:tpch a catalog:VirtualGraph ; 
        rdfs:label "tpch Dataset" ;
        dct:description "tpch Dataset" ;
        foaf:primaryConcept model:Customer ;

        catalog:vgName "tpch" ;
        catalog:hasSource :source:tpch ; 
        catalog:graph <virtual://tpch> .

    :source:tpch a catalog:DataSource, catalog:db:Databricks ;
        rdfs:label "tpch" .
}

<urn:databricks:demos:tpch:model> {
    <urn:databricks:demos:tpch:model> a m:DataModel ;
        rdfs:label "tpch Data Model" ;
        foaf:primaryConcept model:Cusomer ;
        dct:description "tpch Data Model" .

    ## TODO: prov

    model:Customer a owl:Class.
    model:Nation a owl:Class.
    model:Region a owl:Class .
    model:Organization a owl:Class .
    model:Part a owl:Class .
    model:Order a owl:Class .
    model:PartInventory a owl:Class.
    model:LineItem a owl:Class .

    # Customer properites
    model:name a owl:DatatypeProperty ;
        rdfs:domain model:Customer ;
        rdfs:range xsd:string ;
        rdfs:label "name" .
	model:phone a owl:DatatypeProperty ;
        rdfs:domain model:Customer ;
        rdfs:domain model:Organization ;
        rdfs:range xsd:string ;
        rdfs:label "phone" .
    model:address a owl:DatatypeProperty ;
        rdfs:domain model:Customer ;
        rdfs:domain model:Organization ;
        rdfs:range xsd:string ;
        rdfs:label "address" .
    model:nation a owl:ObjectProperty ;
        rdfs:domain model:Customer ;
        rdfs:domain model:Organization ;
        rdfs:range model:Nation ;
        rdfs:label "nation" .
    model:balance a owl:DatatypeProperty ;
        rdfs:domain model:Customer ;
        rdfs:domain model:Organization ;
        rdfs:range xsd:string ;
        rdfs:label "account balance" .
    model:mktSegment a owl:DatatypeProperty ;
        rdfs:domain model:Customer ;
        rdfs:range xsd:string ;
        rdfs:label "market segment" .

    # Properties for Nation
    model:region a owl:ObjectProperty ;
        rdfs:domain model:Nation ;
        rdfs:range model:Region ;
        rdfs:label "has region" .

    # Properties for Part
    model:size a owl:DatatypeProperty ;
        rdfs:domain model:Part ;
        rdfs:range xsd:integer ; 
        rdfs:label "size" .
    model:partType a owl:DatatypeProperty ;
        rdfs:domain model:Part ;
        rdfs:range xsd:string ; 
        rdfs:label "part type" .
    model:manufacturer a owl:DatatypeProperty ;
        rdfs:domain model:Part ;
        rdfs:range xsd:string ; 
        rdfs:label "manufacturer" .
    model:brand a owl:DatatypeProperty ;
        rdfs:domain model:Part ;
        rdfs:range xsd:string ; 
        rdfs:label "brand" .
    model:msrp a owl:DatatypeProperty ;
        rdfs:domain model:Part ;
        rdfs:domain model:PartInventory ;
        rdfs:range xsd:float ; 
        rdfs:label "msrp" .
    model:container a owl:DatatypeProperty ;
        rdfs:domain model:Part ;
        rdfs:range xsd:string ; 
        rdfs:label "container" .

    # Order
    model:status a owl:DatatypeProperty ;
        rdfs:domain model:Order ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:string ; 
        rdfs:label "status" .
    model:customer a owl:ObjectProperty ;
        rdfs:domain model:Order ;
        rdfs:range model:Customer ; 
        rdfs:label "purchaser" .
    model:price a owl:DatatypeProperty ;
        rdfs:domain model:Order ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:float ; 
        rdfs:label "price" .
    model:date a owl:DatatypeProperty ;
        rdfs:domain model:Order ;
        rdfs:range xsd:date ; 
        rdfs:label "date" .
    model:priority a owl:DatatypeProperty ;
        rdfs:domain model:Order ;
        rdfs:range xsd:string ; 
        rdfs:label "priority" .
    model:clerk a owl:DatatypeProperty ;
        rdfs:domain model:Order ;
        rdfs:range xsd:string ; 
        rdfs:label "clerk" .
    model:shipPriority a owl:DatatypeProperty ;
        rdfs:domain model:Order ;
        rdfs:range xsd:string ; 
        rdfs:label "shipPriority" .

    # PartInventory
    model:part a owl:ObjectProperty ;
        rdfs:domain model:PartInventory ;
        rdfs:domain model:LineItem ;
        rdfs:range model:Part ; 
        rdfs:label "part" .
    model:supplier a owl:ObjectProperty ;
        rdfs:domain model:PartInventory ;
        rdfs:domain model:LineItem ;
        rdfs:range model:Organization ; 
        rdfs:label "supplier" .
    model:quantity a owl:DatatypeProperty ;
        rdfs:domain model:PartInventory ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:integer ; 
        rdfs:label "quantity" .

    # LineItem
    model:order a owl:ObjectProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range model:Order ; 
        rdfs:label "order" .
    model:itemNumber a owl:DatatypeProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:string ; 
        rdfs:label "itemNumber" .
    model:discount a owl:DatatypeProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:string ; 
        rdfs:label "discount" .
    model:tax a owl:DatatypeProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:string ; 
        rdfs:label "tax" .
    model:returnFlag a owl:DatatypeProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:string ; 
        rdfs:label "returnFlag" .
    model:shipInstructions a owl:DatatypeProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:string ; 
        rdfs:label "shipInstructions" .
    model:shipMode a owl:DatatypeProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:string ; 
        rdfs:label "shipMode" .
    model:commitDate a owl:DatatypeProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:date ; 
        rdfs:label "commitDate" .
    model:receiptDate a owl:DatatypeProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:date ; 
        rdfs:label "receiptDate" .
    model:shipDate a owl:DatatypeProperty ;
        rdfs:domain model:LineItem ;
        rdfs:range xsd:date ; 
        rdfs:label "shipDate" .
}`;

export const TPCH_SPARQL_QUERY_FILE_MAP = new Map([
  ['customer_value', CUSTOMER_VALUE_SPARQL],
  ['national_revenue_map', NATIONAL_REVENUE_MAP_SPARQL],
  ['national_revenue_trends', NATIONAL_REVENUE_TRENDS_SPARQL],
  ['price_by_priority', PRICE_BY_PRIORITY_SPARQL],
  ['unique_customers', UNIQUE_CUSTOMERS_SPARQL],
  ['unique_suppliers', UNIQUE_SUPPLIERS_SPARQL],
]);
