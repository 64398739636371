import { Button, H2, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import { MANAGE_VOICEBOX_APP_CREATE } from 'src/ui/constants/testIds';
import { VoiceboxAppCard } from 'src/ui/containers/api-tokens/VoiceboxAppCard';
import { showCreateVoiceboxAppDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { VoiceboxApp, useListVoiceboxAppsQuery } from 'src/ui/graph/types';
import * as copy from 'src/ui/templates/copy';

export const VoiceboxApps: React.VFC = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading } = useListVoiceboxAppsQuery(graphQLClient);

  const apps =
    data?.listVoiceboxApps?.filter((x): x is VoiceboxApp => Boolean(x)) || [];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="sd-table">
      <div className="sd-table__header">
        <H2>{copy.components.manageApiTokens.voiceboxApps.header}</H2>
        <div className="sd-table__actions">
          <div className="sd-table__actions__button-container">
            <Button
              data-testid={MANAGE_VOICEBOX_APP_CREATE}
              icon={IconNames.PLUS}
              intent={Intent.PRIMARY}
              minimal
              onClick={() => dispatch(showCreateVoiceboxAppDialog())}
              outlined
            >
              {copy.components.manageApiTokens.voiceboxApps.add}
            </Button>
          </div>
        </div>
      </div>
      <hr className="divider" />
      {apps.length ? (
        apps.map((app, idx) => (
          <VoiceboxAppCard key={app.id} app={app} idx={idx} />
        ))
      ) : (
        <p>{copy.components.manageApiTokens.voiceboxApps.empty}</p>
      )}
    </div>
  );
};
