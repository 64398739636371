import { HTMLTable, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { components } from 'src/ui/templates/copy';

enum Category {
  PRICING = 'pricing',
  HOSTING = 'hosting',
  STORED_EDGES = 'storedEdges',
  DATABASES = 'databases',
  VOICEBOX_DB_COUNT = 'voiceboxDbCount',
  SECURITY = 'security',
  AVAILABILITY = 'availability',
  BACKUPS = 'backups',
  PRIVATE_LINK = 'privateLink',
  BI_SQL_ENDPOINT = 'biSqlEndpoint',
  PROF_SERVICES = 'profServices',
  SELF_MANAGE_OPTION = 'selfManageOption',
  SUPPORT = 'support',
}

export const PlanComparison: React.VFC = () => {
  const { free, essentials, enterprise } = components.getStarted.editions;
  const { comparisonTitles } = components.getStarted;

  const showCheckIcon = (show: boolean) =>
    show ? <Icon icon={IconNames.TICK_CIRCLE} /> : '';

  const editionsToShow = [free, essentials, enterprise];

  const RenderRow: React.VFC<{
    title: string;
    category: Category;
  }> = ({ title, category }) => {
    const isIcon = [
      Category.PRIVATE_LINK,
      Category.BI_SQL_ENDPOINT,
      Category.PROF_SERVICES,
      Category.SELF_MANAGE_OPTION,
    ].includes(category);

    return (
      <tr key={category}>
        <td className="row-title">{title}</td>
        {editionsToShow.map((edition) => {
          return (
            <td key={edition.title}>
              {isIcon
                ? showCheckIcon(Boolean(edition.specs[category]))
                : edition.specs[category]}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <HTMLTable className="comparison-table" bordered>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th id="first-column-header">{free.title}</th>
          <th>{essentials.title}</th>
          <th>{enterprise.title}</th>
        </tr>
      </thead>
      <tbody>
        {
          // for each category render the row
          Object.values(Category).map((category) => {
            return (
              <RenderRow
                key={category}
                title={comparisonTitles[category]}
                category={category}
              />
            );
          })
        }
      </tbody>
    </HTMLTable>
  );
};
