import { H2 } from '@blueprintjs/core';
import classNames from 'classnames';
import * as React from 'react';

import * as copy from 'src/ui/templates/copy';

interface WelcomeProps {
  className?: string;
  titleText?: string;
  subText?: JSX.Element | string;
}

export const Welcome: React.VFC<WelcomeProps> = ({
  className = '',
  titleText = copy.components.home.title,
  subText = copy.components.home.cta,
}: WelcomeProps) => {
  return (
    <div className={classNames('welcome-container', className)}>
      <H2>{titleText}</H2>
      {subText}
    </div>
  );
};
