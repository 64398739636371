import { Classes, DialogStep, MultistepDialog } from '@blueprintjs/core';
import * as React from 'react';

import { DataSourceForm } from 'src/ui/containers/dialogs/DataSourceForm';
import { Connection as GraphConnection } from 'src/ui/graph/types';
import { DataSource } from 'src/ui/hooks/modules';
import * as copy from 'src/ui/templates/copy';

interface ConfigureDataSourcesProps {
  dataSources: DataSource[];
  connection: GraphConnection;
  isOpen: boolean;
  onClose: () => void;
  onFinished: (dataSources: DataSource[]) => void;
}

export const ConfigureDataSources: React.VFC<ConfigureDataSourcesProps> = ({
  dataSources,
  connection,
  isOpen,
  onClose,
  onFinished,
}) => {
  const dialogRef = React.useRef<MultistepDialog>(null);
  const [completedSources, setCompletedSources] = React.useState<DataSource[]>(
    []
  );
  const completedSourceMap = React.useMemo(
    () => new Map(completedSources.map((s) => [s.id, s])),
    [completedSources]
  );

  const nextStep = () => {
    const dialog = dialogRef.current;
    if (!dialog) {
      return;
    }
    const currentIndex = dialog.state.selectedIndex;
    const newIndex = currentIndex + 1;

    // Blueprint doesn't give me control, so I'm taking it!
    dialog.setState({
      lastViewedIndex: Math.max(dialog.state.lastViewedIndex, newIndex),
      selectedIndex: newIndex,
    });
  };

  const handleDataSourceSubmit = (dataSource: DataSource) => {
    const newCompletedSources = [...completedSources, dataSource];
    setCompletedSources(newCompletedSources);

    if (newCompletedSources.length === dataSources.length) {
      onFinished(newCompletedSources);
      onClose();
      return;
    }

    nextStep();
  };

  return (
    <MultistepDialog
      title={copy.components.dataSourceDialog.title}
      isOpen={isOpen}
      onClose={() => onClose()}
      finalButtonProps={{
        text: copy.components.dataSourceDialog.navigation.finish,
        disabled: completedSources.length !== dataSources.length,
      }}
      ref={dialogRef}
    >
      {dataSources.map((source) => {
        // If the source is already configured, don't allow it to be configured again
        const completed = completedSourceMap.get(source.id);

        return (
          <DialogStep
            id={`ds-config-${source.id}`}
            key={source.id}
            title={source.name}
            nextButtonProps={{
              disabled: !completed,
              text: copy.components.dataSourceDialog.navigation.next,
            }}
            panel={
              <div className={Classes.DIALOG_BODY}>
                <DataSourceForm
                  dataSource={completed ?? source}
                  connection={connection}
                  disabled={!!completed}
                  onSubmit={handleDataSourceSubmit}
                />
              </div>
            }
          />
        );
      })}
    </MultistepDialog>
  );
};
