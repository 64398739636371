import * as React from 'react';

import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { StripePrice, User, useGetProductsQuery } from 'src/ui/graph/types';

export const useStripePrices = () => {
  const [stripePrices, setStripePrices] = React.useState<StripePrice[]>();
  const [profile, setProfile] = React.useState<
    Omit<User, 'is_authenticated' | 'username'>
  >();
  const { data, isLoading } = useGetProductsQuery(graphQLClient, undefined);

  React.useEffect(() => {
    if (!data?.getStripePrices || !data.profile) {
      return;
    }
    setStripePrices(
      data.getStripePrices.flatMap((price) => {
        if (!price) {
          return [];
        }

        const stripePrice: StripePrice = {
          id: price.id,
          nickname: price.nickname,
          amount: price.amount,
          stardog_billing_type: price.stardog_billing_type,
          product_info: {
            name: price.product_info?.name,
            description: price.product_info?.description,
            metadata: {
              flavor: price.product_info?.metadata?.flavor,
            },
          },
        };
        return [stripePrice];
      })
    );
    setProfile(data.profile);
  }, [data]);

  return {
    isLoading,
    profile,
    stripePrices,
  };
};
