import { Button, Classes, H4, Intent } from '@blueprintjs/core';
import React from 'react';

import { KitCard } from 'src/ui/containers/marketplace/KitCard';
import {
  NonKitCard,
  NonModule,
} from 'src/ui/containers/marketplace/NonKitCard';
import { Module } from 'src/ui/hooks/modules';
import { components } from 'src/ui/templates/copy';

interface KitsProps {
  defaultMaxDisplay?: number;
  description?: string;
  isVoiceboxLink?: boolean;
  kits: Module[];
  nonKits?: NonModule[];
  title?: string;
}

export const KitsSection: React.VFC<KitsProps> = ({
  defaultMaxDisplay = 8,
  description,
  isVoiceboxLink,
  kits,
  nonKits,
  title,
}) => {
  const [displayButtonClicked, setDisplayButtonClicked] = React.useState(false);
  const displayAll = displayButtonClicked || kits.length <= defaultMaxDisplay;

  const kitsToDisplay = displayAll ? kits : kits.slice(0, defaultMaxDisplay);

  return (
    <div className="kits-section">
      {title && <H4>{title}</H4>}
      {description && <p className={Classes.TEXT_LARGE}>{description}</p>}
      <div className="kits-list">
        {nonKits?.map((nonModule) => (
          <NonKitCard key={nonModule.id} module={nonModule} />
        ))}
        {kitsToDisplay.map((mod: Module) => (
          <KitCard key={mod.id} module={mod} isVoiceboxLink={isVoiceboxLink} />
        ))}
      </div>

      {!displayAll && (
        <div className="kits-load-more">
          <Button
            text={components.marketplace.loadMoreButton}
            onClick={() => setDisplayButtonClicked(true)}
            intent={Intent.PRIMARY}
            outlined
            minimal
            large
          />
        </div>
      )}
    </div>
  );
};
