import { Button, InputGroup } from '@blueprintjs/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Loading } from 'src/ui/components/Loading';
import { NotFound } from 'src/ui/components/NotFound';
import { TagMultiSelector } from 'src/ui/components/TagMultiSelector';
import { MP_MENU_SEARCH, MP_MENU_SEARCH_BTN } from 'src/ui/constants/testIds';
import { SelectOption } from 'src/ui/constants/types';
import { KitsSection } from 'src/ui/containers/marketplace/KitsSection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useMarketplaceSettingsQuery } from 'src/ui/graph/types';
import {
  Module,
  ModuleFilters,
  marketplaceSettingsToDbOptions,
  useListModuleTags,
  useMarketplaceList,
} from 'src/ui/hooks/modules';
import { useQueryParams } from 'src/ui/hooks/query';
import { components } from 'src/ui/templates/copy';
import {
  DEMO_MODULE_TYPE,
  TUTORIAL_MODULE_TYPE,
} from 'src/ui/templates/modules';

const BASE_PATH = '/kits';

export const MPHome: React.VFC = () => {
  const params = useQueryParams();
  const currSearchQuery = decodeURIComponent(params.get('q') || '');
  const currTagsQuery = decodeURIComponent(params.get('tags') || '');
  const history = useHistory();
  const { data: marketplaceSettingsData } = useMarketplaceSettingsQuery(
    graphQLClient
  );

  const marketplaceConfig = marketplaceSettingsToDbOptions(
    marketplaceSettingsData?.marketplaceSettings
  );

  const {
    data: moduleTags,
    isLoading: areModuleTagsLoading,
  } = useListModuleTags(marketplaceConfig);

  const [tagOptions, setTagOptions] = React.useState<SelectOption[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<SelectOption[]>([]);
  const [kitSearch, setKitSearch] = React.useState<string>('');

  const [modules, setModules] = React.useState<Module[]>([]);
  const demoModules = React.useMemo(() => {
    const orderedIds = components.marketplace.demoKits;
    const moduleLookup = new Map(modules.map((module) => [module.id, module]));
    const demoModules = orderedIds
      .map((id) => moduleLookup.get(id))
      .filter((module) => module) as Module[];

    return demoModules;
  }, [modules]);
  const tutorialModules = React.useMemo(
    () => modules.filter((mod) => mod.type === TUTORIAL_MODULE_TYPE),
    [modules]
  );
  // Catch all for other module types, but don't include modules with no type for now because
  // they are likely to not be ready to be customer facing. (Ex. The "Knowledge Kit" kit)
  const otherModules = React.useMemo(
    () =>
      modules.filter(
        (mod) =>
          mod.type &&
          mod.type !== DEMO_MODULE_TYPE &&
          mod.type !== TUTORIAL_MODULE_TYPE
      ),
    [modules]
  );

  React.useEffect(() => {
    setKitSearch(currSearchQuery);
  }, [currSearchQuery]);

  React.useEffect(() => {
    if (!currTagsQuery) {
      setSelectedTags([]);
      return;
    }

    try {
      setSelectedTags(JSON.parse(currTagsQuery));
    } catch (_err) {
      setSelectedTags([]);
    }
  }, [currTagsQuery]);

  React.useEffect(() => {
    if (areModuleTagsLoading || !moduleTags) {
      return;
    }
    setTagOptions(
      moduleTags
        .map((tag) => ({
          value: tag.iri,
          label: tag.label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [areModuleTagsLoading, moduleTags]);

  const filters: ModuleFilters = {
    tags: selectedTags.map((tag) => tag.value as string),
  };

  const {
    data: moduleData,
    isLoading: isModuleDataLoading,
  } = useMarketplaceList(marketplaceConfig, currSearchQuery, filters);

  React.useEffect(() => {
    if (isModuleDataLoading || !moduleData) {
      return;
    }

    setModules(moduleData);
  }, [moduleData, isModuleDataLoading]);

  const setSearchFilter = (evt: any) => {
    setKitSearch(evt.target.value);
  };

  const doSearch = ({ search = kitSearch, tags = selectedTags } = {}) => {
    const q = search ? [`q=${encodeURIComponent(search)}`] : [];
    const tagsQs = tags.length
      ? [`tags=${encodeURIComponent(JSON.stringify(tags))}`]
      : [];
    const qs = [...q, ...tagsQs].join('&');

    history.replace(`${BASE_PATH}?${qs}`);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      doSearch();
    }
  };

  const onTagsChange = (newList: SelectOption[]) => {
    doSearch({ tags: newList });
    setSelectedTags(newList);
  };

  const { sections } = components.marketplace;

  const demoSection = demoModules?.length > 0 && (
    <KitsSection
      title={sections.demos.title}
      description={sections.demos.description}
      kits={demoModules}
    />
  );

  const tutorialSection = tutorialModules?.length > 0 && (
    <KitsSection
      title={sections.tutorials.title}
      description={sections.tutorials.description}
      kits={tutorialModules}
    />
  );

  const otherSection = otherModules?.length > 0 && (
    <KitsSection
      title={sections.dataSetsAndModels.title}
      description={sections.dataSetsAndModels.description}
      kits={otherModules}
    />
  );

  const sectionsToShow = [demoSection, tutorialSection, otherSection].filter(
    (s) => s
  ) as JSX.Element[];

  return (
    <div className="mp-home bg-shapes">
      <div className="sd-page-summary">
        <span className="page-heading">{components.marketplace.title}</span>
        <p className="page-overview">{components.marketplace.summary}</p>
      </div>
      <div className="mp-menu-bar">
        <TagMultiSelector
          className="mp-menu-bar-tag-selector"
          items={tagOptions}
          selectedItems={selectedTags}
          onChange={onTagsChange}
          placeholder={
            areModuleTagsLoading
              ? components.marketplace.menu.tagFilter.loadingPlaceholder
              : components.marketplace.menu.tagFilter.placeholder
          }
        />
        <InputGroup
          type="search"
          data-testid={MP_MENU_SEARCH}
          name="mod-search"
          leftIcon="search"
          rightElement={
            <Button
              data-testid={MP_MENU_SEARCH_BTN}
              icon="arrow-right"
              onClick={() => doSearch()}
              minimal
            />
          }
          placeholder={components.marketplace.menu.search.placeholder}
          onChange={setSearchFilter}
          onKeyDown={onKeyDown}
          value={kitSearch}
        />
      </div>
      {isModuleDataLoading || !moduleData ? (
        <Loading />
      ) : (
        <div className="all-kits">
          {sectionsToShow.map((section, i) => (
            <React.Fragment key={section.props.title}>
              {i > 0 && <hr />}
              {section}
            </React.Fragment>
          ))}
          {modules.length === 0 && (
            <NotFound title={components.marketplace.noKits} description="" />
          )}
        </div>
      )}
    </div>
  );
};
