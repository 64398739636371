import {
  ItemListRenderer as BlueprintItemListRenderer,
  ItemRenderer as BlueprintItemRenderer,
  QueryList as BlueprintQueryList,
  QueryListRendererProps as BlueprintQueryListRendererProps,
  renderFilteredItems,
} from '@blueprintjs/select';
import { Chat, Search } from '@carbon/icons-react';
import classNames from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { NavbarAside } from 'vet-bones/components';
import { Input } from 'vet-bones/design-system/components';
import { selectItemPredicate } from 'vet-bones/utils/select';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  VOICEBOX_CHAT_LIST_ASIDE_INPUT,
  VOICEBOX_CHAT_LIST_ASIDE_INPUT_CLEAR,
} from 'src/ui/constants/testIds';
import {
  ConversationSelectItem,
  useVoiceboxConversations,
} from 'src/ui/containers/voicebox/aside/useVoiceboxConversations';
import { VoiceboxChatListItem } from 'src/ui/containers/voicebox/aside/VoiceboxChatListItem';
import { toggledNavbarAside } from 'src/ui/features/ui';
import { useCurrentConnection } from 'src/ui/hooks/connection';
import { useVoiceboxRouteParams } from 'src/ui/hooks/navbar/routes';
import * as copy from 'src/ui/templates/copy';

export const VoiceboxChatListAside: React.VFC = () => {
  const { id: connectionIdx, conversation: conversationId } =
    useVoiceboxRouteParams() || {};
  const history = useHistory();

  const dispatch = useAppDispatch();

  const { connection } = useCurrentConnection(connectionIdx);
  const conversations = useVoiceboxConversations(connection?.id || '');
  const conversationItems = conversations.items;

  const listRef = React.createRef<BlueprintQueryList<ConversationSelectItem>>();

  const onSelect = (item: ConversationSelectItem) => {
    dispatch(toggledNavbarAside({ isAsideOpen: false }));
    if (item.value !== conversationId) {
      history.push(`/u/${connectionIdx}/voicebox/${item.value}`);
    }
  };

  const itemPredicate = selectItemPredicate()(conversationItems);

  const itemRenderer = React.useCallback<
    BlueprintItemRenderer<ConversationSelectItem>
  >((item, options) => {
    return options.modifiers.matchesPredicate ? (
      <React.Fragment key={item.value}>
        <VoiceboxChatListItem item={item} options={options} />
      </React.Fragment>
    ) : null;
  }, []);

  const itemListRenderer = React.useCallback<
    BlueprintItemListRenderer<ConversationSelectItem>
  >((itemListProps) => {
    return <>{renderFilteredItems(itemListProps)}</>;
  }, []);

  const renderer = React.useCallback<
    (
      listProps: BlueprintQueryListRendererProps<ConversationSelectItem>
    ) => JSX.Element
  >(
    (listProps) => {
      return (
        <>
          <div className="vb-navbar-aside-content">
            <Input
              autoFocus
              clearTestId={VOICEBOX_CHAT_LIST_ASIDE_INPUT_CLEAR}
              icon={Search}
              label=""
              onChange={listProps.handleQueryChange}
              onClear={() => {
                listRef.current?.setQuery('', true);
              }}
              placeholder={
                copy.components.voicebox.chatListAside.filter.placeholder
              }
              size="md"
              testId={VOICEBOX_CHAT_LIST_ASIDE_INPUT}
              value={listProps.query}
            />
          </div>
          <div className="vb-navbar-aside-divider" />
          <div
            className={classNames(
              'vb-navbar-aside-content',
              'sd-voicebox-chat-list-items'
            )}
          >
            {listProps.itemList}
          </div>
        </>
      );
    },
    [listRef]
  );

  return (
    <NavbarAside
      badgeProps={{
        children: conversationItems.length,
        type: 'default',
        variant: 'fill',
      }}
      className="sd-voicebox-chat-list-aside"
      icon={Chat}
      title={copy.components.voicebox.chatListAside.title}
    >
      <BlueprintQueryList
        ref={listRef}
        itemListRenderer={itemListRenderer}
        itemPredicate={itemPredicate}
        itemRenderer={itemRenderer}
        items={conversationItems}
        noResults={
          conversationItems.length
            ? copy.components.voicebox.chatListAside.noResults
            : ''
        }
        onItemSelect={onSelect}
        renderer={renderer}
      />
    </NavbarAside>
  );
};
