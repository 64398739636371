import { useAppDispatch } from 'src/ui/app/hooks';
import {
  clearNotifications,
  queueNotification,
} from 'src/ui/features/notifications';
import {
  GraphError,
  getErrorFromGraphQL,
} from 'src/ui/utils/getErrorFromGraphQL';

/**
 * Custom hook that handles error responses from GraphQL requests.
 */
export const useOnError = () => {
  const dispatch = useAppDispatch();

  const onError = (error: GraphError) => {
    const message = getErrorFromGraphQL(error);
    dispatch(queueNotification(message));
  };

  return onError;
};

/**
 * Custom hook that handles clears notifications onMutations.
 */
export const useOnMutate = () => {
  const dispatch = useAppDispatch();

  const onMutate = () => {
    dispatch(clearNotifications());
  };

  return onMutate;
};
