import { Button, Classes, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import {
  DialogType,
  acknowledgeUsingUnsupportedBrowser,
  closedDialog,
} from 'src/ui/features/ui';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import { components } from 'src/ui/templates/copy';

const isOpenSelector = isDialogOpenSelector(DialogType.UNSUPPORTED_BROWSER);

export const UnsupportedBrowserDialog: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isOpenSelector);

  return (
    <Dialog
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      icon={IconNames.INFO_SIGN}
      isCloseButtonShown={false}
      isOpen={isOpen}
      title={components.unsupportedBrowserDialog.title}
    >
      <div className={Classes.DIALOG_BODY}>
        {components.unsupportedBrowserDialog.body}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            intent={Intent.PRIMARY}
            text={components.unsupportedBrowserDialog.acknowledgeButtonText}
            onClick={() => {
              dispatch(acknowledgeUsingUnsupportedBrowser());
              dispatch(closedDialog());
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
