import * as React from 'react';
import { NavbarSchema } from 'vet-bones/components';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { NAVBAR_EXPAND_BUTTON } from 'src/ui/constants/testIds';
import { toggledNavbarExpanded } from 'src/ui/features/ui';

export const useNavbarExpandSchema = (): NavbarSchema['expand'] => {
  const dispatch = useAppDispatch();

  const isExpanded = useAppSelector((state) => state.ui.navbar.isExpanded);

  const handleExpand = React.useCallback(() => {
    dispatch(toggledNavbarExpanded(!isExpanded));
  }, [dispatch, isExpanded]);

  return React.useMemo(() => {
    return {
      isExpanded,
      onClick: handleExpand,
      testId: NAVBAR_EXPAND_BUTTON,
    };
  }, [handleExpand, isExpanded]);
};
