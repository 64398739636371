import * as React from 'react';

import { DASHBOARD_UNAUTHORIZED_WARNING } from 'src/ui/constants/testIds';
import { copy } from 'src/ui/templates/copy';

interface UnauthorizedWarningProps {
  index: number;
}

/**
 * This uses just a plain button as we use an image to display the actual
 * button. We don't want this to be styled by blueprintjs.
 */
export const UnauthorizedWarning: React.VFC<UnauthorizedWarningProps> = ({
  index,
}) => {
  return (
    <a
      className="sd-warning"
      href={`/u/${index}/diagnostic`}
      data-testid={DASHBOARD_UNAUTHORIZED_WARNING}
    >
      {copy.errors.unauthorized} &rarr;
    </a>
  );
};
