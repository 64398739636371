import { H4 } from '@blueprintjs/core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDidValueChange } from 'vet-bones/utils/hooks';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { withConfirmation } from 'src/ui/components/withConfirmation';
import { noop } from 'src/ui/constants/empty';
import { RECENT_CONNECTION } from 'src/ui/constants/session';
import { SelectOption } from 'src/ui/constants/types';
import { BrowserAuthFailedWarning } from 'src/ui/containers/dashboard/BrowserAuthFailedWarning';
import { Dashboard } from 'src/ui/containers/dashboard/Dashboard';
import { DegradedWarning } from 'src/ui/containers/dashboard/DegradedWarning';
import { UnauthorizedWarning } from 'src/ui/containers/dashboard/UnauthorizedWarning';
import { NewUserLanding } from 'src/ui/containers/landing/NewUserLanding';
import { Welcome } from 'src/ui/containers/landing/Welcome';
import { setConnectionIndex } from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useSavedConnectionsQuery } from 'src/ui/graph/types';
import { useQueryParams } from 'src/ui/hooks/query';
import {
  isBrowserAuthFailureSelector,
  isDegradedSelector,
  isUnauthorizedSelector,
} from 'src/ui/selectors/connection';
import { loggedInUserEmail } from 'src/ui/selectors/loggedInUserEmail';
import * as copy from 'src/ui/templates/copy';

type TParams = { id: string };

export const LandingPage: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isBrowserAuthFailure = useAppSelector(isBrowserAuthFailureSelector);
  const isDegraded = useAppSelector(isDegradedSelector);
  const isUnauthorized = useAppSelector(isUnauthorizedSelector);
  const userEmail = useAppSelector(loggedInUserEmail());
  const history = useHistory();
  const params = useParams<TParams>();
  const queryParams = useQueryParams();
  const hasIndexParam = !!params.id;
  const indexParam = params.id === 'demo' ? 12345 : Number(params.id);
  const indexParamChanged = useDidValueChange(indexParam);
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(null);
  const currentIndexIsSet = currentIndex !== null;
  const [
    currentConnection,
    setCurrentConnection,
  ] = React.useState<SelectOption | null>(null);
  const {
    data: connectionList,
    isLoading: loadingConnections,
  } = useSavedConnectionsQuery(graphQLClient);
  const connectionOptions = React.useMemo<SelectOption[]>(
    () =>
      connectionList?.listConnections?.map((c) => ({
        label: c?.name || '',
        value: c?.index ?? -1,
      })) ?? [],
    [connectionList]
  );
  const singleConnection = connectionOptions.length === 1;
  const noConnections = connectionOptions.length === 0;

  const recentConnectionIndex = React.useMemo<number>(() => {
    if (userEmail === null) {
      return -1;
    }

    const saved = Number.parseInt(
      String(localStorage.getItem(RECENT_CONNECTION(userEmail))),
      10
    );

    return Number.isNaN(saved) ? -1 : saved;
  }, [userEmail]);
  const recentConnectionIndexChanged = useDidValueChange(recentConnectionIndex);

  React.useEffect(() => {
    // This runs if the current index differs from the index param
    if (!hasIndexParam || currentIndex === indexParam) {
      return;
    }
    if (currentIndex === null || indexParamChanged) {
      // First time loading the page with an index param or the index param has changed
      setCurrentIndex(indexParam);
      return;
    }

    // If the current index differs from the index param, and the index param
    // has not changed, then it's been updated by the user and we should
    // update the URL.
    history.push(`/u/${currentIndex}/`);
  }, [hasIndexParam, indexParam, currentIndex, history, indexParamChanged]);

  React.useEffect(() => {
    if (currentIndex === null || currentConnection?.value === currentIndex) {
      return;
    }
    const connection =
      connectionOptions.find((c) => c.value === currentIndex) ?? null;

    setCurrentConnection(connection);
  }, [connectionOptions, currentIndex, currentConnection]);

  React.useEffect(() => {
    if (hasIndexParam) {
      // Don't auto-select a connection if there's an index param,
      // another effect will handle that
      return;
    }
    if (loadingConnections || !connectionList || !userEmail) {
      return;
    }

    const connections = connectionList?.listConnections || [];

    if (connections.length === 1 && connections[0]) {
      setCurrentIndex(connections[0].index);
      return;
    }

    if (connections.length > 1 && connections[0]) {
      // If the connection index hasn't been set yet, and there's no recent
      // connection, then set the current index to the first connection
      if (!currentIndexIsSet && recentConnectionIndex === -1) {
        setCurrentIndex(connections[0].index);
        return;
      }

      // if the recent connection index is not a valid connection index
      // then set the current index to the first connection
      if (
        !connectionList?.listConnections?.find(
          (connection) => connection?.index === recentConnectionIndex
        )
      ) {
        setCurrentIndex(connections[0].index);
        return;
      }

      // Only use the recent connection if the index hasn't been set yet
      // or if the recent connection index gets updated
      if (!currentIndexIsSet || recentConnectionIndexChanged) {
        setCurrentIndex(recentConnectionIndex);
      }
    }
  }, [
    connectionList,
    loadingConnections,
    hasIndexParam,
    recentConnectionIndex,
    recentConnectionIndexChanged,
    currentIndexIsSet,
    userEmail,
  ]);

  /* istanbul ignore next: this just keeps state in sync */
  React.useEffect(() => {
    if (currentIndex === null) {
      return;
    }

    dispatch(setConnectionIndex(currentIndex));
  }, [currentIndex, dispatch]);

  React.useEffect(() => {
    if (
      userEmail === null ||
      currentConnection === null ||
      currentConnection.value === recentConnectionIndex
    ) {
      return;
    }

    localStorage.setItem(
      RECENT_CONNECTION(userEmail),
      String(currentConnection.value)
    );
  }, [currentConnection, recentConnectionIndex, userEmail]);

  React.useEffect(() => {
    if (queryParams.has('upgradeSuccess')) {
      history.replace(history.location.pathname);
      withConfirmation({
        isCloseButtonShown: false,
        message: copy.components.welcomeToEssentialsDialog.body,
        noPrompt: null,
        onConfirmationSuccess: noop,
        title: copy.components.welcomeToEssentialsDialog.title,
        yesPrompt: copy.components.welcomeToEssentialsDialog.buttonText,
      });
    }
  }, [history, queryParams]);

  if (noConnections) {
    return <NewUserLanding />;
  }

  const browserAuthFailedWarning = isBrowserAuthFailure ? (
    <BrowserAuthFailedWarning index={currentIndex ?? -1} />
  ) : null;
  const degradedWarning = isDegraded ? (
    <DegradedWarning index={currentIndex ?? -1} />
  ) : null;
  const unauthorizedWarning = isUnauthorized ? (
    <UnauthorizedWarning index={currentIndex ?? -1} />
  ) : null;

  return (
    <>
      <Welcome />
      {currentIndex !== null && (
        <div>
          {browserAuthFailedWarning}
          {degradedWarning}
          {unauthorizedWarning}
          <div className="sd-landing-dashboard">
            <H4 className="sd-landing-header">{currentConnection?.label}</H4>
            <Dashboard index={currentIndex} />
          </div>
        </div>
      )}
    </>
  );
};
