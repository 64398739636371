import { Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';
import { useQueryClient } from 'react-query';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { FormAction } from 'src/ui/components/FormActions';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import { UpdateProfileForm } from 'src/ui/containers/dialogs/UpdateProfile/UpdateProfileForm';
import { DialogType, closedDialog, profileUpdated } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  ProfileInput,
  useProfileQuery,
  useUpdateProfileMutation,
} from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import * as copy from 'src/ui/templates/copy';

const isOpenSelector = isDialogOpenSelector(DialogType.UPDATE_PROFILE);

export const UpdateProfileDialog: React.VFC = () => {
  const { data, isLoading } = useProfileQuery(graphQLClient);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isOpenSelector);
  const onError = useOnError();
  const onMutate = useOnMutate();
  const queryClient = useQueryClient();
  const {
    mutate: updateProfile,
    isLoading: isUpdatingProfile,
  } = useUpdateProfileMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: () => {
      queryClient.invalidateQueries(useProfileQuery.getKey());
      dispatch(profileUpdated());
    },
  });

  /* istanbul ignore next: trivial close button logic */
  const handleCloseDialog = () => {
    dispatch(closedDialog());
  };

  const handleUpdate = (input: ProfileInput) => {
    updateProfile({ input });
  };

  const actions: FormAction[] = [
    makeSubmitAction(
      copy.components.updateProfileDialog.submit,
      isUpdatingProfile
    ),
  ];

  if (isLoading || !data || !data.profile) {
    return null;
  }

  const hasUpdatedProfile = data.profile.has_updated_profile || false;

  return (
    <Dialog
      className="sd-dialog-profile"
      canOutsideClickClose={hasUpdatedProfile}
      canEscapeKeyClose={hasUpdatedProfile}
      icon={IconNames.PERSON}
      isOpen={isOpen}
      isCloseButtonShown={hasUpdatedProfile}
      onClose={handleCloseDialog}
      title={copy.components.updateProfileDialog.title}
    >
      <div
        className={classNames(Classes.DIALOG_BODY, 'sd-dialog-profile__body')}
      >
        <UpdateProfileForm
          user={data.profile}
          actions={actions}
          onSubmit={handleUpdate}
        />
      </div>
    </Dialog>
  );
};
