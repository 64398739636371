import * as React from 'react';

type HandleTextFieldChangeFn = (
  evt: React.ChangeEvent<HTMLInputElement>
) => void;

type HandleBooleanFieldChangeFn<Type> = <Key extends keyof Type>(
  name: Key
) => () => void;

type HandleValueChangeFn<Type> = <Key extends keyof Type>(
  name: Key
) => (value: Type[Key]) => void;

export type FormValues<Type> = {
  handleTextFieldChange: HandleTextFieldChangeFn;
  handleBooleanFieldChange: HandleBooleanFieldChangeFn<Type>;
  handleValueChange: HandleValueChangeFn<Type>;
  formData: Type;
  setFormData: (data: Type) => void;
  reset: () => void;
};

/**
 * Generic form data hook to set up initial data and provide functions
 * to update that data:
 *
 *    const {formData, handleTextFieldChange } = useFormHandler<Connection>(initial);
 *    return <input onChange={handleTextFieldChange} name="field_name" />;
 *
 * @param initialData - initial form data to set
 */
export const useFormHandler = <Type>(initialData: Type): FormValues<Type> => {
  const [formData, setFormData] = React.useState<Type>(initialData);

  const handleTextFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleValueChange = <Key extends keyof Type>(name: Key) => (
    value: Type[Key]
  ) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBooleanFieldChange = <Key extends keyof Type>(
    name: Key
  ) => () => {
    setFormData({
      ...formData,
      [name]: !formData[name],
    });
  };

  // useCallback to avoid reset() from being recreated on every render
  const reset = React.useCallback(() => {
    setFormData(initialData);
  }, [initialData]);

  return {
    formData,
    setFormData,
    handleTextFieldChange,
    handleBooleanFieldChange,
    handleValueChange,
    reset,
  };
};
