import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  Intent,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import {
  VERIFY_EMAIL_DIALOG_CLOSE_BUTTON,
  VERIFY_EMAIL_DIALOG_RESEND_BUTTON,
} from 'src/ui/constants/testIds';
import { VERIFY_EMAIL_MODAL } from 'src/ui/constants/trackingEventNames';
import { queueMessage } from 'src/ui/features/notifications';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  useResendEmailMutation,
  useTrackEventMutation,
} from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import { loggedInUserEmail } from 'src/ui/selectors/loggedInUserEmail';
import { components, copy } from 'src/ui/templates/copy';
import emailVerificationIcon from 'src/ui/templates/images/email_verification.svg';
import { splitIntoChunks } from 'src/ui/utils/splitIntoChunks';

const isOpenSelector = isDialogOpenSelector(DialogType.VERIFY_EMAIL);

export const VerifyEmailDialog: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isOpenSelector);
  const userEmailSelector = loggedInUserEmail();
  const userEmail = useAppSelector(userEmailSelector);
  const onError = useOnError();
  const onMutate = useOnMutate();
  const { mutate: resendEmailMutation } = useResendEmailMutation(
    graphQLClient,
    {
      onError,
      onMutate,
      onSuccess: ({ resendEmail }) => {
        const intent = resendEmail?.success ? Intent.SUCCESS : Intent.WARNING;
        const message = resendEmail?.error
          ? resendEmail.error
          : copy.errors.unverifiedSent;
        dispatch(
          queueMessage({
            intent,
            message,
            action: 'RE_AUTH',
          })
        );
      },
    }
  );
  const { mutate: trackEvent } = useTrackEventMutation(graphQLClient, {});
  const [clickedToVerify, setClickedToVerify] = React.useState<boolean>(false);

  const resendEmailVerification = () => {
    if (!clickedToVerify) {
      setClickedToVerify(true);
      resendEmailMutation({});
      return;
    }
    dispatch(
      queueMessage({
        intent: Intent.WARNING,
        message: copy.errors.unverifiedAlreadySent,
      })
    );
  };

  const buttonRows = splitIntoChunks(
    components.verifyEmailDialog.emailButtons,
    3
  );

  React.useEffect(() => {
    if (isOpen) {
      trackEvent({ input: { event: VERIFY_EMAIL_MODAL } });
    }
  }, [isOpen]);

  return (
    <Dialog
      canOutsideClickClose
      isOpen={isOpen}
      onClose={() => {
        dispatch(closedDialog());
      }}
      className="sd-dialog-email-verification"
      canEscapeKeyClose
    >
      <div className="sd-dialog-email-verification__header">
        <Button
          minimal
          large
          icon={IconNames.CROSS}
          onClick={() => {
            dispatch(closedDialog());
          }}
          data-testid={VERIFY_EMAIL_DIALOG_CLOSE_BUTTON}
        />
      </div>
      <div
        className={classNames(
          Classes.DIALOG_BODY,
          'sd-dialog-email-verification__body'
        )}
      >
        <span>
          <img alt="email verification" src={emailVerificationIcon} />
        </span>
        <p className="sd-dialog-email-verification__body__title">
          {components.verifyEmailDialog.body.title}
        </p>
        {components.verifyEmailDialog.body.currentIdentity(userEmail || '')}
        <p>{components.verifyEmailDialog.body.pleaseConfirm}</p>

        {buttonRows.map((row, index) => (
          <div
            // index is stable in this case and items are not re-ordered - should be fine to use index as key
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="sd-dialog-email-verification__body__button-grid"
          >
            {row.map(({ name, url }) => (
              <AnchorButton
                intent={Intent.PRIMARY}
                minimal
                outlined
                key={name}
                href={url}
                text={`Open ${name}`}
                target="_blank"
              />
            ))}
          </div>
        ))}
      </div>
      <div
        className={classNames(
          Classes.DIALOG_FOOTER,
          'sd-dialog-email-verification__footer'
        )}
      >
        <button
          data-testid={VERIFY_EMAIL_DIALOG_RESEND_BUTTON}
          type="button"
          className="sd-dialog-email-verification__footer__resend-email-button"
          onClick={resendEmailVerification}
        >
          {components.verifyEmailDialog.resendButton.text}
        </button>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={components.verifyEmailDialog.contactUs.url}
        >
          {components.verifyEmailDialog.contactUs.text}
        </a>
      </div>
    </Dialog>
  );
};
