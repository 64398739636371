import { HTMLTable } from '@blueprintjs/core';
import * as React from 'react';

import { copy } from 'src/ui/templates/copy';

interface OwnProps {
  connectionInfo?: any;
}

export const DiagnosticConnection: React.VFC<OwnProps> = ({
  connectionInfo,
}) => {
  if (!connectionInfo) {
    return null;
  }

  const roles =
    typeof connectionInfo.roles === 'object' ? connectionInfo.roles : {};
  const username = (
    <tr>
      <td>{copy.diagnostic.username}</td>
      <td>{connectionInfo.username}</td>
    </tr>
  );
  const provider = (
    <tr>
      <td>{copy.diagnostic.provider}</td>
      <td>{connectionInfo.provider}</td>
    </tr>
  );
  const subject = (
    <tr>
      <td>{copy.diagnostic.subject}</td>
      <td>{connectionInfo.sub}</td>
    </tr>
  );

  return (
    <div className="diagnostic-connection">
      <HTMLTable condensed bordered>
        <thead>
          <tr>
            <th>Identifier</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {username}
          {provider}
          {subject}
          {Object.keys(roles)
            .sort()
            .map((role) => (
              <tr key={role}>
                <td>{role}</td>
                <td>
                  <ul>
                    {roles[role].map((group: string) => (
                      <li key={group}>{group}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
        </tbody>
      </HTMLTable>
    </div>
  );
};
