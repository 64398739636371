import * as React from 'react';

import { ProductCard } from 'src/ui/components/ProductCard';
import { copy } from 'src/ui/templates/copy';
import stardogDesigner from 'src/ui/templates/images/stardog-designer.svg';
import stardogExplorer from 'src/ui/templates/images/stardog-explorer.svg';
import stardogStudio from 'src/ui/templates/images/stardog-studio.svg';

type StardogAppsProps = {
  currentIndex: number | null;
  goToExplorer: (index?: number) => void;
  goToDesigner: (index?: number) => void;
  goToStudio: (index?: number) => void;
};

/* istanbul ignore next */
export const StardogApps: React.FC<StardogAppsProps> = ({
  currentIndex,
  goToExplorer,
  goToDesigner,
  goToStudio,
}: StardogAppsProps) => (
  <section
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div className="sd-list__collection dashboard-cards">
      <ProductCard
        onClick={() => goToExplorer(currentIndex || undefined)}
        image={stardogExplorer}
        altText={copy.dashboard.explorer.action}
        label={copy.dashboard.explorer.label}
        description={copy.dashboard.explorer.description}
      />
      <ProductCard
        onClick={() => goToDesigner(currentIndex || undefined)}
        image={stardogDesigner}
        altText={copy.dashboard.designer.action}
        label={copy.dashboard.designer.label}
        description={copy.dashboard.designer.description}
      />
      <ProductCard
        onClick={() => goToStudio(currentIndex || undefined)}
        image={stardogStudio}
        altText={copy.dashboard.studio.action}
        label={copy.dashboard.studio.label}
        description={copy.dashboard.studio.description}
      />
    </div>
  </section>
);
