/*
 * Names of tracking events to send to Heap, Hubspot, etc
 */
export const DESIGNER_CREATE_DATA_SOURCE = 'Designer create data source';
export const DESIGNER_CREATE_PROJECT = 'Designer create project';
export const DESIGNER_DEFINE_RESOURCE_CSV = 'Designer define resource csv';
export const DESIGNER_DEFINE_RESOURCE_VIRTUAL =
  'Designer define resource virtual';
export const DESIGNER_EXPORT_PROJECT = 'Designer export project';
export const DESIGNER_PUBLISH_PROJECT = 'Designer publish project';
export const EXPLORER_QUERY_BUILDER_EXECUTION =
  'Explorer query builder execution';
export const EXPLORER_TEXT_BAR_SEARCH = 'Explorer text bar search';
export const STUDIO_CREATE_DATA_SOURCE = 'Studio create data source';
export const STUDIO_CREATE_MODEL = 'Studio create model';
export const STUDIO_CREATE_USER = 'Studio create user';
export const STUDIO_CREATE_VIRTUAL_GRAPH = 'Studio create virtual graph';
export const STUDIO_EDIT_DATA_SOURCE = 'Studio edit data source';
export const STUDIO_EDIT_MODEL = 'Studio edit model';
export const STUDIO_EDIT_VIRTUAL_GRAPH = 'Studio edit virtual graph';
export const STUDIO_SPARQL_QUERY_EXECUTION = 'Studio SPARQL query execution';
export const VERIFY_EMAIL_MODAL = 'Verification modal seen';
