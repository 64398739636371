import {
  Button,
  Callout,
  Card,
  Checkbox,
  Divider,
  Drawer,
  DrawerSize,
  FormGroup,
  H1,
  H2,
  H5,
  Intent,
} from '@blueprintjs/core';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import { withConfirmation } from 'src/ui/components/withConfirmation';
import { isEU } from 'src/ui/constants/environment';
import {
  CLOUD_PURCHASE_BUTTON,
  CLOUD_TERMS_OF_SERVICE,
  GET_STARTED_HEADER,
  GET_STARTED_OVERVIEW,
  LANDING_PAGE_BLOCKED_EMAIL_CONFIRMATION,
} from 'src/ui/constants/testIds';
import { PlanComparison } from 'src/ui/containers/product-card/PlanComparison';
import { ProductCard } from 'src/ui/containers/product-card/ProductCard';
import { InstanceFlavors, StripeBillingType } from 'src/ui/features/connection';
import { queueMessage } from 'src/ui/features/notifications';
import { showVerifyEmailDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  CheckoutLineItem,
  StripePrice,
  useCheckoutCartMutation,
} from 'src/ui/graph/types';
import { useFormHandler } from 'src/ui/hooks/forms';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { useStripePrices } from 'src/ui/hooks/stripePrices';
import { isUnverifiedUser } from 'src/ui/selectors/isUnverifiedUser';
import { components } from 'src/ui/templates/copy';
import * as Window from 'src/ui/utils/window';

type GetCloudForm = {
  privacy: boolean;
};

type TParams = { flavor?: string };

export const GetStarted: React.VFC = () => {
  const isUnverified = useAppSelector(isUnverifiedUser());
  const dispatch = useAppDispatch();
  const params = useParams<TParams>();

  const { isLoading, stripePrices, profile } = useStripePrices();

  const [cart, setCart] = React.useState<StripePrice[]>([]);
  const [cartOpen, setCartOpen] = React.useState<boolean>(false);
  const [warning, setWarning] = React.useState<string | null>(null);

  const { formData, handleBooleanFieldChange } = useFormHandler<GetCloudForm>({
    privacy: false,
  });

  const onError = useOnError();
  const onMutate = useOnMutate();

  const { mutate: checkoutCart } = useCheckoutCartMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: (data) => {
      Window.redirectToUrl(data.checkoutCart?.url || '/');
    },
  });

  React.useEffect(() => {
    if (!stripePrices || !profile) {
      return;
    }
    if (params.flavor === 'free') {
      const free = stripePrices.find(
        (price) =>
          price?.product_info?.metadata?.flavor === InstanceFlavors.MICRO
      ) as StripePrice;
      if (profile.is_blocked_email) {
        Window.redirectToUrl('/get-started');
        return;
      }
      setCart([free]);
      setCartOpen(true);
    }
    if (params.flavor === 'essentials') {
      const essentials = stripePrices.find(
        (price) =>
          price?.product_info?.metadata?.flavor === InstanceFlavors.SMALL_VBX &&
          price?.stardog_billing_type === StripeBillingType.SUBSCRIPTION
      ) as StripePrice;
      setCart([essentials]);
      setCartOpen(true);
    }
  }, [params, profile, stripePrices]);

  const validate = React.useCallback((): string => {
    if (!formData.privacy) {
      return components.getStarted.formValidation.termsRequired;
    }
    return '';
  }, [formData]);

  React.useEffect(() => {
    const validationMessage = validate();
    if (warning && validationMessage === '') {
      setWarning(null);
    }
  }, [warning, formData, validate]);

  if (isLoading || !stripePrices) {
    return <Loading />;
  }

  const addToCart = (thePrice?: StripePrice) => {
    if (!thePrice) {
      dispatch(
        queueMessage({
          message: components.getStarted.productNotFound,
          intent: Intent.DANGER,
        })
      );
      return;
    }

    setCart([thePrice]);
    setCartOpen(true);
  };

  const contactUs = () => {
    Window.openApplicationInTab(components.getStarted.contactUsUrl);
  };

  const getCartSize = () => {
    return cart.length;
  };

  const getCartQuantities = (cart: StripePrice[]) => {
    const cartQuantitiesMap = new Map();
    cart.forEach((item) => {
      if (
        !cartQuantitiesMap.has(item.id) &&
        item.product_info?.metadata?.flavor === InstanceFlavors.SMALL_VBX
      ) {
        cartQuantitiesMap.set(item.id, { price: item, quantity: 5 });
      } else if (!cartQuantitiesMap.has(item.id)) {
        cartQuantitiesMap.set(item.id, { price: item, quantity: 1 });
      } else {
        cartQuantitiesMap.set(item.id, {
          price: cartQuantitiesMap.get(item.id).price,
          quantity: cartQuantitiesMap.get(item.id).quantity + 1,
        });
      }
    });
    return Array.from(cartQuantitiesMap).map(([priceId, cartItem]) => ({
      priceId,
      cartItem,
    }));
  };

  const getCartSubtotal = () => {
    if (cart.length === 1) {
      const smallVbxItem = cart.find(
        (item) =>
          item.product_info?.metadata?.flavor === InstanceFlavors.SMALL_VBX
      );
      if (smallVbxItem) {
        return '195.00';
      }
    }
    return '0.00';
  };

  const handleCloseDrawer = () => {
    setCartOpen(false);
  };

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    // if unverified show dialog
    if (isUnverified) {
      handleCloseDrawer();
      dispatch(showVerifyEmailDialog());
      return;
    }

    const validationMessage = validate();

    if (validationMessage !== '') {
      setWarning(validationMessage);
      return;
    }
    if (cart.length > 0) {
      const itemForPurchase = cart[0];
      const item: CheckoutLineItem = {
        id: itemForPurchase.id as string,
        name: itemForPurchase.product_info?.name as string,
        quantity: 1,
        flavor: itemForPurchase.product_info?.metadata?.flavor as string,
      };
      checkoutCart({ item });
    }
  };

  const callout = warning ? <Callout intent="danger">{warning}</Callout> : null;

  const handleBlockedEmail = () =>
    withConfirmation({
      message: components.getStarted.blockedEmail.message,
      noPrompt: components.getStarted.blockedEmail.noPrompt,
      onConfirmationSuccess: () => {
        Window.redirectToUrl('/auth/logout');
      },
      // empty title to prevent showing the header
      title: '',
      isCloseButtonShown: false,
      yesPrompt: components.getStarted.blockedEmail.yesPrompt,
      yesPromptTestId: LANDING_PAGE_BLOCKED_EMAIL_CONFIRMATION,
    });

  const getOfferings = () => {
    const micro = stripePrices?.find(
      (price) => price.product_info?.metadata?.flavor === InstanceFlavors.MICRO
    );
    const voiceboxEssentials = stripePrices?.find(
      (price) =>
        price.product_info?.metadata?.flavor === InstanceFlavors.SMALL_VBX &&
        price.stardog_billing_type === StripeBillingType.SUBSCRIPTION
    );

    return (
      <div className="get-started__products">
        <ProductCard
          flavor={InstanceFlavors.MICRO}
          addToCart={() =>
            !profile?.is_blocked_email ? addToCart(micro) : handleBlockedEmail()
          }
        />
        <ProductCard
          flavor={InstanceFlavors.SMALL_VBX}
          addToCart={() =>
            isEU() ? contactUs() : addToCart(voiceboxEssentials)
          }
        />
        <ProductCard flavor={InstanceFlavors.MEDIUM} addToCart={contactUs} />
      </div>
    );
  };

  return (
    <>
      <div className="sd-page-summary get-started-summary">
        <H2 data-testid={GET_STARTED_HEADER}>{components.getStarted.title}</H2>
        <p data-testid={GET_STARTED_OVERVIEW}>
          {components.getStarted.overview(isEU())}
        </p>
      </div>
      <div className="get-started">
        <div className="get-started__select-plan">
          <H1>Select the plan that works for you</H1>
          {getOfferings()}
        </div>
        <Divider />
        <div className="get-started__plan-details">
          <H2>Plan details</H2>
          <PlanComparison />
        </div>
        <Drawer
          title={`Your Cart (${getCartSize()})`}
          icon="shopping-cart"
          isOpen={cartOpen}
          size={DrawerSize.SMALL}
          canEscapeKeyClose
          canOutsideClickClose
          onClose={() => {
            handleCloseDrawer();
          }}
        >
          <div className="sd-cart-drawer">
            <div className="sd-cart-drawer-content">
              {getCartQuantities(cart).map((item) => (
                <Card
                  className="sd-cart-drawer-content__card"
                  key={item.priceId}
                >
                  <div className="sd-cart-drawer-content__card-header">
                    <H5>{`${item.cartItem.price.product_info.name} (${item.cartItem.quantity})`}</H5>
                    <p>{`$ ${item.cartItem.price.amount}`}</p>
                  </div>
                </Card>
              ))}
            </div>
            <div className="sd-cart-drawer-footer">
              <H5>{`Subtotal: $${getCartSubtotal()}`}</H5>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Checkbox
                    checked={formData.privacy}
                    onChange={handleBooleanFieldChange('privacy')}
                    data-testid={CLOUD_TERMS_OF_SERVICE}
                    id="sd-cloud-privacy"
                  >
                    {components.getStarted.form.agree}
                    &nbsp;
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.stardog.com/cloud-tos/"
                    >
                      {components.getStarted.form.termsOfService}
                    </a>
                  </Checkbox>
                  {callout}
                </FormGroup>
                <div className="sd-cart__checkout-button-container">
                  <Button
                    large
                    fill
                    intent="success"
                    rightIcon="arrow-right"
                    text="Checkout"
                    outlined
                    onClick={handleSubmit}
                    data-testid={CLOUD_PURCHASE_BUTTON}
                  />
                </div>
              </form>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
