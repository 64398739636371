import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VoiceboxChatPage } from 'vet-bones/components/molecules/Voicebox';
import {
  VoiceboxApp,
  VoiceboxSchema,
  VoiceboxSettings,
} from 'vet-bones/constants/voicebox';
import {
  copyToClipboard,
  getIsClipboardEnabled,
  isEqual,
  noop,
} from 'vet-bones/utils';
import { useDidValueChange } from 'vet-bones/utils/hooks';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import { VOICEBOX_PAGE_DRAWER_BODY_CONTAINER } from 'src/ui/constants/testIds';
import { useVoiceboxConversations } from 'src/ui/containers/voicebox/aside/useVoiceboxConversations';
import {
  PortalVoiceboxContext,
  StaticVoiceboxContext,
} from 'src/ui/containers/voicebox/VoiceboxContext';
import {
  startedNewVoiceboxConversation,
  toggledNavbarAside,
} from 'src/ui/features/ui';
import { useCurrentConnection, useProfile } from 'src/ui/hooks/connection';
import { useQueryParams } from 'src/ui/hooks/query';
import {
  DEFAULT_VOICEBOX_NAMED_GRAPH,
  useNotAllowedVoiceboxPage,
  useVoiceboxPageConnectionData,
} from 'src/ui/hooks/voicebox';
import {
  voiceboxCopy,
  voiceboxQueryBuilder,
  voiceboxTestIds,
} from 'src/ui/templates/voicebox';

type TParams = { id: string; conversation?: string };

export const VoiceboxPage: React.VFC = () => {
  const params = useParams<TParams>();
  const conversationId = params.conversation || '';
  const connectionIdx = Number(params.id) || 0;
  const hasConverstaionId = Boolean(conversationId);

  const dispatch = useAppDispatch();
  const newConversationKey = useAppSelector(
    (state) => state.ui.voicebox.newConversationKey
  );

  const { data: profileData } = useProfile();
  const data = useVoiceboxPageConnectionData(params.id);
  const { connection, isLoading: isLoadingConnection } = useCurrentConnection(
    params.id
  );
  const connectionId = connection?.id || '';

  const history = useHistory();
  const queryParams = useQueryParams();

  const [
    initialSettings,
    setInitialSettings,
  ] = React.useState<VoiceboxSettings | null>(null);
  React.useEffect(() => {
    const databaseId = queryParams.get('db') || '';
    const graphs = queryParams.get('graph') || '';
    const model = queryParams.get('model') || '';
    if (databaseId || model || graphs) {
      setInitialSettings((prev) => {
        const next = {
          databaseId,
          model,
          namedGraphs: graphs
            ? graphs.split(',')
            : [DEFAULT_VOICEBOX_NAMED_GRAPH],
          reasoning: true,
        };
        return isEqual(prev, next) ? prev : next;
      });
    } else {
      setInitialSettings(null);
    }
  }, [history, queryParams]);

  const { loading: isLoadingPage, notAllowed } = useNotAllowedVoiceboxPage(
    params.id
  );
  const didIsLoadingPageChange = useDidValueChange(isLoadingPage);
  const needsRedirect =
    (!isLoadingConnection && !connection) ||
    (didIsLoadingPageChange && !isLoadingPage && notAllowed);

  React.useEffect(() => {
    if (needsRedirect) {
      console.warn(`notAllowed true, redirecting to /u/${connectionIdx}/`);
      history.push(`/u/${connectionIdx}/`);
    } else if (hasConverstaionId) {
      dispatch(toggledNavbarAside({ isAsideOpen: false }));
    }
  }, [connectionIdx, hasConverstaionId, dispatch, history, needsRedirect]);

  const conversations = useVoiceboxConversations(connection?.id || '');
  const didFetchingConversationChange = useDidValueChange(
    conversations.isFetching
  );
  const didNotFindConversation =
    didFetchingConversationChange &&
    !conversations.isFetching &&
    !profileData?.profile?.is_static_voicebox &&
    !conversations.items.find(
      (conversation) => conversation.value === conversationId
    ) &&
    conversationId;
  React.useEffect(() => {
    if (didNotFindConversation) {
      dispatch(startedNewVoiceboxConversation());
      history.push(`/u/${connectionIdx}/voicebox/`);
    }
  }, [connectionIdx, didNotFindConversation, dispatch, history]);

  const voiceboxSchema = React.useMemo(
    (): VoiceboxSchema | null =>
      data
        ? {
            copy: voiceboxCopy,
            testIds: voiceboxTestIds,
            queryBuilder: voiceboxQueryBuilder,

            clipboard: {
              isClipboardEnabled: getIsClipboardEnabled(),
              copyToClipboard,
            },

            config: {
              app: VoiceboxApp.CLOUD,
              catalogDatabaseId: data.catalogDatabaseId,
              connectionId,
              connectionIdx,
              defaultSettings: {
                databaseId: '',
                model: '',
                namedGraphs: [DEFAULT_VOICEBOX_NAMED_GRAPH],
                reasoning: true,
              },
              initialSettings,
            },

            drawer: {
              isOpen: true,
              openLinksInNewTab: true,
              toggleIsOpen: noop,
              usePortal: false,
              useSettings: true,
            },
          }
        : null,
    [data, connectionId, connectionIdx, initialSettings]
  );

  const handleConversationChange = React.useCallback(
    (nextConversationId: string) => {
      /* istanbul ignore if */
      if (nextConversationId !== conversationId) {
        dispatch(toggledNavbarAside({ isAsideOpen: false }));
        history.push(`/u/${connectionIdx}/voicebox/${nextConversationId}`);
      }
    },
    [conversationId, connectionIdx, dispatch, history]
  );

  if (notAllowed || !connection || !voiceboxSchema) {
    return null;
  }

  const element = isLoadingPage ? (
    <Loading />
  ) : (
    <div
      className="sd-voicebox-container"
      data-testid={VOICEBOX_PAGE_DRAWER_BODY_CONTAINER}
    >
      <VoiceboxChatPage />
    </div>
  );

  const isStaticVoicebox = Boolean(profileData?.profile?.is_static_voicebox);
  const body = isStaticVoicebox ? (
    <StaticVoiceboxContext
      initialConversationId={conversationId}
      newConversationKey={newConversationKey}
      onConversationChange={handleConversationChange}
      schema={voiceboxSchema}
    >
      {element}
    </StaticVoiceboxContext>
  ) : (
    <PortalVoiceboxContext
      initialConversationId={conversationId}
      newConversationKey={newConversationKey}
      onConversationChange={handleConversationChange}
      schema={voiceboxSchema}
    >
      {element}
    </PortalVoiceboxContext>
  );

  return (
    <div className="sd-voicebox-page">
      <div className="sd-voicebox-page-body">{body}</div>
    </div>
  );
};
