import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  VoiceboxApp,
  VoiceboxSchema,
  VoiceboxSettings,
} from 'vet-bones/constants/voicebox';
import { copyToClipboard, getIsClipboardEnabled } from 'vet-bones/utils';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Connection, DEMO_CONNECTION_INDEX } from 'src/ui/features/connection';
import {
  NavbarAsideType,
  startedNewVoiceboxConversation,
  toggledNavbarAside,
  toggledVoiceboxDrawer,
} from 'src/ui/features/ui';
import { useCurrentConnection } from 'src/ui/hooks/connection';
import {
  DEFAULT_VOICEBOX_NAMED_GRAPH,
  useVoiceboxDrawerConnectionData,
  useVoiceboxPageConnectionData,
} from 'src/ui/hooks/voicebox';
import {
  voiceboxCopy,
  voiceboxQueryBuilder,
  voiceboxTestIds,
} from 'src/ui/templates/voicebox';

export interface VoiceboxContextData {
  initialConversationId?: string;
  newConversationKey?: string;
  onConversationChange?: (conversationId: string) => void;
  schema: VoiceboxSchema;
  setInitialSettings?: React.Dispatch<
    React.SetStateAction<VoiceboxSettings | null>
  >;
}

export interface VoiceboxContextValue {
  connection: Connection | null;
  isLoadingConnection: boolean;
  drawerData: VoiceboxContextData | null;
  pageData: VoiceboxContextData | null;
}

export const VoiceboxContext = React.createContext<VoiceboxContextValue | null>(
  null
);

export const useVoiceboxContext = () => {
  return React.useContext<VoiceboxContextValue | null>(VoiceboxContext);
};

const useVoiceboxPageContextValue = (
  connectionIndex?: string
): VoiceboxContextValue => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const newConversationKey = useAppSelector(
    (state) => state.ui.voicebox.newConversationKey
  );
  const isOpen = useAppSelector(
    (state) =>
      state.ui.navbar.aside === NavbarAsideType.VOICEBOX_SETTINGS &&
      state.ui.navbar.isAsideOpen
  );

  const data = useVoiceboxPageConnectionData();

  const [
    initialSettings,
    setInitialSettings,
  ] = React.useState<VoiceboxSettings | null>(null);

  const connectionIdx = Number(connectionIndex) || 0;
  const { connection, isLoading: isLoadingConnection } = useCurrentConnection(
    connectionIndex
  );
  const connectionId = connection?.id || '';

  const conversationId = data?.conversationId || '';
  React.useEffect(() => {
    if (!conversationId) {
      // start a new voicebox conversation
      dispatch(startedNewVoiceboxConversation());
    } else {
      // use initial settings from current conversation
      setInitialSettings(null);
    }
    // close any asides when the conversation changes
    dispatch(toggledNavbarAside({ isAsideOpen: false }));
  }, [conversationId, dispatch, setInitialSettings]);

  const handleSaveSettings = React.useCallback(
    (_, nextSettings: VoiceboxSettings) => {
      if (conversationId) {
        // use initial settings from saved settings
        setInitialSettings(nextSettings);
        history.push(`/u/${connectionIdx}/voicebox/`);
      } else {
        // close the aside
        dispatch(toggledNavbarAside({ isAsideOpen: false }));
      }
    },
    [connectionIdx, conversationId, dispatch, history]
  );

  const handleConversationChange = React.useCallback(
    (nextConversationId: string) => {
      /* istanbul ignore if */
      if (nextConversationId !== conversationId) {
        history.push(`/u/${connectionIdx}/voicebox/${nextConversationId}`);
      }
    },
    [conversationId, connectionIdx, history]
  );

  return React.useMemo<VoiceboxContextValue>(
    () => ({
      connection,
      isLoadingConnection,
      drawerData: null,
      pageData: data
        ? {
            initialConversationId: conversationId,
            newConversationKey,
            onConversationChange: handleConversationChange,
            schema: {
              copy: voiceboxCopy,
              testIds: voiceboxTestIds,
              queryBuilder: voiceboxQueryBuilder,

              clipboard: {
                isClipboardEnabled: getIsClipboardEnabled(),
                copyToClipboard,
              },

              config: {
                app: VoiceboxApp.CLOUD,
                catalogDatabaseId: data.catalogDatabaseId,
                connectionId,
                connectionIdx,
                defaultSettings: {
                  databaseId: '',
                  model: '',
                  namedGraphs: [DEFAULT_VOICEBOX_NAMED_GRAPH],
                  reasoning: true,
                },
                initialSettings,
              },

              drawer: {},

              links: {
                openLinksInNewTab: true,
                openLinksInPanel: true,
              },

              settingsAside: {
                isOpen,
                useSettings: true,
                onSaveSettings: handleSaveSettings,
              },
            },
            setInitialSettings,
          }
        : null,
    }),
    [
      connection,
      connectionId,
      connectionIdx,
      conversationId,
      data,
      handleConversationChange,
      handleSaveSettings,
      initialSettings,
      isLoadingConnection,
      isOpen,
      newConversationKey,
    ]
  );
};

const useVoiceboxDrawerContextValue = (): VoiceboxContextValue => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.ui.voicebox.isDrawerOpen);

  const data = useVoiceboxDrawerConnectionData();
  const connectionIdx = DEMO_CONNECTION_INDEX;
  const { connection, isLoading: isLoadingConnection } = useCurrentConnection(
    DEMO_CONNECTION_INDEX.toString()
  );
  const connectionId = connection?.id || '';

  return React.useMemo<VoiceboxContextValue>(
    () => ({
      connection,
      isLoadingConnection,
      drawerData: data
        ? {
            schema: {
              copy: voiceboxCopy,
              testIds: voiceboxTestIds,
              queryBuilder: voiceboxQueryBuilder,

              clipboard: {
                isClipboardEnabled: getIsClipboardEnabled(),
                copyToClipboard,
              },

              config: {
                app: VoiceboxApp.CLOUD,
                catalogDatabaseId: data.catalogDatabaseId,
                connectionId,
                connectionIdx,
                defaultSettings: {
                  databaseId: '',
                  model: '',
                  namedGraphs: [DEFAULT_VOICEBOX_NAMED_GRAPH],
                  reasoning: true,
                },
                initialSettings: {
                  databaseId: data.databaseId || '',
                  model: data.model || '',
                  namedGraphs: data.namedGraphs.length
                    ? data.namedGraphs
                    : [DEFAULT_VOICEBOX_NAMED_GRAPH],
                  reasoning: true,
                },
              },

              drawer: {
                isOpen,
                toggleIsOpen: (isOpen) =>
                  dispatch(toggledVoiceboxDrawer(isOpen)),
                usePortal: true,
              },

              links: {
                openLinksInNewTab: true,
                openLinksInPanel: false,
              },

              settingsAside: {},
            },
          }
        : null,
      pageData: null,
    }),
    [
      connection,
      connectionId,
      connectionIdx,
      data,
      dispatch,
      isLoadingConnection,
      isOpen,
    ]
  );
};

export const useVoiceboxContextValue = (): VoiceboxContextValue | null => {
  const pageMatch = useRouteMatch<{ id: string; conversation?: string }>({
    exact: true,
    path: ['/u/:id(\\d+)/voicebox/:conversation?'],
  });
  const pageContext = useVoiceboxPageContextValue(pageMatch?.params.id);

  const kitsMatch = useRouteMatch<{ id: string }>({
    exact: true,
    path: ['/kits/:id([\\w:\\d\\-\\.%]+)'],
  });
  const drawerContext = useVoiceboxDrawerContextValue();

  if (pageMatch) {
    return pageContext;
  }

  if (kitsMatch) {
    return drawerContext;
  }

  return null;
};
