import { Button, Intent, Spinner } from '@blueprintjs/core';
import * as React from 'react';

import { CONTENT_LOADING_SPINNER } from 'src/ui/constants/testIds';

/**
 * Show a loading spinner as the main content.
 */
export const Loading: React.VFC = () => {
  const [refresh, setRefresh] = React.useState<boolean>(false);

  /* istanbul ignore next: this just handles cases when the loading page is stuck */
  React.useEffect(() => {
    const timer = setTimeout(() => setRefresh(true), 15000);
    return () => clearTimeout(timer);
  }, []);

  /* istanbul ignore next: this just handles cases when the loading page is stuck */
  const refreshLink = refresh ? (
    <p>
      Taking too long? try reloading:&nbsp;
      <Button onClick={() => window.location.reload()}>Reload</Button>
    </p>
  ) : null;

  return (
    <div data-testid={CONTENT_LOADING_SPINNER} className="sd-container">
      <div>
        <Spinner intent={Intent.PRIMARY} />
        {refreshLink}
      </div>
    </div>
  );
};
