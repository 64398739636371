import 'normalize.css/normalize.css';
import 'src/ui/styles/index.scss';

import { FocusStyleManager } from '@blueprintjs/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { cookiebotAgent } from 'vet-bones/utils';

import { store } from 'src/ui/app/store';
import { RUNNING_CYPRESS } from 'src/ui/constants/environment';
import { App } from 'src/ui/containers/App';
import { LaunchpadApp } from 'src/ui/containers/launchpad/LaunchpadApp';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { getSdk } from 'src/ui/graph/sdk';
import { isPortalEnabled } from 'src/ui/utils/features';
import { getMainTarget } from 'src/ui/utils/window';

export const main = async () => {
  FocusStyleManager.onlyShowFocusOnTabs();

  const client = new QueryClient();
  const sdk = getSdk(graphQLClient);

  const data = await sdk.settings();
  if (!process.env.TRACKING_DISABLED && !RUNNING_CYPRESS) {
    cookiebotAgent.load();
  }
  // Set some globals for easy lookup maybe deprecate these
  window.friendlyName = data.settings.friendlyName;
  window.baseURL = data.settings.baseURL;
  window.FEATURES = {
    auth0Auth: data.settings.auth0Auth ? 'true' : 'false',
    azureAuth: data.settings.azureAuth ? 'true' : 'false',
    geoaxisAuth: data.settings.geoaxisAuth ? 'true' : 'false',
    googleAuth: data.settings.googleAuth ? 'true' : 'false',
    keycloakAuth: data.settings.keycloakAuth ? 'true' : 'false',
    openidAuth: data.settings.openidAuth ? 'true' : 'false',
    passwordAuth: data.settings.passwordAuth ? 'true' : 'false',
    portal: data.settings.portal ? 'true' : 'false',
    dataMarketplace: data.settings.dataMarketplace ? 'true' : 'false',
    homeFooterLinks: data.settings.homeFooterLinks ? 'true' : 'false',
    knowledgeCatalog: 'false',
  };

  const app = isPortalEnabled() ? <App /> : <LaunchpadApp />;
  const target = getMainTarget();

  ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={client}>
        <BrowserRouter>{app}</BrowserRouter>
      </QueryClientProvider>
    </Provider>,
    target
  );
};

main();
