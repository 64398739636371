import { Card, H2, H3 } from '@blueprintjs/core';
import * as React from 'react';

import { ExternalLink } from 'src/ui/components/ExternalLink';
import { Loading } from 'src/ui/components/Loading';
import { FOOTER_LINKS_CONTAINER } from 'src/ui/constants/testIds';
import {
  ContentList,
  ContentListItem,
} from 'src/ui/containers/landing/ContentList';
import { Welcome } from 'src/ui/containers/landing/Welcome';
import { PartnerHome } from 'src/ui/containers/partner/databricks/PartnerHome';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useGetUserCurrentPartnerConnectionQuery } from 'src/ui/graph/types';
import * as copy from 'src/ui/templates/copy';
import {
  isDataMarketplaceEnabled,
  isHomeFooterLinksEnabled,
} from 'src/ui/utils/features';

export const Databricks: React.VFC = () => {
  const {
    data: partnerInfo,
    isLoading,
  } = useGetUserCurrentPartnerConnectionQuery(graphQLClient);

  if (isLoading || !partnerInfo) {
    return <Loading />;
  }

  const workspaceId =
    partnerInfo.getUserCurrentPartnerConnection?.databricks_workspace_id || '';
  const workspaceUrl =
    partnerInfo.getUserCurrentPartnerConnection?.workspace_url || '';

  const directDatabricksUser =
    partnerInfo.getUserCurrentPartnerConnection &&
    partnerInfo.getUserCurrentPartnerConnection.databricks_workspace_id
      ? copy.components.databrickspartner.cta(
          partnerInfo?.profile?.username,
          workspaceUrl,
          workspaceId
        )
      : '';

  const tutorialList: ContentListItem[] = copy.landingTutorialsList.map(
    (item) => {
      const contentListItem: ContentListItem = {
        name: item.name,
        hyperlink: item.hyperlink,
        description: item.description,
      };
      return contentListItem;
    }
  );

  const documentationList: ContentListItem[] = copy.landingDocumentationList.map(
    (item) => {
      const contentListItem: ContentListItem = {
        name: item.name,
        hyperlink: item.hyperlink,
        description: item.description,
      };
      return contentListItem;
    }
  );

  const toolsList: ContentListItem[] = copy.landingToolsList.map((item) => {
    const contentListItem: ContentListItem = {
      name: item.name,
      hyperlink: item.hyperlink,
      description: item.description,
      internal: item.internal,
    };
    return contentListItem;
  });

  const blogList: ContentListItem[] = [
    ...copy.databricksExtBlogList,
    ...copy.landingBlogList,
  ].map((item) => {
    const contentListItem: ContentListItem = {
      name: item.name,
      hyperlink: item.hyperlink,
      description: item.description,
    };
    return contentListItem;
  });

  const { databricksVideo } = copy;

  const onVideoCardClick = () => {
    window.heap?.track?.(databricksVideo.heapEvent);
  };

  return (
    <div className="sd-databricks">
      <Welcome
        titleText={copy.components.databrickspartner.welcome}
        subText={directDatabricksUser}
      />
      <div
        className={`sd-landing-grid ${
          isDataMarketplaceEnabled() ? '' : 'marketplace-disabled'
        }`}
      >
        <div className="sd-landing-full-width sd-landing-grid-cell">
          <PartnerHome />
        </div>
        <div className="sd-landing-full-width sd-landing-grid-cell">
          <div className="sd-table__header">
            <H2>{copy.components.databrickspartner.gettingStartedHeader}</H2>
          </div>
          <hr className="divider" />
          <ExternalLink
            href={databricksVideo.link}
            decorated={false}
            icon={null}
          >
            <Card
              className="sd-partner-video-card"
              onClick={onVideoCardClick}
              interactive
            >
              <img
                src={databricksVideo.thumbnail}
                alt={databricksVideo.title}
              />
              <div>
                <H3>{databricksVideo.title}</H3>
                {databricksVideo.description}
              </div>
            </Card>
          </ExternalLink>
        </div>
        <div
          data-testid={FOOTER_LINKS_CONTAINER}
          className={`sd-landing-full-width sd-landing-footer-container ${
            isHomeFooterLinksEnabled() ? '' : 'footer-links-disabled'
          }`}
        >
          <div className="sd-landing-grid-cell">
            <ContentList
              header="Tutorials"
              headerIcon="edit"
              list={tutorialList}
            />
          </div>
          <div className="sd-landing-grid-cell">
            <ContentList
              header="Documentation"
              headerIcon="document"
              list={documentationList}
            />
          </div>
          <div className="sd-landing-grid-cell sd-landing-marketplace">
            <ContentList
              header="Knowledge Kits"
              headerIcon="wrench"
              list={toolsList}
            />
          </div>
          <div className="sd-landing-grid-cell">
            <ContentList header="Blogs" headerIcon="manual" list={blogList} />
          </div>
        </div>
      </div>
    </div>
  );
};
