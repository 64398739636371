import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { user as StardogUser } from 'stardog';

import { SelectOption } from 'src/ui/constants/types';
import { StardogCloud } from 'src/ui/graph/types';

export const DEMO_CONNECTION_INDEX = 12345;
export const DEMO_KIT_DB_DEFAULT = 'marketplace';

export enum InstanceFlavors {
  MICRO = 'micro',
  // small is deprecated for new endpoints. New endpoints use small-vbx
  SMALL = 'small',
  SMALL_VBX = 'small-vbx',
  MEDIUM = 'medium',
}

export enum StripeBillingType {
  SUBSCRIPTION = 'subscription',
  METERED = 'metered',
}

export enum ConnectionStatus {
  'PENDING',
  'CONNECTED',
  'INVALID',
  'DISCONNECTED',
}

export const enum ConnectionDialogTab {
  EDIT = 'EDIT',
  NEW = 'NEW',
  SIGN_IN = 'SIGN_IN',
  DELETE = 'DELETE',
}

export enum StardogDBAccess {
  VIEWER = 'viewer',
  COLLABORATOR = 'collaborator',
  SYS_ADMIN = 'sysadmin',
  OWNER = 'owner',
}

export enum StardogDBAccessRoleStatus {
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  EXISTS_WITH_WRONG_PERMISSIONS = 'EXISTS_WITH_WRONG_PERMISSIONS',
  EXISTS_WITH_CORRECT_PERMISSIONS = 'EXISTS_WITH_CORRECT_PERMISSIONS',
}

export const READER_ROLE_BUILTIN = 'reader';
export const CLOUD_ROLE_BUILTIN = 'cloud';

export const StardogDBAccessOptions: SelectOption[] = [
  { label: 'Viewer', value: StardogDBAccess.VIEWER },
  { label: 'Collaborator', value: StardogDBAccess.COLLABORATOR },
  { label: 'SysAdmin', value: StardogDBAccess.SYS_ADMIN },
  { label: 'Owner', value: StardogDBAccess.OWNER },
];

export const ALL_DATABASES = '*';

export const permissionHash = (p: StardogUser.Permission) =>
  `${p.action}:${p.resourceType}:${p.resources.join(',')}`;

const deduplicatePermissions = (
  permissions: StardogUser.Permission[]
): StardogUser.Permission[] => {
  const permissionSet = new Set<string>();
  return permissions.filter((p) => {
    const hash = permissionHash(p);
    if (permissionSet.has(hash)) {
      return false;
    }
    permissionSet.add(hash);
    return true;
  });
};

const expandPermission = (permission: StardogUser.Permission) => {
  const { action, resourceType, resources } = permission;
  if (action === ('ALL' as StardogUser.Action)) {
    return [
      'CREATE',
      'DELETE',
      'READ',
      'WRITE',
      'GRANT',
      'REVOKE',
      'EXECUTE',
    ].map((subAction) => ({
      action: subAction as StardogUser.Action,
      resourceType,
      resources,
    }));
  }

  return [permission];
};

export const expandPermissions = (
  permissions: StardogUser.Permission[]
): StardogUser.Permission[] => {
  return permissions.reduce(
    (acc, permission) => [...acc, ...expandPermission(permission)],
    [] as StardogUser.Permission[]
  );
};

export const getPermissionsForDBAccess = (
  db: string,
  dbAccess: StardogDBAccess
): StardogUser.Permission[] => {
  const dbResource = db === ALL_DATABASES ? '*' : db;
  const redundantPermissions = new Set();
  let permissions: StardogUser.Permission[] = [
    // TODO?: The catalog db might not exist?
    {
      action: 'READ',
      resourceType: 'db',
      resources: ['catalog'],
    },
    // At the moment, the READ action on stored-queries is invalid, and doesn't do anything
    // {
    //   action: 'READ',
    //   resourceType: 'stored-query' as StardogUser.ResourceType,
    //   resources: ['*'],
    // },
    // If named graph security is on, this doesn't work unless you give explicit permissions to each individual named-graph
    // If named graph security is off, this is unnecessary, and doesn't do anything
    // {
    //   action: 'READ',
    //   resourceType: 'named-graph',
    //   resources: [`${dbResource}\\*`],
    // },
    {
      action: 'READ',
      resourceType: 'virtual-graph' as StardogUser.ResourceType,
      resources: ['*'],
    },
    {
      action: 'READ',
      resourceType: 'db',
      resources: [dbResource],
    },
    {
      action: 'READ',
      resourceType: 'metadata',
      resources: [dbResource],
    },
    {
      action: 'READ',
      resourceType: 'icv-constraints',
      resources: [dbResource],
    },
  ];

  permissions = deduplicatePermissions(permissions);

  if (dbAccess === StardogDBAccess.VIEWER) {
    return permissions;
  }

  // These permissions are made redundant after this point by new permissions, so we'll remove the old ones
  redundantPermissions.add(
    permissionHash({
      action: 'READ',
      resourceType: 'virtual-graph' as StardogUser.ResourceType,
      resources: ['*'],
    })
  );
  redundantPermissions.add(
    permissionHash({
      action: 'READ',
      resourceType: 'icv-constraints',
      resources: ['*'],
    })
  );

  permissions = [
    ...permissions.filter(
      (permission) => !redundantPermissions.has(permissionHash(permission))
    ),
    {
      action: 'WRITE',
      resourceType: 'db',
      resources: [dbResource],
    },
    {
      action: 'WRITE',
      resourceType: 'metadata',
      resources: [dbResource],
    },
    {
      action: 'ALL' as StardogUser.Action,
      resourceType: 'virtual-graph' as StardogUser.ResourceType,
      resources: ['*'],
    },
    {
      action: 'ALL' as StardogUser.Action,
      resourceType: 'data-source' as StardogUser.ResourceType,
      resources: ['*'],
    },
    {
      action: 'ALL' as StardogUser.Action,
      resourceType: 'stored-query' as StardogUser.ResourceType,
      resources: ['*'],
    },
    {
      action: 'ALL' as StardogUser.Action,
      resourceType: 'sensitive-properties' as StardogUser.ResourceType,
      resources: ['*'],
    },
    {
      action: 'ALL' as StardogUser.Action,
      resourceType: 'icv-constraints',
      resources: ['*'],
    },
  ];

  permissions = deduplicatePermissions(permissions);

  if (dbAccess === StardogDBAccess.COLLABORATOR) {
    return permissions;
  }

  permissions = [
    ...permissions,
    {
      action: 'ALL' as StardogUser.Action,
      resourceType: '*' as StardogUser.ResourceType,
      resources: [dbResource],
    },
    {
      action: 'ALL' as StardogUser.Action,
      resourceType: 'admin',
      resources: [dbResource],
    },
  ];

  permissions = deduplicatePermissions(permissions);

  if (dbAccess === StardogDBAccess.SYS_ADMIN) {
    return permissions;
  }

  permissions = [
    {
      action: 'ALL' as StardogUser.Action,
      resourceType: '*' as StardogUser.ResourceType,
      resources: ['*'],
    },
  ];

  if (dbAccess === StardogDBAccess.OWNER) {
    return permissions;
  }

  // The access level is not valid
  return [];
};

export const getFullStardogDBRole = (db: string, dbRole: StardogDBAccess) => {
  if (!db) {
    throw new Error('Database name is required');
  }

  if (db !== ALL_DATABASES) {
    return `sd_auto_${db}_${dbRole}`;
  }

  if (dbRole === StardogDBAccess.VIEWER) {
    return READER_ROLE_BUILTIN;
  }

  return `sd_auto_${dbRole}`;
};

/**
 * Simplified connection object, we really only care about the
 * username and endpoint we don't store tokens or passwords.
 */
export type Connection = {
  id?: string;
  index?: number;
  name: string;
  username: string;
  endpoint: string;
  useBrowserAuth?: boolean;
  useSSO?: boolean;
  password?: string;
  token?: string;
  status: ConnectionStatus;
  isStardogCloud?: boolean;
  isStardogFree?: boolean;
  cloud?: StardogCloud;
  isPortal?: boolean;
  isLaunchpad?: boolean;
};

export const disconnected: Connection = {
  id: '',
  name: '',
  username: '',
  endpoint: '',
  useBrowserAuth: false,
  useSSO: false,
  status: ConnectionStatus.DISCONNECTED,
};

export interface ConnectionState {
  connectionIndex: number;
  editIndex: number;
  ownerEmail: string | null;
  tab: ConnectionDialogTab;
  status: ConnectionStatus;
  isBrowserAuthFailure: boolean;
  isDegraded: boolean;
  isUnauthorized: boolean;
}

const initialState = (): ConnectionState => ({
  connectionIndex: -1,
  editIndex: -1,
  ownerEmail: null,
  tab: ConnectionDialogTab.NEW,
  status: ConnectionStatus.DISCONNECTED,
  isBrowserAuthFailure: false,
  isDegraded: false,
  isUnauthorized: false,
});

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setConnectionIndex: (state, action: PayloadAction<number>) => {
      state.connectionIndex = action.payload;
      state.status = ConnectionStatus.CONNECTED;
    },
    clearConnection: (state) => {
      state.connectionIndex = -1;
      state.editIndex = -1;
      state.status = ConnectionStatus.DISCONNECTED;
      state.tab = ConnectionDialogTab.NEW;
    },
    editConnection: (state, action: PayloadAction<number>) => {
      state.editIndex = action.payload;
      state.tab = ConnectionDialogTab.EDIT;
    },
    deleteConnection: (state, action: PayloadAction<number>) => {
      state.editIndex = action.payload;
      state.tab = ConnectionDialogTab.DELETE;
    },
    connectionInvalid: (state, action: PayloadAction<number>) => {
      state.editIndex = action.payload;
      state.status = ConnectionStatus.INVALID;
      state.tab = ConnectionDialogTab.SIGN_IN;
    },
    clearEditIndex: (state) => {
      state.editIndex = -1;
      state.status = ConnectionStatus.DISCONNECTED;
      state.tab = ConnectionDialogTab.NEW;
    },
    setConnectionTab: (state, action: PayloadAction<ConnectionDialogTab>) => {
      state.tab = action.payload;
    },
    setConnectionStatus: (state, action: PayloadAction<ConnectionStatus>) => {
      state.status = action.payload;
    },
    setConnectionBrowserAuthFailed: (state, action: PayloadAction<boolean>) => {
      state.isBrowserAuthFailure = action.payload;
    },
    setConnectionDegraded: (state, action: PayloadAction<boolean>) => {
      state.isDegraded = action.payload;
    },
    setConnectionOwnerEmail: (state, action: PayloadAction<string>) => {
      state.ownerEmail = action.payload;
    },
    setConnectionUnauthorized: (state, action: PayloadAction<boolean>) => {
      state.isUnauthorized = action.payload;
    },
  },
});

export const {
  clearConnection,
  clearEditIndex,
  connectionInvalid,
  deleteConnection,
  editConnection,
  setConnectionBrowserAuthFailed,
  setConnectionIndex,
  setConnectionStatus,
  setConnectionDegraded,
  setConnectionOwnerEmail,
  setConnectionTab,
  setConnectionUnauthorized,
} = connectionSlice.actions;

export const { getInitialState } = connectionSlice;
