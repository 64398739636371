import { Code } from '@blueprintjs/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import graphql from 'react-syntax-highlighter/dist/cjs/languages/prism/graphql';
// These imports are from `dist/cjs` rather than `dist/esm` as described in the
// documentation because of an error with jest test framework.
// See: https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/221
import java from 'react-syntax-highlighter/dist/cjs/languages/prism/java';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript';
import python from 'react-syntax-highlighter/dist/cjs/languages/prism/python';
import shell from 'react-syntax-highlighter/dist/cjs/languages/prism/shell-session';
import sparql from 'react-syntax-highlighter/dist/cjs/languages/prism/sparql';
import turtle from 'react-syntax-highlighter/dist/cjs/languages/prism/turtle';
import prism from 'react-syntax-highlighter/dist/cjs/styles/prism/prism';
import remarkGfm from 'remark-gfm';

import { CopyToClipboardButton } from 'src/ui/components/CopyToClipboardButton';
import { Module, ModuleExt } from 'src/ui/hooks/modules';

// We are using the 'Light' SyntaxHighlighter to reduce the bundle size by about 500k.
// This requires us to explicitly import and register the languages we want to highlight.
// See: https://www.npmjs.com/package/react-syntax-highlighter#light-build
SyntaxHighlighter.registerLanguage('java', java);
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('shell', shell);
SyntaxHighlighter.registerLanguage('sparql', sparql);
SyntaxHighlighter.registerLanguage('graphql', graphql);
SyntaxHighlighter.registerLanguage('turtle', turtle);

interface OwnProps {
  module: Module;
  moduleMeta?: ModuleExt;
}

/* istanbul ignore next */
export const KitReadme: React.VFC<OwnProps> = ({ module }) => {
  return (
    <div>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          img({ alt, ...props }) {
            return (
              <span className="readme-img-container">
                <img className="readme-img" alt={alt} {...props} />
              </span>
            );
          },
          code({ className, children, ref, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <div className="code-block-container">
                <div className="copy-to-clipboard-container">
                  <CopyToClipboardButton textToCopy={String(children)} />
                </div>
                <SyntaxHighlighter
                  ref={ref as React.LegacyRef<SyntaxHighlighter>}
                  style={prism as any}
                  language={match[1]}
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              </div>
            ) : (
              <Code className={className} {...props}>
                {children}
              </Code>
            );
          },
        }}
      >
        {module.readme || ''}
      </ReactMarkdown>
    </div>
  );
};
