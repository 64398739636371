/* eslint-disable prefer-destructuring */
export const graphqlEndpoint = '/api/graphql';

export const enum Environment {
  DEVELOPMENT = 'development',
  TESTING = 'test',
  PRODUCTION = 'production',
}

export const isDevelopment = (
  nodeEnv: string | undefined = process.env.NODE_ENV
) => {
  return !nodeEnv || nodeEnv === Environment.DEVELOPMENT;
};

export const isTesting = (
  nodeEnv: string | undefined = process.env.NODE_ENV
) => {
  return nodeEnv === Environment.TESTING;
};

export const isProduction = (
  nodeEnv: string | undefined = process.env.NODE_ENV
) => {
  return nodeEnv === Environment.PRODUCTION;
};

export const isEU = () => {
  const url: string | undefined = window.location.hostname;
  return url === 'eu-cloud.stardog.com';
};

export const STARDOG_CONNECTION_COOKIE_NAME = 'stardogConnection';
export const DIAGNOSTIC_AUTHORIZATION_HEADER = 'Sample Authorization Header';
export const DIAGNOSTIC_CUSTOM_HEADER = 'Sample Custom Header';
export const DEFAULT_NEXT_URL = '/';
export const STARDOG_APPS = 'https://apps.stardog.com';

// These are placeholder strings that the backend sets in order to signal to the
// UI what the state of a connection is or otherwise static.
export const DEFAULT_CONNECTION_NAME = '__default_connection__';

// Environment Variables: These should all get replaced with static string values thanks to DotenvWebpack and webpack.DefinePlugin
// Note: Object destructuring does not work with DotenvWebpack. Hence the eslint exception for this file
export const NODE_ENV = process.env.NODE_ENV;
export const RUNNING_CYPRESS = !!window.Cypress;
export const STARDOG_ENDPOINT = process.env.STARDOG_ENDPOINT;
export const HEAP_ID = process.env.HEAP_ID;
export const USERFLOW_ID = process.env.USERFLOW_ID;
export const TRACKING_DISABLED = process.env.TRACKING_DISABLED === 'true';
export const COOKIEBOT_ID = process.env.COOKIEBOT_ID;
