import {
  Button,
  Classes,
  ControlGroup,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch } from 'src/ui/app/hooks';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { Selector } from 'src/ui/components/Selector';
import {
  SHARE_ENDPOINT_ACCESS_LEVEL_BUTTON,
  SHARE_ENDPOINT_ACCESS_LEVEL_ITEM,
} from 'src/ui/constants/testIds';
import { SelectOption } from 'src/ui/constants/types';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import {
  ALL_DATABASES,
  StardogDBAccess,
  StardogDBAccessOptions,
  getFullStardogDBRole,
} from 'src/ui/features/connection';
import { queueMessage, queueNotification } from 'src/ui/features/notifications';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useAddInvitationMutation,
} from 'src/ui/graph/types';
import { useSetupUsersAndRolesForInvite } from 'src/ui/hooks/connection';
import { useListDatabases } from 'src/ui/hooks/db';
import { components } from 'src/ui/templates/copy';
import { uniqueId } from 'src/ui/utils/uniqueId';

interface ShareConnectionProps {
  connection: GraphConnection;
  isOpen: boolean;
  onClose?: () => void;
}

interface InvitedUser {
  id: string;
  email?: string;
  emailValid?: boolean;
}

const SHARE_CONNECTION_USER = 'share-connection-user';

export const ShareConnection: React.VFC<ShareConnectionProps> = ({
  connection,
  isOpen,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const {
    data: dbListData,
    isLoading: dbListIsLoading,
  } = useListDatabases(connection, { refetchOnWindowFocus: false });
  const { isLoading: isInviting, mutate: invite } = useAddInvitationMutation(
    graphQLClient,
    {
      onSuccess: (data, vars) => {
        if (!data?.addInvitation?.success) {
          dispatch(
            queueNotification(
              `Error Inviting User ${vars.input.email}: ${data?.addInvitation?.error}`
            )
          );
        }
      },
      onError: (error: any, vars) => {
        const errorMsg = typeof error === 'string' ? error : error?.message;

        dispatch(
          queueNotification(
            `Error Inviting User ${vars.input.email}: ${errorMsg}`
          )
        );
      },
    }
  );
  const dbOptions = React.useMemo<SelectOption[]>(
    () => [
      { label: 'All Databases', value: ALL_DATABASES },
      ...(dbListData?.map((db) => ({
        label: db,
        value: db,
      })) ?? []),
    ],
    [dbListData]
  );

  const [selectedDatabase, setSelectedDatabase] = React.useState<SelectOption>(
    dbOptions[0]
  );
  const [users, setUsers] = React.useState<InvitedUser[]>([
    { id: `${SHARE_CONNECTION_USER}-default`, email: '', emailValid: true },
  ]);

  const [selectedAccess, setSelectedAccess] = React.useState<SelectOption>(
    StardogDBAccessOptions[0]
  );

  const {
    isLoading: isPrepping,
    mutate: prepUsersAndRoles,
  } = useSetupUsersAndRolesForInvite({
    onError: (error: any) => {
      const errorMsg = typeof error === 'string' ? error : error?.message;

      dispatch(queueNotification(`Error Inviting User: ${errorMsg}`));
    },
  });

  const isWorking = isPrepping || isInviting || dbListIsLoading;

  const handleAddUser = () => {
    setUsers([
      ...users,
      { id: uniqueId(SHARE_CONNECTION_USER), email: '', emailValid: true },
    ]);
  };

  const handleRemoveUser = (id: string) => {
    setUsers(users.filter((u) => u.id !== id));
  };

  const resetForm = () => {
    setSelectedDatabase(dbOptions[0]);
    setSelectedAccess(StardogDBAccessOptions[0]);
    setUsers([
      { id: uniqueId(SHARE_CONNECTION_USER), email: '', emailValid: true },
    ]);
  };

  const handleInvite = () => {
    if (users.find((u) => !u.emailValid)) {
      return;
    }

    prepUsersAndRoles(
      {
        connection,
        emails: users.filter((u) => !!u.email).map((u) => u.email as string),
        db: selectedDatabase.value as string,
        accessLevel: selectedAccess.value as StardogDBAccess,
      },
      {
        onSuccess: (data) => {
          data.forEach((user) => {
            const { username } = user;

            if (!username) {
              return;
            }

            invite(
              {
                input: {
                  email: username,
                  endpoint: connection.endpoint,
                  role: getFullStardogDBRole(
                    selectedDatabase.value as string,
                    selectedAccess.value as StardogDBAccess
                  ),
                },
              },
              {
                onSuccess: (data) => {
                  // This gets called after all invites are sent, not after each one
                  if (!data.addInvitation?.success) {
                    return;
                  }

                  dispatch(
                    queueMessage({
                      message: `${users.length} Invitation${
                        users.length > 1 ? 's' : ''
                      } Sent`,
                      intent: Intent.SUCCESS,
                    })
                  );
                  resetForm();
                  onClose?.();
                },
              }
            );
          });
        },
      }
    );
  };

  const actions: FormAction[] = [
    {
      text: components.shareConnection.closeButton,
      onClick: onClose,
    },
    makeSubmitAction(components.shareConnection.inviteButton, isWorking),
  ];

  return (
    <Dialog
      className="sd-dialog-share-connection"
      isOpen={isOpen}
      onClose={onClose}
      title={components.shareConnection.title}
      icon={IconNames.NEW_PERSON}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>{components.shareConnection.description}</p>
        <FormGroup label={components.shareConnection.usersLabel}>
          {users.map((user, index) => (
            <ControlGroup key={user.id}>
              <Button
                icon={IconNames.CROSS}
                minimal
                onClick={() => handleRemoveUser(user.id)}
                title={components.shareConnection.removeUserTooltip}
              />
              <InputGroup
                type="email"
                placeholder={components.shareConnection.userPlaceholder}
                // TODO: Use this once PLAT-4307 is done "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                pattern="^[a-z_]{1}[-_a-z0-9\.]*@[-_a-z0-9\.]+\.[a-z]{2,}$"
                intent={user.emailValid ? Intent.NONE : Intent.DANGER}
                value={user.email}
                onChange={(event) => {
                  const newUsers = [...users];
                  newUsers[index] = {
                    ...newUsers[index],
                    email: event.target.value,
                    emailValid: event.target.validity.valid,
                  };
                  setUsers(newUsers);
                }}
                fill
              />
            </ControlGroup>
          ))}
          <Button
            className="share-dialog-add-user-button"
            icon={IconNames.PLUS}
            text={components.shareConnection.addUserButton}
            onClick={handleAddUser}
          />
        </FormGroup>
        <FormGroup label={components.shareConnection.accessLevelLabel}>
          <Selector
            buttonText={selectedAccess.label || 'Select Access Level'}
            items={StardogDBAccessOptions}
            itemTestId={SHARE_ENDPOINT_ACCESS_LEVEL_ITEM}
            selectedItem={selectedAccess}
            testId={SHARE_ENDPOINT_ACCESS_LEVEL_BUTTON}
            onSelect={setSelectedAccess}
            disabled={isWorking}
            filterable={false}
          />
        </FormGroup>
        <FormGroup label={components.shareConnection.databaseLabel}>
          <Selector
            buttonText={selectedDatabase.label || 'Select Database'}
            items={dbOptions}
            selectedItem={selectedDatabase}
            onSelect={setSelectedDatabase}
            disabled={isWorking}
            testId="share-connection-database-selector"
          />
        </FormGroup>
      </div>
      <FormActions
        actions={actions}
        handleSubmit={handleInvite}
        isSubmitDisabled={isWorking}
        wide={false}
      />
    </Dialog>
  );
};
