import { ConnectionOptions, Connection as StardogConnection } from 'stardog';
import { BaseConnection, getStardogConnection } from 'vet-bones/utils';

export interface MarketplaceOptions extends ConnectionOptions {
  database: string;
}

export interface ConnectionFactory {
  connection: StardogConnection;

  connectionIndex: number | undefined;

  options: MarketplaceOptions;
}

export const create = (
  idx: number | undefined,
  opts: MarketplaceOptions
): ConnectionFactory =>
  new DefaultConnectionFactory(idx, opts, getStardogConnection);

export const createWithConnection = (
  conn: StardogConnection,
  idx: number | undefined,
  opts: MarketplaceOptions
): ConnectionFactory => new DefaultConnectionFactory(idx, opts, () => conn);

class DefaultConnectionFactory implements ConnectionFactory {
  public connection: StardogConnection;

  public connectionIndex: number | undefined;

  public options: MarketplaceOptions;

  constructor(
    index: number | undefined,
    options: MarketplaceOptions,
    connProvider: (conn: BaseConnection) => StardogConnection
  ) {
    this.connection = connProvider(options);
    this.connectionIndex = index;
    this.options = options;
  }
}
