import { Icon, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { Connection } from 'src/ui/features/connection';
import { copy } from 'src/ui/templates/copy';

import { useHealthCheck } from '../../hooks/diagnostic';

interface OwnProps {
  connection: Connection | undefined | null;
  isLoading: boolean;
}

/* istanbul ignore next: too hard to test this right now */
export const DiagnosticCheckHealth: React.VFC<OwnProps> = ({
  connection,
  isLoading,
}) => {
  const { isLoading: isValidating, mutate: validate } = useHealthCheck();
  const [connectionValid, setConnectionValid] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  // This effect will run when the `connection` changes or when `isLoading`
  // changes. `isLoading` is set by the diagnostic api call and can be retried
  // by the user, aka after adding the correct user. So we need to re-run
  // this check to see if the user is valid.
  React.useEffect(() => {
    if (!connection) {
      return;
    }

    validate(connection.endpoint, {
      onError: (error) => {
        setConnectionValid(false);
        setErrorMessage(error.message);
      },
      onSuccess: () => setConnectionValid(true),
    });
  }, [connection, isLoading]);

  if (!connection) {
    return null;
  }

  const icon = connectionValid ? IconNames.TICK_CIRCLE : IconNames.ERROR;
  const intent = connectionValid ? Intent.SUCCESS : Intent.DANGER;
  const message = connectionValid ? 'Alive' : errorMessage;
  const largeMessage = connectionValid
    ? copy.diagnostic.connectionValid
    : copy.diagnostic.connectionInvalid;
  const serverInfo = `Service: ${connection.endpoint} ${message}`;

  if (isValidating) {
    return (
      <div className="diagnostic-check">
        <Spinner size={SpinnerSize.SMALL}>Loading...</Spinner>
      </div>
    );
  }

  return (
    <div className="diagnostic-check">
      <Icon icon={icon} intent={intent} />
      <span>{serverInfo}</span>
    </div>
  );
};
