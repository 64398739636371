import { MenuItem, PopperBoundary, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
  IItemRendererProps,
  ItemPredicate,
  ItemRenderer,
  MultiSelect,
} from '@blueprintjs/select';
import classNames from 'classnames';
import * as React from 'react';
import { alphaSort } from 'vet-bones/utils';

import { SelectOption } from 'src/ui/constants/types';
import { getRegExpFromString } from 'src/ui/utils/getRegExpFromString';

const TagMultiSelect = MultiSelect.ofType<SelectOption>();

const itemPredicate: ItemPredicate<SelectOption> = (query, item) =>
  getRegExpFromString(query, 'i').test(item.label);

interface TagMultiSelectorProps {
  className?: string;
  itemClassName?: string;
  itemMultiline?: boolean;
  items: readonly SelectOption[];
  noResultsText?: string;
  onChange: (newList: SelectOption[]) => any;
  popoverBoundary?: PopperBoundary;
  popoverClassName?: string;
  popoverPosition?: Position;
  selectedItems: SelectOption[];
  placeholder?: string;
  fill?: boolean;
  disabled?: boolean;
}

export const TagMultiSelector = ({
  className,
  itemClassName,
  itemMultiline,
  items,
  noResultsText = 'No Results',
  onChange,
  popoverBoundary,
  popoverClassName,
  popoverPosition,
  selectedItems,
  placeholder,
  fill,
  disabled = false,
}: TagMultiSelectorProps) => {
  const [query, setQuery] = React.useState('');
  const [activeItem, setActiveItem] = React.useState<SelectOption | null>(null);

  const onItemSelect = (opt: SelectOption) => {
    let isAlreadySelected = false;
    const newSelectedItems = selectedItems.filter((item) => {
      if (item.value === opt.value) {
        isAlreadySelected = true;
        return false;
      }
      return true;
    });
    if (!isAlreadySelected) {
      newSelectedItems.push(opt);
    }

    onChange(newSelectedItems.sort(alphaSort((item) => item.label)));
  };

  const onItemRemove = (opt: SelectOption) => {
    const newSelectedItems = selectedItems.filter((item) => {
      if (item.value === opt.value) {
        return false;
      }
      return true;
    });

    onChange(newSelectedItems.sort(alphaSort((item) => item.label)));
  };

  const itemRenderer: ItemRenderer<SelectOption> = React.useCallback(
    (item: SelectOption, { handleClick, modifiers }: IItemRendererProps) =>
      modifiers.matchesPredicate ? (
        <MenuItem
          className={itemClassName}
          key={item.value}
          text={item.label}
          icon={
            selectedItems.some(({ value }) => value === item.value)
              ? IconNames.TICK
              : IconNames.BLANK
          }
          onClick={handleClick}
          active={modifiers.active}
          multiline={itemMultiline}
        />
      ) : null,
    [itemClassName, itemMultiline, selectedItems]
  );

  return (
    <TagMultiSelect
      activeItem={activeItem}
      className={classNames('sd-selector', {
        [className as any]: Boolean(className),
      })}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      tagRenderer={(item) => item.label}
      items={items as SelectOption[]}
      selectedItems={selectedItems}
      placeholder={placeholder}
      noResults={
        <MenuItem
          className="sd-menu-item-no-results"
          text={noResultsText}
          disabled
        />
      }
      onActiveItemChange={(item) => setActiveItem(item)}
      onItemSelect={(value) => onItemSelect(value)}
      onRemove={(value) => onItemRemove(value)}
      onQueryChange={(value) => setQuery(value)}
      popoverProps={{
        boundary: popoverBoundary,
        minimal: true,
        position: popoverPosition,
        popoverClassName,
      }}
      tagInputProps={{
        disabled,
      }}
      query={query}
      fill={fill}
    />
  );
};
