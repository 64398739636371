import {
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Position,
  Toaster,
} from '@blueprintjs/core';
import React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import {
  DATABRICKS_CONNECTION_NAME,
  DATABRICKS_CONNECTION_SELECTOR,
} from 'src/ui/constants/testIds';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import { queueMessage } from 'src/ui/features/notifications';
import { PartnerConnectionOperation } from 'src/ui/features/partnerconnection';
import { closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  PartnerConnectionDetail,
  UpdatePartnerConnectionInput,
  useUpdatePartnerConnectionMutation,
} from 'src/ui/graph/types';
import {
  useCreateDatabricksJdbcConnection,
  useRemoveDatabricksJdbcDataSource,
} from 'src/ui/hooks/connection';
import {
  InstallDatabricksStarterKitInput,
  useInstallDatabricksStarterKits,
} from 'src/ui/hooks/databricksstarterkit/dbcore';
import { useFormHandler } from 'src/ui/hooks/forms';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import {
  partnerConnectionSelectedOperation,
  selectedPartnerConnection,
} from 'src/ui/selectors/connection';
import * as copy from 'src/ui/templates/copy';

type OwnProps = {
  connections: GraphConnection[];
};

type PartnerConnectionFormData = {
  databricksConnectionName: string;
  stardogServer: string;
};

export const CreateEditDataSourceForm: React.VFC<OwnProps> = ({
  connections,
}) => {
  const dispatch = useAppDispatch();
  const selectedOperation = useAppSelector(partnerConnectionSelectedOperation);
  const [isInstanceAllocating, setIsInstanceAllocating] = React.useState(false);
  const partnerConnectionDetail: PartnerConnectionDetail = useAppSelector(
    selectedPartnerConnection
  );
  const databricksJdbcName =
    partnerConnectionDetail.databricks_connection_name ||
    'databricks_jdbc_datasource';
  const savedStardogServer = partnerConnectionDetail.stardog_server_connection
    ? partnerConnectionDetail.stardog_server_connection.name
    : (connections[0] ? connections[0].name : '') || '';

  const {
    formData,
    setFormData,
    handleTextFieldChange,
  } = useFormHandler<PartnerConnectionFormData>({
    databricksConnectionName: databricksJdbcName,
    stardogServer: savedStardogServer,
  });
  const isCreateTabSelected =
    selectedOperation === PartnerConnectionOperation.CREATE;
  const onError = useOnError();
  const onMutate = useOnMutate();

  const {
    isLoading: updatingPartnerConnection,
    mutate: updatePartnerConnection,
  } = useUpdatePartnerConnectionMutation(graphQLClient, {
    onError,
    onMutate,
  });

  const {
    mutate: installDatabricksStarterKitsMutation,
  } = useInstallDatabricksStarterKits({
    onError: (error: any) => {
      dispatch(
        queueMessage({
          intent: Intent.WARNING,
          message: copy.components.databrickspartner.starterkit.install.failed
            .concat('-')
            .concat(error.message),
        })
      );
      dispatch(closedDialog());
    },
    onSuccess: () => {
      dispatch(
        queueMessage({
          intent: Intent.SUCCESS,
          message:
            copy.components.databrickspartner.starterkit.install.complete,
        })
      );
      dispatch(
        queueMessage({
          intent: Intent.SUCCESS,
          message:
            copy.components.databrickspartner.datasource.creation.completed,
        })
      );
      dispatch(closedDialog());
    },
  });

  const installStarterKits = (
    connection: GraphConnection,
    jdbcUrl: string,
    personalAccessToken: string
  ) => {
    const starterKitInput: InstallDatabricksStarterKitInput = {
      connection,
      jdbcUrl,
      personalAccessToken,
    };

    Toaster.create({
      className: 'recipe-toaster',
      position: Position.BOTTOM_RIGHT,
    }).show({
      timeout: 1000,
      intent: 'primary',
      message: copy.components.marketplace.install.started,
    });
    installDatabricksStarterKitsMutation(starterKitInput);
  };

  const {
    isLoading: creatingDatabricksConnection,
    mutate: createJdbcConnectionMutation,
  } = useCreateDatabricksJdbcConnection({
    onError: (error: any) => {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: `Failed to create connection - ${error.message}`,
        })
      );
    },
    onSuccess: () => {
      const stardogConnectionFound = connections.find(
        (connection) => formData.stardogServer === connection.name
      );
      const input: UpdatePartnerConnectionInput = {
        connection_id: partnerConnectionDetail.connection_id || '',
        stardog_connection_id: stardogConnectionFound?.id || '',
        databricks_connection_name: formData.databricksConnectionName,
      };
      updatePartnerConnection({ input });
      installStarterKits(
        stardogConnectionFound || connections[0],
        partnerConnectionDetail.jdbc_url || '',
        partnerConnectionDetail.databricks_personal_token_id || ''
      );
    },
  });

  const {
    mutate: removeJdbcConnectionMutation,
  } = useRemoveDatabricksJdbcDataSource({
    onError: (error: any) => {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: copy.components.databrickspartner.removeConnectionFailMessage(
            error.message
          ),
        })
      );
    },
  });

  const removePreviousConfiguredDataSource = () => {
    const stardogConnectionFound = connections.find(
      (connection) =>
        partnerConnectionDetail.stardog_server_connection?.name ===
        connection.name
    );
    removeJdbcConnectionMutation({
      connection: stardogConnectionFound || connections[0],
      databricksConnectionName:
        partnerConnectionDetail.databricks_connection_name || '',
    });
  };
  const handleCreate = (evt: React.FormEvent) => {
    evt.preventDefault();
    /* istanbul ignore next : Negative test case where partnerConnectionDetail.stardog_server_connection is undefined is skipped*/
    if (
      !isCreateTabSelected &&
      partnerConnectionDetail.databricks_connection_name ===
        formData.databricksConnectionName &&
      partnerConnectionDetail.stardog_server_connection?.name ===
        formData.stardogServer
    ) {
      dispatch(closedDialog());
      return;
    }
    if (!isCreateTabSelected) {
      removePreviousConfiguredDataSource();
    }
    const jdbcUrl = partnerConnectionDetail.jdbc_url
      ? partnerConnectionDetail.jdbc_url
      : '';
    const personalAccessToken = partnerConnectionDetail.databricks_personal_token_id
      ? partnerConnectionDetail.databricks_personal_token_id
      : '';
    const stardogConnectionFound = connections.find(
      (connection) => formData.stardogServer === connection.name
    );
    const clusterId = partnerConnectionDetail.cluster_id
      ? partnerConnectionDetail.cluster_id
      : '';

    dispatch(
      queueMessage({
        intent: Intent.PRIMARY,
        message: copy.components.databrickspartner.datasource.creation.started,
      })
    );

    createJdbcConnectionMutation({
      connection: stardogConnectionFound || connections[0],
      jdbcUrl,
      databricksConnectionName: formData.databricksConnectionName,
      personalAccessToken,
      clusterId,
    });
  };

  const actions: FormAction[] = [
    makeSubmitAction(
      isCreateTabSelected
        ? copy.components.databrickspartner.connectionMenu.create
        : copy.components.databrickspartner.update,
      creatingDatabricksConnection || updatingPartnerConnection
    ),
  ];
  const isDataSourceConfigured =
    partnerConnectionDetail.is_configured_resources || false;

  const handleDbOnChange = (evt: any) => {
    const { name, value } = evt.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    const stardogConnectionFound = connections.find(
      (connection) => value === connection.name
    );
    setIsInstanceAllocating(stardogConnectionFound?.isAllocating || false);
  };

  const submitTooltipText = isCreateTabSelected
    ? copy.components.databrickspartner.connectionMenu.create
    : copy.components.databrickspartner.update;

  const connectionOptions = connections
    ? connections.map((conn) => <option key={conn.name}>{conn.name}</option>)
    : undefined;

  const onKeyDownEventHandler = (e: React.KeyboardEvent) => {
    if (e.key.match(/^[0-9A-Za-z_]+$/) === null) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <form className="sd-partner-form" onSubmit={handleCreate}>
      <div className="sd-partner-form__body">
        <FormGroup
          label={copy.components.databrickspartner.stardogServer}
          labelFor="stardogServer"
        >
          <HTMLSelect
            id={DATABRICKS_CONNECTION_SELECTOR}
            data-testid={DATABRICKS_CONNECTION_SELECTOR}
            name="stardogServer"
            value={formData.stardogServer}
            onChange={handleDbOnChange}
            fill
          >
            {connectionOptions}
          </HTMLSelect>
        </FormGroup>
        <FormGroup
          label={copy.components.databrickspartner.databricksConnectionLabel}
          labelFor={DATABRICKS_CONNECTION_NAME}
        >
          <InputGroup
            id={DATABRICKS_CONNECTION_NAME}
            data-testid={DATABRICKS_CONNECTION_NAME}
            name="databricksConnectionName"
            value={formData.databricksConnectionName}
            onChange={handleTextFieldChange}
            onKeyDown={(e) => onKeyDownEventHandler(e)}
          />
        </FormGroup>
        <input type="submit" hidden />
      </div>
      <FormActions
        actions={actions}
        handleSubmit={handleCreate}
        isSubmitDisabled={
          creatingDatabricksConnection ||
          updatingPartnerConnection ||
          (isDataSourceConfigured && isCreateTabSelected) ||
          (!isCreateTabSelected && !isDataSourceConfigured) ||
          isInstanceAllocating
        }
        submitTooltipText={
          isInstanceAllocating
            ? copy.components.databrickspartner.stardogInstanceGettingAllocated
            : submitTooltipText
        }
      />
    </form>
  );
};
