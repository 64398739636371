import {
  Button,
  Callout,
  Dialog,
  DialogBody,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  CANCEL_CLOUD_DIALOG_CANCEL_CLOUD_BUTTON,
  CANCEL_CLOUD_DIALOG_CONFIRMATION_INPUT,
} from 'src/ui/constants/testIds';
import { AnyFunc } from 'src/ui/constants/types';
import { InstanceFlavors } from 'src/ui/features/connection';
import { queueMessage } from 'src/ui/features/notifications';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  CancelCloudInput,
  Connection as GraphConnection,
  useCancelCloudMutation,
} from 'src/ui/graph/types';
import { useOnError } from 'src/ui/hooks/graph';
import { components } from 'src/ui/templates/copy';

type CancelCloudDialogProps = {
  connection: GraphConnection;
  isOpen: boolean;
  onClose: AnyFunc;
  onSuccess: AnyFunc;
};

export const CancelCloudDialog: React.VFC<CancelCloudDialogProps> = ({
  connection,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const CANCEL = 'CANCEL';
  const { errors: cancellationErrors } = components.cancelCloudDialog;
  const isEssentials =
    connection.cloud?.flavor?.name === InstanceFlavors.SMALL_VBX ||
    connection.cloud?.flavor?.name === InstanceFlavors.SMALL;
  const isFree = connection?.isStardogFree;

  const dispatch = useAppDispatch();
  const [cancelInput, setCancelInput] = React.useState<string>('');

  const onError = useOnError();
  const { mutate: cancelCloud } = useCancelCloudMutation(graphQLClient, {
    onError,
    onSuccess: (data) => {
      if (data.cancelCloud?.success) {
        dispatch(
          queueMessage({
            intent: Intent.SUCCESS,
            message: `Cloud ${connection.name} deleted`,
          })
        );
        onSuccess();
        return;
      }
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: data.cancelCloud?.error || cancellationErrors.generic,
        })
      );
    },
  });

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    if (!isFree && !isEssentials) {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: cancellationErrors.cloudTypeCannotBeCancelled,
        })
      );
      return;
    }

    if (connection.username !== connection.cloud?.owner?.username) {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: cancellationErrors.cloudOwnerCanOnlyDelete,
        })
      );
      return;
    }

    if (!connection.cloud?.id) {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: cancellationErrors.generic,
        })
      );
      return;
    }

    const input: CancelCloudInput = {
      cloud_id: connection.cloud.id,
      connection_id: connection.id,
    };
    cancelCloud({ input });
  };

  const calloutText = () => {
    const { endpoint } = connection;

    if (isFree) {
      return components.cancelCloudDialog.callout.free(endpoint);
    }
    if (isEssentials) {
      return components.cancelCloudDialog.callout.essentials(endpoint);
    }
    return components.cancelCloudDialog.callout.default(endpoint);
  };

  return (
    <Dialog
      canOutsideClickClose
      isOpen={isOpen}
      onClose={(e) => {
        setCancelInput('');
        onClose(e);
      }}
      className="sd-dialog-cancel-cloud"
      canEscapeKeyClose
      title={components.cancelCloudDialog.title}
      icon={IconNames.WARNING_SIGN}
    >
      <DialogBody className="sd-dialog-cancel-cloud__body">
        <Callout intent={Intent.DANGER} icon={null}>
          {calloutText()}
        </Callout>
        <div className="sd-dialog-cancel-cloud__input_container">
          <FormGroup
            label={components.cancelCloudDialog.cancellationConfirmationLabel}
            labelFor="deletion-input-confirmation"
            labelInfo="(required)"
          >
            <InputGroup
              data-testid={CANCEL_CLOUD_DIALOG_CONFIRMATION_INPUT}
              id="deletion-input-confirmation"
              placeholder={CANCEL}
              value={cancelInput}
              onChange={(e) => setCancelInput(e.target.value)}
            />
          </FormGroup>
          <Button
            data-testid={CANCEL_CLOUD_DIALOG_CANCEL_CLOUD_BUTTON}
            minimal
            outlined
            intent={Intent.DANGER}
            text={components.cancelCloudDialog.cancelConfirmationButton}
            onClick={handleSubmit}
            disabled={cancelInput !== CANCEL}
          />
        </div>
      </DialogBody>
    </Dialog>
  );
};
