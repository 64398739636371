import { RootState } from 'src/ui/app/store';
import { ConnectionStatus } from 'src/ui/features/connection';

export const editIndexSelector = (state: RootState) =>
  state.connection.editIndex;

export const isConnectionStatus = (status: ConnectionStatus) => (
  state: RootState
) => state.connection.status === status;

export const connectionDialogTab = (state: RootState) => state.connection.tab;

export const partnerConnectionSelectedOperation = (state: RootState) =>
  state.partnerconnection.operation;

export const selectedPartnerConnection = (state: RootState) =>
  state.partnerconnection.partnerConnectionDetail;

export const isBrowserAuthFailureSelector = (state: RootState) =>
  state.connection.isBrowserAuthFailure;

export const isDegradedSelector = (state: RootState) =>
  state.connection.isDegraded;

export const isUnauthorizedSelector = (state: RootState) =>
  state.connection.isUnauthorized;
