import { Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import React from 'react';
import { getIntentClassName } from 'vet-bones/utils';

import { FormAction } from 'src/ui/components/FormActions';

export const makeSubmitAction = (
  text: string,
  working: boolean
): FormAction => {
  return {
    icon: working ? (
      <Spinner
        className={getIntentClassName('sd-connection')}
        size={SpinnerSize.SMALL}
      />
    ) : undefined,
    intent: Intent.PRIMARY,
    isSubmit: true,
    text,
  };
};
