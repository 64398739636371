import { FormGroup, InputGroup } from '@blueprintjs/core';
import * as React from 'react';

import type { DataSourceSubFormProps } from 'src/ui/containers/dialogs/DataSourceForm';
import * as copy from 'src/ui/templates/copy';

export const DataSourceSparqlForm: React.VFC<DataSourceSubFormProps> = ({
  dataSource,
  formHandler,
  disabled,
}) => {
  const { formData, handleTextFieldChange } = formHandler;

  return (
    <>
      <FormGroup
        labelFor="data-source-sparql-name"
        label={copy.components.dataSourceDialog.form.name}
        helperText={copy.components.dataSourceDialog.form.nameHelp}
      >
        <InputGroup
          id="data-source-sparql-name"
          value={dataSource.name}
          fill
          disabled
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.sparql.url}
        labelFor="data-source-sparql-url"
      >
        <InputGroup
          id="data-source-sparql-url"
          value={formData['sparql.url']}
          disabled
          name="sparql.url"
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.sparql.username}
        labelFor="data-source-sparql-username"
      >
        <InputGroup
          autoFocus
          id="data-source-sparql-username"
          name="sparql.username"
          autoComplete="username"
          value={formData['sparql.username']}
          disabled={disabled}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.sparql.password}
        labelFor="data-source-sparql-password"
      >
        <InputGroup
          id="data-source-sparql-password"
          name="sparql.password"
          type="password"
          autoComplete="password"
          disabled={disabled}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
    </>
  );
};
