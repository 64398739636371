import {
  Classes,
  Divider,
  FormGroup,
  InputGroup,
  NumericInput,
  TextArea,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useIsMounted } from 'vet-bones/utils/hooks';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { CopyToClipboardButton } from 'src/ui/components/CopyToClipboardButton';
import { Dialog } from 'src/ui/components/Dialog';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import {
  MANAGE_API_TOKEN_CREATE_DIALOG,
  MANAGE_API_TOKEN_CREATE_EXPIRES_INPUT,
  MANAGE_API_TOKEN_CREATE_NAME_INPUT,
  MANAGE_API_TOKEN_CREATE_SECRET,
} from 'src/ui/constants/testIds';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useCreateApiTokenMutation } from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import * as copy from 'src/ui/templates/copy';

export const CreateApiTokenDialog: React.VFC = () => {
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const onMutate = useOnMutate();
  const onError = useOnError();
  const queryClient = useQueryClient();

  const dialog = useAppSelector((state) => state.ui.dialog);
  let isOpen = false;
  let appId: string | undefined;
  if (dialog && dialog.type === DialogType.CREATE_API_TOKEN && dialog.data) {
    isOpen = true;
    appId = dialog.data.appId;
  }

  const [name, setName] = React.useState('');
  const trimmedName = name.trim();
  const [expiresDays, setExpiresDays] = React.useState(90);
  const [secret, setSecret] = React.useState('');

  const { isLoading, mutate: createApiToken } = useCreateApiTokenMutation(
    graphQLClient,
    {
      onMutate,
      onError,
      onSuccess: async (data) => {
        if (!data.createApiToken?.secret) {
          return;
        }
        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }
        await queryClient.refetchQueries(['listApiTokens']);
        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }
        setSecret(data.createApiToken.secret);
      },
    }
  );

  const isSubmitDisabled = !trimmedName || isLoading;

  const handleClose = () => {
    dispatch(closedDialog());
    setName('');
    setExpiresDays(90);
    setSecret('');
  };

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    createApiToken({
      input: {
        app_id: appId,
        expires_days: expiresDays,
        name: trimmedName,
      },
    });
  };

  const didCreate = Boolean(secret);

  const actions: FormAction[] = didCreate
    ? [
        {
          text: copy.components.manageApiTokens.createApiTokenDialog.close,
          onClick: () => handleClose(),
        },
      ]
    : [
        {
          text: copy.components.manageApiTokens.createApiTokenDialog.cancel,
          onClick: () => handleClose(),
        },
        makeSubmitAction(
          copy.components.manageApiTokens.createApiTokenDialog.submit,
          isLoading
        ),
      ];

  let submitTooltipText = '';
  if (!trimmedName) {
    submitTooltipText =
      copy.components.manageApiTokens.createApiTokenDialog.form.nameRequired;
  }

  return (
    <Dialog
      className="sd-dialog-create-api-token"
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      icon={IconNames.KEY}
      isOpen={isOpen}
      onClose={handleClose}
      title={
        appId
          ? copy.components.manageApiTokens.createApiTokenDialog.title.voicebox
          : copy.components.manageApiTokens.createApiTokenDialog.title.user
      }
    >
      <div
        className={Classes.DIALOG_BODY}
        data-testid={MANAGE_API_TOKEN_CREATE_DIALOG}
      >
        <form onSubmit={handleSubmit}>
          <FormGroup
            label={
              copy.components.manageApiTokens.createApiTokenDialog.form.name
            }
          >
            <InputGroup
              autoFocus
              data-testid={MANAGE_API_TOKEN_CREATE_NAME_INPUT}
              disabled={didCreate || isLoading}
              onChange={(evt) => setName(evt.target.value)}
              value={name}
            />
          </FormGroup>
          <FormGroup
            label={
              copy.components.manageApiTokens.createApiTokenDialog.form
                .expiresDays
            }
          >
            <NumericInput
              allowNumericCharactersOnly
              data-testid={MANAGE_API_TOKEN_CREATE_EXPIRES_INPUT}
              disabled={didCreate || isLoading}
              fill
              max={360}
              min={1}
              minorStepSize={null}
              onValueChange={(value) =>
                setExpiresDays((prev) =>
                  Number.isNaN(value) ? prev : Math.max(1, Math.min(360, value))
                )
              }
              value={`${expiresDays}`}
            />
          </FormGroup>

          <input type="submit" hidden />
        </form>
        {secret ? (
          <>
            <Divider />
            <FormGroup
              label={
                copy.components.manageApiTokens.createApiTokenDialog.form.token
              }
            >
              <div className="sd-create-api-token-secret">
                <TextArea
                  data-testid={MANAGE_API_TOKEN_CREATE_SECRET}
                  fill
                  growVertically
                  readOnly
                  value={secret}
                />
                <CopyToClipboardButton textToCopy={secret} />
              </div>
              <p className={Classes.TEXT_SMALL}>
                {
                  copy.components.manageApiTokens.createApiTokenDialog.form
                    .tokenHelp
                }
              </p>
            </FormGroup>
          </>
        ) : null}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <FormActions
            actions={actions}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isSubmitDisabled}
            submitTooltipText={submitTooltipText}
          />
        </div>
      </div>
    </Dialog>
  );
};
