import { Button, NonIdealState } from '@blueprintjs/core';
import * as React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';

import { Loading } from 'src/ui/components/Loading';
import { useProfile } from 'src/ui/hooks/connection';
import { copy } from 'src/ui/templates/copy';

interface OwnProps extends RouteProps {
  children: React.ReactElement;
  path: string;
  adminOnly?: boolean;
  staffOnly?: boolean;
  voiceboxOnly?: boolean;
  voiceboxAPIAccessEnabled?: boolean;
}

/**
 * A wrapper for <Route> that redirects to the login Route if not authenticated.
 */
export const PrivateRoute: React.VFC<OwnProps> = ({
  children,
  path,
  adminOnly = false,
  staffOnly = false,
  voiceboxOnly = false,
  voiceboxAPIAccessEnabled = false,
  ...rest
}) => {
  const { isLoading, isError, data, refetch, remove } = useProfile();
  const isConnected = data && data.profile?.is_authenticated;
  const isSuperUser = data && data.profile?.is_superuser;
  const isStaff = data && data.profile?.is_staff;
  const isVoiceboxEnabledUser = data && data.profile?.is_voicebox_enabled;
  const isVoiceboxAPIAccessEnabled =
    data && data.profile?.is_voicebox_api_access_enabled;

  const reload = () => {
    remove();
    refetch({ cancelRefetch: true });
  };

  const action = (
    <Button intent="primary" onClick={() => reload()}>
      {copy.login.retry}
    </Button>
  );

  const renderFunc = (routeProps: RouteComponentProps) => {
    if (isLoading) {
      return <Loading />;
    }

    if (isError) {
      return (
        <div className="sd-container">
          <NonIdealState
            icon="error"
            title="Error"
            description={copy.errors.connectionFailed}
            action={action}
          />
        </div>
      );
    }

    if (adminOnly && !isSuperUser) {
      // If this is admin only we don't need to do handle un-authenticated
      // use case because this should only be admins anyway they should login first.
      // Otherwise this would end up in a login redirect loop if a non-admin visits.
      return <Redirect to="/" />;
    }

    if (staffOnly && !isStaff) {
      // If this is admin only we don't need to do handle un-authenticated
      // use case because this should only be admins anyway they should login first.
      // Otherwise this would end up in a login redirect loop if a non-admin visits.
      return <Redirect to="/" />;
    }

    if (voiceboxOnly && !isVoiceboxEnabledUser) {
      // Do not show the voicebox route for non-voicebox enabled users
      return <Redirect to="/" />;
    }

    if (voiceboxAPIAccessEnabled && !isVoiceboxAPIAccessEnabled) {
      // Do not show the voicebox route users who do not have access to voicebox APIs
      return <Redirect to="/" />;
    }

    // Handle redirects after a successful login
    const next = routeProps.location?.pathname || '/';
    const search = routeProps.location?.search || '';
    const redirectTo = {
      pathname: '/login',
      search: `?next=${next}${search}`,
    };
    return isConnected ? children : <Redirect to={redirectTo} />;
  };

  return <Route {...rest} path={path} render={renderFunc} />;
};
