import {
  Button,
  CardProps,
  Intent,
  Menu,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import * as React from 'react';

import { useAppDispatch } from 'src/ui/app/hooks';
import { deleteConnection, editConnection } from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useGetStripeSessionUrlMutation,
} from 'src/ui/graph/types';
import { useSetConnectionIndex } from 'src/ui/hooks/connection';
import * as copy from 'src/ui/templates/copy';
import { redirectToUrl } from 'src/ui/utils/window';

interface OwnProps extends CardProps {
  connection: GraphConnection;
  onCancelCloud: (c: GraphConnection) => void;
}

export const ConnectionRow: React.VFC<OwnProps> = ({
  connection,
  onClick,
  onCancelCloud,
}) => {
  const dispatch = useAppDispatch();
  const setConnectionIndex = useSetConnectionIndex();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const { mutate } = useGetStripeSessionUrlMutation(graphQLClient, {
    onSuccess: (data) => {
      redirectToUrl(data?.getStripeSessionUrl?.url || '/');
    },
  });
  const isOwnerOfStardogFreeOrEssentials =
    (connection.isStardogFree || connection.isStardogCloud) &&
    connection.cloud?.owner?.username === connection.username;

  const handleBillingRedirect = () => mutate({});

  const handleEdit = () => dispatch(editConnection(connection.index));

  const handleViewDiagnosticReport = () => {
    setConnectionIndex(connection.index, '/diagnostic');
  };

  const handleDelete = () => {
    if (isOwnerOfStardogFreeOrEssentials) {
      onCancelCloud(connection);
      return;
    }
    dispatch(deleteConnection(connection.index));
  };

  const manageBilling = connection.isStardogCloud ? (
    <MenuItem
      icon={IconNames.CREDIT_CARD}
      onClick={handleBillingRedirect}
      disabled={connection.isAllocating || false}
      text={copy.components.connectionMenu.manageBilling}
    />
  ) : null;

  const contentMenuItem = (
    <Menu>
      <MenuItem
        icon={IconNames.EDIT}
        text={copy.components.connectionMenu.edit}
        onClick={handleEdit}
      />
      <MenuItem
        icon={IconNames.DIAGNOSIS}
        text={copy.components.connectionMenu.viewDiagnosticReport}
        onClick={handleViewDiagnosticReport}
      />
      {manageBilling}
      <MenuItem
        icon={IconNames.TRASH}
        text={
          isOwnerOfStardogFreeOrEssentials
            ? copy.components.connectionMenu.cancelCloud
            : copy.components.connectionMenu.delete
        }
        intent={Intent.DANGER}
        onClick={handleDelete}
      />
    </Menu>
  );

  const username = connection.username ? (
    <span>{connection.username}</span>
  ) : (
    <span>using SSO</span>
  );

  const getConnectionEndpoint = () => {
    if (connection.isAllocating) {
      if (connection.stripeSubscription?.isExpired) {
        return 'Your checkout session has expired!';
      }
      if (connection.stripeSubscription?.isWaitingForPayment) {
        return 'Please complete payment';
      }
      return 'Stardog Cloud instance is being built';
    }
    return connection.endpoint;
  };

  const menuId = `${connection.name}-menu`;
  const popoverId = `${connection.name}-popover`;

  const menuItem = (
    <Popover2
      minimal
      data-testid={popoverId}
      content={contentMenuItem}
      position={Position.LEFT_TOP}
      isOpen={isPopoverOpen}
      onClose={(e) => {
        if (e) {
          // prevent triggering card link when clicking menu items
          e.stopPropagation();
          setIsPopoverOpen(false);
        }
      }}
    >
      <Button
        data-testid={menuId}
        role="button"
        minimal
        active={isPopoverOpen}
        icon={IconNames.MORE}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (e) {
            e.stopPropagation();
            setIsPopoverOpen(!isPopoverOpen);
          }
        }}
      />
    </Popover2>
  );

  return (
    <tr onClick={onClick}>
      <td>
        <strong>{connection.name}</strong>
      </td>
      <td>{username}</td>
      <td>{getConnectionEndpoint()}</td>
      <td>{menuItem}</td>
    </tr>
  );
};
