import {
  AnchorButton,
  Card,
  H5,
  H6,
  Icon,
  Intent,
  UL,
} from '@blueprintjs/core';
import * as React from 'react';
import { Tooltip } from 'vet-bones/components';

import { isEU } from 'src/ui/constants/environment';
import { GET_STARTED_CTA } from 'src/ui/constants/testIds';
import { AnyFunc } from 'src/ui/constants/types';
import { InstanceFlavors } from 'src/ui/features/connection';
import { components } from 'src/ui/templates/copy';

interface ProductCardProps {
  flavor: InstanceFlavors;
  recommended?: boolean;
  addToCart: AnyFunc;
}

export type ProductInfo = {
  tag: string;
  subTag?: string;
  title: string;
  description: string;
  cta: string;
  priceText: string;
  features: { title: string; list: string[] };
};

export const getProductInfo = (flavor: string): ProductInfo => {
  const defaults: ProductInfo = {
    tag: '',
    title: '',
    description: '',
    cta: '',
    priceText: '',
    features: { title: '', list: [] },
  };

  if (flavor === InstanceFlavors.MICRO) {
    return {
      ...components.getStarted.editions.free,
      cta: isEU()
        ? components.getStarted.editions.free.ctaDisabled
        : components.getStarted.editions.free.cta,
    };
  }
  if (flavor === InstanceFlavors.SMALL_VBX) {
    return components.getStarted.editions.essentials;
  }
  if (flavor === InstanceFlavors.MEDIUM) {
    return components.getStarted.editions.enterprise;
  }

  return defaults;
};

export const ProductInfoPanel: React.VFC<{
  flavor: string;
}> = ({ flavor }) => {
  const copy = getProductInfo(flavor);
  return (
    <div className="get-started__product-card-content">
      <p>
        {copy.tag}
        {copy.subTag ? (
          <>
            <br />
            <br />
            {copy.subTag}
          </>
        ) : null}
      </p>
      <span className="price">{copy.priceText}</span>
      <H6>{copy.features.title}</H6>
      <UL className="get-started__product-card-content-list">
        {copy.features.list.map((feature) => (
          <li key={feature}>
            <Icon
              className="get-started__product-card-content-list-item-icon"
              icon="endorsed"
            />
            {feature}
          </li>
        ))}
      </UL>
    </div>
  );
};

export const ProductCard: React.VFC<ProductCardProps> = ({
  flavor,
  recommended,
  addToCart,
}) => {
  const className = recommended
    ? 'get-started__product-card recommended'
    : 'get-started__product-card';

  const intent = Intent.PRIMARY;

  const getAddToCartButton = (cta: string) => {
    return (
      <Tooltip
        content={
          cta === components.getStarted.editions.free.ctaDisabled
            ? components.getStarted.editions.free.ctaDisabledTooltip
            : ''
        }
      >
        <AnchorButton
          onClick={addToCart}
          data-testid={GET_STARTED_CTA(flavor)}
          text={cta}
          intent={intent}
          disabled={cta === components.getStarted.editions.free.ctaDisabled}
        />
      </Tooltip>
    );
  };

  const product = getProductInfo(flavor);

  return (
    <Card className={className} elevation={2}>
      <div className="get-started__product-card-header">
        <div className="get-started__product-card-header-content">
          <H5 className="name">{product.title}</H5>
        </div>
      </div>
      <ProductInfoPanel flavor={flavor} />
      <div className="get-started__product-card-footer">
        {getAddToCartButton(product.cta)}
      </div>
    </Card>
  );
};
