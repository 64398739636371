import { Intent, Position, Toast, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { unqueueNotification } from 'src/ui/features/notifications';

// Component to handle showing and closing "snackbar" notifications. The
// component renders only one notification (the first one in the notifications
// queue) at a time.
export const Notifications: React.VFC = () => {
  const notifications = useAppSelector(
    (state) => state.notifications.notifications
  );
  const dispatch = useAppDispatch();

  const onUnqueueNotification = (id: string) =>
    dispatch(unqueueNotification(id));

  return (
    <Toaster position={Position.BOTTOM_RIGHT}>
      {notifications.map((message) => {
        return (
          <Toast
            key={message}
            timeout={15000}
            onDismiss={() => onUnqueueNotification(message)}
            message={message}
            icon={IconNames.WARNING_SIGN}
            intent={Intent.DANGER}
            data-testid="toast"
          />
        );
      })}
    </Toaster>
  );
};
