import { Button, H2, HTMLTable, InputGroup } from '@blueprintjs/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { CONNECTION_LIST_GET_CLOUD } from 'src/ui/constants/testIds';
import { PartnerConnectionRow } from 'src/ui/containers/partner/databricks/PartnerConnectionRow';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useGetUserCurrentPartnerConnectionQuery,
  useSavedConnectionsQuery,
  useUserPartnerConnectionsQuery,
} from 'src/ui/graph/types';
import * as copy from 'src/ui/templates/copy';

export type FormData = {
  databricksConnectionName: string;
  connectionFilter: string;
};

export const PartnerHome: React.VFC = () => {
  const history = useHistory();
  const { data } = useSavedConnectionsQuery(graphQLClient);

  const [connections, setConnections] = React.useState<GraphConnection[]>([]);

  React.useEffect(() => {
    const changed = data?.listConnections || [];
    setConnections(changed as React.SetStateAction<GraphConnection[]>);
  }, [data]);
  const {
    data: partnerInfo,
    isLoading: isPartnerLoading,
  } = useUserPartnerConnectionsQuery(graphQLClient, undefined, {
    refetchInterval: 5000,
  });

  const {
    data: partnerInfoInSession,
    isLoading,
  } = useGetUserCurrentPartnerConnectionQuery(graphQLClient);
  const [connectionFilter, setConnectionFilter] = React.useState<string>('');
  if (isPartnerLoading || isLoading) {
    return null;
  }
  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setConnectionFilter(evt.target.value);
  };

  const partnerConnections = partnerInfo?.userPartnerConnections;
  const workspaceOwnerInfo = () => {
    if (
      partnerInfoInSession?.getUserCurrentPartnerConnection &&
      partnerInfoInSession?.getUserCurrentPartnerConnection.user_email !==
        partnerInfo?.profile?.email
    ) {
      return copy.components.databrickspartner.workspaceInfo(
        partnerInfoInSession?.getUserCurrentPartnerConnection.user_email || ''
      );
    }
    if (partnerConnections?.length === 0) {
      return copy.components.databrickspartner.information;
    }
    return null;
  };

  const connectionRows =
    !isPartnerLoading && partnerConnections
      ? partnerConnections
          .filter(
            (c) =>
              !connectionFilter ||
              c?.stardog_server_connection?.name
                .toLocaleLowerCase()
                .includes(connectionFilter.toLocaleLowerCase())
          )
          .map((partnerConnection) => {
            /* istanbul ignore next. */
            if (!partnerConnection) {
              return null;
            }

            return (
              <PartnerConnectionRow
                partnerConnectionDetail={partnerConnection}
                key={partnerConnection.databricks_workspace_id}
              />
            );
          })
      : null;

  const getActionButtons = (searchDisabled: boolean) => {
    return (
      <div className="sd-table__actions">
        <div className="sd-table__actions__button-container">
          <Button
            icon="cloud"
            onClick={() => {
              history.push('/get-started');
            }}
            intent="success"
            outlined
            minimal
            data-testid={CONNECTION_LIST_GET_CLOUD}
          >
            {copy.components.connectionList.getCloud}
          </Button>
          <InputGroup
            disabled={searchDisabled}
            type="search"
            data-testid="connection-filter"
            name="connection-filter"
            placeholder={copy.components.databrickspartner.searchConnections}
            onChange={handleSearchChange}
            leftIcon="search"
          />
        </div>
      </div>
    );
  };

  const moreSetupSteps = (() => {
    if (connections.length === 0) {
      return copy.components.databrickspartner.getStardogInstance;
    }

    if (!isPartnerLoading && partnerConnections) {
      if (
        partnerConnections.filter((partnerConnection) => {
          return partnerConnection?.is_configured_resources;
        }).length > 0
      ) {
        return null;
      }
      return copy.components.databrickspartner.useActionsToManageConnection;
    }
    return null;
  })();

  const body =
    partnerConnections?.length === 0 ? (
      <div className="no-connection-container">
        <div className="sd-table__header">
          <H2>{copy.components.databrickspartner.header}</H2>
          {getActionButtons(true)}
        </div>
        <hr className="divider" />
        {workspaceOwnerInfo()}
      </div>
    ) : (
      <div className="sd-table">
        <div className="sd-table__header">
          <H2>{copy.components.databrickspartner.header}</H2>
          {getActionButtons(false)}
        </div>
        <hr className="divider" />

        {workspaceOwnerInfo()}
        <HTMLTable interactive striped>
          <thead>
            <tr>
              <th>Workspace Id</th>
              <th>SQL Endpoint</th>
              <th>Stardog Connection</th>
              <th>DataSource Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{connectionRows}</tbody>
        </HTMLTable>
        {moreSetupSteps ? <hr className="divider" /> : null}
        {moreSetupSteps}
      </div>
    );

  return body;
};
