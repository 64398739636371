import {
  Card,
  Classes,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Label,
  SpinnerSize,
  Switch,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { ItemSelector, Text } from 'vet-bones/components';
import {
  DEFAULT_MODEL_NAME,
  STARDOG_CONTEXT_LOCAL_IRI,
} from 'vet-bones/constants';
import { useIsMounted } from 'vet-bones/utils/hooks';
import { SelectItem, createSelectItem } from 'vet-bones/utils/select';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import {
  MANAGE_VOICEBOX_APP_CREATE_CONNECTION,
  MANAGE_VOICEBOX_APP_CREATE_CONNECTIONS,
  MANAGE_VOICEBOX_APP_CREATE_CONNECTIONS_EMPTY,
  MANAGE_VOICEBOX_APP_CREATE_DATABASE_ITEM,
  MANAGE_VOICEBOX_APP_CREATE_DATABASE_SELECTOR,
  MANAGE_VOICEBOX_APP_CREATE_DIALOG,
  MANAGE_VOICEBOX_APP_CREATE_GRAPH_ITEM,
  MANAGE_VOICEBOX_APP_CREATE_GRAPH_SELECTOR,
  MANAGE_VOICEBOX_APP_CREATE_MODEL_ITEM,
  MANAGE_VOICEBOX_APP_CREATE_MODEL_SELECTOR,
  MANAGE_VOICEBOX_APP_CREATE_NAME_INPUT,
  MANAGE_VOICEBOX_APP_CREATE_REASONING_SWITCH,
} from 'src/ui/constants/testIds';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useCreateVoiceboxAppMutation,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { useValidateConnection } from 'src/ui/hooks/validateConnection';
import {
  SPECIAL_NAMED_GRAPH_IRIS,
  useVoiceboxConfigItems,
} from 'src/ui/hooks/voiceboxConfigItems';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import * as copy from 'src/ui/templates/copy';

const isOpenSelector = isDialogOpenSelector(DialogType.CREATE_VOICEBOX_APP);

export const CreateVoiceboxAppDialog: React.VFC = () => {
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isOpenSelector);
  const onMutate = useOnMutate();
  const onError = useOnError();
  const queryClient = useQueryClient();

  const [name, setName] = React.useState('');
  const trimmedName = name.trim();
  const [connectionId, setConnectionId] = React.useState('');
  const [databaseId, setDatabaseId] = React.useState('');
  const [model, setModel] = React.useState(DEFAULT_MODEL_NAME);
  const [namedGraphs, setNamedGraphs] = React.useState<string[]>([
    STARDOG_CONTEXT_LOCAL_IRI,
  ]);
  const [reasoning, setReasoning] = React.useState<boolean>(true);

  const connectionsQuery = useSavedConnectionsQuery(graphQLClient);
  const validateConnection = useValidateConnection();

  const connections = (
    connectionsQuery.data?.listConnections || []
  ).filter((connection): connection is GraphConnection => Boolean(connection));

  const selectedConnection = connections.find(
    (connection) => connection?.id === connectionId
  );
  const connectionIdx = selectedConnection?.index ?? -1;

  const {
    databasesById,
    databaseItems,
    namedGraphsByDatabaseId,
    namedGraphItems,
    modelItems,
  } = useVoiceboxConfigItems({
    copy: copy.components.manageApiTokens.voiceboxConfig.namedGraphs.dividers,
    connectionIdx,
    databaseId,
    enabled: Boolean(selectedConnection) && !connectionsQuery.isLoading,
  });

  const selectedDatabaseItem = databaseId
    ? databaseItems.find((item) => item.value === databaseId) ||
      createSelectItem(databaseId, databaseId)
    : null;

  const selectedNamedGraphItems = namedGraphs
    .map(
      (namedGraph) =>
        namedGraphItems.find((item) => item.value === namedGraph) ||
        createSelectItem(namedGraph, namedGraph)
    )
    .filter((item): item is SelectItem => Boolean(item));

  const selectedModelItem = model
    ? modelItems.find((item) => item.value === model) ||
      createSelectItem(model, model)
    : null;

  const { isLoading, mutate: createVoiceboxApp } = useCreateVoiceboxAppMutation(
    graphQLClient,
    {
      onMutate,
      onError,
      onSuccess: async (data) => {
        if (!data.createVoiceboxApp?.success) {
          return;
        }

        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }
        await queryClient.refetchQueries(['listVoiceboxApps']);
        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }
        dispatch(closedDialog());
      },
    }
  );

  const isSubmitDisabled =
    !trimmedName || !connectionId || !databaseId || isLoading;

  const handleClose = () => {
    dispatch(closedDialog());
    setName('');
    setConnectionId('');
    setDatabaseId('');
    setModel(DEFAULT_MODEL_NAME);
    setNamedGraphs([STARDOG_CONTEXT_LOCAL_IRI]);
  };

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    createVoiceboxApp({
      input: {
        name: trimmedName,
        connection_id: connectionId,
        database: databaseId,
        model,
        named_graphs: namedGraphs,
        reasoning,
      },
    });
  };

  const actions: FormAction[] = [
    {
      text: copy.components.manageApiTokens.voiceboxApps.createDialog.cancel,
      onClick: () => handleClose(),
    },
    makeSubmitAction(
      copy.components.manageApiTokens.voiceboxApps.createDialog.submit,
      isLoading
    ),
  ];

  const namedGraphsButtonText =
    selectedNamedGraphItems.length === 1
      ? selectedNamedGraphItems[0].text
      : copy.components.manageApiTokens.voiceboxConfig.namedGraphs.selected(
          selectedNamedGraphItems.length
        );

  let submitTooltipText = '';
  if (!trimmedName) {
    submitTooltipText =
      copy.components.manageApiTokens.voiceboxConfig.name.required;
  } else if (!connectionId) {
    submitTooltipText =
      copy.components.manageApiTokens.voiceboxConfig.connection.required;
  } else if (!databaseId) {
    submitTooltipText =
      copy.components.manageApiTokens.voiceboxConfig.database.required;
  }

  return (
    <Dialog
      className="sd-dialog-create-voicebox-app"
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      icon={IconNames.KEY}
      isOpen={isOpen}
      onClose={handleClose}
      title={copy.components.manageApiTokens.voiceboxApps.createDialog.title}
    >
      <div
        className={Classes.DIALOG_BODY}
        data-testid={MANAGE_VOICEBOX_APP_CREATE_DIALOG}
      >
        <form onSubmit={handleSubmit}>
          <FormGroup
            label={copy.components.manageApiTokens.voiceboxConfig.name.label}
            labelFor="sd-create-voicebox-app-name"
          >
            <InputGroup
              data-testid={MANAGE_VOICEBOX_APP_CREATE_NAME_INPUT}
              disabled={isLoading}
              id="sd-create-voicebox-app-name"
              onChange={(evt) => setName(evt.target.value)}
              value={name}
            />
          </FormGroup>

          <Label>
            {copy.components.manageApiTokens.voiceboxConfig.connection.label}
            {connections.length ? (
              <div
                className="sd-create-voicebox-app-connections"
                data-testid={MANAGE_VOICEBOX_APP_CREATE_CONNECTIONS}
              >
                {connections.map((connection, idx) =>
                  connection ? (
                    <Card
                      key={connection.id}
                      className={classNames(
                        'sd-create-voicebox-app-connection-card',
                        Classes.CALLOUT
                      )}
                      data-testid={MANAGE_VOICEBOX_APP_CREATE_CONNECTION(
                        connection.id,
                        idx
                      )}
                      onClick={() => {
                        if (isLoading) {
                          return;
                        }

                        if (connectionId !== connection.id) {
                          validateConnection(connection).then((valid) => {
                            if (!isMounted.current) {
                              return;
                            }

                            setConnectionId(valid ? connection.id : '');
                            setDatabaseId('');
                            setModel(DEFAULT_MODEL_NAME);
                            setNamedGraphs([STARDOG_CONTEXT_LOCAL_IRI]);
                          });
                        }
                      }}
                    >
                      <Icon
                        icon={
                          connectionId === connection.id
                            ? IconNames.SELECTION
                            : IconNames.CIRCLE
                        }
                        intent={Intent.PRIMARY}
                        size={SpinnerSize.SMALL}
                      />
                      <div>
                        <Text>{connection.name}</Text>
                        <Text>
                          {connection.username}@{connection.endpoint}
                        </Text>
                      </div>
                    </Card>
                  ) : null
                )}
              </div>
            ) : (
              <Card className="sd-create-voicebox-app-connections-empty">
                <p>
                  {
                    copy.components.manageApiTokens.voiceboxConfig.connection
                      .empty.before
                  }
                </p>
                <Link
                  data-testid={MANAGE_VOICEBOX_APP_CREATE_CONNECTIONS_EMPTY}
                  to="/get-started/essentials"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {
                    copy.components.manageApiTokens.voiceboxConfig.connection
                      .empty.link
                  }
                </Link>
                {
                  copy.components.manageApiTokens.voiceboxConfig.connection
                    .empty.after
                }
              </Card>
            )}
          </Label>

          {selectedConnection ? (
            <>
              <div className={classNames(Classes.FORM_GROUP, Classes.INLINE)}>
                <div className={Classes.LABEL}>
                  {
                    copy.components.manageApiTokens.voiceboxConfig.database
                      .label
                  }
                </div>
                <ItemSelector.Single
                  buttonIcon={IconNames.DATABASE}
                  buttonText={
                    databaseId ||
                    copy.components.manageApiTokens.voiceboxConfig.database
                      .placeholder
                  }
                  items={databaseItems}
                  itemTestId={MANAGE_VOICEBOX_APP_CREATE_DATABASE_ITEM}
                  onSelect={(item) => {
                    if (isLoading) {
                      return;
                    }

                    if (item && item.value && item.value !== databaseId) {
                      const nextDatabaseId = item.value;
                      setDatabaseId(nextDatabaseId);

                      const nextModels = databasesById[nextDatabaseId]?.models;
                      if (!nextModels?.includes(model)) {
                        setModel(DEFAULT_MODEL_NAME);
                      }

                      const nextNamedGraphs = namedGraphs.filter(
                        (namedGraph) =>
                          SPECIAL_NAMED_GRAPH_IRIS.includes(namedGraph) ||
                          namedGraphsByDatabaseId[nextDatabaseId]?.[namedGraph]
                      );
                      setNamedGraphs(
                        nextNamedGraphs.length
                          ? nextNamedGraphs
                          : [STARDOG_CONTEXT_LOCAL_IRI]
                      );
                    }
                  }}
                  selected={selectedDatabaseItem}
                  testId={MANAGE_VOICEBOX_APP_CREATE_DATABASE_SELECTOR}
                />
              </div>
              <div className={classNames(Classes.FORM_GROUP, Classes.INLINE)}>
                <div className={Classes.LABEL}>
                  {
                    copy.components.manageApiTokens.voiceboxConfig.namedGraphs
                      .label
                  }
                </div>
                <ItemSelector.Multi
                  buttonIcon={IconNames.LAYERS}
                  buttonText={namedGraphsButtonText}
                  disabled={!databaseId}
                  items={namedGraphItems}
                  itemTestId={MANAGE_VOICEBOX_APP_CREATE_GRAPH_ITEM}
                  onSelect={(items) => {
                    if (isLoading) {
                      return;
                    }

                    setNamedGraphs(items.map((item) => item.value));
                  }}
                  resetOnClose
                  selected={selectedNamedGraphItems}
                  testId={MANAGE_VOICEBOX_APP_CREATE_GRAPH_SELECTOR}
                />
              </div>
              <div className={classNames(Classes.FORM_GROUP, Classes.INLINE)}>
                <div className={Classes.LABEL}>
                  {copy.components.manageApiTokens.voiceboxConfig.model.label}
                </div>
                <ItemSelector.Single
                  buttonText={model}
                  disabled={!databaseId}
                  items={modelItems}
                  itemTestId={MANAGE_VOICEBOX_APP_CREATE_MODEL_ITEM}
                  onSelect={(item) => {
                    if (isLoading) {
                      return;
                    }

                    if (item && item.value) {
                      setModel(item.value);
                    }
                  }}
                  selected={selectedModelItem}
                  testId={MANAGE_VOICEBOX_APP_CREATE_MODEL_SELECTOR}
                />
              </div>
              <FormGroup
                inline
                label={
                  copy.components.manageApiTokens.voiceboxConfig.reasoning.label
                }
                labelFor="sd-create-voicebox-app-reasoning"
              >
                <Switch
                  checked={reasoning}
                  data-testid={MANAGE_VOICEBOX_APP_CREATE_REASONING_SWITCH}
                  id="sd-create-voicebox-app-reasoning"
                  large
                  onChange={() => {
                    setReasoning((prev) => !prev);
                  }}
                />
              </FormGroup>
            </>
          ) : null}
        </form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <FormActions
            actions={actions}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isSubmitDisabled}
            submitTooltipText={submitTooltipText}
          />
        </div>
      </div>
    </Dialog>
  );
};
