export type ExportToFileOptions = {
  contents: string;
  filename: string;
  mimeType: string;
};

// NOTE: Only works when a DOM is available.
// File sizes are constrained by browsers.
export const exportBlobToFile = ({
  blob,
  filename,
}: {
  blob: Blob;
  filename: string;
}) => {
  const anchorElement = document.createElement('a');
  anchorElement.href = URL.createObjectURL(blob);
  anchorElement.download = filename;
  anchorElement.click();
  URL.revokeObjectURL(anchorElement.href);
};

export const exportToFile = ({
  contents,
  mimeType,
  filename,
}: ExportToFileOptions) => {
  const blob = new Blob([contents], {
    type: mimeType,
  });
  exportBlobToFile({ blob, filename });
};
