import { useRouteMatch } from 'react-router-dom';

export const useLandingRouteParams = () => {
  const routeMatch = useRouteMatch<{ id: string }>({
    exact: true,
    path: ['/u/:id(\\d+|demo)/', '/'],
  });

  if (!routeMatch) {
    return null;
  }

  return routeMatch?.params;
};

export const useVoiceboxRouteParams = () => {
  const routeMatch = useRouteMatch<{ id: string; conversation?: string }>({
    exact: true,
    path: ['/u/:id(\\d+)/voicebox/:conversation?'],
  });

  if (!routeMatch) {
    return null;
  }

  return routeMatch?.params;
};
