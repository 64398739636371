import { FormGroup, InputGroup } from '@blueprintjs/core';
import * as React from 'react';

import type { DataSourceSubFormProps } from 'src/ui/containers/dialogs/DataSourceForm';
import * as copy from 'src/ui/templates/copy';

export const DataSourceMongoForm: React.VFC<DataSourceSubFormProps> = ({
  dataSource,
  formHandler,
  disabled,
}) => {
  const { formData, handleTextFieldChange } = formHandler;

  return (
    <>
      <FormGroup
        labelFor="data-source-mongo-name"
        label={copy.components.dataSourceDialog.form.name}
        helperText={copy.components.dataSourceDialog.form.nameHelp}
      >
        <InputGroup
          id="data-source-mongo-name"
          value={dataSource.name}
          fill
          disabled
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.mongodb.uri}
        labelFor="data-source-mongo-url"
        data-heap-redact-attributes="value"
        helperText={copy.components.dataSourceDialog.form.mongodb.uriHelp}
      >
        <InputGroup
          id="data-source-mongo-url"
          value={formData['mongodb.uri']}
          name="mongodb.uri"
          disabled={disabled}
          onChange={handleTextFieldChange}
          data-heap-redact-attributes="value"
          data-heap-redact-text
        />
      </FormGroup>
    </>
  );
};
