import { ItemRendererProps } from '@blueprintjs/select';
import { Edit, OverflowMenuVertical, TrashCan } from '@carbon/icons-react';
import classNames from 'classnames';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import {
  Body,
  Input,
  Menu,
  MenuItem,
  MenuItems,
} from 'vet-bones/design-system/components';
import { useIsMounted } from 'vet-bones/utils/hooks';

import { withConfirmationDs } from 'src/ui/components/withConfirmationDs';
import {
  VOICEBOX_CHAT_LIST_ITEM_BUTTON,
  VOICEBOX_CHAT_LIST_ITEM_DELETE_CANCEL,
  VOICEBOX_CHAT_LIST_ITEM_DELETE_CONFIRMATION,
  VOICEBOX_CHAT_LIST_ITEM_DELETE_MENU_ITEM,
  VOICEBOX_CHAT_LIST_ITEM_MENU_BUTTON,
  VOICEBOX_CHAT_LIST_ITEM_RENAME_INPUT,
  VOICEBOX_CHAT_LIST_ITEM_RENAME_MENU_ITEM,
} from 'src/ui/constants/testIds';
import { ConversationSelectItem } from 'src/ui/containers/voicebox/aside/useVoiceboxConversations';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  useDeleteVoiceboxConversationMutation,
  useEditVoiceboxConversationMutation,
} from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import * as copy from 'src/ui/templates/copy';

interface VoiceboxChatListItemProps {
  item: ConversationSelectItem;
  options: ItemRendererProps;
}
export const VoiceboxChatListItem: React.VFC<VoiceboxChatListItemProps> = ({
  item,
  options,
}) => {
  const isMounted = useIsMounted();
  const queryClient = useQueryClient();
  const onError = useOnError();
  const onMutate = useOnMutate();

  const ignoreNextItemClick = React.useRef(false);
  const ignoreNextInputBlur = React.useRef(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [conversationName, setConversationName] = React.useState(item.text);

  const { mutate: handleVoiceboxRename } = useEditVoiceboxConversationMutation(
    graphQLClient,
    {
      onSuccess: async (data) => {
        if (!data.editVoiceboxConversation?.success) {
          return;
        }
        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }
        await queryClient.refetchQueries(['listVoiceboxConversations']);
        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }

        setIsEditing(false);
      },
      onError,
      onMutate,
    }
  );

  const {
    mutate: handleVoiceboxDelete,
  } = useDeleteVoiceboxConversationMutation(graphQLClient, {
    onSuccess: async (data) => {
      if (!data.deleteVoiceboxConversation?.success) {
        return;
      }
      /* istanbul ignore if */
      if (!isMounted.current) {
        return;
      }
      await queryClient.refetchQueries(['listVoiceboxConversations']);
    },
    onError,
    onMutate,
  });

  const deleteConversationConfirmation = () =>
    withConfirmationDs({
      message: copy.components.voicebox.sidebar.delete.message,
      noPrompt: copy.components.voicebox.sidebar.delete.cancel,
      noPromptTestId: VOICEBOX_CHAT_LIST_ITEM_DELETE_CANCEL,
      onConfirmationSuccess: () => {
        handleVoiceboxDelete({
          conversation_id: item.value,
        });
      },
      title: copy.components.voicebox.sidebar.delete.title,
      yesPrompt: copy.components.voicebox.sidebar.delete.delete,
      yesPromptTestId: VOICEBOX_CHAT_LIST_ITEM_DELETE_CONFIRMATION,
    });

  const handleItemMouseDown = () => {
    ignoreNextItemClick.current = isEditing || isOpen;
  };

  const handleItemClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    /* istanbul ignore else */
    if (!ignoreNextItemClick.current) {
      options.handleClick(evt);
    }

    ignoreNextItemClick.current = false;
  };

  const handleInputBlur = () => {
    if (
      !ignoreNextInputBlur.current &&
      conversationName &&
      conversationName !== item.text
    ) {
      handleVoiceboxRename({
        input: {
          id: item.value,
          name: conversationName,
        },
      });
    } else {
      setConversationName(item.text);
    }
    setIsEditing(false);
    ignoreNextInputBlur.current = false;
  };

  return (
    <div
      className={classNames('sd-voicebox-chat-list-item', {
        'sd-voicebox-chat-list-item--active': isEditing || isOpen,
      })}
      data-testid={VOICEBOX_CHAT_LIST_ITEM_BUTTON(
        item.value,
        options.index || 0
      )}
      onClick={(evt) => {
        if (evt.target === evt.currentTarget) {
          handleItemClick(evt);
        }
      }}
      onMouseDown={(evt) => {
        if (evt.target === evt.currentTarget) {
          handleItemMouseDown();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="sd-voicebox-chat-list-item-text">
        {isEditing ? (
          <Input
            autoFocus
            clearable={false}
            inputRef={inputRef}
            label=""
            minimal
            onBlur={handleInputBlur}
            onChange={(evt) => {
              setConversationName(evt.target.value);
            }}
            onFocus={(evt) => {
              evt.target.select();
            }}
            onKeyDown={(evt) => {
              if (evt.key === 'Escape') {
                ignoreNextInputBlur.current = true;
                evt.currentTarget.blur();
              } else if (evt.key === 'Enter') {
                evt.currentTarget.blur();
              }
            }}
            placeholder={item.text}
            size="sm"
            testId={VOICEBOX_CHAT_LIST_ITEM_RENAME_INPUT}
            value={conversationName}
          />
        ) : (
          <div
            onClick={handleItemClick}
            onMouseDown={handleItemMouseDown}
            role="button"
            tabIndex={-1}
          >
            <Body ellipsize size="sm">
              {conversationName}
            </Body>
          </div>
        )}
      </div>
      <div className="sd-voicebox-chat-list-item-actions">
        <Menu
          isOpen={isOpen}
          leftIcon={OverflowMenuVertical}
          menu={
            <MenuItems>
              <MenuItem
                leftIcon={Edit}
                onClick={() => {
                  setIsEditing(true);
                  inputRef.current?.focus();
                }}
                testId={VOICEBOX_CHAT_LIST_ITEM_RENAME_MENU_ITEM}
                text={copy.components.voicebox.sidebar.rename}
              />
              <MenuItem
                leftIcon={TrashCan}
                onClick={deleteConversationConfirmation}
                testId={VOICEBOX_CHAT_LIST_ITEM_DELETE_MENU_ITEM}
                text={copy.components.voicebox.sidebar.delete.delete}
              />
            </MenuItems>
          }
          onInteraction={(nextIsOpen) => {
            setIsOpen(nextIsOpen);
          }}
          size="xs"
          testId={VOICEBOX_CHAT_LIST_ITEM_MENU_BUTTON}
        />
      </div>
    </div>
  );
};
