import { Alignment, FormGroup, InputGroup, Switch } from '@blueprintjs/core';
import * as React from 'react';

import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { STARDOG_ENDPOINT } from 'src/ui/constants/environment';
import { AnyFunc } from 'src/ui/constants/types';
import { Connection } from 'src/ui/features/connection';
import { useFormHandler } from 'src/ui/hooks/forms';
import * as copy from 'src/ui/templates/copy';
import { getConnectionFromCookie } from 'src/ui/utils/cookies/getConnectionFromCookie';

type ConnectionFormProps = {
  actions: FormAction[];
  connection: Connection;
  onSubmit: (connection: Connection, reset: AnyFunc) => any;
  submitDisabled: (connection: Connection) => boolean;
  submitTooltip: (connection: Connection) => string;
  showSSO?: boolean;
};

export const ConnectionForm: React.VFC<ConnectionFormProps> = ({
  actions,
  connection,
  onSubmit,
  submitDisabled,
  submitTooltip,
  showSSO = true,
}) => {
  const {
    formData,
    handleBooleanFieldChange,
    handleTextFieldChange,
    reset,
  } = useFormHandler<Connection>(connection);
  const ssoConnection = getConnectionFromCookie();

  const isSubmitDisabled = submitDisabled(formData);
  const submitTooltipText = submitTooltip(formData);
  const ssoHelperText = formData.useSSO
    ? copy.components.connectionDialog.form.ssoHelp(ssoConnection?.username)
    : null;

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    /* istanbul ignore next: no need to test defensive code */
    if (isSubmitDisabled) {
      return;
    }

    onSubmit(formData, reset);
  };

  return (
    <form className="sd-connection-form" onSubmit={handleSubmit}>
      <div className="sd-connection-form__body">
        <FormGroup
          label={copy.components.connectionDialog.form.username}
          labelFor="sd-connection-username"
        >
          <InputGroup
            autoFocus
            id="sd-connection-username"
            name="username"
            autoComplete="username"
            value={formData.username}
            disabled={formData.useSSO || formData.useBrowserAuth}
            onChange={handleTextFieldChange}
          />
        </FormGroup>
        <FormGroup
          label={copy.components.connectionDialog.form.password}
          labelFor="sd-connection-password"
        >
          <InputGroup
            id="sd-connection-password"
            name="password"
            type="password"
            autoComplete="password"
            disabled={formData.useSSO || formData.useBrowserAuth}
            onChange={handleTextFieldChange}
          />
        </FormGroup>
        <FormGroup
          label={copy.components.connectionDialog.form.endpoint}
          labelFor="sd-connection-endpoint"
          helperText={copy.components.connectionDialog.form.endpointHelp}
        >
          <InputGroup
            id="sd-connection-endpoint"
            name="endpoint"
            autoComplete="url"
            value={formData.endpoint}
            onChange={handleTextFieldChange}
            placeholder={STARDOG_ENDPOINT}
          />
        </FormGroup>
        <FormGroup
          labelFor="sd-connection-name"
          label={copy.components.connectionDialog.form.name}
          helperText={copy.components.connectionDialog.form.nameHelp}
        >
          <InputGroup
            id="sd-connection-name"
            name="name"
            value={formData.name}
            fill
            onChange={handleTextFieldChange}
          />
        </FormGroup>
        {showSSO && ( // Conditionally render the SSO form group
          <FormGroup helperText={ssoHelperText}>
            <Switch
              id="sd-connection-use-sso"
              name="useSSO"
              checked={formData.useSSO}
              disabled={formData.useBrowserAuth}
              alignIndicator={Alignment.RIGHT}
              inline
              labelElement={
                <label htmlFor="sd-connection-use-sso">
                  {copy.components.connectionDialog.form.sso}
                </label>
              }
              onChange={handleBooleanFieldChange('useSSO')}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Switch
            id="sd-connection-use-browser-auth"
            name="useBrowserAuth"
            checked={formData.useBrowserAuth}
            disabled={formData.useSSO}
            alignIndicator={Alignment.RIGHT}
            inline
            labelElement={
              <label htmlFor="sd-connection-use-browser-auth">
                {copy.components.connectionDialog.form.browserAuth}
              </label>
            }
            onChange={handleBooleanFieldChange('useBrowserAuth')}
          />
        </FormGroup>
        <input type="submit" hidden />
      </div>
      <FormActions
        actions={actions}
        handleSubmit={handleSubmit}
        isSubmitDisabled={isSubmitDisabled}
        submitTooltipText={submitTooltipText}
      />
    </form>
  );
};
