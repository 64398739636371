import { Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { Loading } from 'src/ui/components/Loading';
import { NotFound } from 'src/ui/components/NotFound';
import { NewConnectionForm } from 'src/ui/containers/dialogs/NewConnectionForm';
import { SelectConnectionDialog } from 'src/ui/containers/dialogs/SelectConnectionDialog';
import { Connection, ConnectionStatus } from 'src/ui/features/connection';
import { queueNotification } from 'src/ui/features/notifications';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  useGetShareByShortHashQuery,
  useListConnectionsByEndpointQuery,
} from 'src/ui/graph/types';
import { useHealthCheck } from 'src/ui/hooks/diagnostic';
import { components } from 'src/ui/templates/copy';
import { getHostnameFromUrl } from 'src/ui/utils/getHostnameFromUrl';
import { doServiceRedirect } from 'src/ui/utils/window';

type SharePathParams = { hash: string };

export const Share: React.VFC = () => {
  const [endpointValid, setEndpointValid] = React.useState<boolean | null>(
    null
  );
  const pathParams: SharePathParams = useParams();
  const dispatch = useAppDispatch();
  const {
    isLoading: isCheckingEndpoint,
    mutate: checkEndpoint,
  } = useHealthCheck();
  const {
    data: shareData,
    isLoading: shareIsLoading,
  } = useGetShareByShortHashQuery(graphQLClient, {
    shortHash: pathParams.hash,
  });

  const { endpoint, service, target_path: targetPath } =
    shareData?.getShareByShortHash || {};

  const {
    data: connectionData,
    isLoading: connectionsAreLoading,
  } = useListConnectionsByEndpointQuery(
    graphQLClient,
    {
      endpoint: endpoint || '',
    },
    {
      // Don't actually run this query if the previous query hasn't returned a valid endpoint yet
      enabled: !!endpoint && !!endpointValid,
    }
  );

  React.useEffect(() => {
    if (!endpoint) {
      return;
    }

    checkEndpoint(endpoint, {
      onError: (error) => {
        setEndpointValid(false);

        dispatch(
          queueNotification(
            `Could not reach endpoint ${endpoint}: ${error.message}`
          )
        );
      },
      onSuccess: () => setEndpointValid(true),
    });
  }, [endpoint]);

  if (shareIsLoading) {
    return <Loading />;
  }
  if (!shareData?.getShareByShortHash) {
    return (
      <NotFound
        title={components.shareNotFound.title}
        description={components.shareNotFound.description}
      />
    );
  }

  if (!endpoint || !service || !targetPath) {
    // This can't really happen since these values aren't nullable
    dispatch(queueNotification('Invalid Share'));

    return <Redirect to="/" />;
  }

  if (!endpointValid) {
    if (isCheckingEndpoint || endpointValid === null) {
      return <Loading />;
    }

    return <Redirect to="/" />;
  }

  const redirectSubPath = `${service}/#${targetPath}`;
  const isLoading = shareIsLoading || connectionsAreLoading;
  const connections = connectionData?.listConnectionsByEndpoint?.filter(
    (conn) => !!conn
  );
  const numConnections = connections?.length || 0;

  if (isLoading) {
    return <Loading />;
  }

  if (connections && numConnections > 1) {
    return (
      <SelectConnectionDialog
        connections={connections as Connection[]}
        redirectSubPath={redirectSubPath}
        isOpen={!isLoading}
      />
    );
  }

  const firstConnection = connections?.[0];

  if (firstConnection) {
    doServiceRedirect(firstConnection.index, redirectSubPath);

    // It won't actually make it to this return statement
    return <Loading />;
  }

  const newConnection: Connection = {
    name: getHostnameFromUrl(endpoint),
    username: '',
    endpoint,
    status: ConnectionStatus.PENDING,
  };

  return (
    <Dialog
      icon={IconNames.OFFLINE}
      title="Connect To Stardog"
      className={classNames('sd-dialog-connection')}
      isCloseButtonShown={false}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      isOpen={!isLoading}
    >
      <div className={Classes.DIALOG_BODY}>
        <NewConnectionForm
          connection={newConnection}
          redirectSubPath={redirectSubPath}
        />
      </div>
    </Dialog>
  );
};
