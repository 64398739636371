import { AnchorButton, H4, H6, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  LANDING_PAGE_ADD_CONNECTION,
  LANDING_PAGE_GET_STARTED,
} from 'src/ui/constants/testIds';
import { Welcome } from 'src/ui/containers/landing/Welcome';
import { KitsSection } from 'src/ui/containers/marketplace/KitsSection';
import { showAddNewDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useMarketplaceSettingsQuery } from 'src/ui/graph/types';
import {
  Module,
  ModuleFilters,
  marketplaceSettingsToDbOptions,
  useMarketplaceList,
} from 'src/ui/hooks/modules';
import * as copy from 'src/ui/templates/copy';
import { DEMO_MODULE_TYPE } from 'src/ui/templates/modules';

export const NewUserLanding: React.VFC = () => {
  const dispatch = useAppDispatch();

  const { data: marketplaceSettingsData } = useMarketplaceSettingsQuery(
    graphQLClient
  );

  const marketplaceConfig = marketplaceSettingsToDbOptions(
    marketplaceSettingsData?.marketplaceSettings
  );

  const filters: ModuleFilters = {
    type: DEMO_MODULE_TYPE,
  };

  const {
    data: moduleData,
    isLoading: isModuleDataLoading,
  } = useMarketplaceList(marketplaceConfig, '', filters);

  const modules = React.useMemo(() => {
    if (isModuleDataLoading || !moduleData) {
      return [];
    }
    const moduleLookup = new Map(
      moduleData.map((module) => [module.id, module])
    );
    const featuredIds = copy.components.home.newUser.kits.featured;
    const featuredModules = featuredIds
      .map((id) => moduleLookup.get(id))
      .filter((module) => module) as Module[];

    return featuredModules;
  }, [moduleData, isModuleDataLoading]);

  const handleAdd = () => dispatch(showAddNewDialog());

  return (
    <>
      <Welcome
        className="welcome-container-voicebox"
        titleText={copy.components.home.newUser.title}
        subText={copy.components.home.newUser.cta}
      />
      <div className="sd-landing-new-user">
        <H4>{copy.components.home.newUser.intro}</H4>
        <KitsSection
          kits={modules}
          nonKits={[copy.components.home.newUser.kits.useOwnData]}
          isVoiceboxLink
        />
        <div className="sd-landing-new-user-actions">
          <H6>{copy.components.home.newUser.endpoint.title}</H6>
          <div className="new-connection-section">
            <AnchorButton
              href="/get-started"
              icon={IconNames.CLOUD}
              intent={Intent.PRIMARY}
              minimal
              outlined
              data-testid={LANDING_PAGE_GET_STARTED}
            >
              {copy.components.home.newUser.endpoint.selectPlan}
            </AnchorButton>
            <AnchorButton
              onClick={handleAdd}
              icon={IconNames.PLUS}
              intent={Intent.PRIMARY}
              minimal
              outlined
              data-testid={LANDING_PAGE_ADD_CONNECTION}
            >
              {copy.components.home.newUser.endpoint.connectExisting}
            </AnchorButton>
          </div>
        </div>
      </div>
    </>
  );
};
