import {
  AnchorButton,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { Loading } from 'src/ui/components/Loading';
import { NotFound } from 'src/ui/components/NotFound';
import { INVITATION_ENDPOINT_NAME_INPUT } from 'src/ui/constants/testIds';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import {
  Connection,
  ConnectionStatus,
  setConnectionStatus,
} from 'src/ui/features/connection';
import { queueNotification } from 'src/ui/features/notifications';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  AddConnectionMutation,
  useAcceptInvitationMutation,
  useAddConnectionMutation,
  useGetInvitationQuery,
  useProfileQuery,
  useSavedConnectionsQuery,
  useUserPartnerConnectionsQuery,
  useVerifyInvitationMutation,
} from 'src/ui/graph/types';
import {
  useClearConnection,
  useSetConnectionIndex,
  useSetConnectionIndexForPartnerConnect,
  useVerifyConnection,
} from 'src/ui/hooks/connection';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { components } from 'src/ui/templates/copy';
import { getHostnameFromUrl } from 'src/ui/utils/getHostnameFromUrl';

type InvitationPathParams = { id: string };

export const Invitation: React.VFC = () => {
  const { data: profileData, isLoading: profileIsLoading } = useProfileQuery(
    graphQLClient
  );
  const pathParams: InvitationPathParams = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    data: invitationData,
    isLoading: invitationIsLoading,
  } = useGetInvitationQuery(graphQLClient, {
    id: pathParams.id,
  });
  const { data: partnerInfo } = useUserPartnerConnectionsQuery(graphQLClient);
  const { mutate: verifyInvitation } = useVerifyInvitationMutation(
    graphQLClient
  );
  const { mutate: acceptInvitation } = useAcceptInvitationMutation(
    graphQLClient
  );
  const dispatch = useAppDispatch();
  const clearConnection = useClearConnection();
  const onError = useOnError();
  const onMutate = useOnMutate();
  const setConnectionIndex = useSetConnectionIndex();
  const {
    data: savedConnections,
    isLoading: isSavedConnectionsLoading,
  } = useSavedConnectionsQuery(graphQLClient);
  const setConnectionIndexForPartner = useSetConnectionIndexForPartnerConnect();
  const { isLoading: isValidating, mutate: validate } = useVerifyConnection();
  const {
    isLoading: isAddingConnection,
    mutate: addConnection,
  } = useAddConnectionMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: (c: AddConnectionMutation) => {
      if (!c.addConnection) {
        clearConnection();
        return;
      }

      acceptInvitation({
        input: {
          id: pathParams.id,
        },
      });

      if (
        partnerInfo?.userPartnerConnections &&
        partnerInfo?.userPartnerConnections.length > 0
      ) {
        setConnectionIndexForPartner(
          c.addConnection.index,
          `/databricks-partner-home`
        );
        return;
      }

      setConnectionIndex(c.addConnection.index);
    },
  });

  // Verify the invitation, so we know the email has been verified
  React.useEffect(() => {
    if (invitationData?.getInvitation) {
      verifyInvitation({
        input: {
          id: pathParams.id,
        },
      });
    }
  }, [invitationData, pathParams, verifyInvitation]);

  const { endpoint, sender, email } = invitationData?.getInvitation || {};

  const [endpointName, setEndpointName] = React.useState('');

  React.useEffect(() => {
    if (endpoint) {
      setEndpointName(getHostnameFromUrl(endpoint));
    }
  }, [endpoint]);

  if (invitationIsLoading || profileIsLoading) {
    return <Loading />;
  }
  if (!invitationData?.getInvitation) {
    return (
      <NotFound
        title={components.invitationNotFound.title}
        description={components.invitationNotFound.description}
      />
    );
  }
  if (!endpoint || !email) {
    // This can't really happen since these values aren't nullable
    dispatch(queueNotification('Invalid Invitation'));

    return <Redirect to="/" />;
  }

  const existingUser = invitationData.getInvitation.existing_user;

  // Handle redirects after a successful login
  const next = location.pathname || '/';
  const search = location.search || '';
  const signupFlagParam = !existingUser ? '&signup=true' : '';
  const redirectLogin = {
    pathname: '/login',
    search: `?next=${next}${search}${signupFlagParam}`,
  };

  const isLoggedIn = profileData?.profile?.is_authenticated;

  if (isLoggedIn && profileData?.profile?.email !== email) {
    // TODO: This actually doesn't work properly. returnTo doesn't do anything. Figure out how to redirect to the invitation page after login.
    const redirectLogout = `/auth/logout?returnTo=${next}${search}`;

    return (
      <Dialog
        className={classNames('invitation-dialog')}
        isOpen
        title={components.invitationDifferentUser.title}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            {components.invitationDifferentUser.info(
              email,
              profileData?.profile?.email as string
            )}
          </p>
          <p>{components.invitationDifferentUser.instruction(email)}</p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <AnchorButton
              href={redirectLogout}
              intent={Intent.PRIMARY}
              text={components.invitationDifferentUser.logoutButton}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  const handleAccept = () => {
    if (isLoggedIn) {
      const connection: Connection = {
        endpoint,
        name: endpointName,
        username: email,
        useSSO: true,
        status: ConnectionStatus.PENDING,
      };

      validate(connection, {
        onError: (e) => {
          onError({
            ...e,
            message: `Could not connect: ${e.message}`,
          });
        },
        onSuccess: (data) => {
          dispatch(setConnectionStatus(ConnectionStatus.PENDING));
          addConnection({ input: data });
        },
      });
    }
  };

  const isWorking =
    isSavedConnectionsLoading || isValidating || isAddingConnection;

  let submitTooltipText: string;
  if (!endpointName) {
    submitTooltipText =
      components.invitation.formValidation.endpointNameRequired;
  } else if (
    savedConnections?.listConnections?.find((c) => c?.name === endpointName)
  ) {
    submitTooltipText =
      components.invitation.formValidation.endpointNameAlreadyExists;
  } else {
    submitTooltipText = '';
  }
  const isSubmitDisabled = Boolean(submitTooltipText);

  const handleSubmit = isLoggedIn
    ? () => handleAccept()
    : () => history.push(redirectLogin);

  let submitLabel: string;
  if (isLoggedIn) {
    submitLabel = components.invitation.acceptButton;
  } else if (existingUser) {
    submitLabel = components.invitation.loginButton;
  } else {
    submitLabel = components.invitation.signupButton;
  }

  const actions: FormAction[] = [
    {
      text: components.invitation.cancelButton,
      onClick: () => history.push('/'),
    },
    makeSubmitAction(submitLabel, isWorking),
  ];

  return (
    <Dialog
      icon={IconNames.OFFLINE}
      title={components.invitation.title}
      className={classNames('sd-dialog-connection')}
      isCloseButtonShown={false}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      isOpen
    >
      <div className={Classes.DIALOG_BODY}>
        <div>
          {components.invitation.info(sender?.first_name as string, endpoint)}
        </div>
        <div className="sd-dialog-invitation__input_container">
          <FormGroup
            label={components.invitation.endpointNameLabel}
            labelFor="endpoint-name"
          >
            <InputGroup
              autoFocus
              data-testid={INVITATION_ENDPOINT_NAME_INPUT}
              id="endpoint-name"
              onChange={(e) => setEndpointName(e.target.value)}
              value={endpointName}
            />
          </FormGroup>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <FormActions
            actions={actions}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isSubmitDisabled}
            submitTooltipText={submitTooltipText}
          />
        </div>
      </div>
    </Dialog>
  );
};
