import { STARDOG_CONNECTION_COOKIE_NAME } from 'src/ui/constants/environment';
import {
  Connection,
  ConnectionStatus,
  disconnected,
} from 'src/ui/features/connection';
import { getCookieValue } from 'src/ui/utils/cookies/getCookieValue';

export const getConnectionFromCookie = (): Connection => {
  const encodedConnection = getCookieValue(
    STARDOG_CONNECTION_COOKIE_NAME
  ).trim();

  if (!encodedConnection) {
    return disconnected;
  }

  // Make sure the cookie is not encoded properly, we are expecting a
  // JSON object that is base64 encoded and has the correct properties.
  try {
    const decoded = atob(encodedConnection);
    const { username, endpoint, isPortal, isLaunchpad } = JSON.parse(decoded);
    const parsedEndpoint = new URL(endpoint).toString();

    if (typeof username === 'undefined') {
      throw new Error('Connection missing username');
    }

    const connection: Connection = {
      name: '__default__',
      username,
      endpoint: parsedEndpoint,
      status: ConnectionStatus.PENDING, // We must validate this with the server
      isPortal,
      isLaunchpad,
    };
    return connection;
  } catch {
    return disconnected;
  }
};
