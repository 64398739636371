import {
  AnchorButton,
  Button,
  Card,
  Classes,
  Dialog,
  H2,
  H3,
  H5,
  Intent,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { isEU } from 'src/ui/constants/environment';
import { UPGRADE_PLAN_DIALOG_CLOSE_BUTTON } from 'src/ui/constants/testIds';
import { PlanComparison } from 'src/ui/containers/product-card/PlanComparison';
import {
  ProductInfoPanel,
  getProductInfo,
} from 'src/ui/containers/product-card/ProductCard';
import { InstanceFlavors, StripeBillingType } from 'src/ui/features/connection';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { CheckoutLineItem, useUpgradeCloudMutation } from 'src/ui/graph/types';
import { useCurrentConnection } from 'src/ui/hooks/connection';
import { useStripePrices } from 'src/ui/hooks/stripePrices';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import { components } from 'src/ui/templates/copy';
import * as Window from 'src/ui/utils/window';

const isOpenSelector = isDialogOpenSelector(DialogType.UPGRADE_PLAN);

type UpgradePlanDialogProductCardProps = {
  buttonComponent: React.ReactNode;
  flavor: string;
  recommended?: boolean;
};

const UpgradePlanDialogProductCard: React.VFC<UpgradePlanDialogProductCardProps> = ({
  buttonComponent,
  flavor,
  recommended,
}) => {
  const className = recommended
    ? 'get-started__product-card recommended'
    : 'get-started__product-card';

  const product = getProductInfo(flavor);

  return (
    <Card className={className} elevation={2}>
      <div className="get-started__product-card-header">
        <div className="get-started__product-card-header-content">
          <H5 className="name">{product.title}</H5>
        </div>
      </div>
      <ProductInfoPanel flavor={flavor} />
      <div className="get-started__product-card-footer">{buttonComponent}</div>
    </Card>
  );
};

export const UpgradePlanDialog: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isOpenSelector);
  const connectionIndexFromRedux = useAppSelector(
    (state) => state.connection.connectionIndex
  );
  const { connection } = useCurrentConnection(
    connectionIndexFromRedux.toString()
  );
  const { mutate: upgradeMutate } = useUpgradeCloudMutation(graphQLClient, {
    onSuccess: (data) => {
      Window.redirectToUrl(data?.upgradeCloud?.url || '/');
    },
  });

  const { isLoading, profile, stripePrices } = useStripePrices();

  const smallCheckoutLineItem = React.useMemo(() => {
    if (isLoading || !profile || !stripePrices) {
      return null;
    }

    const smallVbx = stripePrices.find(
      (price) =>
        price.product_info?.metadata?.flavor === InstanceFlavors.SMALL_VBX &&
        price.stardog_billing_type === StripeBillingType.SUBSCRIPTION
    );

    /* istanbul ignore if */
    if (
      !smallVbx?.id ||
      !smallVbx?.product_info?.name ||
      !smallVbx?.product_info?.metadata?.flavor
    ) {
      return null;
    }

    const item: CheckoutLineItem = {
      id: smallVbx.id as string,
      name: smallVbx.product_info.name,
      quantity: 5,
      flavor: smallVbx.product_info.metadata.flavor,
    };
    return item;
  }, [isLoading, profile, stripePrices]);

  const handleUpgradeToEssentials = () => {
    /* istanbul ignore if */
    if (!connection || !smallCheckoutLineItem) {
      return;
    }
    upgradeMutate({
      connectionId: connection.id as string,
      item: smallCheckoutLineItem as CheckoutLineItem,
    });
  };

  const contactUs = () => {
    Window.openApplicationInTab(components.getStarted.contactUsUrl);
  };

  return (
    <Dialog
      canOutsideClickClose
      isOpen={isOpen}
      onClose={() => {
        dispatch(closedDialog());
      }}
      className="sd-dialog-upgrade-plan"
      canEscapeKeyClose
    >
      <div className="sd-dialog-upgrade-plan__header">
        <Button
          minimal
          large
          icon={IconNames.CROSS}
          onClick={() => {
            dispatch(closedDialog());
          }}
          data-testid={UPGRADE_PLAN_DIALOG_CLOSE_BUTTON}
        />
      </div>
      <div
        className={classNames(
          Classes.DIALOG_BODY,
          'sd-dialog-upgrade-plan__body'
        )}
      >
        <H2>{components.upgradePlanDialog.header}</H2>
        <div className="sd-dialog-upgrade-plan__body-products">
          <UpgradePlanDialogProductCard
            buttonComponent={
              <AnchorButton
                disabled
                text={components.upgradePlanDialog.currentPlanButton}
              />
            }
            flavor={InstanceFlavors.MICRO}
          />
          <UpgradePlanDialogProductCard
            buttonComponent={
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={() =>
                  isEU() ? contactUs() : handleUpgradeToEssentials()
                }
                text={components.upgradePlanDialog.upgradeToEssentialsButton}
              />
            }
            flavor={InstanceFlavors.SMALL_VBX}
            recommended
          />
          <UpgradePlanDialogProductCard
            buttonComponent={
              <AnchorButton
                intent={Intent.PRIMARY}
                onClick={contactUs}
                text={components.upgradePlanDialog.contactUsButton}
              />
            }
            flavor={InstanceFlavors.MEDIUM}
          />
        </div>
        <p>{components.upgradePlanDialog.hostingDisclaimer(isEU())}</p>
        <H3>{components.upgradePlanDialog.planDetails}</H3>
        <div className="sd-dialog-upgrade-plan__body-comparison">
          <PlanComparison />
        </div>
      </div>
    </Dialog>
  );
};
