import { Classes, FormGroup, InputGroup } from '@blueprintjs/core';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import {
  ConnectionStatus,
  clearEditIndex,
  setConnectionStatus,
} from 'src/ui/features/connection';
import { closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  EditConnectionInput,
  useEditConnectionMutation,
} from 'src/ui/graph/types';
import {
  useCurrentConnection,
  useVerifyConnection,
} from 'src/ui/hooks/connection';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import { editIndexSelector } from 'src/ui/selectors/connection';
import * as copy from 'src/ui/templates/copy';

export const SignInForm: React.VFC = () => {
  const dispatch = useAppDispatch();
  const onError = useOnError();
  const onMutate = useOnMutate();
  const editIndex = useAppSelector(editIndexSelector);
  const { connection } = useCurrentConnection(editIndex.toString());
  const { isLoading: isValidating, mutate: validate } = useVerifyConnection();

  const {
    isLoading: isEditingConnection,
    mutate: editConnection,
  } = useEditConnectionMutation(graphQLClient, {
    onMutate,
    onError,
    onSuccess: () => {
      dispatch(closedDialog());
      dispatch(clearEditIndex());
    },
  });

  const isWorking = isValidating || isEditingConnection;

  const [newPassword, setNewPassword] = React.useState<string>('');

  const handleTextFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setNewPassword(value);
  };

  const isSubmitDisabled = !newPassword;
  const submitTooltipText = isSubmitDisabled
    ? copy.components.connectionDialog.formValidation.passwordRequired
    : '';

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    /* istanbul ignore next: no need to test defensive code */
    if (isSubmitDisabled || !connection) {
      return;
    }

    // Test connection with `newPassword`, it is important that we remove the
    // existing token if there is one because the Stardog.js client will prefer
    // using that and we'll just get a 401 making the user super mad and throw
    // stuff. We don't want to be responsible for broken screens or coffee mugs.
    const newConnection = {
      ...connection,
      token: undefined,
      password: newPassword,
      useSSO: false,
    };

    validate(newConnection, {
      onError,
      onSuccess: (data) => {
        // We need to add in the connection ID to satisfy the edit input
        // and not duplicate all the verification logic with different types.
        const editConnectionInput: EditConnectionInput = {
          id: connection.id as string,
          token: data.token,
          useSSO: false,
        };
        dispatch(setConnectionStatus(ConnectionStatus.PENDING));
        editConnection({ input: editConnectionInput });
      },
    });
  };

  const actions: FormAction[] = [
    makeSubmitAction(copy.components.connectionDialog.signIn.submit, isWorking),
  ];

  return connection ? (
    <div className="sd-sign-in">
      <form className="sd-connection-form" onSubmit={handleSubmit}>
        <div className="sd-connection-form__body">
          <div className="sd-sign-in__title">
            <div className={Classes.TEXT_LARGE}>
              <span>{copy.components.connectionDialog.signIn.title}</span>
              &nbsp;
              <strong>{connection.name}</strong>
            </div>
            <div>{connection.endpoint}</div>
          </div>

          <FormGroup
            label={copy.components.connectionDialog.form.username}
            labelFor="sd-connection-username"
            disabled
          >
            <InputGroup
              id="sd-connection-username"
              name="username"
              autoComplete="username"
              value={connection.username}
              disabled
            />
          </FormGroup>
          <FormGroup
            label={copy.components.connectionDialog.form.password}
            labelFor="sd-connection-password"
          >
            <InputGroup
              autoFocus
              id="sd-connection-password"
              name="password"
              type="password"
              autoComplete="password"
              value={newPassword}
              onChange={handleTextFieldChange}
            />
          </FormGroup>
          <input type="submit" hidden />
        </div>
        <FormActions
          actions={actions}
          handleSubmit={handleSubmit}
          isSubmitDisabled={isSubmitDisabled}
          submitTooltipText={submitTooltipText}
        />
      </form>
    </div>
  ) : null;
};
