import { Card, CardProps, Elevation, Tag } from '@blueprintjs/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { VoiceboxLogo } from 'vet-bones/components';
import { alphaSort } from 'vet-bones/utils';

import { KIT_VOICEBOX_ICON } from 'src/ui/constants/testIds';
import { DEMO_KIT_DB_DEFAULT } from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useMarketplaceSettingsQuery } from 'src/ui/graph/types';
import { useProfile } from 'src/ui/hooks/connection';
import {
  Module,
  marketplaceSettingsToDbOptions,
  useModuleMeta,
} from 'src/ui/hooks/modules';
import { components } from 'src/ui/templates/copy';

interface OwnProps extends CardProps {
  module: Module;
  isVoiceboxLink?: boolean;
}

export const KitCard: React.VFC<OwnProps> = ({ module, isVoiceboxLink }) => {
  const { data: profileData } = useProfile();
  const isVoiceboxEnabledUser = profileData?.profile?.is_voicebox_enabled;
  const { data: marketplaceSettingsData } = useMarketplaceSettingsQuery(
    graphQLClient
  );
  const marketplaceConfig = marketplaceSettingsToDbOptions(
    marketplaceSettingsData?.marketplaceSettings
  );
  const { data: moduleData } = useModuleMeta(module.id, marketplaceConfig);
  const isVoiceboxKit = Boolean(moduleData?.moduleMeta?.voiceboxEnabled);

  const card = (
    <Card
      className="kits-card"
      data-testid={module.id}
      interactive
      elevation={Elevation.ONE}
    >
      <div className="kits-card-top">
        <div className="kits-title">
          <span className="kits-name page-overview">{module.name}</span>
          {isVoiceboxKit && isVoiceboxEnabledUser ? (
            <VoiceboxLogo
              className="kits-icon"
              data-testid={KIT_VOICEBOX_ICON}
              size={20}
              title={components.marketplace.voiceboxKits}
            />
          ) : null}
        </div>
        <span className="kits-description page-text">{module.description}</span>
      </div>
      <div className="kits-tags kits-card-bottom">
        {moduleData?.moduleMeta?.tags
          ?.sort(alphaSort((tag) => tag.label))
          .map((tag) => (
            <Tag key={tag.label}>{tag.label}</Tag>
          ))}
      </div>
    </Card>
  );

  let cardLink = `/kits/${module.id}`;
  if (isVoiceboxKit && isVoiceboxEnabledUser && isVoiceboxLink) {
    const databaseId =
      moduleData?.module?.databaseId ||
      marketplaceConfig?.database ||
      DEMO_KIT_DB_DEFAULT;
    cardLink = `/u/12345/voicebox?db=${encodeURIComponent(databaseId)}`;

    const model = moduleData?.module?.schemaName || '';
    if (model) {
      cardLink += `&model=${encodeURIComponent(model)}`;
    }

    const namedGraph = moduleData?.module?.alias;
    if (namedGraph) {
      cardLink += `&graph=${encodeURIComponent(namedGraph)}`;
    }
  }

  return (
    <Link to={cardLink} className="sd-link-undecorated">
      {card}
    </Link>
  );
};
