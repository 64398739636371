import { useRouteMatch } from 'react-router-dom';

import { useAppSelector } from 'src/ui/app/hooks';

export const useConnectionIdx = () => {
  const routeMatch = useRouteMatch<{ id: string }>({
    path: '/u/:id(\\d+|demo)/',
  });

  const connectionIndexFromRedux = useAppSelector(
    (state) => state.connection.connectionIndex
  );

  if (routeMatch?.params.id && !Number.isNaN(parseInt(routeMatch.params.id))) {
    return routeMatch.params.id;
  }

  if (connectionIndexFromRedux !== -1) {
    return connectionIndexFromRedux.toString();
  }

  return '';
};
