import { Code, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import Stardog from 'stardog';

import { ExternalLink } from 'src/ui/components/ExternalLink';
import { isEU } from 'src/ui/constants/environment';

const jsEx = (
  endpoint: string,
  user: string
) => `const { Connection, query } = require('stardog');
const conn = new Connection({
  username: '${user || 'admin'}',
  password: 'YOUR_PASSWD',
  endpoint: '${endpoint}',
});
query.execute(conn, 'YOUR_DB', 'select distinct ?s where { ?s ?p ?o }', 'application/sparql-results+json', {
  limit: 10,
  reasoning: true,
  offset: 0,
}).then(({ body }) => {
  console.log(body.results.bindings);
});
`;
const cliEx = (
  endpoint: string,
  user: string
) => `# List databases on the server.
$ stardog-admin --server ${endpoint} db list -u ${user} -P
# Execute a simple SPARQL query.
$ stardog query query execute -u ${user} -P ${endpoint}/YOUR_DB_NAME "select * where { ?s ?p ?o }";`;

const pyEx = (endpoint: string, user: string) => `import stardog
conn_details = {
  'endpoint': '${endpoint}',
  'username': '${user}',
  'password': 'YOUR_PASSWD'
}
with stardog.Admin(**conn_details) as admin:
  db = admin.new_database('YOUR_DB')
  with stardog.Connection('YOUR_DB', **conn_details) as conn:
    conn.begin()
    conn.add(stardog.content.File('./test/data/example.ttl'))
    conn.commit()
  db.drop()`;

const javaEx = (
  endpoint: string,
  user: string
) => `try (Connection aConn = ConnectionConfiguration
  .to("YOUR_DB")
  .server("${endpoint}")
  .credentials("${user}", "YOUR_PASSWD")
  .connect()) {
    // code goes here
  }
`;

export const copy = {
  appNavBar: {
    title: 'Stardog Cloud',
    altLogo: 'Stardog Cloud',
  },
  auth0: {
    label: 'Sign in with SSO',
  },
  azure: {
    label: 'Sign in with Microsoft',
  },
  diagnostic: {
    title: 'Diagnostic Report',
    support: 'Please share the following details with support:',
    copy: 'Copy To Clipboard',
    authHeader: 'Test authorization header received',
    customHeader: 'Test custom header received',
    copiedNotification: 'Diagnostic information copied to clipboard.',
    notConnected: 'Not Connected',
    subject: 'OAuth Subject',
    username: 'Username',
    provider: 'Provider',
    connectionValid: 'Your connection to Stardog is valid!',
    connectionInvalid:
      'Your connection to Stardog is not valid, please ask an administrator to verify your username has access.',
    verifiedUser: 'Email Verified',
    voicebox: {
      title: 'Voicebox Report',
      severityTitle: {
        error: 'Error',
        warning: 'Warning',
        info: 'Info',
      },
      generateReportButton: 'Generate Report',
      generateReportResponseError: /* istanbul ignore next  */ (
        error: string
      ) => `Error fetching Voicebox Diagnostics: ${error}`,
      selector: {
        database: 'Select Database',
        model: 'Select Model',
      },
    },
  },
  errors: {
    browserAuthFailed:
      'Could not connect to your endpoint using browser authentication. Please check your browser configuration. Click here to check diagnostic page',
    invalidUserPassword: 'Invalid username and/or password.',
    verificationFailed:
      'Verify your endpoint address is correct, or enter username password for this connection',
    notFound: 'Stardog was not found. Talk to your admin.',
    unknown: 'An unknown error occurred. Talk to your admin.',
    connectionFailed:
      'Unable to contact Stardog server, please check your connection.',
    degraded:
      'This connection will not have an optimal experience, click here for an example configuration',
    unverified: 'You need to verify your email address to make a purchase',
    unverifiedSent: 'We resent you an email to verify your account',
    unverifiedAlreadySent:
      'We have already sent you an email to verify your account',
    unauthorized:
      'There is an error with your connection, please click here to check diagnostic page',
  },
  geoaxis: {
    label: 'Sign in with GEOAxIS',
  },
  google: {
    label: 'Sign in with Google',
  },
  keycloak: {
    label: 'Sign in with Keycloak',
  },
  ping: {
    label: 'Sign in with Ping',
  },
  dashboard: {
    title: 'Please choose an application:',
    explorer: {
      action: 'Visit Explorer',
      label: 'Stardog Explorer',
      description:
        'An intuitive interface to visually browse and query your Knowledge Graph.',
    },
    studio: {
      action: 'Visit Studio',
      label: 'Stardog Studio',
      description: 'A complete IDE to program your Knowledge Graph.',
    },
    designer: {
      action: 'Visit Designer',
      label: 'Stardog Designer',
      description:
        'A no-code, visual environment to model, map, and publish data for your Knowledge Graph.',
    },
  },
  login: {
    username: 'Username',
    password: 'Password',
    label: 'Connect',
    retry: 'Retry',
    portal: {
      title: 'Welcome to Stardog Cloud',
      description: 'Please sign in to get started',
    },
  },
  openid: {
    label: 'Sign in with SSO', // TODO: use config setting
  },
};

export const components = {
  defaults: {
    advancedOptions: 'advanced options',
    confirmation: {
      title: 'Are you sure?',
      no: 'No',
      yes: 'Yes',
    },
    error: 'Something went wrong.',
    filter: 'Filter...',
    noResults: 'No results.',
    cancel: 'Cancel',
  },
  notFound: {
    title: 'Page Not Found',
    description: 'Sorry we are unable to find a matching page.',
  },
  shareNotFound: {
    title: 'Link Not Found',
    description:
      'Sorry the link you have requested is invalid or expired. Make sure you have the correct URL.',
  },
  invitationNotFound: {
    title: 'Invitation Not Found',
    description:
      'Sorry the invitation you have requested is invalid or expired. Please ask the sender to send a new invitation.',
  },
  invitationDifferentUser: {
    title: 'Invitation for Different User',
    info: (expected: string, actual = 'unknown') =>
      `This invitation is for ${expected}, but you are logged in as ${actual}.`,
    instruction: (expected: string) =>
      `Please log out and log in as ${expected} to accept this invitation.`,
    logoutButton: 'Log Out',
  },
  invitation: {
    endpointNameLabel: 'Endpoint Name',
    formValidation: {
      endpointNameAlreadyExists: 'An Endpoint with this name already exists',
      endpointNameRequired: 'Endpoint name is required',
    },
    title: 'Knowledge Graph endpoint Invitation',
    info: (sender: string, endpoint: string) => (
      <>
        <b>{sender}</b>{' '}
        <span>is inviting you to join the Knowledge Graph endpoint at</span>{' '}
        <b>{endpoint}</b>
      </>
    ),
    acceptButton: 'Accept Invitation',
    loginButton: 'Log In',
    signupButton: 'Sign Up',
    cancelButton: 'Cancel',
  },
  shareConnection: {
    title: 'Share Knowledge Graph endpoint',
    description:
      'Share this Knowledge Graph endpoint with others. This will also set up any accounts that need to be created.',
    usersLabel: 'Users',
    userPlaceholder: 'Email',
    addUserButton: 'Add User',
    removeUserTooltip: 'Remove User',
    accessLevelLabel: 'Access Level',
    databaseLabel: 'Database',
    inviteButton: 'Share Endpoint',
    closeButton: 'Close',
  },
  connectionDialog: {
    title: 'Connect to Stardog',
    tabs: {
      edit: 'Edit Endpoint',
      new: 'New Endpoint',
      signIn: 'Sign In',
      delete: 'Delete Endpoint',
    },
    form: {
      advanced: 'Advanced Options',
      browserAuth: 'Connect using browser authentication:',
      username: 'Username',
      password: 'Password',
      endpoint: 'Endpoint URL',
      endpointHelp: '(URL of your existing Knowledge Graph endpoint)',
      sso: 'Connect using SSO credentials:',
      ssoHelp: (username: string | undefined) =>
        username ? `Will log in as: ${username}` : 'Loading...',
      name: 'Name',
      delete: 'Enter Name to Delete',
      nameHelp: '(A unique name to identify this Knowledge Graph endpoint)',
    },
    tooltips: {
      deleteConnectionOfCloudOwner: (
        <>
          Owners of Cloud endpoints may not delete the endpoint from their Cloud
          account. To cancel your Cloud endpoint, use the three-dot menu (
          <Icon icon={IconNames.MORE} />) under Actions.
        </>
      ),
    },
    formValidation: {
      nameRequired: 'Name is required',
      nameUniq: 'A Knowledge Graph endpoint with this name already exists',
      endpointRequired: 'Endpoint is required',
      passwordRequired:
        'Password is required when not using SSO or browser authentication',
      usernameRequired:
        'Username is required when not using SSO or browser authentication',
      invalidEndpoint: 'Endpoint must be a valid url',
      deleteNameMatch:
        'You must enter the name exactly to delete Knowledge Graph endpoint',
    },
    new: {
      submit: 'Connect',
    },
    edit: {
      cancel: 'Cancel',
      submit: 'Update Endpoint',
    },
    signIn: {
      back: 'Back',
      title: 'Sign in to',
      submit: 'Connect',
    },
    delete: {
      title: 'Are you sure you want to delete',
      submit: 'Delete',
      cancel: 'Cancel',
    },
    select: {
      title: 'Select a Knowledge Graph endpoint',
      submit: 'Select',
    },
  },
  upgradePlanDialog: {
    contactUsButton: 'Contact Us',
    currentPlanButton: 'Current Plan',
    upgradeToEssentialsButton: isEU() ? 'Contact Us' : 'Upgrade to Essentials',
    header: 'Upgrade your Knowledge Graph endpoint',
    hostingDisclaimer: (isEU: boolean) =>
      isEU
        ? 'All Knowledge Graph endpoints are currently hosted in the EU (Frankfurt).'
        : 'All Knowledge Graph endpoints are currently hosted in the US.',
    planDetails: 'Plan details',
  },
  cancelCloudDialog: {
    title: 'Cancel Cloud',
    callout: {
      free: (endpoint: string) => (
        <p>
          Cloud cancellation <b>cannot</b> be undone. This will permanently
          delete your Stardog Free endpoint (<Code>{endpoint}</Code>).
        </p>
      ),
      essentials: (endpoint: string) => (
        <p>
          Cloud cancellation <b>cannot</b> be undone. This will permanently
          delete your Stardog Essentials endpoint (<Code>{endpoint}</Code>) and
          cancel your subscription.
        </p>
      ),
      default: /* istanbul ignore next  */ (endpoint: string) => (
        <p>
          Cloud cancellation <b>cannot</b> be undone. This will permanently
          delete your Stardog endpoint (<Code>{endpoint}</Code>) and cancel any
          associated subscription.
        </p>
      ),
    },
    cancellationConfirmationLabel: "Please type 'CANCEL' to confirm",
    cancelConfirmationButton: 'I understand the consequences, cancel my cloud',
    errors: {
      generic:
        'Unable to cancel cloud endpoint. Please contact support at support@stardog.com',
      cloudTypeCannotBeCancelled:
        'This type of cloud endpoint cannot be cancelled.',
      cloudOwnerCanOnlyDelete:
        'Only the cloud endpoint owner can cancel the cloud.',
    },
  },
  deleteAccountDialog: {
    title: 'Stardog Cloud Account Deletion',
    callout: (
      <p>
        Account deletion <b>cannot</b> be undone. This will permanently delete
        your account information and any Stardog Cloud instances for which you
        are the owner of. All active subscriptions will be cancelled.
      </p>
    ),
    deletionConfirmationLabel: "Please type 'DELETE' to confirm",
    deletionButton: 'I understand the consequences, delete my account',
  },
  verifyEmailDialog: {
    body: {
      title: 'Verify your email address',
      currentIdentity: (email: string) => (
        <p>
          You&apos;ve entered <b>{email}</b> as the email address for your
          account.
        </p>
      ),
      pleaseConfirm:
        'Please confirm your identity to get a Stardog Cloud instance.',
    },
    emailButtons: [
      {
        name: 'Gmail',
        url:
          'https://mail.google.com/mail/u/0/#search/from%3A(support%40stardog.com)+subject%3A(Verify+your+email)+in%3Aanywhere',
      },
      { name: 'Outlook', url: 'https://outlook.live.com/mail/0/' },
      {
        name: 'Yahoo',
        url:
          'https://mail.yahoo.com/d/search/keyword=from%253Asupport%2540stardog.com%2520subject%253A%2522Verify%2520your%2520email%2522',
      },
      {
        name: 'Proton',
        url:
          'https://mail.proton.me/u/0/all-mail#keyword=Verify+your+email&from=support%40stardog.com',
      },
      { name: 'iCloud', url: 'https://www.icloud.com/mail/' },
    ],
    resendButton: {
      text: "Didn't get the email? Send it again.",
    },
    contactUs: {
      url: 'https://www.stardog.com/company/contact/',
      text: 'Having trouble? Contact us.',
    },
  },
  unsupportedBrowserDialog: {
    title: 'Unsupported Browser',
    body:
      "You're using a browser we don't support. Try the latest version of Firefox, Google Chrome, or Microsoft Edge to have an optimal experience.",
    acknowledgeButtonText: 'Acknowledge',
  },
  welcomeToEssentialsDialog: {
    title: 'Welcome to Stardog Essentials',
    body: (
      <>
        <p>
          Your Knowledge Graph endpoint was successfully upgraded to Stardog
          Essentials
        </p>
        <p>
          Please note that certain modifications may take up to 48 hours for
          completion, so you may experience a temporary downtime during this
          period
        </p>
      </>
    ),
    buttonText: 'I understand',
  },
  dataSourceDialog: {
    title: 'Add Data Source',
    form: {
      name: 'Name',
      nameHelp: '(A unique name for this data source)',
      uniqueKeySets: 'Unique Key Sets',
      rdbms: {
        url: 'URL',
        username: 'Username',
        password: 'Password',
        driver: 'Driver',
        dialect: 'Dialect',
        catalog: 'Catalog',
        catalogs: 'Catalogs',
        schema: 'Schema',
        schemas: 'Schemas',
      },
      mongodb: {
        uri: 'URI',
        uriHelp: '(The URI of your MongoDB Server including credentials)',
      },
      elasticsearch: {
        urls: 'URLs',
        indexes: 'Indexes',
        username: 'Username',
        password: 'Password',
      },
      cassandra: {
        point: 'Contact Point',
        port: 'Port',
        keyspace: 'Keyspace',
        username: 'Username',
        password: 'Password',
        filtering: 'Filtering',
      },
      sparql: {
        url: 'URL',
        username: 'Username',
        password: 'Password',
        graph: 'Graph',
        statsBasedOptimization: 'Stats Based Optimization',
      },
    },
    new: {
      submit: 'Test and Save',
    },
    alert: {
      created: (name: string) => `Created datasource ${name}`,
      failed: (name: string, response: Stardog.HTTP.Body) =>
        `Failed to create datasource ${name} - ${response.body.code}: ${response.body.message}`,
    },
    navigation: {
      next: 'Next',
      finish: 'Finish',
    },
  },
  connectionList: {
    add: 'Connect to an Existing Endpoint',
    getCloud: 'Create new Knowledge Graph Endpoint',
    searchConnections: 'Search Endpoints',
    noConnections: 'You do not have any saved Knowledge Graph endpoints.',
  },
  manageApiTokens: {
    apiTokenRow: {
      delete: {
        cancel: 'Cancel',
        confirmationMessage: 'Are you sure you want to delete this API key?',
        submit: 'Delete',
        title: 'Delete API key',
      },
      table: {
        name: 'Name',
        created: 'Created',
        lastUsed: 'Last Used',
        expires: 'Expires',
      },
    },
    createApiTokenDialog: {
      cancel: 'Cancel',
      close: 'Close',
      submit: 'Create',
      title: {
        user: 'Create User API Key',
        voicebox: 'Create Voicebox API Key',
      },
      form: {
        expiresDays: 'Expires In (days)',
        token: 'API Key',
        tokenHelp:
          "Be sure to copy your API Key now. For security purposes, you won't see it again.",
        name: 'Name',
        nameRequired: 'Name is required',
      },
    },
    userTokens: {
      add: 'New User Key',
      empty: 'No API keys.',
      header: 'Manage User API Keys',
    },
    voiceboxAppCard: {
      add: 'New App Key',
      delete: {
        cancel: 'Cancel',
        confirmationMessage:
          'Are you sure you want to delete this Voicebox App?',
        menu: 'Delete App',
        submit: 'Delete',
        title: 'Delete Voicebox App',
      },
      empty: 'No API keys.',
      update: {
        cancel: 'Cancel',
        menu: 'Rename',
        submit: 'Rename',
        title: 'Rename Voicebox App',
      },
      tokens: 'API Keys',
    },
    voiceboxApps: {
      add: 'New Voicebox App',
      createDialog: {
        cancel: 'Cancel',
        submit: 'Create',
        title: 'Create Voicebox App',
      },
      updateDialog: {
        cancel: 'Cancel',
        submit: 'Rename',
        title: 'Rename Voicebox App',
      },
      empty: 'No Voicebox Apps.',
      header: 'Manage Voicebox Apps',
    },
    voiceboxConfig: {
      connection: {
        label: 'Endpoint',
        required: 'Endpoint is required',
        empty: {
          before: 'You do not have any saved Knowledge Graph endpoints.',
          link: 'Get Stardog Essentials',
          after: ' to get started with Voicebox.',
        },
      },
      database: {
        label: 'Database',
        placeholder: 'Select Database',
        required: 'Database is required',
      },
      model: {
        default: 'Default',
        label: 'Model',
      },
      name: {
        label: 'Name',
        required: 'Name is required',
      },
      namedGraphs: {
        dividers: {
          alias: 'Aliases',
          named: 'Named',
          virtual: 'Virtual',
        },
        label: 'Graph',
        selected: (num: number) => `${num} selected`,
      },
      reasoning: {
        label: 'Reasoning',
        enabled: 'Yes',
        disabled: 'No',
      },
    },
    voiceboxCreditUsage: {
      header: 'Voicebox API Credit Usage',
      purchased: 'Purchased',
      remaining: 'Remaining Credits',
      usedCredits: 'Used Credits',
    },
  },
  manageConnections: {
    header: 'Manage Knowledge Graph Endpoints',
  },
  navbar: {
    aside: {
      portalEndpoint: {
        manageEndpoints: 'Manage Endpoints',
        selector: 'Endpoint',
      },
    },
    avatar: {
      menuItems: {
        deleteAccount: 'Delete Account',
        employeeHome: 'Employee Home',
        logout: 'Logout',
        manageApiTokens: 'Manage API Keys',
        manageBilling: 'Manage Billing',
        updateCookieConsent: 'Update Cookie Consent',
        updateProfile: 'Update Profile',
      },
    },
    landing: {
      endpoint: 'Endpoint',
    },
    voicebox: {
      addNew: 'Add New',
      chatList: 'Chat list',
      chatListItems: 'LATEST',
      settings: 'Settings',
    },
  },
  connectionMenu: {
    edit: 'Edit Knowledge Graph Endpoint',
    delete: 'Delete Knowledge Graph Endpoint',
    manageBilling: 'Manage Billing',
    cancelCloud: 'Cancel Cloud',
    viewDiagnosticReport: 'View Diagnostic Report',
  },
  portalRedirect: {
    title: 'Add New Connection',
    submit: 'Try Out Stardog Apps!',
    cancel: 'Cancel',
    reasons: [
      'Single Sign On support.',
      'Increase security by using API Tokens.',
      'Access to the latest Stardog applications.',
      'Support for connections to multiple Stardog servers.',
    ],
  },
  createProfileDialog: {
    title: 'Create Stardog Profile',
    formStepStatus: {
      aboutYou: 'About you',
      company: 'Company',
    },
    textInputs: {
      firstName: {
        label: 'First name*',
      },
      lastName: {
        label: 'Last name*',
      },
      companyName: {
        label: 'Company name*',
      },
      useCase: {
        label: 'Company use case*',
      },
    },
    selectors: {
      industry: {
        label: 'Company industry*',
        options: {
          financialServices: 'Financial Services',
          lifeSciences: 'Life Sciences',
          manufacturing: 'Manufacturing',
          other: 'Other',
        },
      },
      bestDescribesCompany: {
        label: 'Select the option that best describes your company*',
        options: {
          consultingServices: 'Consulting or services firm',
          enterprise: 'Enterprise company',
          smallMedium: 'Small or medium business',
          govNonProfit: 'Government or non-profit',
          personal: 'Personal',
        },
      },
      bestDescribesRole: {
        label: 'What role best describes you?*',
        options: {
          developer: 'Data engineer, scientist, or developer',
          ontologist: 'Data ontologist, librarian, or architect',
          admin: 'System administrator or operator',
          analyst: 'Analyst or business expert',
          none: 'None of the above',
        },
      },
    },
    checkboxes: {
      agree: 'I agree to the Stardog',
      usageTerms: 'Terms of Use',
      privacyPolicy: 'Privacy Policy',
    },
    buttons: {
      back: 'Back',
      submit: 'Access Stardog',
    },
    formValidation: {
      companyRequired: 'Company name is required',
      firstNameRequired: 'First name is required',
      lastNameRequired: 'Last name is required',
      useCaseRequired: 'Company use case is required',
      industryRequired: 'Company industry is required',
      bestDescribesCompany:
        'You must select an option that best describes your company',
      bestDescribesRole:
        'You must select an option that best describes your role',
      privacyRequired: 'You must agree to the Stardog Privacy Policy',
      termsRequired: 'You must agree to the Stardog Terms of Use',
    },
  },
  updateProfileDialog: {
    title: 'Update Stardog Profile',
    submit: 'Update',
  },
  getStarted: {
    title: 'Get Started with Stardog Cloud',
    overview: (isEU: boolean) => {
      return (
        <>
          A complete Enterprise Knowledge Graph platform provided as a managed
          service. <br />
          {isEU
            ? 'All Knowledge Graph endpoints are currently hosted in the EU (Frankfurt).'
            : 'All Knowledge Graph endpoints are currently hosted in the US.'}
        </>
      );
    },
    blockedEmail: {
      message: (
        <>
          A business email address is required to request a free Stardog Cloud
          endpoint. Free or educational email domains (such as Gmail, Yahoo, or
          .edu) are not eligible for this feature.
          <br />
          <br />
          Please sign up using a business email address.
        </>
      ),
      yesPrompt: 'Sign Up',
      noPrompt: 'Cancel',
    },
    classes: { micro: {}, small: {}, medium: {} },
    comparisonTitles: {
      pricing: 'Pricing',
      hosting: 'Hosting',
      storedEdges: 'Stored edges',
      databases: 'Databases',
      voiceboxDbCount: 'Voicebox Databases',
      security: 'Authentication',
      backups: 'Backups/Retention',
      privateLink: 'AWS Private Link',
      biSqlEndpoint: 'BI/SQL Endpoint',
      availability: 'Service Availability',
      support: 'Support',
      profServices: 'Professional Services',
      selfManageOption: 'Option to self-manage Stardog',
    },
    editions: {
      free: {
        tag: 'Learning environment to create your first Knowledge Graph.',
        title: 'Free',
        cta: 'Get Stardog Free',
        ctaDisabled: 'Coming Soon',
        ctaDisabledTooltip:
          'Stardog Free is not currently available for use in our EU-hosted Stardog Cloud experience. Please use our US-hosted Stardog Cloud for Free endpoints: https://cloud.stardog.com.',
        priceText: 'Free',
        description:
          "Stardog Free is the industry's first cloud-native Enterprise Knowledge Graph Platform that connects data in every cloud as well as on-premise environments. Stardog transforms your existing enterprise data infrastructure into an end-to-end, comprehensive data fabric and answers complex queries across data silos, unifies data across the enterprise ecosystem based on its meaning, and context to create a true, connected network of knowledge.",
        features: {
          title: 'Feature Highlights',
          list: [
            'Shared Cloud hosting',
            'Store up to 1M edges',
            'Community Support',
          ],
        },
        specs: {
          pricing: 'Free',
          hosting: 'Shared',
          storedEdges: (
            <>
              Up to <b>1 million</b>
            </>
          ),
          databases: '3',
          voiceboxDbCount: '',
          security: 'SSO/Google',
          backups: '',
          availability: '',
          support: (
            <a href="https://community.stardog.com/">
              Community <Icon icon={IconNames.ARROW_RIGHT} />
            </a>
          ),
          privateLink: false,
          biSqlEndpoint: false,
          profServices: false,
          selfManageOption: false,
        },
      },
      essentials: {
        tag: 'Everything you need for a PoC or development environment.',
        title: 'Essentials',
        cta: isEU() ? 'Contact Us' : 'Get Stardog Essentials',
        priceText: '$39/user per month',
        description:
          "Stardog Essentials is the industry's first cloud-native Enterprise Knowledge Graph Platform that connects data in every cloud as well as on-premise environments. Stardog transforms your existing enterprise data infrastructure into an end-to-end, comprehensive data fabric and answers complex queries across data silos, unifies data across the enterprise ecosystem based on its meaning, and context to create a true, connected network of knowledge.",
        features: {
          title: 'Everything in Free, plus',
          list: [
            'Stardog Voicebox',
            'Includes 5 users',
            'Dedicated Cloud hosting',
            'Store up to 50M edges',
            '95% uptime SLA',
          ],
        },
        specs: {
          pricing: '$39/user per month (5 users)',
          hosting: 'Dedicated',
          storedEdges: (
            <>
              Up to <b>50 million</b>
            </>
          ),
          databases: 'Unlimited',
          voiceboxDbCount: '1',
          security: 'SSO/Google',
          backups: '',
          availability: '95% uptime',
          support: (
            <a href="https://community.stardog.com/">
              Community <Icon icon={IconNames.ARROW_RIGHT} />
            </a>
          ),
          privateLink: false,
          biSqlEndpoint: false,
          profServices: false,
          selfManageOption: false,
        },
      },

      enterprise: {
        tag:
          'Full production environment tailored to the needs of your company.',
        cta: 'Contact Us',
        title: 'Enterprise',
        priceText: 'Custom',
        description:
          "Stardog Enterprise is the industry's first cloud-native Enterprise Knowledge Graph Platform that connects data in every cloud as well as on-premise environments. Stardog transforms your existing enterprise data infrastructure into an end-to-end, comprehensive data fabric and answers complex queries across data silos, unifies data across the enterprise ecosystem based on its meaning, and context to create a true, connected network of knowledge.",
        features: {
          title: 'Everything in Essentials, plus',
          list: [
            'Option to self-manage Stardog',
            'Custom limit for stored edges',
            // 'Daily backups, 30d retention',
            'Premium Support, 99.9% uptime SLA',
          ],
        },
        specs: {
          pricing: (
            <a href="https://www.stardog.com/company/contact/">
              Contact us <Icon icon={IconNames.ARROW_RIGHT} />
            </a>
          ),
          hosting: 'Dedicated',
          storedEdges: 'Custom',
          databases: 'Unlimited',
          voiceboxDbCount: 'Custom',
          security: 'SSO/Google, Azure AD, OpenID',
          backups: 'Hourly/90 days',
          availability: '99.9% uptime',
          support: (
            <a href="https://www.stardog.com/support/">
              Premium <Icon icon={IconNames.ARROW_RIGHT} />
            </a>
          ),
          privateLink: true,
          biSqlEndpoint: true,
          profServices: true,
          selfManageOption: true,
        },
      },
      academics: {
        title: 'Academic Program',
        overview:
          'We proudly support educators who are introducing the next generation of data enthusiasts to the world of graph and researchers who are looking to see how knowledge graph can solve the world&apos;s next uncrackable problem with our Academic Program. Get in touch to learn more.',
        cta: 'Learn More',
      },
    },
    form: {
      agree: 'I agree to the Stardog',
      termsOfService: 'Terms of Service',
    },
    formValidation: {
      termsRequired: 'You must agree to the Stardog Terms of Service',
    },
    connectToExisting: 'or Add a connection to an existing Stardog Server',
    quotaReached:
      'You have reached your limit, please contact support to add another Cloud instance.',
    hasStardogFree: 'You already have a Stardog Free instance',
    soldOut: 'Sold out!',
    contactUsUrl: 'https://www.stardog.com/company/contact/',
    productNotFound: 'Error adding to cart, product missing',
  },
  ServerOverview: {
    button: {
      contactOwner: 'Contact Owner',
      manageBilling: 'Manage Billing',
      upgradePlan: 'Upgrade Plan',
    },
    title: {
      metrics: 'Metrics',
      stardogEssentials: 'Stardog Essentials',
      stardogFree: 'Stardog Free',
    },
    labels: {
      storedEdges: 'Stored Edges',
    },
    metrics: {
      writes: {
        label: 'Writes',
        description:
          'The total number of writes to all of your knowledge graphs.',
      },
      queries: {
        label: 'Queries',
        description:
          'The total number of queries against all of your knowledge graphs.',
      },
      edges: {
        label: 'Edges',
        description:
          'The total number of relationships (edges) in all of your knowledge graphs.',
      },
      nodes: {
        label: 'Nodes',
        description:
          'The total number of connected nodes (nodes with outgoing edges) in all of your knowledge graphs.',
      },
      reach: {
        label: 'Reach',
        description: 'The total number of edges queried in the last hour.',
      },
      trialDaysRemaining: {
        label: 'Trial Days Remaining',
        description: 'Number of days remaining in your trial period.',
        days: (num: number) => `${num} day${num === 1 ? '' : 's'}`,
      },
      hostingEssentials: {
        label: 'Hosting',
        description:
          'Dedicated server resources supporting up to 50 million edges.',
        value: 'Dedicated',
      },
      hostingFree: {
        label: 'Hosting',
        description:
          'Shared server resources, which might impact query performance. Your data is always isolated from other environments.',
        value: 'Shared',
      },
    },
  },
  contactOwnerDialog: {
    title: 'Contact owner',
    body: (ownerEmail: string | null) =>
      `Please contact the owner of this Knowledge Graph endpoint to manage its plan and billing${
        ownerEmail ? `: ${ownerEmail}` : '.'
      }`,
    buttonText: 'Close',
  },
  home: {
    title: 'Welcome to Stardog Cloud',
    cta: (
      <p>
        Have questions or feedback? Join the&nbsp;
        <ExternalLink href="https://community.stardog.com">
          Stardog Community
        </ExternalLink>
        &nbsp;today.
      </p>
    ),
    new: {
      title: "What's new at Stardog?",
    },
    newUser: {
      title: 'Welcome to Stardog',
      cta: (
        <>
          <p>
            Have questions or feedback? Join the&nbsp;
            <ExternalLink href="https://community.stardog.com/" icon={null}>
              Stardog Community&nbsp;
            </ExternalLink>
            today.
          </p>
          <br />
          <p>
            Stardog combines the power of Enterprise Knowledge Graphs with
            conversational AI to create the world&apos;s first conversational
            data platform -- Stardog Voicebox.
          </p>
        </>
      ),
      intro:
        'Get started using your own data or one of our industry solution packs to experience Voicebox:',
      endpoint: {
        title: 'Manage Endpoints',
        selectPlan: 'New Stardog Cloud Endpoint',
        connectExisting: 'Existing Stardog Endpoint',
      },
      kits: {
        featured: [
          'stardog:c360pack:1.0',
          'stardog:supplychain-demo:1.0',
          'default:insurance:1.0',
        ],
        useOwnData: {
          id: '__use-own-data__',
          name: 'Bring your own data',
          description:
            'Build a conversational data and AI platform against your own data! Once built you can ask questions across your dataset.',
          link: '/get-started/essentials',
        },
      },
    },
  },
  dashboard: {
    intro: (
      <p className="dashboard-description">
        Here is your server dashboard. Below you can access all of the&nbsp;
        <ExternalLink href="https://docs.stardog.com/stardog-applications/">
          Stardog Applications
        </ExternalLink>
        &nbsp; to work with your server. At the bottom of the screen you will
        find some simple metrics that summarize your knowledge graphs and their
        usage.
      </p>
    ),
    quickActionsTitle: 'Quick Actions',
    quickActions: {
      manageEndpoints: 'Manage endpoints',
      manageDatabricks: 'Manage Databricks connections',
      exploreCatalog: 'Explore the Knowledge Catalog',
      startProject: 'Start a project',
      inviteUsers: 'Invite users',
    },
    help: {
      link: 'in Studio',
    },
    examples: {
      description: 'Select your access method to learn more:',
      langs: {
        javascript: {
          language: 'javascript',
          label: 'Javascript',
          example: jsEx,
          description: (
            <p className="lang-desc">
              Stardog.js is&nbsp;
              <ExternalLink href="https://github.com/stardog-union/stardog.js">
                open source
              </ExternalLink>
              ! Check out the project page for more information.
            </p>
          ),
        },
        python: {
          language: 'python',
          label: 'Python',
          example: pyEx,
          description: (
            <p className="lang-desc">
              For more information about using Stardog in Python please refer
              to&nbsp;
              <ExternalLink href="https://docs.stardog.com/developing/programming-with-stardog/python">
                our docs
              </ExternalLink>
              .
            </p>
          ),
        },
        java: {
          language: 'java',
          label: 'Java',
          example: javaEx,
          description: (
            <p className="lang-desc">
              Java is the native API for working with Stardog. Learn more about
              working with the Java API in&nbsp;
              <ExternalLink href="https://docs.stardog.com/developing/programming-with-stardog/java">
                our docs
              </ExternalLink>
              &nbsp;or refer to&nbsp;
              <ExternalLink href="https://github.com/stardog-union/stardog-examples">
                our examples
              </ExternalLink>
              &nbsp;for sample usage.
            </p>
          ),
        },
        cli: {
          language: 'shell',
          label: 'CLI',
          example: cliEx,
          description: (
            <p className="lang-desc">
              All of Stardog&apos;s capabilities are exposed via our command
              line, from database administraction to permission creation, the
              CLI supports it. Learn more in our&nbsp;
              <ExternalLink href="https://docs.stardog.com/stardog-cli-reference/">
                documentation
              </ExternalLink>
              .
            </p>
          ),
        },
      },
    },
  },
  gs: {
    text:
      'This looks like a new instance of Stardog. Would you like to create a Knowledge Graph and then head over to the Knowledge Kits and add some data?',
    cta: 'Go!',
    db: {
      label: 'New Knowledge Graph Name',
      placeholder: 'MyFirstDb',
    },
  },
  internalDashboard: {
    home: {
      title: 'Stardog Employee Home',
      description: 'Access to internal views and reports.',
      disclaimer:
        'Please be mindful about sharing information contained in these views. The views are restricted to Stardog employees intentionally.',
      actionGrid: {
        userSearch: {
          title: 'Stardog Cloud User Search',
          description: 'Search and lookup details for any Stardog Cloud user.',
        },
        cloudReport: {
          title: 'Cloud Report',
          description:
            'Report containing information about cloud instances waiting to be allocated, total instances allocated, and inactive instances.',
        },
      },
    },
    userSearch: {
      title: 'Stardog Cloud User Search',
      download: 'Download',
      searchInput: {
        placeholder: 'Search by email, first name, last name, or company',
      },
      filters: {
        staff: {
          label: 'Administrative Staff',
          checked: 'User is Administrative Staff',
          unchecked: 'User is not Administrative Staff',
          indeterminate: 'No filter applied',
        },
        voiceboxApi: {
          label: 'Voicebox API',
          checked: 'User has Voicebox API enabled',
          unchecked: 'User has Voicebox API disabled',
          indeterminate: 'No filter applied',
        },
      },
    },
    userDashboard: {
      error: {
        message: "Unable to find user's information",
        buttonText: 'Return to user search',
      },
      sections: {
        userInfo: {
          detailsKeyNames: {
            email: 'Email',
            firstName: 'First Name',
            lastName: 'Last Name',
            company: 'Company',
            title: 'Title',
            phone: 'Phone',
            useCase: 'Use Case',
            dateJoined: 'Date Joined',
            lastLogin: 'Last Login',
            hasStardogFree: 'Has Stardog Free',
            isDatabricksUser: 'Databricks User',
            isDesignerStorageEnabled: 'Designer Storage',
            isVerified: 'Verified',
            isStaff: 'Administrative Staff',
            isVoiceboxEnabled: 'Voicebox',
            isStaticVoicebox: 'Use Canned Voicebox',
            isVoiceboxAPIEnabled: 'Voicebox API',
          },
          userFeatures: {
            designerStorage: {
              enabled: 'Enabled Designer Storage',
              disabled: 'Disabled Designer Storage',
            },
            staticVoicebox: {
              enabled: 'Enabled canned Voicebox responses',
              disabled: 'Enabled live Voicebox API',
            },
            voicebox: {
              enabled: 'Enabled Voicebox',
              disabled: 'Disabled Voicebox',
            },
            voiceboxAPI: {
              enabled: 'Enabled Voicebox API',
              disabled: 'Disabled Voicebox API',
            },
          },
        },
        voicebox: {
          header: 'Voicebox',
          sections: {
            credit: {
              header: 'Credit',
              description:
                "The Voicebox credit is used to limit the user's access to the Voicebox public API.",
              limitInputHelperText: 'The current limit assigned to the credit',
              usageInputHelperText: 'The current usage of the credit',
              insufficientPermissionsTooltip:
                'You need Voicebox Credit Admin permissions to update a credit.',
              updateCreditButton: 'Update Credit',
            },
          },
        },
        connections: {
          header: 'Connections',
        },
        clouds: {
          header: 'Cloud Instances',
          description: 'Select a row to get additional information.',
        },
        archivedClouds: {
          header: 'Archived Clouds',
        },
        selectedCloudDetails: {
          grafanaButtonText: 'View Instance in Grafana',
          detailsKeyNames: {
            endpoint: 'Endpoint',
            flavor: 'Flavor',
            status: 'Status',
            region: 'Region',
            paymentRef: 'Payment Ref',
            created: 'Created',
            updated: 'Updated',
          },
        },
      },
    },
  },
  marketplace: {
    demoKits: [
      'stardog:underwriting-demo:1.0',
      'default:insurance:1.0',
      'stardog:singleprotocol:1.0',
      'stardog:supplychain-demo:1.0',
      'stardog:c360pack:1.0',
      'stardog:training-c360:1.0',
      'stardog:healthcare-demo:1.0',
      'default:flight_planning:1.0',
      'stardog:royals-demo:1.0',
      'stardog:starwars:2.0',
      'stardog:superheroes-demo:1.0',
    ],
    title: 'Knowledge Kits',
    summary:
      'Demos, tutorials, data sets, and data models built to speed up your Knowledge Graph journey.',
    loadMoreButton: 'Load more',
    noKits: 'No Knowledge Kits found',
    voiceboxKits: 'Voicebox enabled',
    sections: {
      demos: {
        title: 'Demos',
        description:
          'Learn Stardog basics by browsing industry-related and pop culture Knowledge Graphs.',
      },
      tutorials: {
        title: 'Tutorials',
        description:
          'Master specific Knowledge Graph concepts, from SPARQL to Machine Learning.',
      },
      dataSetsAndModels: {
        title: 'Data sets and data models',
        description:
          'Build your Knowledge Graph faster using preloaded data sets and data models.',
      },
    },
    install: {
      started: 'Installation Started',
      complete: 'Installation complete!',
      failed: 'Installation failed!',
      createdDatabase: (db: string) => `Created database ${db}`,
      failedToCreateDatabase: (db: string) =>
        `Unable to create database ${db}. ${components.marketplace.install.failed}`,
    },
    menu: {
      install: 'Install',
      search: {
        placeholder: 'Search the kits',
      },
      filter: {
        placeholder: 'Filter results by name',
      },
      typeFilter: {
        allTypesLabel: 'All Types',
      },
      tagFilter: {
        loadingPlaceholder: 'Loading tags...',
        placeholder: 'Filter tags...',
      },
    },
    card: {
      menu: {
        open: 'Open',
        install: 'Install',
        delete: 'Uninstall',
      },
    },
    overview: {
      title: 'Overview',
      actions: 'Try It Out',
      about: 'About',
      related: 'Related Kits',
      prov: "What's inside?",
      ctaInstall: 'Install in your endpoint',
      ctaInstallDatabase: 'Database',
      demoExplorerButton: 'Open in Explorer',
      demoStudioButton: 'Open in Studio',
      install: 'Install',
      meta: {
        links: 'Additional Content',
        lastModified: 'Last Modified',
        publisher: 'Publisher',
        license: 'License',
        contributors: 'Contributors',
        size: 'Size',
        tags: 'Tags',
        primary: 'Primary Concept',
        themes: 'Core Concepts',
      },
    },
    db: {
      description: 'Here you can manage the Knowledge Kits for your database.',
    },
    kitSelector: {
      openInExplorer: 'Open in Explorer',
      connectionSelectorInitialText: 'Select Endpoint',
      createDatabaseTooltip:
        'Database will be created during module installation.',
      databaseSelectorButton: 'Select or Create Database',
      databaseSelectorPlaceholder: 'Search or create...',
    },
    validation: {
      databaseName: {
        required: 'Database name is required',
        characters: (chars: string[]) =>
          `The database name is invalid. The ${
            chars.length > 1 ? 'characters' : 'character'
          } "${chars.join('", "')}" ${
            chars.length > 1 ? 'are' : 'is'
          } not allowed.`,
      },
    },
  },
  databrickspartner: {
    welcome: `Get Started with Databricks Partner Connect`,
    navigationMenu: 'Manage Databricks Connections',
    createDatabricksConnection: 'Create Databricks connection',
    databricksConnectionLabel: 'Databricks Connection Name',
    redirectingToDashboard: 'Redirecting to Dashboard',
    searchConnections: 'Search Connections',
    cta: (
      username: string | undefined,
      workspaceUrl: string,
      workspaceId: string
    ) => (
      <p>
        Hi {username}! You have come here from Databricks Partner Connect
        Workspace&nbsp;
        <ExternalLink href={workspaceUrl}>{workspaceId}</ExternalLink>
        &nbsp;.
      </p>
    ),
    removeConnectionFailMessage: (detailError: string | undefined) =>
      `Failed to remove connection - ${detailError}`,
    connectionMenu: {
      create: 'Create Datasource',
      edit: 'Edit Datasource',
      delete: 'Delete Datasource',
      deletePartnerConnection: 'Delete Partner Connection',
      deletePrompt: 'Are you sure you want to delete this connection?',
    },
    update: 'Update Datasource',
    dialogTitle: 'Manage Databricks Datasource',
    successMessage: 'Databricks JDBC Datasource created successfully',
    header: 'Databricks Partner Connections',
    gettingStartedHeader: 'Getting Started',
    information: (
      <p>
        You don&apos;t have any Databricks Partner Connection. Please visit
        the&nbsp;
        <ExternalLink href="https://databricks.com/partnerconnect">
          Databricks Partner Connect
        </ExternalLink>
        &nbsp; portal to get yourself connected.
      </p>
    ),
    uninstall: {
      started: 'Removing Databricks Source',
      complete: 'Databricks data source Removed!',
      failed: 'Databricks data source & starter kit uninstallation failed!',
    },
    stardogServer: 'Stardog Server',
    deletePermanentlyConfirmation:
      'You will lose all the details of Partner Connection. Do you wish to continue?',
    deletePermanently: 'Delete Permanently',
    stardogInstanceGettingAllocated:
      'Please wait till Stardog Instance is getting allocated',
    workspaceInfo: (email: string | undefined) => (
      <p>
        The administrator of the Stardog instance for this Databricks workspace
        is&nbsp;
        <a href={'mailto:'.concat(email || '')}>{email}</a>. Please contact the
        administrator to continue setting up your Stardog account
      </p>
    ),
    getStardogInstance: (
      <p>
        <Icon icon={IconNames.INFO_SIGN} /> You do not have Stardog Connection
        configured. <strong>Get a Stardog Cloud instance</strong> by clicking
        the button above.
      </p>
    ),
    useActionsToManageConnection: (
      <p>
        <Icon icon={IconNames.INFO_SIGN} /> Use <strong>Actions</strong> to
        manage Databricks datasource and get connected with your workspace!
      </p>
    ),
    datasource: {
      creation: {
        started: 'Databricks Datasource creation started',
        completed:
          'Databricks Datasource creation completed. You can click on Stardog server connection to interact with Databricks on Stardog Studio or Designer.',
      },
      deletion: {
        completed: 'Databricks Datasource successfully deleted',
        virtualGraphInfo: (virtualGraphs: string[]) =>
          'Deleting the data source will delete the following virtual graph(s): \n'.concat(
            virtualGraphs.join(', ')
          ),
      },
    },
    starterkit: {
      install: {
        complete: 'Databricks starter kit installation complete',
        failed: 'Databricks starter kit installation failed',
      },
    },
  },
  copyToClipboardButton: {
    initialText: 'Copy',
    successText: 'Copied!',
    failureText: 'Failed to Copy',
  },
  voicebox: {
    chatListAside: {
      filter: {
        placeholder: 'Search',
      },
      noResults: 'No results.',
      title: 'Chat list',
    },
    downloadCsvError: /* istanbul ignore next */ (err: string) =>
      `Could not download results: ${err}`,
    downloadCsvSuccess: /* istanbul ignore next */ (filename: string) =>
      `Downloaded results to ${filename}`,
    sidebar: {
      filter: {
        placeholder: 'Filter',
      },
      new: 'New Chat',
      noResults: 'No results.',
      rename: 'Rename',
      delete: {
        delete: 'Delete',
        message: 'Are you sure you want to delete this conversation?',
        title: 'Delete Conversation',
        cancel: 'Cancel',
      },
    },
  },
  launchpad: {
    login: {
      dialogTitle: 'Login to Stardog Launchpad',
    },
    accountMenu: {
      login: 'Login',
      logout: 'Logout',
    },
    dashboard: {
      title: 'Stardog Launchpad',
      welcome: 'Welcome to Stardog',
    },
    emptyState: {
      title: 'No Endpoints',
      description: 'You have not connected any endpoints.',
    },
    connections: {
      addBasic: 'Add an Endpoint',
      addSso: 'Add SSO Endpoint',
    },
    addSsoConnectionDialog: {
      title: 'Add SSO Endpoint',
      overview: 'Please fill out the details to add a new SSO endpoint.',
      redirectNote:
        'After adding the endpoint, you will be redirected to your SSO provider to authenticate.',
      helpToggle: {
        label: "Don't see your SSO connection?",
        details:
          "If you don't see your preconfigured SSO connection in the list, please check with your system administrator to ensure SSO connections are set up for your organization.",
      },
      inputs: {
        connectionRegistration: {
          label: 'SSO Connection Registration',
          loading: 'Loading connection registrations...',
          helperText: 'Select your SSO connection registration from the list',
        },
        name: {
          label: 'Endpoint Name',
          helperText:
            'Enter a unique, editable name for display on your dashboard.',
        },
        endpoint: {
          label: 'Stardog Endpoint',
          helperText: 'Enter the Stardog endpoint URL',
        },
      },
      validation: {
        name: 'Name cannot be empty',
        endpoint: 'Endpoint must start with "http"',
        connectionRegistration: 'Please select an SSO connection registration',
      },
      buttons: {
        addAndAuthenticate: 'Add and Authenticate',
      },
      tooltips: {
        invalidInputs: 'Please fill out all required fields',
      },
    },
    connectionDetails: {
      info: {
        username: 'Username',
        endpoint: 'Endpoint',
        alivenessCheck: 'Aliveness Check',
      },
      tags: {
        sso: 'SSO',
      },
      collapsibleSections: {
        ssoControls: 'SSO Controls',
      },
      buttons: {
        reauthenticate: 'Re-authenticate',
        logout: 'Logout',
        edit: 'Edit Endpoint',
        delete: 'Delete Endpoint',
        diagnostics: 'Diagnostics',
      },
      errorCallouts: {
        basicAuthFailure: {
          title: 'Stardog Authentication Failed',
          body: /* istanbul ignore next  */ (
            reauthorizationButtoon: JSX.Element
          ) => {
            return (
              <div>
                <p>
                  Authentication to the Stardog endpoint has failed. Please try
                  re-authenticating.
                </p>
                {reauthorizationButtoon}
              </div>
            );
          },
        },
        browserAuthFailure: {
          title: 'Stardog Authentication Failed',
          body:
            'Browser authentication failed for this endpoint. Please re-authenticate.',
        },
        ssoAuthFailure: {
          noToken: {
            title: 'SSO Authentication Required',
            body: /* istanbul ignore next  */ (
              reauthorizionButton: JSX.Element
            ) => {
              return (
                <div>
                  <p>
                    Your session has expired. Please log in again to continue
                    using this Stardog endpoint. This endpoint requires Single
                    Sign-On (SSO) authentication.
                  </p>
                  {reauthorizionButton}
                </div>
              );
            },
          },
          existingToken: {
            title: 'Stardog Authentication Failed',
            body: /* istanbul ignore next  */ (
              roleClaims: any,
              reauthorizionButton: JSX.Element
            ) => {
              return (
                <>
                  <p>
                    Your authentication for this endpoint has expired or is not
                    valid.
                  </p>
                  {roleClaims.length > 0 && (
                    <>
                      <p>
                        <strong>Your current role claims:</strong>
                      </p>
                      <ul>
                        {roleClaims.map((role: string) => (
                          <li key={role}>
                            <strong>{role}</strong>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <p>
                    To authenticate successfully, your user roles must be
                    properly configured on the Stardog server. If you&apos;re
                    experiencing issues, please contact your system
                    administrator to verify your role configuration.
                  </p>
                  <p>Please log in again to refresh your authentication.</p>
                  {reauthorizionButton}
                </>
              );
            },
          },
          existingTokenFallback: {
            title: 'Stardog Authentication Failed',
            body: /* istanbul ignore next  */ (
              reauthorizionButton: JSX.Element
            ) => {
              return (
                <>
                  <p>
                    Your authentication for this endpoint has expired or is not
                    valid.
                  </p>
                  <p>Please re-authenticate to obtain a valid token.</p>
                  {reauthorizionButton}
                </>
              );
            },
          },
        },
      },
    },
  },
};

export const landingDocumentationList = [
  {
    name: 'Getting Started with Stardog Cloud',
    hyperlink:
      'https://docs.stardog.com/get-started/getting-started-stardog-cloud',
    description: '',
  },
  {
    name: 'Virtual Graphs',
    hyperlink: 'https://docs.stardog.com/virtual-graphs/',
    description: '',
  },
  {
    name: 'Inference Engine',
    hyperlink: 'https://docs.stardog.com/inference-engine/',
    description: '',
  },
  {
    name: 'Stardog Designer',
    hyperlink: 'https://docs.stardog.com/stardog-applications/designer/',
    description: '',
  },
  {
    name: 'Stardog Explorer',
    hyperlink: 'https://docs.stardog.com/stardog-applications/explorer/',
    description: '',
  },
];

export const landingToolsList = [
  {
    name: 'Tutorials',
    hyperlink: '/kits?type=urn%3Astardog%3Amodules%3Atypes%3ATutorial',
    internal: true,
  },
  {
    name: 'Demos',
    hyperlink: '/kits?type=urn%3Astardog%3Amodules%3Atypes%3ADemo',
    internal: true,
  },
  {
    name: 'More Kits...',
    hyperlink: '/kits',
    description: 'Load your database with a selection of pre-made datasets',
    internal: true,
  },
];

export const landingTutorialsList = [
  {
    name: 'Getting Started Series',
    hyperlink: 'https://docs.stardog.com/tutorials/getting-started-series/',
    description: '',
  },
  {
    name: 'Using Virtual Graphs',
    hyperlink: 'https://docs.stardog.com/tutorials/using-virtual-graphs',
    description: '',
  },
  {
    name: 'Virtual Graph Mappings',
    hyperlink: 'https://docs.stardog.com/tutorials/virtual-graph-mappings',
    description: '',
  },
  {
    name: 'Learn SPARQL',
    hyperlink: 'https://docs.stardog.com/tutorials/learn-sparql',
    description: '',
  },
  {
    name: 'Build a Python App',
    hyperlink: 'https://docs.stardog.com/tutorials/build-a-python-app',
    description: '',
  },
];

export const databricksExtBlogList = [
  {
    name: 'Stardog Is Now Available in Databricks Partner Connect',
    hyperlink:
      'https://www.stardog.com/blog/stardog-is-now-available-in-databricks-partner-connect/',
    description: 'Aug 24, 2022, 6 minute read',
  },
];

export const landingBlogList = [
  {
    name:
      'Databricks Lakehouse + Stardog Enterprise Knowledge Graph = Data Democratization',
    hyperlink:
      'https://www.stardog.com/blog/databricks-lakehouse-stardog-enterprise-knowledge-graph-data-democratization/',
    description: 'Mar 22, 2022, 5 minute read',
  },
  {
    name: 'Knowledge Graphs and Machine Learning',
    hyperlink:
      'https://www.stardog.com/blog/knowledge-graphs-and-machine-learning/',
    description: 'Mar 17, 2022, 10 minute read',
  },
  {
    name: 'How to Build a Knowledge Graph',
    hyperlink: 'https://www.stardog.com/building-a-knowledge-graph/',
    description: 'Feb 23, 2022, 13 minute read',
  },
];

export const blogList = [
  {
    title:
      'Databricks Lakehouse + Stardog Enterprise Knowledge Graph = Data Democratization',
    meta: 'Mar 22, 2022, 5 minute read',
    author: 'Navin Sharma',
    link:
      'https://www.stardog.com/blog/databricks-lakehouse-stardog-enterprise-knowledge-graph-data-democratization/',
    hero:
      'https://www.stardog.com/img/complex-abstract-graph-background-picture-id943570972.jpg',
    abstract:
      'Creating a data-driven culture that looks to democratize data across all aspects of their business functions and operations for richer, faster insights that turn into actionable intelligence at the speed of business.',
  },
  {
    title: 'Knowledge Graphs and Machine Learning',
    meta: 'Mar 17, 2022, 10 minute read',
    author: 'Colleen Luther',
    link: 'https://www.stardog.com/blog/knowledge-graphs-and-machine-learning/',
    hero:
      'https://www.stardog.com/img/screen-shot-2022-03-09-at-9.51.50-am.png',
    abstract:
      'Knowledge graphs make it easier to feed better and richer data into ML algorithms. The inherent traits of knowledge graphs posit them as a top tool of modern AI and ML strategy. Let’s examine a few ways in which they help.',
  },
  {
    title: 'How to Build a Knowledge Graph',
    meta: 'Feb 23, 2022, 13 minute read',
    author: '',
    link: 'https://www.stardog.com/building-a-knowledge-graph/',
    hero: 'https://www.stardog.com/img/based-on-graph.gif',
    abstract:
      'Practical steps for building knowledge graphs: powerful tools for linked data, data integration, and data management. Scale all those use cases that have been inspired by data science. Increase your number of users, as needed. And spread the use of data itself. Do you really need a knowledge graph?  Data rules the world. But organizations struggle to leverage that data for a competitive advantage. Raw, uninterpreted data in a system somewhere isn’t very helpful.',
  },
];

export const databricksVideo = {
  title: 'Getting Started with Databricks',
  link: 'https://www.youtube.com/watch?v=VusaBqefrfo',
  thumbnail: 'http://img.youtube.com/vi/VusaBqefrfo/hqdefault.jpg',
  heapEvent: 'Databricks Getting Started Video Clicked',
  description: (
    <>
      <p>
        When you establish a connection with Databricks using Partner Connect,
        two Knowledge Kits are automatically installed for you. These Kits
        establish a Semantic Layer for two popular Databricks samples, NYC Taxi,
        and TPCH.
      </p>
      <p>
        Watch this video for a step by step overview of how to get started with
        Stardog &amp; Databricks.
      </p>
      <p>For more information about Stardog, check out the resources below!</p>
    </>
  ),
};
