import { HTMLTable } from '@blueprintjs/core';
import * as React from 'react';

import { ApiTokenRow } from 'src/ui/containers/api-tokens/ApiTokenRow';
import { ApiToken } from 'src/ui/graph/types';
import * as copy from 'src/ui/templates/copy';

interface ApiTokensTableProps {
  apiTokens: ApiToken[];
  appId?: string;
}

export const ApiTokensTable: React.VFC<ApiTokensTableProps> = ({
  apiTokens,
  appId,
}) => {
  return (
    <HTMLTable striped>
      <thead>
        <tr>
          <th>{copy.components.manageApiTokens.apiTokenRow.table.name}</th>
          <th>{copy.components.manageApiTokens.apiTokenRow.table.created}</th>
          <th>{copy.components.manageApiTokens.apiTokenRow.table.lastUsed}</th>
          <th>{copy.components.manageApiTokens.apiTokenRow.table.expires}</th>
        </tr>
      </thead>
      <tbody>
        {apiTokens.map((apiToken, idx) => (
          <ApiTokenRow
            key={apiToken.id}
            apiToken={apiToken}
            appId={appId}
            idx={idx}
          />
        ))}
      </tbody>
    </HTMLTable>
  );
};
