import {
  Connection as StardogConnection,
  dataSources as StardogDataSources,
  db as StardogDb,
  query as StardogQuery,
  virtualGraphs as StardogVirtualGraphs,
} from 'stardog';

import { Connection as GraphConnection } from 'src/ui/graph/types';
import {
  CREATE_GRAPHS_ALIAS_QUERY,
  CREATE_README_QUERY,
} from 'src/ui/hooks/databricksstarterkit/constants/common';
import {
  NYCTAXI_MAPPINGS,
  NYCTAXI_MODULE_TRIG,
  NYC_SPARQL_QUERY_FILE_MAP,
} from 'src/ui/hooks/databricksstarterkit/constants/nyctaxi';
import {
  TPCH_MAPPINGS,
  TPCH_MODULE_TRIG,
  TPCH_SPARQL_QUERY_FILE_MAP,
} from 'src/ui/hooks/databricksstarterkit/constants/tpch';
import { add } from 'src/ui/hooks/db';
import { runWithRefetchConnectionToken } from 'src/ui/hooks/refetchConnectionToken';

export class ModuleDeployment {
  private db: string;

  private connection: GraphConnection;

  private stardogConnection: StardogConnection;

  constructor(
    connection: GraphConnection,
    stardogConnection: StardogConnection,
    database: string
  ) {
    this.connection = connection;
    this.stardogConnection = stardogConnection;
    this.db = database;
  }

  public add = async (
    module: string,
    jdbcUrl: string,
    personalAccessToken: string
  ) => {
    const databricksModule = 'databricks_'.concat(module);
    const listDataSources = await runWithRefetchConnectionToken(
      this.stardogConnection,
      this.connection.index,
      (stardogConn) => StardogDataSources.list(stardogConn)
    );
    const dataSources: string[] = listDataSources.body.data_sources;
    let addDataSource = true;
    const dataSourceExists = dataSources.indexOf(databricksModule) >= 0;
    // Add datasource
    if (!dataSourceExists) {
      addDataSource = await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) =>
          StardogDataSources.add(stardogConn, databricksModule, {
            'jdbc.url': jdbcUrl,
            'jdbc.driver': 'com.databricks.client.jdbc.Driver',
            testOnBorrow: true,
            defaultCatalog: 'samples',
            'parser.sql.quoting': 'ANSI',
            validationQuery: 'select 1',
            'sql.schemas': '*.*',
            'jdbc.username': 'token',
            'jdbc.password': personalAccessToken,
          })
      ).then((response) => Promise.resolve(response.ok));
    }
    await add(
      this.connection,
      this.db,
      module === 'nyctaxi' ? NYCTAXI_MODULE_TRIG : TPCH_MODULE_TRIG,
      'application/trig'
    );
    const response = await runWithRefetchConnectionToken<StardogDb.transaction.TransactionResponse>(
      this.stardogConnection,
      this.connection.index,
      (stardogConn) => StardogDb.transaction.begin(stardogConn, this.db)
    );
    try {
      if (!dataSourceExists) {
        // Import readme
        await runWithRefetchConnectionToken(
          this.stardogConnection,
          this.connection.index,
          (stardogConn) =>
            StardogQuery.execute(
              stardogConn,
              this.db,
              CREATE_README_QUERY(databricksModule)
            )
        );
      }
      // Create virtual graphs
      const vgOptions: StardogVirtualGraphs.AllVgOptions = {
        'mappings.syntax': 'SMS2',
        'jdbc.url': jdbcUrl,
        'jdbc.driver': 'com.databricks.client.jdbc.Driver',
        'parser.sql.quoting': 'ANSI',
        'sql.schemas': '*.*',
        'jdbc.username': 'token',
        'jdbc.password': personalAccessToken,
      };
      const listVGs = await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) => StardogVirtualGraphs.list(stardogConn)
      );
      const virtualGraphsList: string[] = listVGs.body.virtual_graphs;
      let addedVirtuals = true;
      const virtualGraphName = 'vg_'.concat(databricksModule);
      if (
        virtualGraphsList.indexOf('virtual://'.concat(virtualGraphName)) < 0
      ) {
        addedVirtuals = await runWithRefetchConnectionToken(
          this.stardogConnection,
          this.connection.index,
          (stardogConn) =>
            StardogVirtualGraphs.add(
              stardogConn,
              virtualGraphName,
              databricksModule === 'databricks_nyctaxi'
                ? NYCTAXI_MAPPINGS
                : TPCH_MAPPINGS,
              vgOptions,
              { db: this.db, dataSource: databricksModule }
            )
        ).then((response) => Promise.resolve(response.ok));
      }
      let saveAlias = true;
      // Save alias
      if (!dataSourceExists) {
        saveAlias = await runWithRefetchConnectionToken(
          this.stardogConnection,
          this.connection.index,
          (stardogConn) =>
            StardogQuery.execute(
              stardogConn,
              this.db,
              CREATE_GRAPHS_ALIAS_QUERY(databricksModule)
            )
        ).then((response) => Promise.resolve(response.ok));
      }
      // Add reasoning schemas
      const meta = {
        'reasoning.schemas': `tpch=urn:databricks:demos:tpch:model\x02nyctaxi=urn:databricks:demos:nyctaxi:model`,
      };

      const saveSchema = await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) => StardogDb.options.set(stardogConn, this.db, meta)
      ).then((response) => Promise.resolve(response.ok));

      // Create stored query
      const storedQuery = await this.createStoredQuery(databricksModule);
      await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) =>
          StardogDb.transaction.commit(
            stardogConn,
            this.db,
            response.transactionId
          )
      );
      const persistSecurity: Promise<boolean> = Promise.resolve(true);

      const persistOp: Promise<boolean> = Promise.all([
        addDataSource,
        addedVirtuals,
        saveAlias,
        saveSchema,
        persistSecurity,
        storedQuery,
      ]).then((allResults) => !allResults.find((r) => r === false));

      return persistOp;
    } catch (exception) {
      await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) =>
          StardogDb.transaction.rollback(
            stardogConn,
            this.db,
            response.transactionId
          )
      );
      console.warn(exception);
      return Promise.resolve(false);
    }
  };

  public remove = async (module: string): Promise<boolean> => {
    try {
      // Remove stored query
      let sparqlQueryFileMap = NYC_SPARQL_QUERY_FILE_MAP;
      if (module === 'tpch') {
        sparqlQueryFileMap = TPCH_SPARQL_QUERY_FILE_MAP;
      }
      const databricksModule = 'databricks_'.concat(module);

      const tableNames = sparqlQueryFileMap.keys();
      let iter = tableNames.next();
      while (!iter.done) {
        try {
          const val = iter.value;
          runWithRefetchConnectionToken(
            this.stardogConnection,
            this.connection.index,
            (stardogConn) => StardogQuery.stored.remove(stardogConn, val)
          )
            .then((response) => Promise.resolve(response.ok))
            .catch((error) => console.error(error));
        } catch (exception) {
          return Promise.resolve(false);
        }
        iter = tableNames.next();
      }

      // Remove virtual graphs
      const removedVirtuals = await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) =>
          StardogVirtualGraphs.remove(
            stardogConn,
            'vg_'.concat(databricksModule)
          )
      );
      if (!removedVirtuals.ok) {
        /* istanbul ignore next: no need to test defensive code */ return Promise.resolve(
          removedVirtuals.ok
        );
      }

      // Remove datasource
      const removeDataSource = await runWithRefetchConnectionToken(
        this.stardogConnection,
        this.connection.index,
        (stardogConn) =>
          StardogDataSources.remove(stardogConn, databricksModule)
      ).then((response) => Promise.resolve(response.ok));
      const persistSecurity: Promise<boolean> = Promise.resolve(true);

      const persistOp: Promise<boolean> = Promise.all([
        removeDataSource,
        persistSecurity,
      ]).then((allResults) => !allResults.find((r) => r === false));

      return persistOp;
    } catch (exception) /* istanbul ignore next: no need to test defensive code */ {
      return Promise.resolve(false);
    }
  };

  private createStoredQuery = async (module: string) => {
    let sparqlQueryFileMap = NYC_SPARQL_QUERY_FILE_MAP;
    if (module === 'databricks_tpch') {
      sparqlQueryFileMap = TPCH_SPARQL_QUERY_FILE_MAP;
    }

    try {
      await Promise.all(
        Array.from(sparqlQueryFileMap).map(
          async ([key, value]: [string, string]) => {
            const queryOptions: StardogQuery.StoredQueryOptions = {
              name: value,
              database: this.db,
              query: key,
              shared: true,
            };
            return runWithRefetchConnectionToken(
              this.stardogConnection,
              this.connection.index,
              (stardogConn) =>
                StardogQuery.stored.update(stardogConn, queryOptions)
            ).then((response) => Promise.resolve(response.ok));
          }
        )
      );
    } catch (exception) /* istanbul ignore next: no need to test defensive code */ {
      return false;
    }
    return true;
  };
}
