/* istanbul ignore file: just rendering a button */
import { Classes } from '@blueprintjs/core';
import * as React from 'react';

import { copy } from 'src/ui/templates/copy';
import pingLogo from 'src/ui/templates/images/ping-logo.svg';
import { doPingRedirect } from 'src/ui/utils/window';

/**
 * This uses just a plain button as we use an image to display the actual
 * button. We don't want this to be styled by blueprintjs.
 */
export const PingLink: React.VFC = () => {
  return (
    <button
      type="button"
      onClick={doPingRedirect}
      className={`${Classes.BUTTON} oauth`}
    >
      <img src={pingLogo} alt={copy.ping.label} />
    </button>
  );
};
