import { FormGroup, InputGroup } from '@blueprintjs/core';
import * as React from 'react';

import type { DataSourceSubFormProps } from 'src/ui/containers/dialogs/DataSourceForm';
import * as copy from 'src/ui/templates/copy';

export const DataSourceRdbmsForm: React.VFC<DataSourceSubFormProps> = ({
  dataSource,
  formHandler,
  disabled,
}) => {
  const { formData, handleTextFieldChange } = formHandler;

  return (
    <>
      <FormGroup
        labelFor="data-source-rdbms-name"
        label={copy.components.dataSourceDialog.form.name}
        helperText={copy.components.dataSourceDialog.form.nameHelp}
      >
        <InputGroup
          id="data-source-rdbms-name"
          value={dataSource.name}
          fill
          disabled
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.rdbms.url}
        labelFor="data-source-rdbms-url"
      >
        <InputGroup
          id="data-source-rdbms-url"
          value={formData['jdbc.url']}
          disabled
          name="jdbc.url"
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.rdbms.username}
        labelFor="data-source-rdbms-username"
      >
        <InputGroup
          autoFocus
          id="data-source-rdbms-username"
          name="jdbc.username"
          autoComplete="username"
          value={formData['jdbc.username']}
          disabled={disabled}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <FormGroup
        label={copy.components.dataSourceDialog.form.rdbms.password}
        labelFor="data-source-rdbms-password"
      >
        <InputGroup
          id="data-source-rdbms-password"
          name="jdbc.password"
          type="password"
          autoComplete="password"
          disabled={disabled}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
    </>
  );
};
