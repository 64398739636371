import {
  Callout,
  Checkbox,
  FormGroup,
  InputGroup,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { Selector } from 'src/ui/components/Selector';
import { SelectOption } from 'src/ui/constants/types';
import { User } from 'src/ui/graph/sdk';
import { ProfileInput } from 'src/ui/graph/types';
import { useFormHandler } from 'src/ui/hooks/forms';
import { components } from 'src/ui/templates/copy';

type ProfileFormProps = {
  actions: FormAction[];
  user: User;
  onSubmit: (profile: ProfileInput) => any;
};

export const UpdateProfileForm: React.VFC<ProfileFormProps> = ({
  actions,
  user,
  onSubmit,
}) => {
  const {
    formData: localUser,
    handleValueChange,
    handleTextFieldChange,
  } = useFormHandler<ProfileInput>({
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    company: user.company || '',
    use_case: user.use_case || '',
    industry: user.industry || '',
    best_describes_role: user.best_describes_role || '',
    familiarity_with_kgs: user.familiarity_with_kgs || '',
    best_describes_company: user.best_describes_company || '',
  });
  const [terms, setTerms] = React.useState<boolean>(true);
  const [policy, setPolicy] = React.useState<boolean>(true);
  const [warning, setWarning] = React.useState<string | null>(null);

  React.useEffect(() => {
    const validationMessage = validate();
    if (warning && validationMessage === '') {
      setWarning(null);
    }
  }, [warning, localUser]);

  /* istanbul ignore next: no need to test react state updates */
  const handleTermsChange = () => {
    setTerms(!terms);
  };
  /* istanbul ignore next: no need to test react state updates */
  const handlePolicyChange = () => {
    setPolicy(!policy);
  };

  /* istanbul ignore next: lots of trivial branches here */
  const validate = (): string | null => {
    if (!localUser.first_name) {
      return components.createProfileDialog.formValidation.firstNameRequired;
    }
    if (!localUser.last_name) {
      return components.createProfileDialog.formValidation.lastNameRequired;
    }
    if (!localUser.company) {
      return components.createProfileDialog.formValidation.companyRequired;
    }
    if (!localUser.use_case) {
      return components.createProfileDialog.formValidation.useCaseRequired;
    }
    if (!localUser.industry) {
      return components.createProfileDialog.formValidation.industryRequired;
    }
    if (!localUser.best_describes_company) {
      return components.createProfileDialog.formValidation.bestDescribesCompany;
    }
    if (!terms) {
      return components.createProfileDialog.formValidation.termsRequired;
    }
    if (!policy) {
      return components.createProfileDialog.formValidation.privacyRequired;
    }
    return null;
  };

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    const validationMessage = validate();

    if (validationMessage !== null) {
      setWarning(validationMessage);
      return;
    }

    onSubmit(localUser);
  };

  const selectorsCopy = components.createProfileDialog.selectors;

  const industryOptions: SelectOption[] = Object.values(
    selectorsCopy.industry.options
  ).map((option) => {
    return { label: option, value: option };
  });

  const bestDescribesCompanyOptions: SelectOption[] = Object.values(
    selectorsCopy.bestDescribesCompany.options
  ).map((option) => {
    return { label: option, value: option };
  });

  const handleIndustryChange = (selectOption: SelectOption) => {
    const { value } = selectOption;
    handleValueChange<keyof ProfileInput>('industry')(
      value as keyof ProfileInput
    );
  };

  const handleBestDescribesCompanyChange = (selectOption: SelectOption) => {
    const { value } = selectOption;
    handleValueChange<keyof ProfileInput>('best_describes_company')(
      value as keyof ProfileInput
    );
  };

  const callout = warning ? (
    <Callout intent="danger" data-testid="profile-validation-error">
      {warning}
    </Callout>
  ) : null;

  return (
    <form onSubmit={handleSubmit}>
      <div className="sd-dialog-profile__name__container">
        <FormGroup
          label={components.createProfileDialog.textInputs.firstName.label}
          labelFor="update-profile-first_name"
          className="sd-dialog-profile__name__form-group"
        >
          <InputGroup
            className="sd-dialog-profile__text-input"
            id="update-profile-first_name"
            name="first_name"
            value={localUser.first_name}
            onChange={handleTextFieldChange}
          />
        </FormGroup>
        <FormGroup
          label={components.createProfileDialog.textInputs.lastName.label}
          labelFor="update-profile-last_name"
          className="sd-dialog-profile__name__form-group"
        >
          <InputGroup
            className="sd-dialog-profile__text-input"
            id="update-profile-last_name"
            name="last_name"
            value={localUser.last_name}
            onChange={handleTextFieldChange}
          />
        </FormGroup>
      </div>
      <FormGroup
        label={components.createProfileDialog.textInputs.companyName.label}
        labelFor="update-profile-company_name"
      >
        <InputGroup
          className="sd-dialog-profile__text-input"
          id="update-profile-company_name"
          name="company"
          value={localUser.company}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <FormGroup
        labelFor="update-profile-use_case"
        label={components.createProfileDialog.textInputs.useCase.label}
      >
        <InputGroup
          className="sd-dialog-profile__text-input"
          id="update-profile-use_case"
          name="use_case"
          value={localUser.use_case}
          onChange={handleTextFieldChange}
        />
      </FormGroup>

      <div className="sd-dialog-profile__selector-container">
        <p>{components.createProfileDialog.selectors.industry.label}</p>
        <Selector
          selectedItem={{
            label: localUser.company,
            value: localUser.company,
          }}
          onSelect={handleIndustryChange}
          items={industryOptions}
          buttonText={localUser.industry || ''}
          popoverPosition={Position.BOTTOM_LEFT}
          filterable={false}
          className="sd-dialog-profile__selector"
          popoverTargetClassName="sd-dialog-profile__selector__popover-target"
          buttonClassName="sd-dialog-profile__selector__button"
          buttonProps={{
            outlined: true,
          }}
          rightIcon={IconNames.CHEVRON_DOWN}
        />
      </div>
      <div className="sd-dialog-profile__selector-container">
        <p>
          {components.createProfileDialog.selectors.bestDescribesCompany.label}
        </p>
        <Selector
          selectedItem={{
            label: localUser.best_describes_company || '',
            value: localUser.best_describes_company || '',
          }}
          onSelect={handleBestDescribesCompanyChange}
          items={bestDescribesCompanyOptions}
          buttonText={localUser.best_describes_company || ''}
          popoverPosition={Position.BOTTOM_LEFT}
          filterable={false}
          className="sd-dialog-profile__selector"
          popoverTargetClassName="sd-dialog-profile__selector__popover-target"
          buttonClassName="sd-dialog-profile__selector__button"
          buttonProps={{
            outlined: true,
          }}
          rightIcon={IconNames.CHEVRON_DOWN}
        />
      </div>
      <FormGroup>
        <Checkbox checked={terms} onChange={handleTermsChange} required>
          {components.createProfileDialog.checkboxes.agree}
          &nbsp;
          <a
            href="https://www.stardog.com/cloud-tos"
            target="_blank"
            rel="noreferrer"
          >
            {components.createProfileDialog.checkboxes.usageTerms}
          </a>
        </Checkbox>
        <Checkbox checked={policy} onChange={handlePolicyChange} required>
          {components.createProfileDialog.checkboxes.agree}
          &nbsp;
          <a
            href="https://www.stardog.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {components.createProfileDialog.checkboxes.privacyPolicy}
          </a>
        </Checkbox>
        {callout}
      </FormGroup>
      <input type="submit" hidden />
      <FormActions actions={actions} handleSubmit={handleSubmit} />
    </form>
  );
};
