import {
  AnchorButton,
  Callout,
  H4,
  Intent,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { ItemSelector, Tooltip } from 'vet-bones/components';
import { alphaSorter, numericSort } from 'vet-bones/utils';
import { SelectItem, createSelectItem } from 'vet-bones/utils/select';
import {
  StardogVoiceboxDiagnosticsResult,
  StardogVoiceboxDiagnosticsSeverity,
  requestStardogVoiceboxDiagnostics,
  usePortalVoiceboxDatabases,
} from 'vet-bones/utils/voicebox';

import {
  DIAGNOSTIC_VOICEBOX_DATABASE_SELECTOR,
  DIAGNOSTIC_VOICEBOX_DATABASE_SELECTOR_ITEM,
  DIAGNOSTIC_VOICEBOX_GENERATE_REPORT_BUTTON,
  DIAGNOSTIC_VOICEBOX_MODEL_SELECTOR,
  DIAGNOSTIC_VOICEBOX_MODEL_SELECTOR_ITEM,
  DIAGNOSTIC_VOICEBOX_RESULTS_SPINNER,
} from 'src/ui/constants/testIds';
import { components, copy } from 'src/ui/templates/copy';

export interface DiagnosticVoiceboxSectionProps {
  connectionIndex: number;
  connectionId: string;
}

export const DiagnosticVoiceboxSection: React.VFC<DiagnosticVoiceboxSectionProps> = ({
  connectionIndex,
  connectionId,
}) => {
  const databasesQuery = usePortalVoiceboxDatabases(connectionIndex);

  const [databaseId, setDatabaseId] = React.useState('');
  const [model, setModel] = React.useState('');

  const databasesById = databasesQuery.data || {};
  const databaseItems = Object.keys(databasesById)
    .sort(alphaSorter)
    .map((databaseId) => createSelectItem(databaseId, databaseId));
  const selectedDatabaseItem = databaseId
    ? databaseItems.find((item) => item.value === databaseId) ||
      createSelectItem(databaseId, databaseId)
    : null;

  const modelItems: SelectItem[] = [];
  const databaseData = databasesById[databaseId];
  if (databaseData) {
    modelItems.push(
      ...[...databaseData.models]
        .sort(alphaSorter)
        .map((model) => createSelectItem(model, model))
    );
  }
  const selectedModelItem = model
    ? modelItems.find((item) => item.value === model) ||
      createSelectItem(model, model)
    : null;

  const resultsCalloutsRef = React.useRef<HTMLDivElement>(null);
  const [results, setResults] = React.useState<
    StardogVoiceboxDiagnosticsResult[]
  >([]);
  const [resultsLoading, setResultsLoading] = React.useState(false);

  const onGenerateReport = React.useCallback(async () => {
    if (!databaseId) {
      return;
    }

    setResults([]);
    setResultsLoading(true);
    const response = await requestStardogVoiceboxDiagnostics({
      connectionId,
      databaseId,
      model: model || undefined,
      checkQueries: true,
    });
    setResultsLoading(false);

    if (response.error) {
      setResults([
        {
          severity: StardogVoiceboxDiagnosticsSeverity.ERROR,
          text: copy.diagnostic.voicebox.generateReportResponseError(
            response.error
          ),
        },
      ]);
      return;
    }

    setResults(response.results || []);

    if (resultsCalloutsRef.current) {
      resultsCalloutsRef.current.scrollIntoView({
        behavior: 'smooth',
        // scroll the top of the resultsCalloutsRef to the top of the screen,
        // since we're the last element on the page
        block: 'start',
      });
    }
  }, [connectionId, databaseId, model]);

  const anyLoading = databasesQuery.isLoading || resultsLoading;

  const resultsCallouts = React.useMemo(() => {
    const textBySeverity: Map<
      StardogVoiceboxDiagnosticsSeverity,
      string[]
    > = new Map();
    results.forEach(({ severity, text }) => {
      textBySeverity.set(severity, [
        ...(textBySeverity.get(severity) || []),
        text,
      ]);
    });

    return Array.from(textBySeverity.entries())
      .sort(
        numericSort(([severity]) => {
          if (severity === StardogVoiceboxDiagnosticsSeverity.ERROR) {
            return 0;
          }
          if (severity === StardogVoiceboxDiagnosticsSeverity.WARNING) {
            return 1;
          }
          if (severity === StardogVoiceboxDiagnosticsSeverity.INFO) {
            return 2;
          }
          return 3;
        })
      )
      .map(([severity, texts]) => {
        let title: string | undefined;
        let intent: Intent = Intent.NONE;
        if (severity === StardogVoiceboxDiagnosticsSeverity.ERROR) {
          title = copy.diagnostic.voicebox.severityTitle.error;
          intent = Intent.DANGER;
        } else if (severity === StardogVoiceboxDiagnosticsSeverity.WARNING) {
          title = copy.diagnostic.voicebox.severityTitle.warning;
          intent = Intent.WARNING;
        } else if (severity === StardogVoiceboxDiagnosticsSeverity.INFO) {
          title = copy.diagnostic.voicebox.severityTitle.info;
          intent = Intent.PRIMARY;
        }
        return (
          <Callout
            key={severity}
            className="sd-diagnostic-voicebox__results__callout"
            intent={intent}
            title={title}
          >
            <ul>
              {texts.map((text) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          </Callout>
        );
      });
  }, [results]);

  return (
    <div className="sd-diagnostic-voicebox">
      <H4>{copy.diagnostic.voicebox.title}</H4>
      <div className="sd-diagnostic-voicebox__top-bar">
        <div className="sd-diagnostic-voicebox__top-bar__selectors">
          <ItemSelector.Single
            buttonIcon={IconNames.DATABASE}
            buttonText={
              databaseId ||
              components.manageApiTokens.voiceboxConfig.database.placeholder
            }
            items={databaseItems}
            itemTestId={DIAGNOSTIC_VOICEBOX_DATABASE_SELECTOR_ITEM}
            onSelect={(item) => {
              if (databasesQuery.isLoading) {
                return;
              }

              if (item && item.value && item.value !== databaseId) {
                const nextDatabaseId = item.value;
                setDatabaseId(nextDatabaseId);

                const nextModels = databasesById[nextDatabaseId]?.models;
                if (!model || !nextModels?.includes(model)) {
                  setModel('');
                }
              }
            }}
            selected={selectedDatabaseItem}
            testId={DIAGNOSTIC_VOICEBOX_DATABASE_SELECTOR}
          />
          <ItemSelector.Single
            clearable
            buttonText={model || copy.diagnostic.voicebox.selector.model}
            disabled={!databaseId}
            items={modelItems}
            itemTestId={DIAGNOSTIC_VOICEBOX_MODEL_SELECTOR_ITEM}
            onSelect={(item) => {
              if (databasesQuery.isLoading) {
                return;
              }

              setModel(item?.value || '');
            }}
            selected={selectedModelItem}
            testId={DIAGNOSTIC_VOICEBOX_MODEL_SELECTOR}
          />
        </div>
        <div className="sd-diagnostic-voicebox__top-bar__submit">
          {resultsLoading ? (
            <Spinner
              data-testid={DIAGNOSTIC_VOICEBOX_RESULTS_SPINNER}
              size={SpinnerSize.SMALL}
            />
          ) : null}
          <Tooltip
            content={copy.diagnostic.voicebox.selector.database}
            disabled={Boolean(databaseId)}
          >
            <AnchorButton
              data-testid={DIAGNOSTIC_VOICEBOX_GENERATE_REPORT_BUTTON}
              disabled={!databaseId || anyLoading}
              intent={Intent.PRIMARY}
              onClick={onGenerateReport}
            >
              {copy.diagnostic.voicebox.generateReportButton}
            </AnchorButton>
          </Tooltip>
        </div>
      </div>

      <div ref={resultsCalloutsRef} className="sd-diagnostic-voicebox__results">
        {resultsCallouts}
      </div>
    </div>
  );
};
