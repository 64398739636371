import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFound } from 'src/ui/components/NotFound';
import { PrivateRoute } from 'src/ui/components/PrivateRoute';
import { ManageConnections } from 'src/ui/containers/connection-list/ManageConnections';
import { Diagnostic } from 'src/ui/containers/diagnostic/Diagnostic';
import { LaunchpadDashboard } from 'src/ui/containers/launchpad/dashboard/LaunchpadDashboard';
import { Login } from 'src/ui/containers/launchpad/Login';
import { VoiceboxPage } from 'src/ui/containers/voicebox/VoiceboxPage';

export const LaunchpadAppBody: React.VFC = () => {
  return (
    <div className="sd-app-body">
      <div className="sd-vertical">
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute exact path="/">
            <LaunchpadDashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/connections">
            <ManageConnections isOnPremise />
          </PrivateRoute>
          <PrivateRoute exact path="/u/:id(\d+)/">
            <LaunchpadDashboard />
          </PrivateRoute>
          <PrivateRoute path="/u/:id(\d+)/diagnostic">
            <Diagnostic />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/u/:id(\d+)/voicebox/:conversation?"
            voiceboxOnly
          >
            <VoiceboxPage />
          </PrivateRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
