import { Button, Checkbox, H1, InputGroup, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'vet-bones/components';
import { useDidValueChange, usePrevious } from 'vet-bones/utils/hooks';

import {
  INTERNAL_USER_SEARCH,
  INTERNAL_USER_SEARCH_DOWNLOAD,
  INTERNAL_USER_SEARCH_FILTER,
} from 'src/ui/constants/testIds';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  User,
  UserSearchFiltersInput,
  useGetUserSearchResultsQuery,
} from 'src/ui/graph/types';
import { useQueryParams } from 'src/ui/hooks/query';
import * as copy from 'src/ui/templates/copy';
import { getFormattedDateTime } from 'src/ui/utils/dateUtils';
import { exportToFile } from 'src/ui/utils/exportToFile';

const CSV_HEADERS: Array<keyof User> = [
  'email',
  'first_name',
  'last_name',

  'company',
  'title',

  'id',
  'last_login',

  'is_databricks_user',
  'is_staff',
  'is_verified',

  'has_stardog_free',
  'is_voicebox_enabled',
  'is_voicebox_api_access_enabled',
  'is_designer_storage_enabled',
];

type SearchFilters = {
  staff: string;
  voiceboxApi: string;
};

export const BASE_PATH = '/internal/user/search';
const buildPath = (query: string, filters: SearchFilters) => {
  let path = `${BASE_PATH}?q=${encodeURIComponent(query)}`;
  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      path += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
  });
  return path;
};

const buildFiltersInput = (filters: SearchFilters): UserSearchFiltersInput => {
  const filtersInput: UserSearchFiltersInput = {};
  if (filters.staff) {
    filtersInput.is_staff = filters.staff === 'on';
  }
  if (filters.voiceboxApi) {
    filtersInput.is_voicebox_api_access_enabled = filters.voiceboxApi === 'on';
  }
  return filtersInput;
};

export const UserSearch: React.VFC = () => {
  const history = useHistory();
  const params = useQueryParams();

  const currSearchQuery = decodeURIComponent(params.get('q') || '');
  const [searchQuery, setSearchQuery] = React.useState(currSearchQuery || '');

  const currStaff = decodeURIComponent(params.get('staff') || '');
  const currVoiceboxApi = decodeURIComponent(params.get('voiceboxApi') || '');
  const [filters, setFilters] = React.useState<SearchFilters>({
    staff: currStaff || '',
    voiceboxApi: currVoiceboxApi || '',
  });

  const handleSearchQueryChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = evt.target;
    history.replace(buildPath(value, filters));
    setSearchQuery(value);
  };

  const handleFilterChange = (key: keyof SearchFilters) => {
    const value = filters[key];
    let nextValue = '';
    if (!value) {
      nextValue = 'on';
    } else if (value === 'on') {
      nextValue = 'off';
    }
    const nextFilters = { ...filters, [key]: nextValue };
    history.replace(buildPath(searchQuery, nextFilters));
    setFilters(nextFilters);
  };

  return (
    <div className="page">
      <H1>{copy.components.internalDashboard.userSearch.title}</H1>
      <Search
        query={searchQuery}
        handleQueryChange={handleSearchQueryChange}
        filters={filters}
        handleFilterChange={handleFilterChange}
      />
      <SearchResults query={searchQuery} filters={filters} />
    </div>
  );
};

type SearchResultsProps = {
  query: string;
  filters: SearchFilters;
};

export interface HistoryState {
  from: string;
}

const DownloadSearchResults: React.VFC<
  SearchResultsProps & { total: number }
> = ({ query, filters, total }) => {
  const { data, isLoading, refetch } = useGetUserSearchResultsQuery(
    graphQLClient,
    {
      token: query,
      filters: buildFiltersInput(filters),
      offset: 0,
      limit: total,
    },
    { enabled: false }
  );

  const prevLoading = usePrevious(isLoading);
  const didLoadingChange = useDidValueChange(isLoading);
  const didDefinedLoadingChange = prevLoading !== undefined && didLoadingChange;
  React.useEffect(() => {
    if (didDefinedLoadingChange && !isLoading) {
      const users = data?.searchUsers as User[] | undefined;
      if (!users?.length) {
        return;
      }

      const usedKeys = new Set(users.flatMap((user) => Object.keys(user)));
      const headers = CSV_HEADERS.filter((key) => usedKeys.has(key));
      const rows = [headers.join(',')];
      users.forEach((user) =>
        rows.push(
          headers
            .map((header) =>
              user[header] === undefined ? '' : `${user[header]}`
            )
            .join(',')
        )
      );

      exportToFile({
        contents: rows.join('\n'),
        filename: `user-search-${Date.now()}.csv`,
        mimeType: 'text/csv',
      });
    }
  }, [data, didDefinedLoadingChange, isLoading]);

  return (
    <Button
      data-testid={INTERNAL_USER_SEARCH_DOWNLOAD}
      disabled={!total || isLoading}
      icon={IconNames.DOWNLOAD}
      onClick={() => refetch()}
      text={copy.components.internalDashboard.userSearch.download}
    />
  );
};

const SearchResults: React.VFC<SearchResultsProps> = ({ query, filters }) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const { data, isLoading } = useGetUserSearchResultsQuery(
    graphQLClient,
    {
      token: query,
      filters: buildFiltersInput(filters),
      offset,
      limit,
    },
    { enabled: Boolean(query || Object.values(filters).some(Boolean)) }
  );

  const columns = [
    {
      name: 'Email',
      id: 1,
      selector: (row: User) => row?.email || '',
    },
    {
      name: 'First Name',
      id: 2,
      selector: (row: User) => row?.first_name || '',
    },
    {
      name: 'Last Name',
      id: 3,
      selector: (row: User) => row?.last_name || '',
    },
    {
      name: 'Company',
      id: 4,
      selector: (row: User) => row?.company || '',
    },
    {
      name: 'Title',
      id: 5,
      selector: (row: User) => row?.title || '',
    },
    {
      name: 'Last Login',
      id: 6,
      selector: (row: User) =>
        getFormattedDateTime(row?.last_login || '', true),
    },
    {
      name: 'Stardog Free',
      id: 7,
      selector: (row: User) => (row?.has_stardog_free ? 'Y' : 'N'),
    },
    {
      name: 'Voicebox',
      id: 8,
      selector: (row: User) => (row?.is_voicebox_enabled ? 'Y' : 'N'),
    },
    {
      name: 'Voicebox API',
      id: 9,
      selector: (row: User) =>
        row?.is_voicebox_api_access_enabled ? 'Y' : 'N',
    },
    {
      name: 'Designer Storage',
      id: 10,
      selector: (row: User) => (row?.is_designer_storage_enabled ? 'Y' : 'N'),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (page === 1) {
      setOffset(0);
      return;
    }
    setOffset(page * limit - limit);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setCurrentPage(page);
    if (page === 1) {
      setOffset(0);
      return;
    }
    setOffset(page * newPerPage - newPerPage);
  };

  const handleRowClicked = (user: User) => {
    // we push state so that the UserDashboard knows how to navigate "Back to Search" properly.
    // If state is set, it knowns to just goBack to preserve search state stored in query param. If not (user navigated to UserDashboard directly),
    // just navigate them back to the URL this component is accessed at ('/internal/user/search')
    const historyState: HistoryState = {
      from: BASE_PATH,
    };
    history.push(`/internal/user/${user.id}`, historyState);
  };

  return (
    <DataTable
      actions={
        <DownloadSearchResults
          filters={filters}
          query={query}
          total={data?.getUserSearchDetails?.total || 0}
        />
      }
      columns={columns}
      data={data?.searchUsers ? (data.searchUsers as User[]) : []}
      dense
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      onRowClicked={handleRowClicked}
      pagination
      paginationDefaultPage={currentPage}
      paginationServer
      paginationTotalRows={
        data?.getUserSearchDetails?.total
          ? data?.getUserSearchDetails?.total
          : 0
      }
      pointerOnHover
      progressPending={isLoading}
      striped
    />
  );
};

type SearchProps = {
  query: string;
  handleQueryChange: (evt: React.ChangeEvent<HTMLInputElement>) => any;
  filters: SearchFilters;
  handleFilterChange: (key: keyof SearchFilters) => any;
};

const Search: React.VFC<SearchProps> = ({
  query,
  handleQueryChange,
  filters,
  handleFilterChange,
}) => {
  return (
    <div className="search">
      <InputGroup
        data-testid={INTERNAL_USER_SEARCH}
        key="search"
        leftIcon="search"
        name="search"
        placeholder={
          copy.components.internalDashboard.userSearch.searchInput.placeholder
        }
        type="search"
        onChange={(e) => {
          handleQueryChange(e);
        }}
        value={query}
      />
      <div className="search-filters">
        <SearchCheckbox
          filterKey="staff"
          filters={filters}
          handleFilterChange={handleFilterChange}
          testId={INTERNAL_USER_SEARCH_FILTER('is_staff')}
        />
        <SearchCheckbox
          filterKey="voiceboxApi"
          filters={filters}
          handleFilterChange={handleFilterChange}
          testId={INTERNAL_USER_SEARCH_FILTER('is_voicebox_api_access_enabled')}
        />
      </div>
    </div>
  );
};

type SearchCheckboxProps = {
  filterKey: keyof SearchFilters;
  filters: SearchFilters;
  handleFilterChange: (key: keyof SearchFilters) => any;
  testId: string;
};

const SearchCheckbox: React.VFC<SearchCheckboxProps> = ({
  filterKey,
  filters,
  handleFilterChange,
  testId,
}) => (
  <Checkbox
    data-testid={testId}
    indeterminate={!filters[filterKey]}
    checked={filters[filterKey] === 'on'}
    onChange={() => handleFilterChange(filterKey)}
  >
    <Tooltip
      content={
        <>
          <Checkbox
            indeterminate
            label={
              copy.components.internalDashboard.userSearch.filters[filterKey]
                .indeterminate
            }
          />
          <Checkbox
            checked
            label={
              copy.components.internalDashboard.userSearch.filters[filterKey]
                .checked
            }
          />
          <Checkbox
            checked={false}
            label={
              copy.components.internalDashboard.userSearch.filters[filterKey]
                .unchecked
            }
          />
        </>
      }
      popoverClassName="search-filters-tooltip"
      position={Position.BOTTOM_RIGHT}
    >
      {copy.components.internalDashboard.userSearch.filters[filterKey].label}
    </Tooltip>
  </Checkbox>
);
