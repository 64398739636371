import {
  AnchorButton,
  Icon,
  IconName,
  InputGroupProps,
  MenuItem,
  PopperBoundary,
  Position,
  Text,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
  IItemRendererProps,
  ItemPredicate,
  ItemRenderer,
  Select,
} from '@blueprintjs/select';
import classNames from 'classnames';
import * as React from 'react';

import { SelectOption } from 'src/ui/constants/types';
import { getRegExpFromString } from 'src/ui/utils/getRegExpFromString';

const OptionSelect = Select.ofType<SelectOption>();

const itemPredicate: ItemPredicate<SelectOption> = (query, item) =>
  getRegExpFromString(query, 'i').test(item.label);

interface SelectorProps {
  buttonProps?: React.ComponentProps<typeof AnchorButton>;
  buttonClassName?: string;
  buttonIcon?: IconName;
  buttonIconColor?: string;
  buttonText: string;
  className?: string;
  disabled?: boolean;
  filterable?: boolean;
  filterableInputProps?: InputGroupProps;
  itemClassName?: string;
  itemMultiline?: boolean;
  items: readonly SelectOption[];
  itemTestId?: (id: string) => string;
  noResultsText?: string;
  onSelect: (value: SelectOption) => any;
  popoverBoundary?: PopperBoundary;
  popoverClassName?: string;
  popoverPosition?: Position;
  popoverTargetClassName?: string;
  rightIcon?: IconName;
  selectedItem: SelectOption | null;
  testId?: string;
}

export const Selector = ({
  buttonProps,
  buttonClassName,
  buttonIcon,
  buttonIconColor,
  buttonText,
  className,
  disabled,
  filterable,
  filterableInputProps,
  itemClassName,
  itemMultiline,
  items,
  itemTestId,
  noResultsText = 'No Results',
  onSelect,
  popoverBoundary,
  popoverClassName,
  popoverTargetClassName,
  popoverPosition,
  rightIcon = IconNames.CARET_DOWN,
  selectedItem,
  testId,
}: SelectorProps) => {
  const [query, setQuery] = React.useState('');
  const [activeItem, setActiveItem] = React.useState<SelectOption | null>(null);

  const itemRenderer: ItemRenderer<SelectOption> = React.useCallback(
    (item: SelectOption, { handleClick, modifiers }: IItemRendererProps) =>
      modifiers.matchesPredicate ? (
        <MenuItem
          data-testid={itemTestId ? itemTestId(`${item.value}`) : undefined}
          className={classNames(itemClassName, {
            'sd-indent-menu': !!item.indent,
          })}
          key={item.value}
          text={item.label}
          icon={
            selectedItem?.value === item.value
              ? IconNames.TICK
              : IconNames.BLANK
          }
          onClick={handleClick}
          active={modifiers.active}
          multiline={itemMultiline}
        />
      ) : null,
    [itemClassName, selectedItem]
  );

  return (
    <OptionSelect
      activeItem={activeItem}
      className={classNames('sd-selector', {
        [className as any]: Boolean(className),
      })}
      disabled={disabled}
      filterable={filterable}
      inputProps={filterableInputProps}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      items={items as SelectOption[]}
      noResults={
        <MenuItem
          className="sd-menu-item-no-results"
          text={noResultsText}
          disabled
        />
      }
      onActiveItemChange={(item) => setActiveItem(item)}
      onItemSelect={(value) => onSelect(value)}
      onQueryChange={(value) => setQuery(value)}
      popoverProps={{
        boundary: popoverBoundary,
        minimal: true,
        position: popoverPosition,
        popoverClassName,
        targetClassName: popoverTargetClassName,
      }}
      query={query}
    >
      <AnchorButton
        data-testid={testId}
        className={classNames('sd-selector-button', {
          [buttonClassName as any]: Boolean(buttonClassName),
        })}
        disabled={disabled}
        icon={
          buttonIcon ? (
            <Icon icon={buttonIcon} color={buttonIconColor} />
          ) : undefined
        }
        rightIcon={rightIcon}
        {...buttonProps}
      >
        <Text ellipsize>{buttonText}</Text>
      </AnchorButton>
    </OptionSelect>
  );
};
