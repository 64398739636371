import { Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';

import { AzureLink } from 'src/ui/components/AzureLink';
import { Dialog } from 'src/ui/components/Dialog';
import { GeoaxisLink } from 'src/ui/components/GeoaxisLink';
import { GoogleLink } from 'src/ui/components/GoogleLink';
import { KeycloakLink } from 'src/ui/components/KeycloakLink';
import { Loading } from 'src/ui/components/Loading';
import { OpenIDLink } from 'src/ui/components/OpenIDLink';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useProfileQuery } from 'src/ui/graph/types';
import { components } from 'src/ui/templates/copy';
import {
  isAzureAuthEnabled,
  isGeoaxisAuthEnabled,
  isGoogleAuthEnabled,
  isKeycloakAuthEnabled,
  isOpenIDAuthEnabled,
} from 'src/ui/utils/features';
import { redirectToUrl } from 'src/ui/utils/window';

export const Login: React.VFC = () => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(true);
  const {
    data: profileData,
    isLoading: profileDataIsLoading,
  } = useProfileQuery(graphQLClient);

  React.useEffect(() => {
    if (profileDataIsLoading || !profileData || !profileData.profile) {
      return;
    }
    if (profileData.profile.is_authenticated) {
      setIsDialogOpen(false);
      redirectToUrl('/');
    }
  }, [profileData, profileDataIsLoading]);

  const azureAuthEnabled = isAzureAuthEnabled();
  const geoaxisAuthEnabled = isGeoaxisAuthEnabled();
  const googleAuthEnabled = isGoogleAuthEnabled();
  const keycloakAuthEnabled = isKeycloakAuthEnabled();
  const openidAuthEnabled = isOpenIDAuthEnabled();

  const azureButton = azureAuthEnabled ? <AzureLink /> : null;
  const geoaxisButton = geoaxisAuthEnabled ? <GeoaxisLink /> : null;
  const googleButton = googleAuthEnabled ? <GoogleLink /> : null;
  const keycloakButton = keycloakAuthEnabled ? <KeycloakLink /> : null;
  const openidButton = openidAuthEnabled ? <OpenIDLink /> : null;

  return isDialogOpen ? (
    <Dialog
      icon={IconNames.OFFLINE}
      title={components.launchpad.login.dialogTitle}
      className={classNames('sd-dialog-connection')}
      isCloseButtonShown={false}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      isOpen={isDialogOpen}
    >
      <div className={Classes.DIALOG_FOOTER}>
        <div className="controls">
          {geoaxisButton}
          {googleButton}
          {keycloakButton}
          {azureButton}
          {openidButton}
        </div>
      </div>
    </Dialog>
  ) : (
    <Loading />
  );
};
