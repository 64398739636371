import {
  AnchorButton,
  MaybeElement,
  Position,
  mergeRefs,
} from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import { Popover2, Popover2Props, Tooltip2 } from '@blueprintjs/popover2';
import * as React from 'react';

export type OwnProps = {
  tooltip: string;
  icon: IconName | MaybeElement;
  testId: string;
};

/**
 * Simple Popover Menu for the NavBar
 *
 * This handles all the complex renderTarget logic to make sure the tooltip is not
 * shown when the menu is open.
 *
 * @example
 *
 * <PopoverMenu content={<Menu />} tooltip="Click ME" icon="plus" testId="my-special" />
 */
export const PopoverMenu: React.VFC<OwnProps & Popover2Props> = ({
  content,
  position,
  tooltip,
  icon,
  testId,
}) => {
  return (
    <Popover2
      content={content}
      position={position || Position.BOTTOM}
      className="pop-over"
      renderTarget={({ isOpen: isPopoverOpen, ref: ref1, ...popoverProps }) => (
        <Tooltip2
          content={tooltip}
          disabled={isPopoverOpen}
          openOnTargetFocus={false}
          position={position || Position.BOTTOM}
          renderTarget={({
            isOpen: _isTooltipOpen,
            ref: ref2,
            ...tooltipProps
          }) => (
            <AnchorButton
              {...popoverProps}
              {...tooltipProps}
              active={isPopoverOpen}
              elementRef={mergeRefs(ref1, ref2)}
              data-testid={testId}
              large
              minimal
              icon={icon}
            />
          )}
        />
      )}
    />
  );
};
