import { EqualityFn, createSelectorHook, useDispatch } from 'react-redux';

import type { AppDispatch, RootState } from 'src/ui/app/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: <Selected = unknown>(
  selector: (state: RootState) => Selected,
  equalityFn?: EqualityFn<Selected> | undefined
) => Selected = createSelectorHook();
