import * as React from 'react';

import { Messages } from 'src/ui/components/Messages';
import { Notifications } from 'src/ui/components/Notifications';
import { ConnectionDialog } from 'src/ui/containers/dialogs/ConnectionDialog';
import { AddSSOConnectionDialog } from 'src/ui/containers/launchpad/AddSSOConnection';
import { LaunchpadAppHooks } from 'src/ui/containers/launchpad/LaunchpadAppHooks';
import { LaunchpadNavbarWrapper } from 'src/ui/containers/launchpad/LaunchpadNavbarWrapper';
import { getFriendlyName } from 'src/ui/utils/window';

export const LaunchpadApp: React.VFC = () => {
  React.useEffect(() => {
    document.title = getFriendlyName();
  }, []);

  return (
    <>
      <main>
        <LaunchpadNavbarWrapper />
      </main>
      <LaunchpadAppHooks />
      <Notifications />
      <Messages />
      <ConnectionDialog showSSO={false} />
      <AddSSOConnectionDialog />
    </>
  );
};
