import { Button, Callout, Intent } from '@blueprintjs/core';
import * as React from 'react';

import { AboutCompany } from 'src/ui/containers/dialogs/CreateProfile/AboutCompany';
import { AboutYou } from 'src/ui/containers/dialogs/CreateProfile/AboutYou';
import { ProfileInput } from 'src/ui/graph/types';
import { useFormHandler } from 'src/ui/hooks/forms';
import { components } from 'src/ui/templates/copy';

type CreateProfileFormProps = {
  onSubmit: (profile: ProfileInput) => any;
  isSubmitting: boolean;
};

export const CreateProfileForm: React.VFC<CreateProfileFormProps> = ({
  onSubmit,
  isSubmitting,
}) => {
  const {
    formData: localUser,
    handleValueChange,
    handleTextFieldChange,
  } = useFormHandler<ProfileInput>({
    first_name: '',
    last_name: '',
    company: '',
    use_case: '',
    familiarity_with_kgs: '',
    best_describes_role: '',
    industry: '',
    best_describes_company: '',
  });

  const [terms, setTerms] = React.useState<boolean>(false);
  const [policy, setPolicy] = React.useState<boolean>(false);
  const [warning, setWarning] = React.useState<string | null>(null);

  const validate = (): string | null => {
    if (!localUser.first_name) {
      return components.createProfileDialog.formValidation.firstNameRequired;
    }
    if (!localUser.last_name) {
      return components.createProfileDialog.formValidation.lastNameRequired;
    }
    if (!localUser.best_describes_role) {
      return components.createProfileDialog.formValidation.bestDescribesRole;
    }
    if (!localUser.company) {
      return components.createProfileDialog.formValidation.companyRequired;
    }
    if (!localUser.use_case) {
      return components.createProfileDialog.formValidation.useCaseRequired;
    }
    if (!localUser.industry) {
      return components.createProfileDialog.formValidation.industryRequired;
    }
    if (!localUser.best_describes_company) {
      return components.createProfileDialog.formValidation.bestDescribesCompany;
    }
    if (!terms) {
      return components.createProfileDialog.formValidation.termsRequired;
    }
    if (!policy) {
      return components.createProfileDialog.formValidation.privacyRequired;
    }
    return null;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const validateMessage = validate();
    if (validateMessage) {
      setWarning(validateMessage);
      return;
    }

    // in case we have a validation message from a previous attempt to continue, clear it
    setWarning('');
    onSubmit(localUser);
  };

  const callout = warning ? (
    <Callout
      className="sd-dialog-profile__validation-callout"
      intent={Intent.DANGER}
      data-testid="profile-validation-error"
    >
      {warning}
    </Callout>
  ) : null;

  return (
    <form onSubmit={handleSubmit}>
      <AboutYou
        profile={localUser}
        handleTextFieldChange={handleTextFieldChange}
        handleValueChange={handleValueChange}
      />
      <AboutCompany
        profile={localUser}
        handleTextFieldChange={handleTextFieldChange}
        handleValueChange={handleValueChange}
        terms={terms}
        setTerms={setTerms}
        policy={policy}
        setPolicy={setPolicy}
      />
      <div className="sd-dialog-profile__button-container">
        <Button
          type="submit"
          intent={Intent.PRIMARY}
          text={components.createProfileDialog.buttons.submit}
          disabled={isSubmitting}
        />
      </div>
      {callout}
    </form>
  );
};
