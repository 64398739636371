import { AnchorButton, Classes, IconName, Intent } from '@blueprintjs/core';
import classnames from 'classnames';
import * as React from 'react';

import { Tooltip } from 'src/ui/components/Tooltip';
import { AnyFunc } from 'src/ui/constants/types';

export type FormAction = {
  disabled?: boolean;
  icon?: IconName | JSX.Element;
  intent?: Intent;
  isSubmit?: boolean;
  onClick?: AnyFunc;
  text: string;
};

type FormProps = {
  actions: FormAction[];
  submitTooltipText?: string;
  isSubmitDisabled?: boolean;
  handleSubmit: AnyFunc;
  wide?: boolean;
};

const getTestID = (action: FormAction): string => {
  const buttonType = action.isSubmit ? 'submit' : 'button';
  const actionText = action.text.toLowerCase().replace(' ', '-');
  return `${actionText}-${buttonType}`;
};

export const FormActions: React.VFC<FormProps> = ({
  actions,
  handleSubmit,
  submitTooltipText = '',
  isSubmitDisabled = false,
  wide = true,
}) => {
  return (
    <div className={Classes.DIALOG_FOOTER}>
      <div
        className={classnames(Classes.DIALOG_FOOTER_ACTIONS, {
          'controls-wide': wide,
        })}
      >
        {actions.map((action) => (
          <Tooltip
            key={action.text}
            content={action.isSubmit ? submitTooltipText : ''}
            disabled={!action.isSubmit || !submitTooltipText}
          >
            <AnchorButton
              disabled={action.isSubmit ? isSubmitDisabled : action.disabled}
              icon={action.icon}
              intent={action.intent}
              text={action.text}
              data-testid={getTestID(action)}
              onClick={action.isSubmit ? handleSubmit : action.onClick}
            />
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
