import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Loading } from 'src/ui/components/Loading';
import { ConnectionStatus } from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  AzureProvider,
  OktaProvider,
  PingProvider,
  useGetCustomerSsoSettingsQuery,
  useProfileQuery,
} from 'src/ui/graph/types';
import { getConnectionFromCookie } from 'src/ui/utils/cookies/getConnectionFromCookie';
import {
  doAuth0Redirect,
  doCustomerAuthRedirect,
  redirectToUrl,
} from 'src/ui/utils/window';

type TParams = { customer?: string };

export const Login: React.VFC = () => {
  const params = useParams<TParams>();
  const { customer } = params;
  const {
    data: profileData,
    isLoading: profileDataIsLoading,
  } = useProfileQuery(graphQLClient);
  const {
    data: customerAuth,
    isLoading: customerAuthIsLoading,
  } = useGetCustomerSsoSettingsQuery(graphQLClient);

  function handleAuthRedirect(
    customer: string | undefined,
    providers: Array<PingProvider | AzureProvider | OktaProvider | null>,
    providerType: 'azure' | 'ping' | 'okta'
  ): boolean {
    if (
      customer &&
      providers.find(
        (provider) =>
          provider?.customerName.toLowerCase() === customer.toLowerCase()
      )
    ) {
      doCustomerAuthRedirect(customer, providerType);
      return true;
    }
    return false;
  }

  React.useEffect(() => {
    if (
      profileDataIsLoading ||
      !profileData ||
      !profileData.profile ||
      customerAuthIsLoading ||
      !customerAuth ||
      !customerAuth.customerSsoSettings
    ) {
      return;
    }
    const {
      azureProviders,
      pingProviders,
      oktaProviders,
    } = customerAuth.customerSsoSettings;

    const connection = getConnectionFromCookie();

    // If the user is authenticated don't ask then to login again
    // but there is probably an issue with the next param, just redirect
    // to the root application.
    if (
      profileData.profile.is_authenticated &&
      connection.status === ConnectionStatus.CONNECTED
    ) {
      redirectToUrl('/');
      return;
    }
    if (handleAuthRedirect(customer, azureProviders, 'azure')) return;
    if (handleAuthRedirect(customer, pingProviders, 'ping')) return;
    if (handleAuthRedirect(customer, oktaProviders, 'okta')) return;

    doAuth0Redirect();
  }, [
    profileData,
    customer,
    profileDataIsLoading,
    customerAuth,
    customerAuthIsLoading,
  ]);

  return <Loading />;
};
