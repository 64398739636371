import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { usePortalCsrfToken } from 'vet-bones/utils/portal';

import { getConnectionFromCookie } from 'src/ui/utils/cookies/getConnectionFromCookie';
import { redirectToUrl } from 'src/ui/utils/window';

/**
 * Run Application level hooks so we don't force the main App to re-render
 */

export const LaunchpadAppHooks: React.VFC = () => {
  const location = useLocation();
  const isLoginRoute = (location.pathname || '').match(/login/);

  usePortalCsrfToken();

  const connectionCookie = getConnectionFromCookie();
  if (
    !isLoginRoute &&
    connectionCookie.name &&
    !connectionCookie.isLaunchpad &&
    !connectionCookie.isPortal
  ) {
    redirectToUrl('/login');
  }

  return null;
};
