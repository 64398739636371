import { Classes, FormGroup, InputGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useIsMounted } from 'vet-bones/utils/hooks';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import {
  MANAGE_VOICEBOX_APP_UPDATE_DIALOG,
  MANAGE_VOICEBOX_APP_UPDATE_NAME_INPUT,
} from 'src/ui/constants/testIds';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { VoiceboxApp, useUpdateVoiceboxAppMutation } from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import * as copy from 'src/ui/templates/copy';

export const UpdateVoiceboxAppDialog: React.VFC = () => {
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const onMutate = useOnMutate();
  const onError = useOnError();
  const queryClient = useQueryClient();

  const dialog = useAppSelector((state) => state.ui.dialog);
  let isOpen = false;
  let app: VoiceboxApp | undefined;
  if (dialog && dialog.type === DialogType.UPDATE_VOICEBOX_APP && dialog.data) {
    isOpen = true;
    app = dialog.data.app;
  }

  const [name, setName] = React.useState('');
  React.useEffect(() => {
    setName(app?.name || '');
  }, [app]);
  const trimmedName = name.trim();

  const { isLoading, mutate: updateVoiceboxApp } = useUpdateVoiceboxAppMutation(
    graphQLClient,
    {
      onMutate,
      onError,
      onSuccess: async (data) => {
        if (!data.updateVoiceboxApp?.success) {
          return;
        }

        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }
        await queryClient.refetchQueries(['listVoiceboxApps']);
        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }
        dispatch(closedDialog());
      },
    }
  );

  const isSubmitDisabled = !trimmedName || isLoading;

  const handleClose = () => {
    dispatch(closedDialog());
    setName('');
  };

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    if (!app || isSubmitDisabled) {
      return;
    }

    updateVoiceboxApp({
      input: {
        id: app.id,
        name: trimmedName,
      },
    });
  };

  const actions: FormAction[] = [
    {
      text: copy.components.manageApiTokens.voiceboxApps.updateDialog.cancel,
      onClick: () => handleClose(),
    },
    makeSubmitAction(
      copy.components.manageApiTokens.voiceboxApps.updateDialog.submit,
      isLoading
    ),
  ];

  let submitTooltipText = '';
  if (!trimmedName) {
    submitTooltipText =
      copy.components.manageApiTokens.voiceboxConfig.name.required;
  }

  return (
    <Dialog
      className="sd-dialog-update-voicebox-app"
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      icon={IconNames.EDIT}
      isOpen={isOpen}
      onClose={handleClose}
      title={copy.components.manageApiTokens.voiceboxApps.updateDialog.title}
    >
      <div
        className={Classes.DIALOG_BODY}
        data-testid={MANAGE_VOICEBOX_APP_UPDATE_DIALOG}
      >
        <form onSubmit={handleSubmit}>
          <FormGroup
            label={copy.components.manageApiTokens.voiceboxConfig.name.label}
            labelFor="sd-update-voicebox-app-name"
          >
            <InputGroup
              autoFocus
              data-testid={MANAGE_VOICEBOX_APP_UPDATE_NAME_INPUT}
              disabled={isLoading}
              id="sd-update-voicebox-app-name"
              onChange={(evt) => setName(evt.target.value)}
              value={name}
              placeholder={app?.name}
            />
          </FormGroup>
        </form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <FormActions
            actions={actions}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isSubmitDisabled}
            submitTooltipText={submitTooltipText}
          />
        </div>
      </div>
    </Dialog>
  );
};
